/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';

import { clsx } from 'clsx';
import PropTypes from 'prop-types';
import { AiOutlineCheck } from 'react-icons/ai';

function PlanCard({
  type,
  price,
  description,
  cadence,
  features,
  handleCheckout,
  buttonText,
}) {
  return (
    <div className="w-full h-full  bg-white pt-8 shadow-xl flex flex-col">
      <div className="px-16">
        <div>
          <h3
            className={clsx(
              'text-base bg-gray-100 text-gray-500 font-source inline-block font-semibold px-4 py-1 rounded-xl capitalize',
            )}
          >
            {type}
          </h3>
        </div>
        <div className="flex items-end pt-6">
          <h1 className="text-6xl font-semibold font-barlow">{price}</h1>
          <h4 className="text-gray-600 font-semibold text-base ml-1">
            {cadence}
          </h4>
        </div>
        <p className="text-gray-600 font-source text-base mt-4 italic ">
          {description}
        </p>
      </div>
      <div className="flex flex-col h-full px-16 mt-8">
        <div className="w-full h-full pt-6 pb-8 rounded-b-xl flex flex-col space-y-6">
          {React.Children.toArray(
            features.map((ftr) => (
              <div className="flex items-center w-full space-x-4" key={ftr.title}>
                <span className="p-3 bg-shamrock text-white rounded-full">
                  <AiOutlineCheck size={26} />
                </span>
                <div>
                  <h2 className="font-semibold capitalize text-base">
                    {ftr.title}
                  </h2>
                  <p className="text-gray-500 text-sm">{ftr.description}</p>
                </div>
              </div>
            )),
          )}
        </div>
        <button
          type="button"
          onClick={handleCheckout}
          className="btn primary flex items-center justify-center text-white w-full p-3 rounded mb-10"
        >
          {buttonText}
        </button>
      </div>
    </div>
  );
}

PlanCard.defaultProps = {
  type: '',
  price: '',
  description: '',
  features: [],
  handleCheckout: () => { },
  buttonText: 'Get started',
};

PlanCard.propTypes = {
  type: PropTypes.string,
  buttonText: PropTypes.string,
  price: PropTypes.string,
  description: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  cadence: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  features: PropTypes.arrayOf(PropTypes.any),
  handleCheckout: PropTypes.func,
};

export default PlanCard;
