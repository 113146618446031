import { logout } from '../../../config/token';

import {
  AdsIcon,
  Flag,
  HelpIcon,
  LogoutIcon,
  SavedLogo,
  TemplatesLogo,
  ToolsLogo,
  UserIcon,
  WriterLogo,
} from './components/Logo';

export const userItems = [
  {
    icon: UserIcon,
    data: {
      slug: '/settings',
    },
    label: 'Settings',
  },
  {
    icon: LogoutIcon,
    data: {
      slug: '/',
    },
    label: 'Logout',
    onClick: () => logout(),
    disableActive: true,
  },
];

export const menuItems = [
  {
    icon: TemplatesLogo,
    data: {
      slug: '/browse',
    },
    label: 'Templates',
  },
  {
    icon: WriterLogo,
    data: {
      slug: '/writer',
    },
    label: 'Writer',
  },
  {
    icon: AdsIcon,
    data: {
      slug: '/url-to-ads',
    },
    label: 'URL to ads',
  },
  {
    icon: SavedLogo,
    data: {
      slug: '/saved',
    },
    label: 'Saved',
  },
  {
    icon: ToolsLogo,
    data: {
      slug: '/tools',
    },
    label: 'Tools',
  },
  {
    icon: Flag,
    data: val => ({
      slug: `${val}?feedback=show`,
    }),
    disableActive: true,
    label: 'Feedback',
  },
  {
    icon: HelpIcon,
    data: {
      link: 'https://documentation.unbounce.com/hc/en-us/categories/4406903492372?utm_source=webapp&utm_medium=sidebar',
    },
    label: 'Help',
  },
];
