/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React from 'react';

import { clsx } from 'clsx';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import * as FeatherIcons from 'react-icons/fi';
import * as SimpleIcons from 'react-icons/si';
import { FaStar, FaRegStar } from 'react-icons/fa';
import { Loader as Loading } from '@unbounce/ui-components';
import { templateVariants } from '../constants';
import {
  getAvailableTemplates,
  markAsFavorite,
  unmarkAsFavorite,
} from '../actions';

// eslint-disable-next-line react/prop-types
function Anchor({ to, children }) {
  return (
    <a href={to} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
}

function DynamicIcon({ name, ...props }) {
  let SVG = FeatherIcons[name];
  if (!SVG) {
    SVG = SimpleIcons[name];
  }
  if (!SVG) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <FeatherIcons.FiFileText {...props} />;
  }

  return <SVG {...props} />;
}

function TemplateCards({
  template: {
    card_icon,
    slug,
    title,
    description,
    notAvailable,
    label,
    color,
    collapse,
    link,
    is_favorited,
    id,
    // categoryId,
  },
  prefix,
}) {
  // const [isFavorite, setFavorite] = React.useState(is_favorited);
  const [isLoading, setLoading] = React.useState(false);
  const LinkWraper = link ? Anchor : Link;
  return (
    <LinkWraper to={link || `${prefix}${slug}`}>
      <motion.div
        className={clsx(
          'p-6 w-full border relative hover:border-gray-400 group rounded-md h-full',
          notAvailable ? 'cursor-default ' : 'bg-white',
        )}
        variants={templateVariants}
        style={{
          boxShadow: notAvailable ? 'inset 0 2px 4px 0 rgba(0, 0, 0, 0.3)' : '',
          minHeight: collapse ? null : 80,
        }}
      >
        {isLoading && (
          <div className="absolute bg-white bg-opacity-50 top-0 bottom-0 right-0 left-0 flex items-center justify-center z-100">
            <Loading />
          </div>
        )}
        <div className="text-left">
          <div className="flex justify-between items-center">
            <div className="flex space-x-2 items-center ">
              <div className={clsx('p-2 rounded', `bg-${color}-100`)}>
                <DynamicIcon
                  name={card_icon}
                  size={18}
                  className={`p-0 m-0 text-${color}-700`}
                />
              </div>

              <p className="text-base text-xl text-black mr-2 font-barlow leading-tight flex sm:hidden">
                {title}
              </p>
              {collapse && (
                <div className="flex flex-wrap items-center">
                  <p className="text-base text-xl text-black mr-2 font-barlow leading-tight">
                    {title}
                  </p>
                  {label && (
                    <p
                      className="font-semibold bg-gray-400 text-white rounded-lg p-1 w-8 text-center"
                      style={{ fontSize: 8 }}
                    >
                      {label}
                    </p>
                  )}
                </div>
              )}
            </div>
            <button
              type="button"
              onClick={async (e) => {
                e.stopPropagation();
                e.preventDefault();
                // setFavorite(!isFavorite);
                setLoading(true);
                try {
                  if (is_favorited) {
                    await unmarkAsFavorite(id);
                  } else await markAsFavorite(id);
                  await getAvailableTemplates();
                  // for refactor after design enhancement
                  // req.set(V3_TEMPLATES, state => {
                  //   const oldState = state[V3_TEMPLATES];
                  //   const idx = oldState?.findIndex(dt => dt.id === categoryId);

                  //   if (idx > -1) {
                  //     const category = oldState[idx];
                  //     if (Array.isArray(category?.content)) {
                  //       const content = [...category?.content];
                  //       const templateIdx = content?.findIndex(
                  //         dt => dt.id === id
                  //       );

                  //       if (templateIdx > -1) {
                  //         set(
                  //           oldState,
                  //           `${idx}.content.${templateIdx}.is_favorited`,
                  //           !isFavorite
                  //         );
                  //       }
                  //     }
                  //   }
                  //   return oldState;
                  // });
                // eslint-disable-next-line no-empty
                } catch (_e) {
                } finally {
                  setLoading(false);
                }
              }}
              disabled={isLoading}
            >
              {is_favorited ? (
                <FaStar className="text-yellow-400" size={20} />
              ) : (
                <FaRegStar
                  className="text-gray-300 hover:text-yellow-400"
                  size={20}
                />
              )}
            </button>
          </div>

          {!collapse && (
            <>
              <div className="flex items-center mt-8 sm:flex hidden">
                <p className="text-xl text-black mr-2 font-barlow leading-tight ">
                  {title}
                </p>
                {label && (
                  <p
                    className="hidden sm:flex font-semibold text-white bg-shamrock rounded-lg p-1 px-1.5 text-center"
                    style={{ fontSize: 8 }}
                  >
                    {label}
                  </p>
                )}
              </div>
              <p
                className={`text-gray-500 mt-4 font-source font-thin sm:text-base text-sm ${
                  notAvailable ? 'text-lg' : 'text-sm'
                } `}
              >
                {notAvailable ? 'Coming Soon' : description}
              </p>
            </>
          )}
        </div>
      </motion.div>
    </LinkWraper>
  );
}

TemplateCards.defaultProps = {
  prefix: '',
};
TemplateCards.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  template: PropTypes.objectOf(PropTypes.any).isRequired,
  prefix: PropTypes.string,
};
export default TemplateCards;
