import React from 'react';
import { clsx } from 'clsx';
import { ICreditsRadioOption } from './types';

function CreditsRadioOption({
  selected,
  creditsNumber,
  creditsPrice,
  onChangeCredits,
  className,
}: ICreditsRadioOption) {
  return (
    <div className={clsx('text-center mb-4', className || 'w-full')}>
      <input
        type="radio"
        value={creditsNumber}
        name="credits-price"
        className="h-5 w-5 cursor-pointer inline-block"
        checked={selected}
        onChange={onChangeCredits}
        id={`${creditsNumber}-credits-${creditsPrice}`}
      />
      <label
        htmlFor={`${creditsNumber}-credits-${creditsPrice}`}
        className="font-barlow text-2xl ml-2 cursor-pointer text-shamrock"
      >
        {`${creditsNumber} Credits`}
        <span className="font-barlow text-3xl font-bold text-raven">
          &nbsp; ${creditsPrice}
        </span>
      </label>
    </div>
  );
}

export default CreditsRadioOption;
