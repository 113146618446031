/* eslint-disable react/no-array-index-key */
import React from 'react';
// eslint-disable-next-line import/no-cycle
import PageLayout from '../../partials/pagelayout/containers/PageLayout';
import LandingPagesTemplate from '../components/templates/LandingPagesTemplate';
// landingpage
// Landing Page
export default function LandingPages() {
  return (
    <PageLayout template="landing-pages" title="Landing Page">
      {({ contents }) => React.Children.toArray(
        contents.map((content) => (
          <LandingPagesTemplate key={content.content?.id || Math.random()} data={content} />
        )),
      )}
    </PageLayout>
  );
}
