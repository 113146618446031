/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clsx } from 'clsx';
import { addDataLayer } from '../../../helpers';
import { setOpenUpgradeModal } from "../../../../redux-setup/actions/upgradeModal";

function CreditsBar() {
  const dispatch = useDispatch();
  const { remaining_credits, pack_credits } = useSelector(
    (state) => state['PERSONALIZE/DATA'],
  );
  const [unlimitedFlag, setUnlimitedFlag] = useState(false);
  const credits = useMemo(() =>
    pack_credits + remaining_credits,
    [pack_credits, remaining_credits]);
  const widthCredits = useMemo(() => {
    switch (credits) {
      case 0:
        return 'w-3';
      case 1:
        return 'w-1/5';
      case 2:
        return 'w-2/5';
      case 3:
        return 'w-3/5';
      case 4:
        return 'w-4/5';
      case 6:
        return 'w-8/12';
      case 7:
        return 'w-9/12';
      case 8:
        return 'w-10/12';
      case 9:
        return 'w-11/12';
      case 5:
      case 10:
        return 'w-full';
      default:
        return 'w-full';
    }
  }, [credits]);
  const pluralizeCredits = useMemo(() => {
    if (credits === 1) {
      return 'Credit';
    }
    return 'Credits';
  }, [credits]);

  useEffect(() => {
    const timeout = setInterval(() => {
      setUnlimitedFlag((preS) => !preS);
    }, 5000);
    return () => clearInterval(timeout);
  }, []);
  return (
    <button
      type="button"
      className="h-full bg-gallery rounded-2xl relative text-center w-full betterhover:group-hover:bg-klein-blue"
      onClick={() => {
        addDataLayer({
          event: 'free_button_click',
        });
        dispatch(setOpenUpgradeModal(true));
      }}
    >
      <div
        className={clsx(
          'top-0 h-full',
          credits === 5 || credits >= 10 ? 'rounded-2xl' : 'rounded-l-2xl',
          'bg-blue-200 absolute betterhover:group-hover:bg-klein-blue',
          widthCredits,
        )}
      />
      <span className="text-klein-blue text-sm absolute inset-0 leading-6 select-none betterhover:group-hover:text-white">
        {unlimitedFlag
          ? credits === 0 && Math.random() < 0.5
            ? 'Oops We Are Done For Today'
            : 'Click Here To Get Unlimited'
          : `${credits} ${pluralizeCredits} Left`}
      </span>
    </button>
  );
}

export default CreditsBar;
