/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router-dom';

import { ReactComponent as Logo } from '../../../assets/img/UBSmartCopy.svg';
import Loader from '../../common/Loader/Loader';
import CodeVerification from '../CodeVerification';
import StepperRegister from './StepperRegister';
import SignUp from '../SignUp';
import Checkout from '../Checkout';
import { logout } from '../../../config/token';
import { isPaidPlan } from "../../../common/functions";

function RegisterContainer() {
  const isLoading = useSelector((state) => state.isLoading);
  const {
    email: userEmail,
    first_plan,
    first_tenure,
    first_coupon,
    isEmailValidated,
    isDone,
    isPaid,
  } = useSelector((state) => state['PERSONALIZE/DATA']);
  const history = useHistory();
  const { pathname } = useLocation();
  const { plan, tenure } = useParams();
  const [step, setStep] = useState(2);

  useEffect(() => {
    if (pathname.slice(0, 9) === '/register') {
      setStep(1);
    } else if (pathname.slice(0, 19) === '/validate') {
      if (isEmailValidated) {
        history.push('/welcome');
      } else {
        setStep(2);
      }
    } else if (pathname.slice(0, 8) === '/welcome') {
      if (!isEmailValidated && !isDone) {
        history.push('/validate');
      } else {
        setStep(3);
      }
    }
  }, [pathname]);

  return (
    <div className="w-full">
      <section className="h-screen flex">
        <div
          className="w-full px-6 m-auto xl:w-10/12 md:w-11/12 md:px-0 box-border"
          style={{ maxWidth: 600 }}
        >
          {step === 2 && (
            <div className="w-full text-right">
              <button
                type="button"
                className="underline text-ubblue"
                onClick={logout}
              >
                Back to Register
              </button>
            </div>
          )}
          <div className="mt-6 mb-10 flex justify-center">
            <Logo height={40} width="100%" />
          </div>
          <StepperRegister
            step={step}
            paidAccount={isPaidPlan(first_plan)}
          />
          {step === 1 && <SignUp plan={plan} tenure={tenure} />}
          {step === 2 && <CodeVerification userEmail={userEmail} />}
          {step === 3
            && (isPaidPlan(first_plan))
            && isPaid
            && history.push('/personalize')}
          {step === 3
            && (isPaidPlan(first_plan)) && (
              <Checkout first_plan={first_plan} first_tenure={first_tenure} first_coupon={first_coupon} />
            )}
          {step === 3
            && (first_plan === 'starter' || first_plan === 'free' || first_plan === 'cpfree')
            && history.push('/personalize')}
        </div>
      </section>
      {isLoading && <Loader />}
    </div>
  );
}

export default RegisterContainer;
