import React from 'react';
import { ILoginUnbounceProps } from './props';
import UBLoginForm from './UBLoginForm';
import LogoUBSC from './LogoUBSC';

function LoginUnbounce({ email }: ILoginUnbounceProps) {
  return (
    <div className="wrapper clearfix">
      <div className="auth-left-panel">
        <section className="w-full">
          <div
            className="mx-auto my-auto max-w-screen-md mt-12 w-full bg-white px-12 pt-8 pb-16 "
            style={{ maxWidth: 600 }}
          >
            <LogoUBSC />
            <UBLoginForm email={email} />
          </div>
        </section>
      </div>

      <div className="auth-right-panel">
        <iframe
          frameBorder="0"
          height="100%"
          scrolling="no"
          src="https://smartcopy.ubpages.com/login/"
          width="100%"
          title="Login"
        />
      </div>
    </div>
  );
}

export default LoginUnbounce;
