/* eslint-disable camelcase */
import { clsx } from 'clsx';
import { RootStateOrAny, useSelector } from 'react-redux';
import TwoOptionSwitch from '../../../common/TwoOptionSwitch';
import ExampleCard from '../ExampleCard';
import { IFullCardNewProps } from './props';

function FullCardNew({
  className,
  handleCheckout,
  annual,
  handleChangeSwitch,
  onCanceling,
  onReactivateClick,
  onUpgradeDowngrade,
}: IFullCardNewProps) {
  const { current_plan, current_tenure } = useSelector(
    (state: RootStateOrAny) => state['PERSONALIZE/DATA'],
  );

  return (
    <div
      className={clsx(
        'grid grid-cols-5 border boder-usilver bg-pampas rounded-xl',
        className,
      )}
    >
      <div className="col-span-2">
        <TwoOptionSwitch
          rightText="Annual"
          leftText="Monthly"
          rightValue={annual}
          textColor="text-ugray"
          textSelectedColor="text-shamrock"
          wrapperClass="my-10"
          handleChangeSwitch={handleChangeSwitch}
        />
        <ul className="text-center pt-3">
          <li className="mb-4 font-semibold text-sm">45 + Templates</li>
          <li className="mb-4 font-semibold text-sm">30+ Languages</li>
          <li className="mb-4 font-semibold text-sm">Chrome Extension</li>
          <li className="mb-4 font-semibold text-sm">Desktop App</li>

          <li className="mb-4 font-semibold text-sm inline-block">Credits</li>
          <li className="mb-4 font-semibold text-sm">Projects</li>
          <li className="mb-4 font-semibold text-sm">Writer</li>
          <li className="mb-4 font-semibold text-sm">SEO Analysis</li>
          <li className="mb-4 font-semibold text-sm">Support</li>
          <li className="mb-4 font-semibold text-sm">Community</li>
        </ul>
      </div>
      {annual ? (
        <>
          <ExampleCard
            selectedPlan={
              current_plan === 'free' && current_tenure === 'annual'
            }
            className="col-span-1"
            annual
            planName="free"
            priceMonthly="0"
            priceYear="0"
            creditsPerMonth="40 Credits"
            projects="1 Project"
            onCheckout={() => handleCheckout('free', 'annual')}
            currentPlan={current_plan}
            writer="trial"
            creditsTooltip="Up to 10 credits refreshed weekly"
          />
          <ExampleCard
            selectedPlan={
              current_plan === 'essential' && current_tenure === 'annual'
            }
            className="col-span-1"
            selected={current_plan === 'free' || current_plan === 'cpfree'}
            annual
            planName="essential"
            priceMonthly="8"
            priceYear="96"
            creditsPerMonth="200 Credits"
            projects="3 Projects"
            writer="yes"
            onCheckout={() => handleCheckout('essential', 'annual')}
            onCanceling={onCanceling}
            onReactivateClick={onReactivateClick}
            currentPlan={current_plan}
            fromOriginalPrice="15"
            creditsTooltip="200 credits every month"
            onUpgradeDowngrade={() => onUpgradeDowngrade('essential', 'annual')}
          />
          <ExampleCard
            selectedPlan={
              current_plan === 'unlimited' && current_tenure === 'annual'
            }
            className="col-span-1 rounded-r-xl"
            annual
            planName="unlimited"
            priceMonthly="40"
            priceYear="480"
            creditsPerMonth="Unlimited"
            projects="Unlimited"
            writer="yes"
            seo
            support
            onCheckout={() => handleCheckout('unlimited', 'annual')}
            onCanceling={onCanceling}
            onReactivateClick={onReactivateClick}
            currentPlan={current_plan}
            onUpgradeDowngrade={() => onUpgradeDowngrade('unlimited', 'annual')}
          />
        </>
      ) : (
        <>
          <ExampleCard
            selectedPlan={
              current_plan === 'free' && current_tenure === 'monthly'
            }
            className="col-span-1"
            planName="free"
            priceMonthly="0"
            priceYear="0"
            creditsPerMonth="40 Credits"
            projects="1 Project"
            onCheckout={() => handleCheckout('free', 'monthly')}
            currentPlan={current_plan}
            writer="trial"
            creditsTooltip="Up to 10 credits refreshed weekly"
          />
          <ExampleCard
            selectedPlan={
              current_plan === 'essential' && current_tenure === 'monthly'
            }
            className="col-span-1"
            planName="essential"
            priceMonthly="9"
            priceYear="96"
            creditsPerMonth="200 Credits"
            projects="3 Projects"
            selected={current_plan === 'free' || current_plan === 'cpfree'}
            writer="yes"
            onCheckout={() => handleCheckout('essential', 'monthly')}
            onCanceling={onCanceling}
            onReactivateClick={onReactivateClick}
            currentPlan={current_plan}
            fromOriginalPrice="19"
            creditsTooltip="200 credits every month"
            onUpgradeDowngrade={() =>
              onUpgradeDowngrade('essential', 'monthly')
            }
          />
          <ExampleCard
            selectedPlan={
              current_plan === 'unlimited' && current_tenure === 'monthly'
            }
            className="col-span-1 rounded-r-xl"
            planName="unlimited"
            priceMonthly="49"
            creditsPerMonth="Unlimited"
            projects="Unlimited"
            writer="yes"
            seo
            support
            onCheckout={() => handleCheckout('unlimited', 'monthly')}
            onCanceling={onCanceling}
            onReactivateClick={onReactivateClick}
            currentPlan={current_plan}
            onUpgradeDowngrade={() =>
              onUpgradeDowngrade('unlimited', 'monthly')
            }
          />
        </>
      )}
    </div>
  );
}

export default FullCardNew;
