/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import * as FeatherIcons from 'react-icons/fi';
import * as SimpleIcons from 'react-icons/si';

function DynamicIcon({ name, ...props }) {
  let SVG = FeatherIcons[name];
  if (!SVG) {
    SVG = SimpleIcons[name];
  }
  if (!SVG) {
    return <FeatherIcons.FiFileText {...props} />;
  }

  return <SVG {...props} />;
}

export default DynamicIcon;
