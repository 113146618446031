import React, { useState } from 'react';
import InputField from '../../../../../../common/InputField';
import Keywords from '../../../../../../common/Keywords';
import TextField from '../../../../../../common/TextField';
import { IProjectInfoProps } from './props';

function ProjectInfo({
  project,
  setProject,
  disabled,
  setFocusEdit,
}: IProjectInfoProps) {
  const [audience, setAudience] = useState('');
  const [brandKeywords, setBrandKeywords] = useState('');
  const onChangeAudition = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const val = evt.target.value;
    if (val.length <= 50) {
      setAudience(val);
    }
  };
  const onChangeBrandKeywords = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const val = evt.target.value;
    if (val.length < 50) {
      setBrandKeywords(val);
    }
  };

  const addBrandKeyword = () => {
    setProject((prePr) => {
      if (prePr) {
        const brkeys = brandKeywords.trim();
        setBrandKeywords('');
        if (brkeys) {
          return {
            ...prePr,
            brand_keywords: [...prePr.brand_keywords, brkeys],
          };
        } else {
          return prePr;
        }
      } else {
        return null;
      }
    });
  };

  const addAudienceKeyword = () => {
    setProject((prePr) => {
      if (prePr) {
        const audi = audience.trim();
        setAudience('');
        if (audi) {
          return {
            ...prePr,
            audience: [...prePr.audience, audi],
          };
        } else {
          return prePr;
        }
      } else {
        return null;
      }
    });
  };

  const handleRemoveAudience = (indx: number) => {
    setProject((preP) => {
      if (preP) {
        const audience = preP.audience.filter((_val, inx) => inx !== indx);
        return { ...preP, audience: audience };
      }
      return preP;
    });
  };

  const handleRemoveBrandKeywords = (indx: number) => {
    setProject((preP) => {
      if (preP) {
        const brandKeys = preP.brand_keywords.filter(
          (_val, inx) => inx !== indx,
        );
        return { ...preP, brand_keywords: brandKeys };
      }
      return preP;
    });
  };

  const handleChangeName = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const val = evt.target.value;
    setProject((preP) => {
      if (preP && val.length < 76) {
        return { ...preP, name: val };
      }
      return preP;
    });
  };

  const handlePasteName = (evt: React.ClipboardEvent<HTMLInputElement>) => {
    evt.preventDefault();
    let data = evt.clipboardData.getData('text');
    if (data.length > 75) {
      data = data.slice(0, 75);
    }
    setProject((preP) => {
      if (preP) {
        return { ...preP, name: data };
      }
      return preP;
    });
  };

  const handleChangeDescription = (
    evt: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const val = evt.target.value;
    setProject((preP) => {
      if (preP && val.length < 251) {
        return { ...preP, description: val };
      }
      return preP;
    });
  };

  const handlePasteDescription = (
    evt: React.ClipboardEvent<HTMLTextAreaElement>,
  ) => {
    evt.preventDefault();
    let data = evt.clipboardData.getData('text');
    if (data.length > 250) {
      data = data.slice(0, 250);
    }
    setProject((preP) => {
      if (preP) {
        return { ...preP, description: data };
      }
      return preP;
    });
  };

  return (
    <div>
      <InputField
        label="Project / Product / Service Name"
        required
        name="product-service-name"
        placeholder=""
        value={project?.name || ''}
        onChange={handleChangeName}
        error={false}
        wrapperClass="my-2"
        disabled={disabled}
        disabledOnClick={() => {
          setFocusEdit(true);
        }}
        onPaste={handlePasteName}
        maxCounter={75}
        tooltip="The name of your first Project. We will also use it in some of the templates for copy generations."
        classNameTooltip="text-sm ml-1"
        classNameLabel="font-source text-ugray text-sm font-semibold leading-6 text-ugray"
      />
      <TextField
        label="Description"
        required
        name="description"
        placeholder=""
        value={project?.description || ''}
        onChange={handleChangeDescription}
        error={false}
        rows={4}
        wrapperClass="my-2"
        disabled={disabled}
        disabledOnClick={() => {
          setFocusEdit(true);
        }}
        onPaste={handlePasteDescription}
        maxCounter={250}
        tooltip='Describe what your Project is about in a few sentences. Take your time on this, as it will significantly impact the quality of the output.
        For example: "Rid yourself of pain conveniently with our affordable physiotherapy clinics. Insurance Reimbursable.'
        classNameTooltip="text-sm ml-1"
        classNameLabel="font-source text-ugray text-sm font-semibold leading-6 text-ugray"
      />
      <Keywords
        label="Audience"
        required
        keywords={project?.audience || []}
        wrapperClass="my-2"
        onChangeInput={onChangeAudition}
        inputValue={audience}
        name="audience"
        addKeyword={addAudienceKeyword}
        handleRemoveKeyword={handleRemoveAudience}
        disabled={disabled}
        disabledOnClick={() => {
          setFocusEdit(true);
        }}
        maxInput={25}
        tooltip="Add keywords that best describe the intended target group for your content. For example, based on demographics (millennials, gen z), types of users (consumers, business owners) or something entirely different (industrial supplies purchasers)."
        classNameTooltip="text-sm ml-1"
        classNameLabel="font-source text-ugray text-sm font-semibold leading-6 text-ugray"
        onPasteInput={(evt) => {
          evt.preventDefault();
          const data = evt.clipboardData
            .getData('text')
            .trim()
            .substring(0, 50);
          setAudience(data);
        }}
      />
      <Keywords
        wrapperClass="my-2"
        label="Keywords"
        required
        keywords={project?.brand_keywords || []}
        onChangeInput={onChangeBrandKeywords}
        inputValue={brandKeywords}
        name="keywords"
        addKeyword={addBrandKeyword}
        handleRemoveKeyword={handleRemoveBrandKeywords}
        disabled={disabled}
        disabledOnClick={() => {
          setFocusEdit(true);
        }}
        maxInput={25}
        tooltip="Add keywords to describe your product, service, or brand. They can be keywords and phrases that people may be looking for or the names of products/services you offer."
        classNameTooltip="text-sm ml-1"
        classNameLabel="font-source text-ugray text-sm font-semibold leading-6 text-ugray"
        onPasteInput={(evt) => {
          evt.preventDefault();
          const data = evt.clipboardData
            .getData('text')
            .trim()
            .substring(0, 50);
          setBrandKeywords(data);
        }}
      />
    </div>
  );
}

export default ProjectInfo;
