import { getToken } from '../config/token';
import {
  billingPortalURL,
  collectionsURL,
  couponInfoURL,
  getProjectsFromCollectionURL,
  obtainRequestURL,
  obtainSubscriptionIDURL,
  obtainSubscriptionsURL,
  projectURL,
  singleProjectURL,
} from './URLS';

const sendFetch = (url = '') =>
  fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
      'Access-Control-Allow-Origin': '*',
    },
  });

// eslint-disable-next-line import/prefer-default-export
export const getSubscriptionsByUser = (customer) =>
  sendFetch(`${obtainSubscriptionsURL()}/${customer}`);

export const getTemplateResults = (id) => sendFetch(obtainRequestURL(id));

export const getBillingPortalURL = () => sendFetch(billingPortalURL());

export const getCouponInfo = (id) => sendFetch(couponInfoURL(id));

export const getStripeSubscriptionID = () =>
  sendFetch(obtainSubscriptionIDURL());
export const getUserCollections = () => sendFetch(collectionsURL());
export const getSingleProject = (id) => sendFetch(singleProjectURL(id));
export const getDefaultProject = () => sendFetch(projectURL());
export const getProjectsFromCollection = (id) =>
  sendFetch(getProjectsFromCollectionURL(id));
