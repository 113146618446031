import { Modal } from 'react-responsive-modal';
import { useHistory } from 'react-router-dom';
import { RootStateOrAny } from 'react-redux';
import IWriterModalProps from './props';
import writerVid from '../../../assets/img/Writer.mp4';
import Button from '../../../common/Button';
import { sendRequestWriterTrial } from '../../../common/sendInfo';
import req from '../../../redux-setup';
import { PERSONALIZE } from '../../personalize/constants';

function WriterModal({ open, setOpen, trialFinish }: IWriterModalProps) {
  const history = useHistory();
  const requestWriterTrial = () => {
    sendRequestWriterTrial()
      .then((response) => response.json())
      .then((result) => {
        if (result.writer_trial_start) {
          // we need to update the date to the result on redux
          req.set(PERSONALIZE, (state: RootStateOrAny) => ({
            ...state[PERSONALIZE],
            writer_trial_start: result.writer_trial_start,
          }));
        }
      })
      .catch((err) => console.log('error', err));
  };
  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
        history.push('/browse');
      }}
      center
      styles={{ modal: { maxWidth: '680px', borderRadius: '10px' } }}
    >
      <div className="m-10">
        <h1 className="font-medium text-center font-barlow text-2xl">
          Introducing Writer
        </h1>
        <video width="100%" height="600" autoPlay loop muted playsInline>
          <source src={writerVid} type="video/mp4" />
          Your browser does not support HTML video.
        </video>
        <h3 className="font-semibold">Writer is a page that writes itself.</h3>
        <p className="mt-6">
          Seriously. With minimal inputs, you can generate sentences,
          paragraphs, and whole stories in seconds. Write any kind of long-form
          content on different topics, with expert level understanding.
        </p>
        <div className="flex justify-between my-6 gap-x-4">
          <Button
            className="w-1/2"
            onClick={requestWriterTrial}
            type="primary"
            disabled={trialFinish}
          >
            Try It For 3 Days
          </Button>
          <Button
            className="w-1/2"
            type="primary"
            onClick={() => history.push('/plan')}
          >
            Upgrade For Unlimited Access
          </Button>
        </div>
        <p className="text-ubblue text-center font-semibold">
          {trialFinish
            ? 'Thank you for trying out Writer, we hope you loved it as much as we do. To continue using Writer, upgrade to a higher plan.'
            : 'Take Writer for a spin for a 3-day trial. Use the credits from your account to explore this awesome feature.'}
        </p>
      </div>
    </Modal>
  );
}

export default WriterModal;
