/* eslint-disable react/jsx-no-constructed-context-values */
import React from 'react';
import { V3_TEMPLATES } from '../../templates/actions';
import { useApiData } from '../../../redux-setup';
import { useTemplateSidebar } from '../context';
import WriterTemplateDetails from './WriterTemplateDetails';
import WriterTemplateLayout from './WriterTemplateLayout';

function WriterTemplatesList() {
  const { viewProfile } = useTemplateSidebar();
  const templateData = useApiData(V3_TEMPLATES, []);

  const recommended = [];

  return (
    <div className="flex flex-col relative flex-grow">
      <div className="absolute top-0 left-0 right-0 bottom-0 overflow-y-auto pr-5">
        {viewProfile ? (
          <WriterTemplateLayout recommended={recommended} />
        ) : (
          <>
            {recommended?.length > 0 &&
              React.Children.toArray(
                <div className="flex flex-col space-y-3 mb-5">
                  <p className="text-lg font-barlow font-medium">Recommended</p>
                  {recommended.map((content) => (
                    <WriterTemplateDetails
                      key={content.id}
                      data={{
                        category: 'Recommended',
                        ...content,
                      }}
                    />
                  ))}
                </div>,
              )}
            {React.Children.toArray(
              templateData
                .filter(
                  (td) =>
                    td.name !== 'Everyday Tools' &&
                    td.name !== 'Fresh Templates',
                )
                .map((section) => (
                  <div
                    className="flex flex-col space-y-3 mb-5"
                    key={section.id}
                  >
                    <p className="text-lg font-barlow font-medium">
                      {section?.name}
                    </p>
                    {section?.content?.map((content) =>
                      content.name !== 'Writer' && content.name !== 'writer' ? (
                        <WriterTemplateDetails
                          key={content.id}
                          data={{
                            category: section?.name,
                            ...content,
                          }}
                        />
                      ) : null,
                    )}
                  </div>
                )),
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default WriterTemplatesList;
