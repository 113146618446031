/* eslint-disable react/jsx-key */
/* eslint-disable react/no-array-index-key */
import React from 'react';

import PropTypes from 'prop-types';
import ContentEditable from 'react-contenteditable';
import { AiOutlineThunderbolt } from 'react-icons/ai';
import { FaFacebookF, FaTwitter, FaInstagramSquare } from 'react-icons/fa';
import { FiGlobe } from 'react-icons/fi';
import { RiScales3Line } from 'react-icons/ri';

import useTemplateState from '../../../../hooks/useTemplateState';
import { editTemplate } from '../../../savedcontent/actions';

import TemplateFooter from '../footer/TemplateFooter';

const featureIcons = [
  <FiGlobe size={22} />,
  <RiScales3Line size={22} />,
  <AiOutlineThunderbolt size={22} />,
];
function LandingPagesTemplate({ data, onEditMode, hideFooter }) {
  const { templateValue, handleOnChange } = useTemplateState(data);
  const { headline, description, button, benefit, features, companyName } =
    templateValue.content;

  // landing
  const landingHeadline = (
    <h3 className="text-center text-xl sm:text-2xl mxl:text-3xl mxl:text-4xl font-extrabold font-semibold text-black">
      <ContentEditable
        html={headline || ''}
        onBlur={({ target: { innerHTML } }) =>
          editTemplate(innerHTML, data, 'content.headline')
        }
        onChange={(e) => handleOnChange(e.target.value, 'content.headline')}
        spellCheck={false}
        disabled={!onEditMode}
      />
    </h3>
  );

  const landingDescription = (
    <div className="mt-4 text-gray-600 mt-3 max-w-md mx-auto text-center text-base sm:text-lg md:mt-5 mxl:text-xl md:max-w-3xl">
      <ContentEditable
        disabled={!onEditMode}
        html={description || ''}
        onBlur={({ target: { innerHTML } }) =>
          editTemplate(innerHTML, data, 'content.description')
        }
        onChange={(e) => handleOnChange(e.target.value, 'content.description')}
        spellCheck={false}
      />
    </div>
  );

  const landingContentNoPhotos = (
    <div className="mb-6">
      <div className="flex justify-between items-center">
        <h1 className="text-base sm:text-lg xlg:text-xl text-gray-600">
          {companyName || '-'}
        </h1>
        <p className="text-gray-600 text-sm sm:text-base">{button}</p>
      </div>
      <div className="mt-12 flex flex-col justify-center items-center">
        {landingHeadline}
        {landingDescription}
        <div className="flex justify-center items-center gap-x-4 mt-4 w-full">
          <button
            type="button"
            className="cursor-default rounded-md shadow flex items-center justify-center px-4 py-2 border border-transparent text-xs sm:text-sm xlg:text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
          >
            Get Started
          </button>
          <button
            type="button"
            className="cursor-default rounded-md shadow flex items-center justify-center px-4 py-2  border border-transparent text-xs sm:text-sm xlg:text-base  font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-5"
          >
            Learn More
          </button>
        </div>
      </div>
    </div>
  );

  // features

  const featuresContent = (
    <div className="mt-16 grid grid-cols-1 md:grid-cols-3 gap-y-4 gap-x-6">
      {features &&
        React.Children.toArray(
          features.map((feature, key) => (
            <div key={key}>
              <div className="flex items-center justify-center h-8 w-8 xlg:h-10 xlg:w-10 rounded-md bg-indigo-500 text-white">
                {featureIcons[key]}
              </div>
              <div className="mt-5">
                <dt className="text-sm sm:text-lg leading-6 font-medium text-gray-900">
                  <ContentEditable
                    disabled={!onEditMode}
                    html={feature.headline || ''}
                    onBlur={({ target: { innerHTML } }) =>
                      editTemplate(
                        innerHTML,
                        data,
                        `content.features.${key}.headline`,
                      )
                    }
                    onChange={(e) =>
                      handleOnChange(
                        e.target.value,
                        `content.features.${key}.headline`,
                      )
                    }
                    spellCheck={false}
                  />
                </dt>
                <dd className="mt-2 text-xs sm:text-sm text-gray-500">
                  <ContentEditable
                    disabled={!onEditMode}
                    html={feature.description || ''}
                    onBlur={({ target: { innerHTML } }) =>
                      editTemplate(
                        innerHTML,
                        data,
                        `content.features.${key}.description`,
                      )
                    }
                    onChange={(e) =>
                      handleOnChange(
                        e.target.value,
                        `content.features.${key}.description`,
                      )
                    }
                    spellCheck={false}
                  />
                </dd>
              </div>
            </div>
          )),
        )}
    </div>
  );

  const benefitNoPhotos = (
    <div className="bg-indigo-700 mt-6">
      <div className="max-w-2xl mx-auto text-center py-8 px-4 sm:px-6 lg:px-8">
        <h2 className="text-xl xlg:text-2xl font-extrabold text-white ">
          <ContentEditable
            html={(benefit && benefit.headline) || ''}
            disabled={!onEditMode}
            onBlur={({ target: { innerHTML } }) =>
              editTemplate(innerHTML, data, 'content.benefit.headline')
            }
            onChange={(e) =>
              handleOnChange(e.target.value, 'content.benefit.headline')
            }
            spellCheck={false}
          />
        </h2>
        <div className="mt-4 text-base xlg:text-lg leading-6 text-indigo-200">
          <ContentEditable
            html={(benefit && benefit.description) || ''}
            disabled={!onEditMode}
            onBlur={({ target: { innerHTML } }) =>
              editTemplate(innerHTML, data, 'content.benefit.description')
            }
            onChange={(e) =>
              handleOnChange(e.target.value, 'content.benefit.description')
            }
            spellCheck={false}
          />
        </div>
        <button
          type="button"
          className="cursor-default mt-6 mx-auto rounded-md shadow flex items-center justify-center px-4 py-2  border border-transparent text-xs sm:text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-5"
        >
          Learn More
        </button>
      </div>
    </div>
  );

  return (
    <div
      style={{
        position: 'relative',
        marginBottom: 25,
      }}
    >
      <div
        className="bg-white mb-2 shadow-md font-source"
        style={{
          boxShadow: '0px 4px 18px rgba(0, 0, 0, 0.06)',
          borderRadius: '16px',
        }}
      >
        <div className="py-6 px-8">
          {landingContentNoPhotos}
          {featuresContent}
        </div>
        {benefitNoPhotos}
        <footer className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-y-4 gap-x-8 px-6 py-8 text-sm text-gray-500 w-full justify-center">
          <ul className="flex flex-col gap-y-4">
            <li className="text-gray-300 font-semibold uppercase truncate">
              {companyName || 'My Company'}
            </li>
            <li
              className="overflow-hidden ellipsis"
              style={{
                maxHeight: 150,
              }}
            >
              {description || 'Description'}
            </li>
            <li className="flex items-center">
              <FaFacebookF size={16} className="mr-2" />
              <FaInstagramSquare size={16} className="mr-2" />
              <FaTwitter size={16} className="mr-2" />
            </li>
          </ul>
          <ul className="flex flex-col gap-y-2">
            <li className="text-gray-300 font-semibold uppercase truncate">
              Solutions
            </li>
            <li>Marketing</li>
            <li>Analytics</li>
            <li>Commerce</li>
            <li>Insights</li>
          </ul>
          <ul className="flex flex-col gap-y-2">
            <li className="text-gray-300 font-semibold uppercase truncate">
              Support
            </li>
            <li>Pricing</li>
            <li>Documentation</li>
            <li>Guides</li>
            <li>API Status</li>
          </ul>
          <ul className="flex flex-col gap-y-2">
            <li className="text-gray-300 font-semibold uppercase truncate">
              Company
            </li>
            <li>About</li>
            <li>Blog</li>
            <li>Jobs</li>
            <li>Press</li>
            <li>Partners</li>
          </ul>
          <ul className="flex flex-col gap-y-2">
            <li className="text-gray-300 font-semibold uppercase truncate">
              Legal
            </li>
            <li>Claim</li>
            <li>Privacy</li>
            <li>Terms</li>
          </ul>
        </footer>
      </div>
      {!hideFooter && (
        <TemplateFooter
          data={templateValue}
          onEditMode={onEditMode}
          handleOnChange={handleOnChange}
          type="landing-pages"
        />
      )}
    </div>
  );
}

LandingPagesTemplate.defaultProps = {
  data: {},
  onEditMode: false,
  hideFooter: false,
};

LandingPagesTemplate.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.objectOf(PropTypes.any),
  onEditMode: PropTypes.bool,
  hideFooter: PropTypes.bool,
};
export default LandingPagesTemplate;
