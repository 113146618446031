// eslint-disable-next-line import/no-cycle
import { getToken } from '../config/token';
import { setLoading } from '../redux-setup/actions/loading';
import store from '../redux-setup/store';
import {
  cancelCancelationURL,
  cancelSubscriptionURL,
  checkoutSessionURL,
  collectionsURL,
  copiedResultURL,
  createRequestURL,
  creditsCheckoutURL,
  downgradeToEssentialURL,
  insertValueURL,
  logoutURL,
  projectDuplicateURL,
  projectMoveURL,
  projectURL,
  updateAddressURL,
  updateSubscriptionURL,
  validateEmailURL,
  verifiedUBCompURL,
  voidInvoiceURL,
  writerTrialURL,
} from './URLS';

const sendFetch = (url = '', data = {}) =>
  fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
      'Access-Control-Allow-Origin': '*',
    },
    body: JSON.stringify(data),
  });

export const sendPost = async (url, payload, loading = true) => {
  if (loading) {
    store.dispatch(setLoading(true));
  }
  try {
    const response = await sendFetch(url, payload);
    if (response.status === 200) {
      const result = await response.json();
      if (loading) {
        store.dispatch(setLoading(false));
      }
      return { data: result.data };
    } else {
      if (response.status === 401) {
        sessionStorage.clear();
        localStorage.clear();
        window.location.href = '/';
        if (loading) {
          store.dispatch(setLoading(false));
        }
      } else {
        const result = await response.json();
        if (loading) {
          store.dispatch(setLoading(false));
        }
        return { data: null, message: result.message };
      }
    }
  } catch (e) {
    if (loading) {
      store.dispatch(setLoading(false));
    }
    return {
      data: null,
      message:
        e.toString() === 'TypeError: Failed to fetch' ? (
          <span>
            Our System hit a snag, please email us at{' '}
            <a
              href="mailto:smartcopy-help@unbounce.com?subject=Smart%20Copy%20Support"
              className="font-semibold underline"
            >
              smartcopy-help@unbounce.com
            </a>
          </span>
        ) : (
          e.toString()
        ),
    };
  }
};

export const sendZapierError = (data) =>
  fetch('https://hooks.zapier.com/hooks/catch/5491237/bsxk655/', {
    method: 'POST',
    body: JSON.stringify(data),
  });

export const postValidateEmail = (body) => sendFetch(validateEmailURL(), body);
export const postLogout = () => sendFetch(logoutURL());
export const sendCopied = (body) => sendFetch(copiedResultURL(), body);
export const sendRequestWriterTrial = () => sendFetch(writerTrialURL());
export const requestCheckoutSesion = (body) =>
  sendFetch(checkoutSessionURL(), body);
export const requestTemplate = (body) => sendFetch(createRequestURL(), body);
export const cancelSubscription = (body) =>
  sendFetch(cancelSubscriptionURL(), body);
export const cancelCancelation = (body) =>
  sendFetch(cancelCancelationURL(), body);
export const downgradeToEssential = (body) =>
  sendFetch(downgradeToEssentialURL(), body);
export const updateSubscription = (body) =>
  sendFetch(updateSubscriptionURL(), body);
export const requestCreditsCheckout = (body) =>
  sendFetch(creditsCheckoutURL(), body);
export const updateStripeAddress = (body) =>
  sendFetch(updateAddressURL(), body);
export const voidStripeInvoice = (body) => sendFetch(voidInvoiceURL(), body);
export const createColletion = (body) => sendFetch(collectionsURL(), body);
export const createProject = (body) => sendFetch(projectURL(), body);
export const moveProjectTo = (body) => sendFetch(projectMoveURL(), body);
export const duplicateProjectTo = (body) =>
  sendFetch(projectDuplicateURL(), body);
export const sendInserted = (body) => sendFetch(insertValueURL(), body);
export const sendUBCompEmail = (body) => sendFetch(verifiedUBCompURL(), body);
