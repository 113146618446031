import React from 'react';
import { Modal as RRModal } from 'react-responsive-modal';
import { IModalProps } from './props';
const closeIcon = (
  <svg
    width="28"
    height="28"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.15234 6.15234C6.46875 5.83594 6.99609 5.83594 7.3125 6.15234L8.96484 7.83984L10.6523 6.15234C10.9688 5.83594 11.4961 5.83594 11.8125 6.15234C12.1641 6.50391 12.1641 7.03125 11.8125 7.34766L10.1602 9L11.8125 10.6523C12.1641 11.0039 12.1641 11.5312 11.8125 11.8477C11.4961 12.1992 10.9688 12.1992 10.6523 11.8477L8.96484 10.1953L7.3125 11.8477C6.99609 12.1992 6.46875 12.1992 6.15234 11.8477C5.80078 11.5312 5.80078 11.0039 6.15234 10.6523L7.80469 9L6.15234 7.34766C5.80078 7.03125 5.80078 6.50391 6.15234 6.15234ZM18 9C18 13.9922 13.957 18 9 18C4.00781 18 0 13.9922 0 9C0 4.04297 4.00781 0 9 0C13.957 0 18 4.04297 18 9ZM9 1.6875C4.95703 1.6875 1.6875 4.99219 1.6875 9C1.6875 13.043 4.95703 16.3125 9 16.3125C13.0078 16.3125 16.3125 13.043 16.3125 9C16.3125 4.99219 13.0078 1.6875 9 1.6875Z"
      fill="black"
    />
  </svg>
);

function Modal({
  isOpen,
  onCloseModal,
  children,
  showHeader,
  title,
  minWidth = 300,
}: IModalProps) {
  return (
    <RRModal
      closeIcon={closeIcon}
      styles={{
        modal: { minWidth, borderRadius: '10px', padding: '1rem' },
      }}
      open={isOpen}
      onClose={onCloseModal}
      center
    >
      {/* header */}
      {showHeader && (
        <div className="flex justify-between items-center border-b border-b-gray-400 p-4">
          <p className="text-ubblack font-semibold text-xl font-barlow">
            {title}
          </p>
        </div>
      )}
      {/* body */}
      <div className="p-4">{children}</div>
    </RRModal>
  );
}

export default Modal;
