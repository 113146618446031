/* eslint-disable react/no-array-index-key */
import React from 'react';
// eslint-disable-next-line import/no-cycle
import PageLayout from '../../../partials/pagelayout/containers/PageLayout';
import WebsiteFeatureTemplate from '../../components/templates/WebsiteFeatureTemplate';

function WebsiteFeature() {
  return (
    <PageLayout template="features" title="Features">
      {({ contents }) => (
        <div
          className="grid grid-cols-1 lg:grid-cols-2 w-full"
          style={{
            columnGap: 48,
          }}
        >
          {React.Children.toArray(
            contents.map((content) => (
              <WebsiteFeatureTemplate key={content.id || Math.random()} data={content} />
            )),
          )}
        </div>
      )}
    </PageLayout>
  );
}
export default WebsiteFeature;
