import React, { useEffect, useMemo } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { logout } from '../../../config/token';
import Loader from '../../common/Loader/Loader';
import { sendUBCompEmail } from '../../../common/sendInfo';
import { isFramed } from '../../helpers';

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

function SecuredUnbounceRoute() {
  const history = useHistory();
  const emailRedux = useSelector(
    (state: RootStateOrAny) => state['PERSONALIZE/DATA'].email,
  );
  const query = useQuery();
  const email = query.get('email');
  const ubid = query.get('ubid');
  const test = query.get('trial') === 'true';

  // we need to look with the email to te page and know the result there
  useEffect(() => {
    if (!isFramed()) {
      history.push('/');
    } else {
      sessionStorage.setItem('ube', email || '');
      sessionStorage.setItem('ubi', ubid || '');
      sessionStorage.setItem('ubt', test.toString() || '');
      if (email !== emailRedux) {
        logout();
      } else if (email) {
        sendUBCompEmail({ email, ub: ubid, trial: test })
          .then((response) => response.json())
          .then(() => {
            history.push('/unbouncer');
          })
          .catch(() => {
            history.push('/unbouncer');
          });
      }
    }
  }, []);

  return (
    <div>
      <Loader />
    </div>
  );
}

export default SecuredUnbounceRoute;
