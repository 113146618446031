/* eslint-disable react/no-array-index-key */
import React from 'react';

import { clsx } from 'clsx';
import PropTypes from 'prop-types';
import ContentEditable from 'react-contenteditable';

import useTemplateState from '../../../../hooks/useTemplateState';
import { editTemplate } from '../../../savedcontent/actions';

import TemplateFooter from '../footer/TemplateFooter';

function ContentExpanderTemplate({ data, onEditMode, hideFooter }) {
  const { templateValue, handleOnChange } = useTemplateState(data);
  return (
    <div
      style={{
        position: 'relative',
        marginBottom: 25,
      }}
    >
      <div
        className="bg-white p-4 mb-2 shadow-md font-source"
        style={{
          boxShadow: '0px 4px 18px rgba(0, 0, 0, 0.06)',
          borderRadius: '20px',
        }}
      >
        <div className="text-md text-gray-600">
          {React.Children.toArray(
            (Array.isArray(templateValue?.content)
              ? // eslint-disable-next-line no-unsafe-optional-chaining
                templateValue?.content
              : []
            ).map((val, index) => (
              <div
                key={index}
                className={clsx('text-xs sm:text-base', index > 0 && 'mt-4')}
              >
                <ContentEditable
                  html={val}
                  disabled={!onEditMode}
                  onBlur={({ target: { innerHTML } }) =>
                    editTemplate(innerHTML, data, `content.${index}`)
                  }
                  onChange={(e) =>
                    handleOnChange(e.target.value, `content.${index}`)
                  }
                  spellCheck={false}
                />
              </div>
            )),
          )}
        </div>
      </div>
      {!hideFooter && (
        <TemplateFooter
          data={templateValue}
          onEditMode={onEditMode}
          handleOnChange={handleOnChange}
          type="content-expander"
        />
      )}
    </div>
  );
}

ContentExpanderTemplate.defaultProps = {
  data: {},
  onEditMode: false,
  hideFooter: false,
};
ContentExpanderTemplate.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.objectOf(PropTypes.any),
  onEditMode: PropTypes.bool,
  hideFooter: PropTypes.bool,
};
export default ContentExpanderTemplate;
