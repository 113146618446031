import { RootStateOrAny, useSelector } from 'react-redux';
import { clsx } from 'clsx';
import ExampleCard from '../ExampleCard';
import ExampleCardText from '../ExampleCardText';
import { IHalfCardNewProps } from './props';

function HalfCardNew({
  className,
  annual,
  handleCheckout,
  onCanceling,
  onReactivateClick,
  onUpgradeDowngrade,
}: IHalfCardNewProps) {
  const { current_plan, current_tenure } = useSelector(
    (state: RootStateOrAny) => state['PERSONALIZE/DATA'],
  );

  return (
    <div
      className={clsx(
        'grid grid-cols-2 border boder-usilver bg-pampas rounded-xl',
        className,
      )}
    >
      <ExampleCardText
        annual={annual}
        selectedPlan={
          current_plan === 'essential' &&
          ((annual && current_tenure === 'annual') ||
            (!annual && current_tenure === 'monthly'))
        }
        className="col-span-1 rounded-l-xl"
        planName="essential"
        priceMonthly={annual ? '8' : '9'}
        priceYear={'96'}
        creditsPerMonth="200 Credits"
        projects="3 Projects"
        writer
        onCheckout={() =>
          handleCheckout('essential', annual ? 'annual' : 'monthly')
        }
        fromOriginalPrice={annual ? '15' : '19'}
        creditsTooltip="200 credits every month"
        currentPlan={current_plan}
        onCanceling={onCanceling}
        onReactivateClick={onReactivateClick}
        onUpgradeDowngrade={() =>
          onUpgradeDowngrade('essential', annual ? 'annual' : 'monthly')
        }
      />
      <ExampleCard
        selected
        annual={annual}
        selectedPlan={
          current_plan === 'unlimited' &&
          ((annual && current_tenure === 'annual') ||
            (!annual && current_tenure === 'monthly'))
        }
        className="col-span-1"
        planName="unlimited"
        priceMonthly={annual ? '40' : '49'}
        priceYear="480"
        creditsPerMonth="Unlimited"
        projects="Unlimited"
        writer="yes"
        seo
        support
        onCheckout={() =>
          handleCheckout('unlimited', annual ? 'annual' : 'monthly')
        }
        onCanceling={onCanceling}
        onReactivateClick={onReactivateClick}
        currentPlan={current_plan}
        onUpgradeDowngrade={() =>
          onUpgradeDowngrade('unlimited', annual ? 'annual' : 'monthly')
        }
      />
    </div>
  );
}

export default HalfCardNew;
