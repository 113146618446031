import React from 'react';

function SkeletonLongText() {
  return (
    <div className="mb-4">
      <div className="border border-gallery bg-white py-4 px-6 mb-3 shadow-md font-source rounded-lg">
        <div className="font-semibold text-xl">
          <div role="status" className="w-full animate-pulse">
            <div className="h-2 bg-gray-200 rounded-full max-w-[90%] mb-2.5" />
            <div className="h-2 bg-gray-200 rounded-full mb-2.5" />
            <div className="h-2 bg-gray-200 rounded-full max-w-[85%] mb-2.5" />
            <div className="h-2 bg-gray-200 rounded-full max-w-[95%] mb-2.5" />
            <div className="h-2 bg-gray-200 rounded-full mb-2.5" />
            <div className="h-2 bg-gray-200 rounded-full max-w-[95%] mb-2.5" />
            <div className="h-2 bg-gray-200 rounded-full max-w-[90%] mb-2.5" />
            <div className="h-2 bg-gray-200 rounded-full mb-2.5" />
            <div className="h-2 bg-gray-200 rounded-full max-w-[90%] mb-2.5" />
            <br />
            <div className="h-2 bg-gray-200 rounded-full max-w-[95%] mb-2.5" />
            <div className="h-2 bg-gray-200 rounded-full mb-2.5" />
            <div className="h-2 bg-gray-200 rounded-full max-w-[90%] mb-2.5" />
            <div className="h-2 bg-gray-200 rounded-full max-w-[95%] mb-2.5" />
            <div className="h-2 bg-gray-200 rounded-full max-w-[85%] mb-2.5" />
            <div className="h-2 bg-gray-200 rounded-full max-w-[97%] mb-2.5" />
            <div className="h-2 bg-gray-200 rounded-full max-w-[93%] mb-2.5" />
            <div className="h-2 bg-gray-200 rounded-full mb-2.5" />
            <div className="h-2 bg-gray-200 rounded-full max-w-[80%] mb-2.5" />
            <br />
            <div className="h-2 bg-gray-200 rounded-full max-w-[98%] mb-2.5" />
            <div className="h-2 bg-gray-200 rounded-full max-w-[88%] mb-2.5" />
            <div className="h-2 bg-gray-200 rounded-full max-w-[95%] mb-2.5" />
            <div className="h-2 bg-gray-200 rounded-full max-w-[99%] mb-2.5" />
            <div className="h-2 bg-gray-200 rounded-full max-w-[92%] mb-2.5" />
            <div className="h-2 bg-gray-200 rounded-full max-w-[87%] mb-2.5" />
            <div className="h-2 bg-gray-200 rounded-full max-w-[95%] mb-2.5" />
            <div className="h-2 bg-gray-200 rounded-full mb-2.5" />
            <div className="h-2 bg-gray-200 rounded-full max-w-[93%] mb-2.5" />
            <div className="h-2 bg-gray-200 rounded-full max-w-[80%] mb-2.5" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SkeletonLongText;
