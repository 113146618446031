import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js';
import { Modal } from 'react-responsive-modal';
import LogoB from '../../../../../assets/img/UBSmartCopy-short.svg';
import Button from '../../../../../common/Button';
import InputField from '../../../../../common/InputField';
import SelectForm from '../../../../../common/SelectForm';
import CheckoutForm from '../CheckoutForm';
import { ICreditsModalProps } from './props';

const stripeKey = process.env.REACT_APP_STRIPE_KEY || '';

const stripePromise = loadStripe(stripeKey);

function CreditsModal({
  openPayModal,
  onClosePayModal,
  step,
  clientSecret,
  countryOptions,
  stateOptions,
  formAddress,
  handleChangeAddressInput,
  onChangeCountry,
  onChangeState,
  onClickSendAddress,
  stateDefault,
  countryDefault,
  stateKey,
  creditsObj,
}: ICreditsModalProps) {
  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  } as StripeElementsOptions;

  return (
    <Modal
      open={openPayModal}
      onClose={onClosePayModal}
      center
      styles={{
        modal: {
          paddingLeft: '2.25rem',
          paddingRight: '2.25rem',
          borderRadius: '0.5rem',
        },
      }}
    >
      <img src={LogoB} className="h-8 mx-auto" />
      <h3 className="font-barlow font-medium text-center text-tundora mt-4">
        Top up with{' '}
        <span className="text-shamrock font-barlow">
          {creditsObj.filter((cre) => cre.selected)[0].numberCredits} credits
        </span>{' '}
        and continue writing
      </h3>
      {step === 1 ? (
        <div>
          <h3 className="text-sm mt-4 text-ugray text-center">
            Please fill out your billing address.
          </h3>
          <SelectForm
            label="Country"
            options={countryOptions}
            onChangeSelect={onChangeCountry}
            required
            defaultValue={countryDefault}
          />
          <SelectForm
            label="State"
            options={stateOptions}
            onChangeSelect={onChangeState}
            required
            defaultValue={stateDefault}
            key={stateKey}
          />
          <InputField
            label="City"
            name="line1"
            placeholder="City"
            value={formAddress?.city}
            onChange={(e) => handleChangeAddressInput(e, 'city')}
            error={false}
            required
          />
          <InputField
            label="Address Line 1"
            name="line1"
            placeholder="Adress Line 1"
            value={formAddress?.line1}
            onChange={(e) => handleChangeAddressInput(e, 'line1')}
            error={false}
            required
          />
          <InputField
            label="Address Line 2"
            name="line1"
            placeholder="Adress Line 2"
            value={formAddress?.line2}
            onChange={(e) => handleChangeAddressInput(e, 'line2')}
            error={false}
          />
          <InputField
            label="Postal Code"
            name="line1"
            placeholder="Postal Code"
            value={formAddress?.postal_code}
            onChange={(e) => handleChangeAddressInput(e, 'postal_code')}
            error={false}
            required
          />
          <Button
            type="primary"
            className="w-full my-6"
            onClick={onClickSendAddress}
            disabled={
              !formAddress.city ||
              !formAddress.country ||
              !formAddress.line1 ||
              !formAddress.postal_code ||
              !formAddress.state
            }
          >
            Continue to Payment
          </Button>
        </div>
      ) : (
        <div className="p-6">
          <h3 className="text-sm mb-4 text-ugray">
            Please fill out your payment info
          </h3>
          <Elements options={options} stripe={stripePromise}>
            <CheckoutForm
              amount={creditsObj.filter((cre) => cre.selected)[0].costCredits}
              numberOfCredits={
                creditsObj.filter((cre) => cre.selected)[0].numberCredits
              }
            />
          </Elements>
        </div>
      )}
    </Modal>
  );
}

export default CreditsModal;
