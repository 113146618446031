/* eslint-disable react/no-array-index-key */
import React from 'react';
import * as dayjs from 'dayjs';
import PropTypes from 'prop-types';
import ContentEditable from 'react-contenteditable';

import useTemplateState from '../../../../hooks/useTemplateState';
import { PERSONALIZE } from '../../../personalize/constants';
import { editTemplate } from '../../../savedcontent/actions';
import { useApiData } from '../../../../redux-setup';

import TemplateFooter from '../footer/TemplateFooter';

function ColdEmailTemplate({ data, onEditMode, hideFooter }) {
  const { templateValue, handleOnChange } = useTemplateState(data);
  const { name } = useApiData(PERSONALIZE, {});

  return (
    <div
      style={{
        position: 'relative',
        marginBottom: 25,
      }}
    >
      <div
        className="bg-white p-4 mb-2 shadow-md sm:p-10"
        style={{
          boxShadow: '0px 4px 18px rgba(0, 0, 0, 0.06)',
          borderRadius: '20px',
        }}
      >
        <div className=" text-gray-600 border rounded-md p-3 sm:p-8">
          <div className="flex items-center space-x-2 mb-4">
            <div className="text-xl sm:text-3xl h-6 w-6 sm:w-12 sm:h-12 flex items-center justify-center bg-indigo-500 rounded-full text-white">
              {name ? name?.toString()?.slice(0, 1) : 'J'}
            </div>
            <div className="flex flex-col text-xs sm:text-sm">
              <h1>{name || 'Jane Smith'}</h1>
              <h1>{dayjs().format('hh:mm a')}</h1>
            </div>
          </div>
          {React.Children.toArray(
            (Array.isArray(templateValue?.content)
              // eslint-disable-next-line no-unsafe-optional-chaining
              ? templateValue?.content
              : []
            ).map((val, index) => (
              <div
                key={index}
                className="mt-4 text-xs sm:text-sm xlg:text-base"
              >
                <ContentEditable
                  html={val}
                  disabled={!onEditMode}
                  onBlur={({ target: { innerHTML } }) => editTemplate(innerHTML, data, `content.${index}`)}
                  onChange={(e) => handleOnChange(e.target.value, `content.${index}`)}
                  spellCheck={false}
                />
              </div>
            )),
          )}
        </div>
      </div>
      {!hideFooter && (
        <TemplateFooter
          data={templateValue}
          onEditMode={onEditMode}
          handleOnChange={handleOnChange}
          type="cold-email"
        />
      )}
    </div>
  );
}

ColdEmailTemplate.defaultProps = {
  data: {},
  onEditMode: false,
  hideFooter: false,
};
ColdEmailTemplate.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.objectOf(PropTypes.any),
  onEditMode: PropTypes.bool,
  hideFooter: PropTypes.bool,
};
export default ColdEmailTemplate;
