import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { faArchive } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RootStateOrAny, useSelector } from 'react-redux';
import Button from '../../../../common/Button';
import ProjectInfo from '../../../Settings/ProjectsTab/ProjectSection/Project/ProjectInfo';
import ProjectInfoAutosave from '../../../Settings/ProjectsTab/ProjectSection/Project/ProjectInfoAutosave';
import { IProjectDataProps } from '../../../Settings/ProjectsTab/props';

function ManageProjects() {
  const { isPaid } = useSelector(
    (state: RootStateOrAny) => state['PERSONALIZE/DATA'],
  );
  const selectedProfile = useSelector(
    (state: RootStateOrAny) => state['PROJECT'],
  );
  const history = useHistory();
  const [project, setProject] = useState<IProjectDataProps | null>(null);

  useEffect(() => {
    if (selectedProfile.id > 0) {
      setProject(selectedProfile);
    }
  }, [selectedProfile]);

  if (isPaid) {
    return (
      <div className="relative group">
        <ProjectInfoAutosave
          project={project}
          setProject={setProject}
          setFocusEdit={() => null}
          disabled={false}
        />
      </div>
    );
  }
  return (
    <div className="relative group">
      <ProjectInfo
        project={project}
        setProject={setProject}
        disabled={true}
        setFocusEdit={() => null}
      />
      <div className="absolute inset-0 bg-white/50 opacity-0 betterhover:hover:opacity-100">
        <div className="flex h-full w-full justify-center items-center">
          <Button
            type="secondary"
            onClick={() => history.push('/settings/projects')}
          >
            <FontAwesomeIcon icon={faArchive} className="mr-2" />
            Manage your projects
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ManageProjects;
