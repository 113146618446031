import { getStorage, removeStorage, storeStorage } from '../helpers';

export const convertParagraphIntoArray = (
  text = '',
  arrayOfParags = [],
  iterate = 1,
) => {
  // eslint-disable-next-line no-unsafe-optional-chaining
  const firstStartIndex = text?.indexOf('<p>') + 3;
  const firstLastIndex = text?.indexOf('</p>');

  const parag = text.substring(firstStartIndex, firstLastIndex);
  const newText = text?.slice(firstLastIndex + 4, text?.length);
  if (parag === '&nbsp;') {
    return convertParagraphIntoArray(newText, arrayOfParags, iterate + 1);
  }
  if (newText) {
    return convertParagraphIntoArray(
      newText,
      [...arrayOfParags, parag],
      iterate + 1,
    );
  }
  return [...arrayOfParags, parag];
};

export const checkForGrayText = (data) => data?.inlineStyleRanges.some((v) => v.style === 'GRAYTEXT');
export const saveCurrentDoc = (content) => storeStorage('document', content);
export const getCurrentDoc = () => getStorage('document');
export const updateCurrentDoc = (newData = {}) => {
  const current = getCurrentDoc();
  if (current) {
    saveCurrentDoc({
      ...current,
      ...newData,
    });
  }
};

export const removeCurrentDoc = () => removeStorage('document');
export const hasStoredDoc = () => !!getStorage('document');
export const getCurrentDocId = () => {
  const doc = getStorage('document');
  if (doc) {
    return doc?.id;
  }
  return null;
};
