/* eslint-disable default-param-last */
/* eslint-disable camelcase */
export const removePrefixNewline = (content = '') => {
  const nlIndex = content?.indexOf('\n\n');
  const nlIndex2 = content?.indexOf('\n');
  if (nlIndex < 1) return content.replace('\n\n', '');
  if (nlIndex2 < 0) return content.replace('\n', '');
  return content;
};

export const transformIntro = ({ contents }) => {
  if (Array.isArray(contents)) {
    return contents.map(({ content }) => {
      // handle expander at the start
      if (Array.isArray(content) && content?.length > 0) {
        return content
          .map(
            (c) => `<p>${removePrefixNewline(c)?.replaceAll(
              '\n\n',
              '<br/><br/>',
            )}</p>`,
          )
          .join('');
      }
      return `${removePrefixNewline(content)?.replaceAll(
        '\n\n',
        '<br/><br/>',
      )}`;
    });
  }
  return [];
};

export const writerToSavedPayload = (
  title = '',
  content = '',
  persona_id,
  writerState = {},
) => ({
  name: `${title || 'Writer'}`,
  sub_template: 'Magic',
  content: {
    isTinyMCE: true,
    writerContent: content,
    writerState,
  },
  template: 'Writer',
  persona_id,
});
