import React, { useState, useEffect } from 'react';
import Button from '../../../../common/Button';
import Modal from '../../../../common/Modal';
import SelectForm from '../../../../common/SelectForm';
import { IModalDuplicateProjectProps } from './props';
import { SingleValue } from 'react-select';
import { IDefaultSelect } from '../../../../common/props';
import { RootStateOrAny, useSelector } from 'react-redux';
import { ICollectionDataProps } from '../props';

function ModalDuplicateProject({
  openDuplicateProject,
  setOpenDuplicateProject,
  handleDuplicateProject,
  defaultInputValue,
}: IModalDuplicateProjectProps) {
  const collections = useSelector(
    (state: RootStateOrAny) => state.COLLECTIONS,
  ) as ICollectionDataProps[];
  const [selectValue, setSelectValue] = useState<IDefaultSelect>({
    value: '',
    label: '',
  });

  const onChangeSelect = (newValue: SingleValue<IDefaultSelect>) => {
    if (newValue) {
      setSelectValue(newValue);
    }
  };

  useEffect(() => {
    setSelectValue(defaultInputValue);
  }, [defaultInputValue]);

  return (
    <Modal
      isOpen={openDuplicateProject.open}
      onCloseModal={() => {
        setOpenDuplicateProject({ open: false, id: 0 });
      }}
      minWidth={350}
    >
      <h1 className="font-source text-usilver text-center my-4 w-full">
        Choose a collection below
      </h1>
      <SelectForm
        options={collections.map((coll) => ({
          label: coll.name,
          value: coll.id.toString(),
        }))}
        defaultValue={defaultInputValue}
        onChangeSelect={onChangeSelect}
      />
      <Button
        type="primary"
        className="w-full mt-4"
        onClick={() => handleDuplicateProject(selectValue)}
        disabled={!selectValue.value}
      >
        Duplicate to Collection
      </Button>
    </Modal>
  );
}

export default ModalDuplicateProject;
