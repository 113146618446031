/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';

import PropTypes from 'prop-types';

const SideNavContext = React.createContext({
  openMenu: false,
  setOpenMenu: () => { },
});

export function MenuProvider({ children }) {
  const [openMenu, setOpenMenu] = React.useState(false);
  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <SideNavContext.Provider value={{ openMenu, setOpenMenu }}>
      {children}
    </SideNavContext.Provider>
  );
}

MenuProvider.defaultProps = {
  children: <div />,
};
MenuProvider.propTypes = {
  children: PropTypes.node,
};
export default SideNavContext;
