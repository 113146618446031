/* eslint-disable @typescript-eslint/no-empty-function */
import DOMPurify from 'dompurify';
import { NotificationManager } from 'react-notifications';

export const safeFunction = (func) => {
  if (typeof func === 'function') {
    return func();
  }
  return () => {};
};
export const convertToSlug = (string = '') =>
  string
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '');

export const sanitizeContent = (content) =>
  DOMPurify.sanitize(content, {
    ALLOWED_TAGS: [],
  });

export const addDataLayer = (payload = {}) => {
  (Array.isArray(window.dataLayer) ? window.dataLayer : []).push(payload);
};

export const countWords = (words = '') =>
  !words ? words?.trim()?.split(' ').length : 0;
export const countChars = (words = '') =>
  !words ? words?.split('').length : 0;

export const updateIntercom = (data = {}) => {
  if (window.intercomSettings) {
    window.intercomSettings.name = data.name;
    window.intercomSettings.email = data.email;
    window.intercomSettings.Industry = data.org_type;
    window.intercomSettings.is_paying = data.isPaid;
    window.intercomSettings.remaining_credits = data.remaining_credits;
    window.intercomSettings.user_id = data.user_id;
    window.intercomSettings.stripe_id = data.stripe_id;
    window.intercomSettings.generations_used = data.generations_used;
    window.intercomSettings.last_generation = data.last_generation;
    window.intercomSettings.last_credited = data.last_credited;

    window.Intercom('update');
  }
};

export const storeStorage = (name = '', value = {}) =>
  localStorage.setItem(name, JSON.stringify(value));

export const removeStorage = (name = '') => localStorage.removeItem(name);

export const getStorage = (name = '') => JSON.parse(localStorage.getItem(name));

export const getQueryParams = (queryKey = '') => {
  const q = new URLSearchParams(window.location.search);
  const queryValue = q.get(queryKey) || '';
  return queryValue;
};

export const getRewardfulCode = () => {
  const { Rewardful } = window;

  if (Rewardful) {
    return Rewardful?.referral;
  }
  return '';
};

export const hasStoredPersona = () => !!getStorage('last-persona');
export const getStoredPersona = () => getStorage('last-persona');

export const copyWords = async (content = '', showCopySuccessNotif = false) =>
  new Promise((res, rej) => {
    try {
      const clipBoardElem = document.createElement('textarea');
      clipBoardElem.style.whiteSpace = 'pre-wrap';
      document.body.appendChild(clipBoardElem);
      clipBoardElem.innerHTML = content;
      clipBoardElem.select();
      document.execCommand('copy');
      document.body.removeChild(clipBoardElem);
      if (showCopySuccessNotif)
        NotificationManager.success('Copied to clipboard');

      res();
    } catch (e) {
      NotificationManager.error(
        'Something went wrong while copying to clipboard',
      );
      rej();
    }
  });

export const isFramed = () => {
  let referer = '';
  if (window.location.ancestorOrigins) {
    referer = window.location.ancestorOrigins[0] || '';
  } else {
    referer =
      window.location !== window.parent.location
        ? document.referrer
        : document.location.href;
  }
  // TODO WE NEED THE FRAME URL
  return (
    !!referer.match(/^(https|http):\/\/app-test\.unbounce\.com.*$/) ||
    !!referer.match(/^(https|http):\/\/app\.unbounce\.com.*$/)
  );
  // return referer === 'http://127.0.0.1:5500';
};

export const isNotUbTrial = () => {
  const trial = sessionStorage.getItem('ubt') || '';
  return trial === 'false';
};
