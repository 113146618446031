import React from 'react';

import { transformerFunctions } from '../../../API/helper';

import BlogIdeasForm from './BlogIdeasForm';
import ContentExpanderForm from './ContentExpanderForm';
import FacebookAdsForm from './FacebookAdsForm';
import GoogleAdForm from './GoogleAdForm';
import ItemizeForm from './ItemizeForm';
import LandingPagesForm from './LandingPagesForm';
import ProdDescriptionForm from './ProdDescriptionForm';
import RemixerForm from './RemixerForm';
import ToneChangerForm from './ToneChangerForm';
import TaglinesForm from './TaglinesForm';

export default {
  'google-ads': GoogleAdForm,
  'landing-pages': LandingPagesForm,
  taglines: TaglinesForm,
  'product-descriptions': ProdDescriptionForm,
  'facebook-ads': FacebookAdsForm,
  'content-expander': ContentExpanderForm,
  'header-description': ItemizeForm,
  features: ItemizeForm,
  benefits: ItemizeForm,
  'blog-ideas': BlogIdeasForm,
  remixer: RemixerForm,
  'tone-changer': ToneChangerForm,
  default: () => (
    <div className="text-red-600 font-bold uppercase text-xl">
      No form found!!
    </div>
  ),
};

export const getPayload = (values = {}, template = '') => {
  if (transformerFunctions[template]) return transformerFunctions[template](values);
  return values;
};
