import React from 'react';
import Button from '../../../../common/Button';
import InputField from '../../../../common/InputField';
import Modal from '../../../../common/Modal';
import { IModalNewCollectionProps } from './props';

function ModalNewCollection({
  openNewCollection,
  setOpenNewCollection,
  setNewCollectionObj,
  newCollectionObj,
  handleNewCollection,
}: IModalNewCollectionProps) {
  return (
    <Modal
      isOpen={openNewCollection}
      onCloseModal={() => setOpenNewCollection(false)}
      minWidth={350}
    >
      <h1 className="font-semibold text-mine-shaft text-center mt-4 w-full">
        Create New Collection
      </h1>
      <InputField
        name="collection-name"
        placeholder="Collection Name"
        onChange={(e) => {
          const val = e.target.value;
          if (val === '' || (val.length < 26 && /^[A-Za-z0-9_-]+$/.test(val))) {
            setNewCollectionObj({ value: val, error: '' });
          }
        }}
        value={newCollectionObj.value}
        error={!!newCollectionObj.error}
        errorMsg={newCollectionObj.error}
        maxCounter={25}
      />
      <Button
        type="primary"
        className="w-full mt-4"
        onClick={handleNewCollection}
      >
        Create Collection
      </Button>
    </Modal>
  );
}

export default ModalNewCollection;
