/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { arrayMoveImmutable } from 'array-move';

import { sortableContainer } from 'react-sortable-hoc';
import SortableItem from './SortableItem';
import { changeOrder, updateOrderList } from '../actions';
import req from '../../../redux-setup';
import { V3_TEMPLATES } from '../../templates/actions';

const SortableContainer = sortableContainer(({ children }) => <div>{children}</div>);

function SortableSection({ initialTemplates, id }) {
  const [templates, setTemplates] = useState(initialTemplates);

  const onSortEnd = async ({ oldIndex, newIndex }) => {
    const newTemplateOrder = arrayMoveImmutable(templates, oldIndex, newIndex);

    const orderedTemplates = changeOrder(newTemplateOrder);
    try {
      setTemplates(newTemplateOrder);
      await updateOrderList({
        category_id: id,
        templates: orderedTemplates,
      });

      req.set(V3_TEMPLATES, (state) => {
        const oldState = state[V3_TEMPLATES];
        const idx = oldState?.findIndex((dt) => dt.id === id);
        if (idx > -1) {
          const category = oldState[idx];
          if (Array.isArray(category?.content)) {
            oldState[idx] = {
              ...category,
              content: newTemplateOrder,
            };
          }
        }
        return oldState;
      });
    } catch (_e) {
      // catch
    }
  };

  return (
    <SortableContainer onSortEnd={onSortEnd}>
      {templates.map((template, i) => (
        <SortableItem key={template.id} index={i} content={template} />
      ))}
    </SortableContainer>
  );
}

export default SortableSection;
