/* eslint-disable no-case-declarations */
/* eslint-disable max-len */
import {
  ADD_COLLECTION,
  ADD_PROJECT_IN_COLLECTION,
  CHANGE_COLLECTION_NAME,
  CHANGE_DEFAULT_PROJECT_ID,
  CHANGE_PROJECT,
  CHANGE_USER_OUTPUT_LANGUAGE,
  REMOVE_COLLECTION,
  REMOVE_PROJECT_IN_COLLECTION,
  SET_COLLECTIONS,
  SET_IS_LOADING_FALSE,
  SET_IS_LOADING_TRUE,
  SET_PROJECT,
  SET_PROJECTS_IN_COLLECTION,
  SET_PROJECT_AUDIENCE,
  SET_PROJECT_DESCRIPTION,
  SET_PROJECT_KEYWORDS,
  SET_PROJECT_NAME,
} from './actions/common';
import { CHANGE_LOADING } from './actions/loading';
import {
  UPDATESTEP1WELCOME,
  UPDATESTEP2WELCOME,
  UPDATESTEP3WELCOME,
} from './actions/profile';
import { CHANGE_OPEN_UPGRADE_MODAL } from './actions/upgradeModal';
import {
  SET_VALUE,
  SET_LOADING,
  STORE_DEFAULT,
  RESET_STATE,
  REMOVE_VALUE,
  SET_ROOT_VALUE,
  UPDATE_TEMPLATE_RESULT,
  ADD_MULTIADS_RESULT,
} from './constants';

const omitValue = (key, state) => {
  const { [key]: _unused, ...newState } = state;
  return newState;
};

const reducer = (state = STORE_DEFAULT, action = {}) => {
  switch (action.type) {
    case SET_ROOT_VALUE:
      return {
        ...state,
        ...action?.data?.payload,
      };
    case SET_VALUE:
      return {
        ...state,
        [action?.data?.key]: action?.data?.payload,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: !state?.loading,
      };
    case SET_IS_LOADING_TRUE:
      return {
        ...state,
        loading: true,
      };
    case SET_IS_LOADING_FALSE:
      return {
        ...state,
        loading: false,
      };
    case REMOVE_VALUE:
      return {
        ...omitValue(action.key, state),
      };
    case RESET_STATE:
      return STORE_DEFAULT;
    case CHANGE_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case UPDATE_TEMPLATE_RESULT:
      const preVcontents =
        state.TEMPLATE_VALUES &&
        state.TEMPLATE_VALUES[action.payload.key]?.contents
          ? [
              ...action.payload.payload.contents,
              // {
              //   content: '- EXPLORE MORE WITH UNBOUNCE -',
              //   persona_id: 0,
              //   user_id: 0,
              //   template: 'V2 Template',
              //   templateName: 'ads-unbounce',
              //   label: 0,
              //   caracters_count: 0,
              //   id: 'ads-unbounce',
              // },
              ...state.TEMPLATE_VALUES[action.payload.key].contents,
            ]
          : action.payload.payload.contents;
      const keyImportant =
        state.TEMPLATE_VALUES && state.TEMPLATE_VALUES[action.payload.key]
          ? {
              ...state.TEMPLATE_VALUES[action.payload.key],
              id: action.payload.payload.id,
              contents: preVcontents,
            }
          : action.payload.payload;
      return {
        ...state,
        TEMPLATE_VALUES: {
          ...state.TEMPLATE_VALUES,
          [action.payload.key]: keyImportant,
        },
      };
    case CHANGE_OPEN_UPGRADE_MODAL:
      return {
        ...state,
        upgradeModal: { ...state.upgradeModal, open: action.payload },
      };
    case CHANGE_USER_OUTPUT_LANGUAGE:
      return {
        ...state,
        'PERSONALIZE/DATA': {
          ...state['PERSONALIZE/DATA'],
          output_language: action.payload,
        },
      };
    case CHANGE_DEFAULT_PROJECT_ID:
      return {
        ...state,
        'PERSONALIZE/DATA': {
          ...state['PERSONALIZE/DATA'],
          project_default: action.payload,
        },
      };
    case ADD_COLLECTION:
      return {
        ...state,
        COLLECTIONS: [...state.COLLECTIONS, action.payload],
      };
    case CHANGE_COLLECTION_NAME: {
      const collection = state.COLLECTIONS.map((coll) => {
        if (coll.id === action.payload.id) {
          return {
            ...coll,
            name: action.payload.name,
          };
        }
        return coll;
      });
      return {
        ...state,
        COLLECTIONS: collection,
      };
    }
    case REMOVE_COLLECTION: {
      const collection = state.COLLECTIONS.filter(
        (coll) => coll.id !== action.payload,
      );
      return { ...state, COLLECTIONS: collection };
    }
    case SET_COLLECTIONS:
      return { ...state, COLLECTIONS: action.payload };
    case ADD_PROJECT_IN_COLLECTION: {
      const collection = state.COLLECTIONS.map((coll) => {
        if (coll.id === action.payload.collectionID) {
          return {
            ...coll,
            projects: [action.payload.project, ...coll.projects],
          };
        }
        return coll;
      });
      return { ...state, COLLECTIONS: collection };
    }
    case REMOVE_PROJECT_IN_COLLECTION: {
      const collection = state.COLLECTIONS.map((coll) => {
        if (coll.id === action.payload.collectionID) {
          const projects = coll.projects.filter(
            (proj) => proj.id !== action.payload.projectID,
          );
          return {
            ...coll,
            projects: projects,
          };
        }
        return coll;
      });
      return { ...state, COLLECTIONS: collection };
    }
    case SET_PROJECTS_IN_COLLECTION: {
      const collection = state.COLLECTIONS.map((coll) => {
        if (coll.id === action.payload.collectionID) {
          return { ...coll, projects: action.payload.projects };
        }
        return coll;
      });
      return { ...state, COLLECTIONS: collection };
    }
    case CHANGE_PROJECT: {
      const collection = state.COLLECTIONS.map((coll) => {
        if (coll.id === action.payload.collectionID) {
          const projects = coll.projects.map((prj) => {
            if (prj.id === action.payload.project.id) {
              return action.payload.project;
            }
            return prj;
          });
          return { ...coll, projects };
        }
        return coll;
      });
      return { ...state, COLLECTIONS: collection };
    }
    case SET_PROJECT:
      return { ...state, PROJECT: action.payload };
    case SET_PROJECT_NAME:
      const collection = state.COLLECTIONS.map((coll) => {
        if (coll.id == action.payload.collID) {
          const projects = coll.projects.map((prj) => {
            if (prj.id === action.payload.prjID) {
              return { ...prj, name: action.payload.name };
            }
            return prj;
          });
          return { ...coll, projects };
        }
        return coll;
      });
      return {
        ...state,
        PROJECT: {
          ...state.PROJECT,
          name: action.payload.name,
          company_name: action.payload.name,
        },
        COLLECTIONS: collection,
      };
    case SET_PROJECT_DESCRIPTION:
      return {
        ...state,
        PROJECT: {
          ...state.PROJECT,
          description: action.payload,
        },
      };
    case SET_PROJECT_AUDIENCE:
      return {
        ...state,
        PROJECT: {
          ...state.PROJECT,
          audience: action.payload,
        },
      };
    case SET_PROJECT_KEYWORDS:
      return {
        ...state,
        PROJECT: {
          ...state.PROJECT,
          brand_keywords: action.payload,
        },
      };
    case UPDATESTEP1WELCOME:
      return {
        ...state,
        'PERSONALIZE/DATA': {
          ...state['PERSONALIZE/DATA'],
          needs: action.payload.needs,
          usage_frequency: action.payload.usageFrequency,
        },
      };
    case UPDATESTEP2WELCOME:
      return {
        ...state,
        'PERSONALIZE/DATA': {
          ...state['PERSONALIZE/DATA'],
          company: action.payload.companyName,
          company_name: action.payload.companyName,
          website: action.payload.website,
          org_type: action.payload.insdustry,
          industry: action.payload.insdustry,
          business_size: action.payload.businessSize,
        },
      };
    case UPDATESTEP3WELCOME:
      return {
        ...state,
        'PERSONALIZE/DATA': {
          ...state['PERSONALIZE/DATA'],
          company: action.payload.companyName,
          company_name: action.payload.companyName,
          audience: action.payload.audience,
          brand_keywords: action.payload.keywords,
          description: action.payload.description,
          isDone: true,
        },
      };
    case ADD_MULTIADS_RESULT:
      return {
        ...state,
        multiAds: {
          ...state.Multiads,
          contents: [...action.payload, ...state.multiAds.contents],
        },
      };
    default:
      return state;
  }
};

export default reducer;
