import React from 'react';
import ContentEditable from 'react-contenteditable';
import { RootStateOrAny, useSelector } from 'react-redux';
import { getRandomNumber } from '../../../../../../common/functions';
import useTemplateState from '../../../../../../hooks/useTemplateState';
import { editTemplate } from '../../../../../savedcontent/actions';
import TemplateFooter from '../../../../components/footer/TemplateFooter';
import { IHeaderDescriptionProps } from './props';

function HeaderDescriptionResult({
  data,
  onEditMode = false,
  hideFooter = false,
}: IHeaderDescriptionProps) {
  const { templateValue, handleOnChange } = useTemplateState(data);
  let headline = '';
  let description = '';
  if (typeof data.content === 'string') {
    headline = data.content.slice(0, data.content.indexOf(' - '));
    description = data.content.slice(data.content.indexOf(' - ') + 3, data.content.length);
    if (description === data.content.slice(2)) {
      headline = '';
      description = data.content;
    }
  } else {
    headline = data.content.headline;
    description = data.content.description;
  }
  const { company_name, brand_keywords } = useSelector(
    (state: RootStateOrAny) => state.PROJECT,
  );

  const landingHeadline = (
    <h3 className="text-center text-xl sm:text-2xl mxl:text-4xl font-semibold text-mine-shaft">
      <ContentEditable
        html={headline || ''}
        onBlur={({ target: { innerHTML } }) =>
          editTemplate(innerHTML, data, 'content.headline')
        }
        onChange={(e) => handleOnChange(e.target.value, 'content.headline')}
        spellCheck={false}
        disabled={!onEditMode}
      />
    </h3>
  );

  const landingDescription = (
    <div className="mt-4 text-gray-600 mt-3 max-w-md mx-auto text-center text-base sm:text-lg md:mt-5 mxl:text-xl md:max-w-3xl">
      <ContentEditable
        disabled={!onEditMode}
        html={description || ''}
        onBlur={({ target: { innerHTML } }) =>
          editTemplate(innerHTML, data, 'content.description')
        }
        onChange={(e) => handleOnChange(e.target.value, 'content.description')}
        spellCheck={false}
      />
    </div>
  );

  const landingContentNoPhotos = (
    <div className="mb-4">
      <div className="flex justify-between items-center">
        <h1 className="font-source sm:text-lg xlg:text-xl text-mine-shaft">
          {company_name || '-'}
        </h1>
        <h1 className="capitalize font-source sm:text-lg xlg:text-xl text-mine-shaft">
          {brand_keywords[getRandomNumber(brand_keywords.length)] || ''}
        </h1>
      </div>
      <div className="mt-12 flex flex-col justify-center items-center">
        {landingHeadline}
        {landingDescription}
        <div className="flex justify-center items-center gap-x-4 mt-4 w-full">
          <button
            type="button"
            className="cursor-default shadow flex items-center justify-center px-4 py-2 border border-transparent text-xs sm:text-sm xlg:text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
          >
            Get Started
          </button>
          <button
            type="button"
            className="cursor-default shadow flex items-center justify-center px-4 py-2  border border-transparent text-xs sm:text-sm xlg:text-base  font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-5"
          >
            Learn More
          </button>
        </div>
      </div>
    </div>
  );
  return (
    <div className="relative mb-4">
      <div className="border lg:border-0 border-gallery bg-white py-4 px-6 mb-3 shadow-lg font-source rounded-lg">
        <div className="py-6 px-8">{landingContentNoPhotos}</div>
      </div>
      {!hideFooter && (
        <TemplateFooter
          data={templateValue}
          onEditMode={onEditMode}
          handleOnChange={handleOnChange}
          type="header-description"
        />
      )}
    </div>
  );
}

export default HeaderDescriptionResult;
