import React, { useEffect, useState } from 'react';
import { clsx } from 'clsx';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArchive } from '@fortawesome/pro-regular-svg-icons';
import getForms from '../../../templates/forms';
// import Customize from '../containers/Customize';
// import Profile from '../containers/Profile';
import { FORM_DEFAULT_VALUE, TAB_PROMPT, TAB_RESULTS } from '../constants';
import { usePageLayoutContext } from '../hooks';
import Language from '../../../../common/Language';
import SelectProject from '../../../../common/Profile/SelectProject';
import FineTune from '../containers/FineTune';
import Button from '../../../../common/Button';
import ProjectInfo from '../../../Settings/ProjectsTab/ProjectSection/Project/ProjectInfo';

function TemplateContent() {
  const {
    formRef,
    selectedTab,
    template,
    handleFormSubmit,
    isTools,
    contentValue,
    isPaid,
    templateData,
    setSelectedTab,
    buttonStatus,
  } = usePageLayoutContext();
  const Form = getForms[template] || getForms.default;
  const selectedProfile = useSelector((state) => state['PROJECT']);
  const history = useHistory();
  const [project, setProject] = useState(null);

  useEffect(() => {
    if (selectedProfile.id > 0) {
      setProject(selectedProfile);
    }
  }, [selectedProfile]);

  return (
    <>
      <div className="mb-5 mt-5 border-b border-gray-200 sm:pb-0">
        <nav className="-mb-px flex space-x-8">
          <button
            type="button"
            className={clsx(
              'whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm',
              selectedTab === TAB_PROMPT
                ? 'border-ubblue text-ubblue'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
            )}
            onClick={() => setSelectedTab(TAB_PROMPT)}
          >
            Prompt
          </button>
          {(!isEmpty(contentValue) || buttonStatus === 'loading') && (
            <button
              type="button"
              className={clsx(
                'whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm',
                selectedTab === TAB_RESULTS
                  ? 'border-ubblue text-ubblue'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
              )}
              onClick={() => setSelectedTab(TAB_RESULTS)}
            >
              Results
            </button>
          )}
        </nav>
      </div>
      {isTools && (
        <div
          className={clsx('mb-6', selectedTab !== TAB_PROMPT && 'hidden')} // this needs to be hidden to not lose the ref to the form and generate new content
        >
          <Form
            ref={formRef}
            onSubmit={handleFormSubmit}
            initialValues={
              !isEmpty(contentValue?.templateState)
                ? contentValue?.templateState
                : FORM_DEFAULT_VALUE[template] || {}
            }
          />
        </div>
      )}
      <div
        className={clsx(selectedTab !== TAB_PROMPT && 'hidden')} // this needs to be hidden to not lose the ref to the form and generate new content
      >
        <div className="gap-4">
          <Language />
          <SelectProject />
        </div>
        <FineTune
          className="mt-4"
          isHidden={templateData?.fine_tune === 'no-ideas'}
          isSidebar={true}
          onSubmitForm={handleFormSubmit}
        />
        {!(templateData?.fine_tune === 'no-ideas') && selectedProfile && (
          <div className="relative group">
            <ProjectInfo
              project={project}
              setProject={setProject}
              disabled={true}
              setFocusEdit={() => null}
            />
            <div className="absolute inset-0 bg-white/50 opacity-0 betterhover:hover:opacity-100">
              <div className="flex h-full w-full justify-center items-center">
                <Button
                  type="secondary"
                  onClick={() => history.push('/settings/projects')}
                >
                  <FontAwesomeIcon icon={faArchive} className="mr-2" />
                  Manage your projects
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

TemplateContent.defaultProps = {
  isMobileView: false,
};
TemplateContent.propTypes = {
  isMobileView: PropTypes.bool,
};
export default TemplateContent;
