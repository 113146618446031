import React from 'react';

import PropTypes from 'prop-types';

function Container({ children }) {
  return (
    <div className="w-full px-2 mx-auto xl:w-10/12 md:w-11/12 md:px-0 box-border">
      {children}
    </div>
  );
}
Container.propTypes = {
  children: PropTypes.node.isRequired,
};
export default Container;
