/* eslint-disable react/no-array-index-key */
import React from 'react';

import PropTypes from 'prop-types';
import ContentEditable from 'react-contenteditable';
import { FiGlobe } from 'react-icons/fi';

import useTemplateState from '../../../../hooks/useTemplateState';
import { editTemplate } from '../../../savedcontent/actions';

import TemplateFooter from '../footer/TemplateFooter';

function WebsiteFeatureTemplate({ data, onEditMode, hideFooter }) {
  const { templateValue, handleOnChange } = useTemplateState(data);
  const { headline, description } = data.content;
  return (
    <div
      style={{
        position: 'relative',
        marginBottom: 25,
      }}
    >
      <div
        className="bg-white mb-2 shadow-md font-source h-full"
        style={{
          boxShadow: '0px 4px 18px rgba(0, 0, 0, 0.06)',
          borderRadius: '16px',
          minHeight: 230,
        }}
      >
        <div className="py-6 px-8">
          <div>
            <div className="flex items-center justify-center h-10 w-10 rounded-md bg-indigo-500 text-white">
              <FiGlobe size={22} />
            </div>
            <div className="mt-5">
              <dt className="text-lg leading-6 font-medium text-gray-900">
                <ContentEditable
                  html={headline || ''}
                  disabled={!onEditMode}
                  onBlur={({ target: { innerHTML } }) =>
                    editTemplate(innerHTML, data, 'content.headline')
                  }
                  onChange={(e) =>
                    handleOnChange(e.target.value, 'content.headline')
                  }
                  spellCheck={false}
                />
              </dt>
              <dd className="mt-2 text-sm text-gray-500">
                <ContentEditable
                  disabled={!onEditMode}
                  html={description || ''}
                  onBlur={({ target: { innerHTML } }) =>
                    editTemplate(innerHTML, data, 'content.description')
                  }
                  onChange={(e) =>
                    handleOnChange(e.target.value, 'content.description')
                  }
                  spellCheck={false}
                />
              </dd>
            </div>
          </div>
        </div>
      </div>
      {!hideFooter && (
        <TemplateFooter
          data={templateValue}
          onEditMode={onEditMode}
          handleOnChange={handleOnChange}
          type="features"
        />
      )}
    </div>
  );
}

WebsiteFeatureTemplate.defaultProps = {
  data: {},
  onEditMode: false,
  hideFooter: false,
};

WebsiteFeatureTemplate.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.objectOf(PropTypes.any),
  onEditMode: PropTypes.bool,
  hideFooter: PropTypes.bool,
};
export default WebsiteFeatureTemplate;
