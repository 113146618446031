const CHANGE_USER_OUTPUT_LANGUAGE = 'CHANGE_USER_OUTPUT_LANGUAGE';
const CHANGE_DEFAULT_PROJECT_ID = 'CHANGE_DEFAULT_PROJECT_ID';
const ADD_COLLECTION = 'ADD_COLLECTION';
const CHANGE_COLLECTION_NAME = 'CHANGE_COLLECTION_NAME';
const REMOVE_COLLECTION = 'REMOVE_COLLECTION';
const SET_COLLECTIONS = 'SET_COLLECTIONS';
const ADD_PROJECT_IN_COLLECTION = 'ADD_PROJECT_IN_COLLECTION';
const REMOVE_PROJECT_IN_COLLECTION = 'REMOVE_PROJECT_IN_COLLECTION';
const SET_PROJECTS_IN_COLLECTION = 'SET_PROJECTS_IN_COLLECTIONS';
const CHANGE_PROJECT = 'CHANGE_PROJECT';
const SET_PROJECT = 'SET_PROJECT';
const SET_IS_LOADING_TRUE = 'SET_IS_LOADING_TRUE';
const SET_IS_LOADING_FALSE = 'SET_IS_LOADING_FALSE';
const SET_PROJECT_NAME = 'SET_PROJECT_NAME';
const SET_PROJECT_DESCRIPTION = 'SET_PROJECT_DESCRIPTION';
const SET_PROJECT_AUDIENCE = 'SET_PROJECT_AUDIENCE';
const SET_PROJECT_KEYWORDS = 'SET_PROJECT_KEYWORDS';

const setOutputLanguage = (outputLanguage) => ({
  type: CHANGE_USER_OUTPUT_LANGUAGE,
  payload: outputLanguage,
});

const setDefaultProject = (projectID) => ({
  type: CHANGE_DEFAULT_PROJECT_ID,
  payload: projectID,
});

const addCollection = (collection) => ({
  type: ADD_COLLECTION,
  payload: collection,
});

const updateCollectionName = (collection) => ({
  type: CHANGE_COLLECTION_NAME,
  payload: collection,
});

const removeCollection = (collectionID) => ({
  type: REMOVE_COLLECTION,
  payload: collectionID,
});

const setCollections = (collections) => ({
  type: SET_COLLECTIONS,
  payload: collections,
});

const addProjectInCollection = (prjInCollObj) => ({
  type: ADD_PROJECT_IN_COLLECTION,
  payload: prjInCollObj,
});

const removeProjectInCollection = (prjInCollObj) => ({
  type: REMOVE_PROJECT_IN_COLLECTION,
  payload: prjInCollObj,
});

const setProjectsInCollection = (projectsInCollObj) => ({
  type: SET_PROJECTS_IN_COLLECTION,
  payload: projectsInCollObj,
});

const updateProject = (projectInCollObj) => ({
  type: CHANGE_PROJECT,
  payload: projectInCollObj,
});

const setProject = (projectObj) => ({ type: SET_PROJECT, payload: projectObj });

const setIsLoadingTrue = () => ({
  type: SET_IS_LOADING_TRUE,
});

const setIsLoadingFalse = () => ({
  type: SET_IS_LOADING_FALSE,
});

const setProjectName = (prjNameObj) => ({
  type: SET_PROJECT_NAME,
  payload: prjNameObj,
});

const setProjectDescription = (description) => ({
  type: SET_PROJECT_DESCRIPTION,
  payload: description,
});

const setProjectAudience = (audience) => ({
  type: SET_PROJECT_AUDIENCE,
  payload: audience,
});

const setProjectKeywords = (keywords) => ({
  type: SET_PROJECT_KEYWORDS,
  payload: keywords,
});

export {
  CHANGE_USER_OUTPUT_LANGUAGE,
  CHANGE_DEFAULT_PROJECT_ID,
  ADD_COLLECTION,
  CHANGE_COLLECTION_NAME,
  REMOVE_COLLECTION,
  SET_COLLECTIONS,
  ADD_PROJECT_IN_COLLECTION,
  REMOVE_PROJECT_IN_COLLECTION,
  SET_PROJECTS_IN_COLLECTION,
  CHANGE_PROJECT,
  SET_PROJECT,
  SET_IS_LOADING_TRUE,
  SET_IS_LOADING_FALSE,
  SET_PROJECT_NAME,
  SET_PROJECT_DESCRIPTION,
  SET_PROJECT_AUDIENCE,
  SET_PROJECT_KEYWORDS,
  setOutputLanguage,
  setDefaultProject,
  addCollection,
  updateCollectionName,
  removeCollection,
  setCollections,
  addProjectInCollection,
  removeProjectInCollection,
  setProjectsInCollection,
  updateProject,
  setProject,
  setIsLoadingTrue,
  setIsLoadingFalse,
  setProjectName,
  setProjectDescription,
  setProjectAudience,
  setProjectKeywords,
};
