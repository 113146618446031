/* eslint-disable @typescript-eslint/no-empty-function */
import req from '../../redux-setup';

// eslint-disable-next-line import/prefer-default-export
export const getSharedContent = (
  id = '',
  successCallback = () => { },
  errorCallback = () => { },
  finalCallback = () => { },
) => {
  req
    .get({
      url: `/shared-content/${id}`,
      useLoading: false,
    })
    .then(({ data }) => {
      successCallback(data);
    })
    .catch(() => errorCallback())
    .finally(() => finalCallback((prev) => !prev));
};
