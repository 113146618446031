/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';

import PropTypes from 'prop-types';
import ContentEditable from 'react-contenteditable';

import useTemplateState from '../../../../hooks/useTemplateState';
import { editTemplate } from '../../../savedcontent/actions';

import TemplateFooter from '../footer/TemplateFooter';

function BlogIdeasTemplates({ data, onEditMode, hideFooter }) {
  const { templateValue, handleOnChange } = useTemplateState(data);

  return (
    <div className="relative mb-4">
      <div className="bg-white py-4 px-6 mb-3 shadow-md font-source rounded-lg">
        <div className="font-source font-medium text-sm sm:text-base xlg:text-lg text-ubblack">
          <ContentEditable
            html={templateValue.content}
            disabled={!onEditMode}
            onBlur={({ target: { innerHTML } }) =>
              editTemplate(innerHTML, data, 'content')
            }
            onChange={(e) => handleOnChange(e.target.value, 'content')}
            spellCheck={false}
          />
        </div>
      </div>
      {!hideFooter && (
        <TemplateFooter
          data={templateValue}
          handleOnChange={handleOnChange}
          onEditMode={onEditMode}
          type="blog-ideas"
        />
      )}
    </div>
  );
}
BlogIdeasTemplates.defaultProps = {
  data: {},
  onEditMode: false,
  hideFooter: false,
};

BlogIdeasTemplates.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.objectOf(PropTypes.any),
  onEditMode: PropTypes.bool,
  hideFooter: PropTypes.bool,
};
export default BlogIdeasTemplates;
