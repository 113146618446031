/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { Button, Loader } from '@unbounce/ui-components';
import { AiOutlineCheck } from 'react-icons/ai';
import { VscError } from 'react-icons/vsc';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ReactComponent as Logo } from '../../../assets/img/UBSmartCopy-short.svg';
import { addDataLayer, getQueryParams } from '../../helpers';
import { getProfile } from '../../personalize/actions';
import { PERSONALIZE } from '../../personalize/constants';
import { useApiData } from '../../../redux-setup';
import GrowPlanFeatures from '../components/GrowPlanFeatures';
import { sendZapierError } from '../../../common/sendInfo';
import { isUnlimitedPlan } from "../../../common/functions";
import EssentialPlanFeatures from "../components/EssentialPlanFeatures";

const useCheckProfilePayment = () => {
  const [count, setCount] = useState(1);
  const [errorPaying, setErrorPaying] = useState(false);
  const { isLoaded, isPaid } = useApiData(PERSONALIZE, {});
  useEffect(() => {
    if (isLoaded && !isPaid && count <= 20) {
      setTimeout(() => {
        setCount(count + 1);
        getProfile();
      }, 1000);
    } else if (!isPaid && count > 20) {
      setErrorPaying(true);
    }
  }, [count, isLoaded, isPaid]);
  return { errorPaying };
};

function SuccessPage() {
  const history = useHistory();
  const [textPayment, setTextPayment] = useState(
    'Your payment has been received',
  );
  const [plan, setPlan] = useState('growth');
  const { isLoaded, isPaid, stripe_id } = useSelector(
    (state) => state['PERSONALIZE/DATA'],
  );
  const { errorPaying } = useCheckProfilePayment();

  useEffect(() => {
    const queryPlan = getQueryParams('plan');
    if (getQueryParams('p') === 'true') {
      setTextPayment('Your subscription has been created');
    }
    if (queryPlan) {
      setPlan(queryPlan);
    }
  }, []);

  useEffect(() => {
    if (
      getQueryParams('success') === 'true'
    ) {
      addDataLayer({
        event: 'paidsignup',
        plan: getQueryParams('plan'),
      });
    }
  }, []);

  const goToBrowse = () => {
    history.push('/browse');
  };

  const contentText = () => {
    if (errorPaying) {
      return (
        <p className="text-gray-500 text-center mb-4">
          Please click below to Send Error or email us at
          <a
            className="text-ubblue"
            href={`mailto:smartcopy-help@unbounce.com?subject=Payment Error with ${stripe_id}&body=Payment error ${stripe_id}`}
          >
            smartcopy-help@unbounce.com
          </a>
          and we&apos;ll get it sorted for you
        </p>
      );
    } if (!isLoaded || !isPaid) {
      return (
        <>
          <p className="text-2xl font-semibold mb-2 text-center">
            Thank You for Choosing Smart Copy
          </p>
          <p className="text-gray-500 text-center">
            Get ready for unlimited, polished content to help your business
            grow.
          </p>
          {isUnlimitedPlan(plan) ? < GrowPlanFeatures /> : <EssentialPlanFeatures />}
        </>
      );
    }
    return (
      <>
        <p className="text-2xl font-semibold mb-2 text-center">
          Welcome to Smart Copy!
        </p>
        <p className="text-gray-500 text-center">
          You now have unlimited, polished content to help your business grow.
        </p>
        {isUnlimitedPlan(plan) ? < GrowPlanFeatures /> : <EssentialPlanFeatures />}
      </>
    );
  };

  useEffect(() => {
    if (errorPaying) {
      sendZapierError({
        error: 'subscription not found, payment done',
        customer_id: stripe_id,
      });
    }
  }, [errorPaying, stripe_id]);
  return (
    <div className="w-full flex flex-col items-center justify-center min-h-screen min-w-screen">
      <Logo height={40} width="100%" />
      <div className="flex flex-col items-center justify-center p-4 w-full max-w-xl bg-white rounded-xl">
        {!errorPaying ? (
          !isLoaded || !isPaid ? (
            <Loader />
          ) : (
            <div className="flex items-center">
              <div className="w-10 md:w-12 h-10 md:h-12 rounded-full bg-frosted-mint flex items-center justify-center  my-6">
                <AiOutlineCheck size={27} className="text-shamrock" />
              </div>
              <div className="text-shamrock ml-4 text md:text-lg">
                {textPayment}
              </div>
            </div>
          )
        ) : (
          <div className="flex items-center">
            <div className="w-10 md:w-12 h-10 md:h-12 rounded-full flex items-center justify-center my-6">
              <VscError size={40} className="text-red-500" />
            </div>
            <div className="text-red-500 ml-4 text md:text-lg">
              Looks like something didn&apos;t go quite right
            </div>
          </div>
        )}
        {contentText()}
        <div className="w-full">
          {errorPaying ? (
            <div className="px-0 md:px-20">
              <Button
                className="mt-4"
                primary
                large
                fullWidth
                // eslint-disable-next-line no-return-assign
                onClick={() => (window.location = `mailto:smartcopy-help@unbounce.com?subject=Payment Error with ${stripe_id}&body=Payment error ${stripe_id}`)}
              >
                Send Error
              </Button>
            </div>
          ) : (
            <div className="px-0 md:px-20">
              <Button
                className="mt-4"
                primary
                large
                fullWidth
                disabled={!isLoaded || !isPaid}
                onClick={goToBrowse}
              >
                {!isLoaded || !isPaid
                  ? 'Upgrading Your Account'
                  : 'Explore Smart Copy'}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SuccessPage;
