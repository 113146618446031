import React from 'react';

import { Formik, Form, Field } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import { Input } from '../../common/Formik/FormInputs';

// validation
const rqdmsg = 'This field is required.';
const validateForm = Yup.object().shape({
  topic: Yup.string().required(rqdmsg).max(400).label('Topic'),
});

// eslint-disable-next-line react/display-name
const BlogIdeasForm = React.forwardRef(({ initialValues, onSubmit }, ref) => (
  <Formik
    innerRef={ref}
    initialValues={initialValues}
    validationSchema={validateForm}
    onSubmit={(values) => onSubmit(values)}
    enableReinitialize
  >
    {({ values }) => (
      <Form>
        <p className="text-black font-semibold text-md">Topic</p>
        <Field
          component={Input}
          type="text"
          name="topic"
          value={values.topic}
          placeholder="The world is..."
          wrapperClass="my-2"
        />
      </Form>
    )}
  </Formik>
));

BlogIdeasForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  initialValues: PropTypes.objectOf(PropTypes.any).isRequired,
  onSubmit: PropTypes.func.isRequired,
};
export default BlogIdeasForm;
