/* eslint-disable camelcase */
import React, { useState, useEffect, useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Loader as Loading } from '@unbounce/ui-components';
import PropTypes from 'prop-types';
import { NotificationManager } from 'react-notifications';
import isEmpty from 'lodash/isEmpty';
import 'draft-js/dist/Draft.css';
import * as dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import req from '../../../redux-setup';
import { saveDoc, autoSaveV2 } from '../actions';
import WriterTitle from '../components/WriterTitle';

import { writerInitSettings, WRITER_DETAILS } from '../contants';
import {
  getCurrentDoc,
  hasStoredDoc,
  removeCurrentDoc,
  saveCurrentDoc,
} from '../helpers';
import Loader from '../../common/Loader/Loader';
// eslint-disable-next-line import/no-cycle
import WriterSidebar from '../components/WriterSidebar';
// eslint-disable-next-line import/no-cycle
import WriterTemplateSidebar from '../components/WriterTemplateSidebar';
import CreditsBar from '../../templates/components/CreditsBar';
import Button from '../../../common/Button';
import defaultWriterValue from './writerConstants';
import { requestTemplate } from '../../../common/sendInfo';
import { PERSONALIZE } from '../../personalize/constants';
import { getTemplateData } from '../../../common/functions';
import { setOpenUpgradeModal } from '../../../redux-setup/actions/upgradeModal';
import { Grammarly, GrammarlyEditorPlugin } from '@grammarly/editor-sdk-react';

function WriterEditorTinyMCE({
  defaultValue,
  currentDoc,
  writerState,
  personaId,
  generateNewContent,
}) {
  const editorRef = useRef();
  const dispatch = useDispatch();
  const { is_unlimited: isUnlimited, project_default: personaSelectedID } =
    useSelector((state) => state['PERSONALIZE/DATA']);
  const writerSidebar = useSelector((state) => state.WRITER_SIDEBAR) || {};
  const [value, setValue] = useState(defaultValue);
  const [loading, setLoading] = useState(false);
  const [writeMoreHover, setWriteMoreHover] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [lastSavedContent, setLastSavedContent] = useState(defaultValue);
  const [isWriterLoading, setWriterLoading] = useState(true);
  const [isAutoWriting, setAutoWriting] = useState(false);
  const [savedDetails, setSavedDetails] = useState(currentDoc);
  // eslint-disable-next-line no-unused-vars
  const [prevEditorState, setPrevEditorState] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [redoStateValue, setRedoStateValue] = useState(null);

  const hasDoc = hasStoredDoc();
  const doc = getCurrentDoc();
  const defaultTitle = hasDoc ? doc?.contents[0]?.name || '' : '';

  const onChange = (newContent) => {
    autoSaveV2(
      newContent,
      lastSavedContent,
      savedDetails,
      () => setIsSaving(true),
      (newSaved) => {
        setTimeout(() => {
          setSavedDetails(newSaved);
          setLastSavedContent(newContent);
          setRedoStateValue(newContent);
          setIsSaving(false);
          saveCurrentDoc({
            id: newSaved?.id,
            writerState,
            contents: [
              {
                ...newSaved,
                saveId: newSaved?.id,
                saved: true,
                content: {
                  isTinyMCE: true,
                  writerContent: newContent,
                  writerState,
                },
              },
            ],
          });
        }, 1000);
      },
      writerState,
    );
  };

  const replaceText = (highlighedText, newText, appendToEnd = '') => {
    setValue((prev) => {
      const current = prev?.replace(
        highlighedText,
        `<p><span class="colored-text" style="color: #7e8c8d;">${newText}</span></p>`,
      );

      setRedoStateValue(current);
      if (appendToEnd) {
        const currentVal = `${current}<p><span class="colored-text" style="color: #7e8c8d;">${appendToEnd}</span></p>`;
        onChange(currentVal);
        return currentVal;
      }
      onChange(current);
      return current;
    });
  };

  const addNextText = (highlighedText, newText) => {
    setValue((prev) => {
      const idx = prev.indexOf(highlighedText);
      if (idx < 0) return prev;
      return `${prev?.substring(
        0,
        idx + highlighedText.length,
      )} ${newText} ${prev?.substring(idx + highlighedText.length)}`;
    });
  };

  const writeNewCopy = (res, writeAtEnd = false, i = 0) => {
    const text = res?.split(' ');
    if (!res) {
      setAutoWriting(false);
      onChange(editorRef?.current?.getContent());
      setValue((prev) => {
        const current = prev?.replace(
          '<span class="generated-copy">&nbsp;</span>',
          '',
        );
        return current;
      });
      return;
    }
    const firstText = text?.shift();
    setValue((prev) => {
      if (writeAtEnd) {
        if (i > 0) {
          return prev?.replace(
            '<span class="generated-copy">&nbsp;</span>',
            `<span class="colored-text" style="color: #7e8c8d;">${firstText}</span> <span class="generated-copy">&nbsp;</span>`,
          );
        }

        const current = prev?.replace(
          '<span class="generated-copy">&nbsp;</span>',
          '',
        );
        return `${current}<p><span class="colored-text" style="color: #7e8c8d;">${firstText}</span> <span class="generated-copy">&nbsp;</span></p>`;
      }

      const current = prev?.replace(
        '<span class="generated-copy">&nbsp;</span>',
        `<span class="colored-text" style="color: #7e8c8d;">${firstText}</span> <span class="generated-copy">&nbsp;</span>`,
      );
      return current;
    });

    setTimeout(() => {
      writeNewCopy(text?.join(' '), writeAtEnd, i + 1);
    }, 15); // add delay
  };

  const removeMarker = (editor) =>
    editor
      .getBody()
      .querySelectorAll('span.marker')
      .forEach((elem) => {
        elem.remove();
      });

  const handleWrite = (ignoreSelected = false) => {
    const editor = editorRef?.current;

    if (!editor) return;
    try {
      setPrevEditorState(editor?.getContent());
      const currentHighlightedContent = editor.selection.getContent({
        format: 'text',
      });
      removeMarker(editor);

      if (currentHighlightedContent && !ignoreSelected) {
        editor.selection.collapse();
      }
      editor.execCommand(
        'mceInsertContent',
        false,
        '<span class="marker">\ufeff</span><span class="generated-copy">&nbsp;</span>',
      );
      setAutoWriting(true);
      const currentContent = editor?.getContent();
      const currentContentWithoutHTML = editor?.getContent({ format: 'text' });
      setValue(currentContent);
      const range = editor?.dom?.createRng();
      const marker = editor.getBody().querySelectorAll('span.marker');
      let content = editor.selection.getContent({ format: 'text' });
      if (marker?.length > 0 && !currentHighlightedContent) {
        range.setStart(editor?.getBody(), 0);
        range.setEnd(marker[0], 1);
        editor.selection.setRng(range);
        content = editor.selection.getContent({ format: 'text' });
      }
      setLoading(true);
      const generateCopyMarkerIndex = currentContent?.indexOf(
        '<span class="generated-copy">&nbsp;</span>',
      );

      const sendAllToSummary = generateCopyMarkerIndex < 30;
      const original = sendAllToSummary
        ? currentContentWithoutHTML
        : currentHighlightedContent || content;
      const slicedOriginal = original.slice(-3000);
      const firstSentenceIndex = original.indexOf('.');
      const finalOriginal = slicedOriginal.slice(firstSentenceIndex + 1);
      requestTemplate({
        custom: {
          original: original.length > 3000 ? finalOriginal : original,
        },
        persona_id: personaSelectedID,
        template: 'V2 Template',
        template_id: 165,
      })
        .then((response) => response.json())
        .then(async (result) => {
          if (result.message) {
            dispatch(setOpenUpgradeModal(true));
            return;
          }
          // NEEDS REFACTOR
          const { remaining_credits } = result;
          req.updateObject(PERSONALIZE, {
            remaining_credits,
          });
          const templateData = await getTemplateData(result.id);
          if (
            templateData[0].content &&
            templateData[0].content ===
              "Sorry, we couldn't generate any content that we were proud of."
          ) {
            NotificationManager.error(
              "Sorry, we couldn't generate any content that we were proud of.",
            );
            return;
          }
          if (templateData[0].content) {
            if (sendAllToSummary) {
              writeNewCopy(
                templateData[0].content.replaceAll('\n', '<br>'),
                true,
              );
              // eslint-disable-next-line consistent-return
              return templateData[0].content;
            }
            writeNewCopy(templateData[0].content.replaceAll('\n', '<br>'));
          }
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setLoading(false);
          setAutoWriting(false);
        });
    } catch (e) {
      setAutoWriting(false);
      NotificationManager.error('ERROR ON WRITING COPY');
    }
  };

  useEffect(() => {
    if (!hasStoredDoc() && !isWriterLoading) {
      setIsSaving(true);
      saveDoc(
        `Project name - ${dayjs().format('MM/DD/YYYY - hh:mm a')}`,
        editorRef.current.getContent(),
        personaId,
        writerState,
      ).then((details) => {
        setSavedDetails(details);
        saveCurrentDoc({
          id: details?.id,
          writerState,
          contents: [
            {
              ...details,
              saveId: details?.id,
              saved: true,
            },
          ],
        });
        setIsSaving(false);
      });
    }
  }, [isWriterLoading]);

  const removeColoredText = () => {
    if (value?.indexOf('class="colored-text" style="color: #7e8c8d;"') > -1) {
      setValue((prev) => {
        const current = prev?.replaceAll(
          'class="colored-text" style="color: #7e8c8d;"',
          '',
        );
        onChange(current);
        return current;
      });
    }
  };

  return (
    <>
      <div className="mt-4 w-full">
        <div className="flex items-center mb-2">
          <WriterTitle
            text={
              defaultTitle ||
              `Project name - ${dayjs().format('MM/DD/YYYY - hh:mm a')}`
            }
          />
          {writeMoreHover ? (
            <p className="text-xs sm:text-sm tracking-tight font-medium text-gray-400 min-w-max hidden mlg:inline-block">
              Press Cmd/Ctrl + Enter
            </p>
          ) : (
            <p className="text-xs sm:text-sm tracking-tight font-medium text-gray-400 min-w-max hidden mlg:inline-block">
              {!isSaving ? 'Changes Saved' : 'Saving Changes'}
            </p>
          )}
        </div>
        <div className="flex items-center justify-end gap-x-2 flex-wrap">
          {!isUnlimited && (
            <div className="w-96 mlg:w-64 h-6 mb-4">
              <CreditsBar />
            </div>
          )}
          <div className="flex gap-x-2 items-center w-full mlg:w-auto h-16 sm:h-auto">
            {writeMoreHover ? (
              <p className="text-xs sm:text-sm tracking-tight font-medium text-gray-400 inline-block mlg:hidden mr-auto pr-2">
                Press Cmd/Ctrl + Enter
              </p>
            ) : (
              <p className="text-xs sm:text-sm tracking-tight font-medium text-gray-400 inline-block mlg:hidden mr-auto pr-2">
                {!isSaving ? 'Changes Saved' : 'Saving Changes'}
              </p>
            )}
            <Button
              type="secondary"
              disabled={loading}
              className={
                'text-xs sm:text-sm px-2 sm:px-8 w-full sm:w-auto h-10 sm:h-auto'
              }
              onClick={() => {
                req.set(WRITER_DETAILS, defaultWriterValue);
                removeCurrentDoc();
              }}
            >
              New Document
            </Button>
            <Button
              type="primary"
              disabled={loading || isSaving}
              className={
                'text-xs sm:text-sm px-2 sm:px-8 w-full sm:w-auto h-10 sm:h-auto '
              }
              onClick={() => handleWrite()}
              loading={loading}
              onMouseEnter={() => setWriteMoreHover(true)}
              onMouseLeave={() => setWriteMoreHover(false)}
            >
              {loading ? 'Writing' : 'Write More'}
            </Button>
          </div>
        </div>
      </div>
      {isWriterLoading && <Loader />}
      <div
        className="flex relative mt-2"
        style={{ height: 'calc(100% - 130px)' }}
      >
        {!isEmpty(writerSidebar) &&
          (writerSidebar?.type === 'template' ? (
            <WriterTemplateSidebar editor={editorRef.current} />
          ) : (
            <WriterSidebar
              replaceText={replaceText}
              generateContent={generateNewContent}
              editor={editorRef?.current}
              addNextText={addNextText}
            />
          ))}
        <div className="w-full">
          <Grammarly clientId={process.env.REACT_APP_GRAMMARLY_CLIENT_ID}>
            <GrammarlyEditorPlugin
              config={{
                autocomplete: 'on',
                introText:
                  'Smart Copy added Grammarly to help you write clearly and mistake-free.',
                toneDetector: 'on',
              }}
            >
              <Editor
                onInit={(_e, editor) => {
                  // set editor to ref
                  setWriterLoading(false);
                  editorRef.current = editor;
                }}
                onClick={removeColoredText}
                disabled={isAutoWriting}
                value={value}
                onEditorChange={(val) => {
                  if (isAutoWriting) return;
                  onChange(val);
                  setValue(val);
                }}
                initialValue={defaultValue}
                onKeyDown={(e) => {
                  if (e.metaKey && e.key === 'Enter') {
                    e.preventDefault();
                    handleWrite();
                  }

                  if (e.ctrlKey && e.key === 'Enter') {
                    e.preventDefault();
                    handleWrite();
                  }
                }}
                init={writerInitSettings(
                  handleWrite,
                  window.innerWidth,
                  window.innerHeight - 200,
                )}
                apiKey={process.env.REACT_APP_TINYMCE_KEY}
                cloudChannel="6.2"
              />
            </GrammarlyEditorPlugin>
          </Grammarly>
        </div>
        {loading && (
          <div className="absolute bg-white bg-opacity-50 top-0 bottom-0 right-0 left-0 flex items-center justify-center z-100">
            <Loading />
          </div>
        )}
      </div>
    </>
  );
}

WriterEditorTinyMCE.defaultProps = {
  defaultValue: '',
  personaId: '',
  writerState: {},
  currentDoc: {},
};
WriterEditorTinyMCE.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  generateNewContent: PropTypes.func.isRequired,
  personaId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  // eslint-disable-next-line react/forbid-prop-types
  writerState: PropTypes.objectOf(PropTypes.any),
  // eslint-disable-next-line react/forbid-prop-types
  currentDoc: PropTypes.objectOf(PropTypes.any),
};

export default WriterEditorTinyMCE;
