/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { clsx } from 'clsx';
import PropTypes from 'prop-types';
import { AiFillStar } from 'react-icons/ai';
import { FaInfoCircle } from 'react-icons/fa';

function FormSwitch({
  onChange,
  label,
  checked,
  showSkeleton,
  tooltip,
  showStar,
  labelWrapperClass,
  ...props
}) {
  const [showInfoText, setShow] = React.useState(false);

  return (
    <div className="flex items-center justify-between">
      {label && (
        <div className={clsx('flex items-center', labelWrapperClass)}>
          {showStar && (
            <AiFillStar className="text-yellow-300 mr-1" size={20} />
          )}
          <label htmlFor="switch" className="text-main-active tracking-wide">
            {label}
          </label>
          {tooltip && (
            <div
              className="relative"
              onMouseEnter={() => setShow(true)}
              onMouseLeave={() => setShow(false)}
            >
              <FaInfoCircle size={12} className="text-gray-300 ml-1" />
              {showInfoText && tooltip && (
                <div
                  style={{
                    zIndex: 9999,
                    right: 0,
                  }}
                  className="absolute p-4 bg-white text-gray-700 rounded-lg text-sm w-64 shadow-xl border"
                >
                  {tooltip}
                </div>
              )}
            </div>
          )}
        </div>
      )}

      <button
        type="button"
        onClick={() => onChange(!checked)}
        className="bg-gray-200 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none"
        aria-pressed="false"
        style={{
          backgroundColor: checked ? '#27cc8d' : '#8F9BAC',
        }}
        disabled={showSkeleton}
        {...props}
      >
        <span
          className={clsx(
            'translate-x-0 pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
            checked ? 'translate-x-5' : 'translate-x-0',
          )}
        >
          <span
            className={clsx(
              ' absolute inset-0 h-full w-full flex items-center justify-center transition-opacity',
              checked
                ? 'opacity-0 ease-out duration-100'
                : 'opacity-100 ease-in duration-200',
            )}
            aria-hidden="true"
          />
          <span
            className={clsx(
              'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity',
              checked
                ? 'opacity-100 ease-in duration-200'
                : 'opacity-0 ease-out duration-100 ',
            )}
            aria-hidden="true"
          />
        </span>
      </button>
    </div>
  );
}

FormSwitch.defaultProps = {
  label: null,
  showSkeleton: false,
  tooltip: '',
  showStar: false,
  labelWrapperClass: '',
};

FormSwitch.propTypes = {
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  showSkeleton: PropTypes.bool,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.node,
  ]),
  tooltip: PropTypes.string,
  showStar: PropTypes.bool,
  labelWrapperClass: PropTypes.string,
};
export default FormSwitch;
