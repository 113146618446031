import React from 'react';
import BenefitsSkeleton from './BenefitSkeleton';
import FeaturesSkeleton from './FeaturesSkeleton';
import FooterSkeleton from './FooterSkeleton';
import TitleDescriptionSkeleton from './TitleDescriptionSkeleton';

function LandingPageSkeleton() {
  return (
    <div className="mb-4">
      <div className="border border-gallery bg-white py-4 px-6 mb-3 shadow-md font-source rounded-lg animate-pulse">
        <TitleDescriptionSkeleton />
        <div className="flex justify-between w-full gap-6">
          <FeaturesSkeleton />
          <FeaturesSkeleton />
          <FeaturesSkeleton />
        </div>
        <BenefitsSkeleton />
        <FooterSkeleton />
      </div>
    </div>
  );
}

export default LandingPageSkeleton;
