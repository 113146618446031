import React from 'react';
import { clsx } from 'clsx';
import Tooltip from '../Tooltip';
import Keyword from './Keyword';
import KeywordInput from './KeywordInput';
import IKeywordsFieldProps from './props';

function Keywords({
  wrapperClass,
  keywords,
  label,
  required,
  inputValue,
  onChangeInput,
  name,
  addKeyword,
  handleRemoveKeyword,
  disabled,
  disabledOnClick,
  maxInput,
  classNameLabel,
  classNameTooltip,
  tooltip,
  onPasteInput,
}: IKeywordsFieldProps) {
  return (
    <div className={clsx(wrapperClass, disabled && 'select-none', 'relative')}>
      {label && (
        <label
          className={clsx(
            classNameLabel ? classNameLabel : 'text-sm font-medium',
            disabled ? 'text-usilver' : 'text-ugray',
          )}
        >
          {`${label}${required ? '*' : ''}`}
          {tooltip && <Tooltip text={tooltip} className={classNameTooltip} />}
        </label>
      )}
      <div
        className={clsx(
          'w-full rounded border border-alto py-1 px-2 flex flex-wrap items-center font-source text-sm',
        )}
        onClick={disabled ? disabledOnClick : undefined}
      >
        {keywords.map((el, i) => (
          <Keyword
            key={`el-${String(i)}`}
            name={el}
            index={i}
            handleRemoveKeyword={handleRemoveKeyword}
            disabled={disabled}
          />
        ))}
        <KeywordInput
          value={inputValue}
          onChange={onChangeInput}
          name={name}
          insertInput={addKeyword}
          onPaste={onPasteInput}
          disabled={
            disabled || (maxInput ? keywords.length >= maxInput : false)
          }
        />
      </div>
      {maxInput && (
        <span
          className={clsx(
            'text-ugray text-xs absolute bottom-1 right-2 font-source',
            keywords.length >= maxInput && 'text-red-600',
          )}
        >
          {`${keywords.length}/${maxInput}`}
        </span>
      )}
    </div>
  );
}

export default Keywords;
