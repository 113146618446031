/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useContext } from 'react';

const defaultValue = {
  showNotepad: false,
  setShowNotepad: () => { },
};

export const LayoutContext = createContext(defaultValue);
export const useLayoutContext = () => useContext(LayoutContext);
