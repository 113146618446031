import React from 'react';

function QuestionSection() {
  return (
    <div className="my-8">
      <h3 className="text-ugray font-semibold pb-2">Questions</h3>
      <p className="text-silver-chalice text-sm">
        Anything else, email{' '}
        <a
          href="mailto:smartcopy-help@unbounce.com?subject=Smart%20Copy%20Support"
          className="text-ubblue"
        >
          smartcopy-help@unbounce.com
        </a>
      </p>
    </div>
  );
}

export default QuestionSection;
