/* eslint-disable react/prop-types */
import React, { useState } from 'react';

import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { Button } from '@unbounce/ui-components/esm';
import { AlertMarker } from '../../common/Alerts';
import { InputSm, SelectSm } from '../../common/Formik/FormInputs';

const rqdmsg = 'This field is required.';
const validateForm = Yup.object().shape({
  name: Yup.string().required(rqdmsg).max(50).label('Name'),
  code: Yup.string().max(50).label('Code'),
  company_name: Yup.string().required(rqdmsg).max(50).label('Company'),
  email: Yup.string().required(rqdmsg).max(50).email().label('Email'),
  password: Yup.string()
    .required(rqdmsg)
    .min(8)
    .max(50)
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/,
      'Your password must be at least 8 characters long and must contain both uppercase and lowercase letters, at least one special character, and at least one number.',
    )
    .label('Password'),
  passwordConfirm: Yup.string()
    .required(rqdmsg)
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .min(8)
    .max(50)
    .label('Password Confirm'),
  pricingPlan: Yup.string().required(rqdmsg).label('Pricing Plan'),
});

function RegisterForm({ handleSignup, defaultValue, options, startValue }) {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  return (
    <Formik
      validationSchema={validateForm}
      initialValues={{
        ...defaultValue,
      }}
      onSubmit={(value, { setSubmitting }) => {
        handleSignup(value, setSubmitting);
      }}
    >
      {({ values, handleSubmit, isSubmitting }) => {
        return (
          <Form>
            <AlertMarker name="register" />
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4">
              <Field
                label="Full Name"
                component={InputSm}
                name="name"
                value={values.name}
                placeholder="Jane Smith"
                wrapperClass="mt-6 font-bold"
                onKeyPress={(e) => (e.which === 13 ? handleSubmit() : null)}
              />
              <Field
                label="Company"
                component={InputSm}
                name="company_name"
                value={values.company_name}
                placeholder="MyCompany"
                wrapperClass="mt-6 font-bold"
                onKeyPress={(e) => (e.which === 13 ? handleSubmit() : null)}
              />
              <Field
                label="Business Email"
                component={InputSm}
                name="email"
                type="email"
                value={values.email}
                placeholder="jane.smith@mycompany.com"
                wrapperClass="mt-6 font-bold"
                onKeyPress={(e) => (e.which === 13 ? handleSubmit() : null)}
              />
              <Field
                label="Plan"
                component={SelectSm}
                name="pricingPlan"
                type="select"
                wrapperClass="mt-6 font-bold"
                options={options}
                value={values.pricingPlan}
                startValue={startValue}
              />
              <Field
                label="Password"
                component={InputSm}
                name="password"
                type={showPassword ? 'text' : 'password'}
                value={values.password}
                placeholder="Password"
                wrapperClass="mt-6 font-bold"
                isPassword={true}
                show={showPassword}
                changeShow={setShowPassword}
                maxLength={51}
                onKeyPress={(e) => (e.which === 13 ? handleSubmit() : null)}
              />

              <Field
                label="Confirm Password"
                component={InputSm}
                name="passwordConfirm"
                type={showConfirm ? 'text' : 'password'}
                isPassword={true}
                show={showConfirm}
                changeShow={setShowConfirm}
                value={values.passwordConfirm}
                placeholder="Confirm Password"
                wrapperClass="mt-6 font-bold"
                maxLength={51}
                onKeyPress={(e) => (e.which === 13 ? handleSubmit() : null)}
              />
            </div>
            <div className="px-0 md:px-20">
              <Button
                primary
                fullWidth
                name="Continue"
                className="mt-8"
                large
                onClick={() => {
                  handleSubmit();
                }}
                disabled={isSubmitting}
              >
                {values.pricingPlan === 'ubgrowthYear' ||
                values.pricingPlan === 'ubgrowthMonth'
                  ? 'Continue To My Free Trial'
                  : 'Continue'}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default RegisterForm;
