import React from 'react';

import Skeleton from 'react-loading-skeleton';

function SkeletonTaglines() {
  return (
    <div
      style={{
        position: 'relative',
        marginBottom: 25,
      }}
    >
      <div
        className="bg-white py-8 px-4 my-2 shadow-md"
        style={{
          borderRadius: '20px',
        }}
      >
        <div className="font-semibold text-xl">
          <Skeleton count={6} />
        </div>
      </div>
    </div>
  );
}

export default SkeletonTaglines;
