import { faGlobe } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import ContentEditable from 'react-contenteditable';
import useTemplateState from '../../../../../../hooks/useTemplateState';
import { editTemplate } from '../../../../../savedcontent/actions';
import TemplateFooter from '../../../../components/footer/TemplateFooter';
import { IFeaturesResultProps } from './props';

function FeaturesResult({
  data,
  onEditMode = false,
  hideFooter = false,
}: IFeaturesResultProps) {
  const { templateValue, handleOnChange } = useTemplateState(data);
  let headline = '';
  let description = '';
  if (typeof data.content === 'string') {
    headline = data.content.slice(0, data.content.indexOf(' - '));
    description = data.content.slice(data.content.indexOf(' - ') + 3, data.content.length);
    if (description === data.content.slice(2)) {
      headline = '';
      description = data.content;
    }
  } else {
    headline = data.content.headline;
    description = data.content.description;
  }
  return (
    <div className="relative mb-4 px-px">
      <div className="border lg:border-0 border-gallery bg-white py-4 px-6 mb-3 shadow-lg font-source rounded-lg">
        <div className="flex items-center gap-4">
          <div className="flex items-center justify-center h-10 w-10 rounded-md bg-ubblue text-white">
            <FontAwesomeIcon icon={faGlobe} />
          </div>
          <div className="text-lg leading-6 font-medium text-mine-shaft">
            <ContentEditable
              html={headline || ''}
              disabled={!onEditMode}
              onBlur={({ target: { innerHTML } }) =>
                editTemplate(innerHTML, data, 'content.headline')
              }
              onChange={(e) =>
                handleOnChange(e.target.value, 'content.headline')
              }
              spellCheck={false}
            />
          </div>
        </div>
        <div className="mt-5 text-sm text-ugray">
          <ContentEditable
            disabled={!onEditMode}
            html={description || ''}
            onBlur={({ target: { innerHTML } }) =>
              editTemplate(innerHTML, data, 'content.description')
            }
            onChange={(e) =>
              handleOnChange(e.target.value, 'content.description')
            }
            spellCheck={false}
          />
        </div>
      </div>
      {!hideFooter && (
        <TemplateFooter
          data={templateValue}
          onEditMode={onEditMode}
          handleOnChange={handleOnChange}
          type="features"
        />
      )}
    </div>
  );
}

export default FeaturesResult;
