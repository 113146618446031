import React from 'react';
import { clsx } from 'clsx';
import IBigCardProps from './props';

function BigCard({
  title,
  subtitle,
  paragraph,
  buttonText,
  imageLink,
  buttonLink,
  className,
}: IBigCardProps) {
  return (
    <div className={clsx('border bg-tundora rounded-lg relative', className)}>
      <div className="bg-upink w-3 h-28 absolute top-6" />
      <div className="bg-upink w-3 h-14 absolute right-0 bottom-12" />
      <div className="flex flex-wrap pl-4 md:pl-8 items-center">
        <div className="order-2 lg:order-1 w-full slg:w-5/12 lg:w-6/12 xlg:w-6/12 px-4 py-6">
          <div className="flex items-center">
            <span className="w-9 h-px border-y border-white mr-2" />
            <h2 className="font-barlow text-white text-sm">{title}</h2>
          </div>
          <h2 className="font-source text-white text-2xl my-6">{subtitle}</h2>
          <p className="font-source text-white text-sm mb-6">{paragraph}</p>
          <a
            href={buttonLink}
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block w-full lg:w-auto text-center text-white font-source font-semibold text-sm bg-ubblue px-4 py-2 rounded betterhover:hover:bg-klein-blue"
          >
            {buttonText}
          </a>
        </div>
        <div className="order-1 lg:order-2 w-full slg:w-7/12 lg:w-6/12 xlg:w-6/12 flex justify-center items-end">
          <img className="max-w-56 lg:max-w-96" src={imageLink} alt={title} />
        </div>
      </div>
    </div>
  );
}

export default BigCard;
