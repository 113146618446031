import React from 'react';
import { clsx } from 'clsx';
import Button from '../../../../../common/Button';
import { ICreditsCardInterface } from './props';

function CreditsCard({
  creditsNumber,
  creditsPrice,
  onClickCredits,
  wrapperClass,
}: ICreditsCardInterface) {
  return (
    <div
      className={clsx(
        'text-center border border-gallery rounded-lg py-6 px-10 shadow-lg bg-white',
        wrapperClass,
      )}
    >
      <h3 className="text-2xl font-medium font-barlow text-shamrock">{`${creditsNumber} Credits`}</h3>
      <h3 className="text-2xl font-medium font-barlow mb-4">{`$${creditsPrice} USD`}</h3>
      <Button type="primary" onClick={() => onClickCredits(creditsNumber)}>
        {`Buy ${creditsNumber} Credits`}
      </Button>
    </div>
  );
}

export default CreditsCard;
