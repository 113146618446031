import React from 'react';

import { Link } from 'react-router-dom';

import { ReactComponent as Logo } from '../../../assets/img/UBSmartCopy.svg';
import LoginForm from '../forms/LoginForm';
import Container from '../../common/Container';

function LoginPage() {
  return (
    <div className="wrapper clearfix">
      <div className="auth-left-panel">
        <section>
          <Container>
            <div
              className="mx-auto my-auto max-w-screen-md mt-12 w-full bg-white px-12 pt-8 pb-16 "
              style={{ maxWidth: 600 }}
            >
              <div
                className="my-5 flex justify-center"
                style={{ marginBottom: 50 }}
              >
                <Logo height={40} width="100%" />
              </div>
              <LoginForm />
              <div className="mt-6">
                <Link
                  to="/register"
                  className="text-center block text-md text-ubblue hover:text-ubblue-hover font-source"
                >
                  No account yet? Sign up now!
                </Link>
              </div>

              <div className="text-center block text-md text-gray-400 mt-2 font-source">
                {'By signing up you agree to the following '}
                <a
                  href="https://unbounce.com/terms-of-service/"
                  className="underline font-source"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms & Conditions
                </a>
                {' and '}
                <a
                  href="https://unbounce.com/privacy/"
                  className="underline font-source"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
              </div>
            </div>
          </Container>
        </section>
      </div>

      <div className="auth-right-panel">
        <iframe
          frameBorder="0"
          height="100%"
          scrolling="no"
          src="https://smartcopy.ubpages.com/login/"
          width="100%"
          title="Login"
        />
      </div>
    </div>
  );
}

export default LoginPage;
