import { clsx } from 'clsx';
import { MdDone } from 'react-icons/md';
import { IFeaturesProps } from './props';

function Features({
  wrapperClass,
  icon,
  title,
  subtitle,
  containerClass,
  iconColorClass,
  titleClass,
  subtitleClass,
}: IFeaturesProps) {
  return (
    <div className={clsx(wrapperClass || 'flex items-center mt-2')}>
      <span
        className={clsx(
          iconColorClass || 'text-shamrock',
          'text-xl font-bold inline-block mr-4',
        )}
      >
        {icon || <MdDone />}
      </span>
      <div className={clsx(containerClass || 'text-left')}>
        <h2 className={clsx(titleClass || 'font-semibold')}>{title}</h2>
        {subtitle && (
          <p className={clsx(subtitleClass || 'text-xs')}>{subtitle}</p>
        )}
      </div>
    </div>
  );
}

export default Features;
