// eslint-disable-next-line import/no-cycle
import { getToken } from '../config/token';
import { singleCollectionURL, singleProjectURL } from './URLS';

const sendFetch = (url = '') =>
  fetch(url, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
      'Access-Control-Allow-Origin': '*',
    },
  });

export const deleteCollection = (id) => sendFetch(singleCollectionURL(id));
export const deleteProject = (id) => sendFetch(singleProjectURL(id));
