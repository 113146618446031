/* eslint-disable camelcase */
import React, { useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Logo from '../../../assets/img/UBSmartCopy-short.svg';
import { isPaidPlan } from '../../../common/functions';
import Loader from '../../common/Loader/Loader';
import Stepper from './Stepper';
import WelcomeForm from './WelcomeForm';

function Welcome() {
  const { isPaid, first_plan, isDone, isLoaded } = useSelector(
    (state: RootStateOrAny) => state['PERSONALIZE/DATA'],
  );
  const isLoading = useSelector((state: RootStateOrAny) => state.isLoading);
  const [step, setStep] = useState<1 | 2 | 3>(1);
  if (isPaidPlan(first_plan) && !isPaid) {
    return <Redirect to="/welcome" />;
  }

  if (isDone && isLoaded) return <Redirect to="/browse" />;
  return (
    <div className="p-10 mx-auto sm:w-3/4 lg:w-4/5 xl:w-1/2 mxl:w-2/4 xxl:w-3/8">
      <div className="mt-5 mb-7 flex justify-center items-center flex-wrap">
        <h2 className="font-barlow text-4xl font-semibold align-baseline">
          Welcome to
        </h2>
        <img src={Logo} className="h-12 ml-4" />
      </div>
      <Stepper step={step} />
      <WelcomeForm step={step} setStep={setStep} />
      {isLoading && <Loader />}
    </div>
  );
}

export default Welcome;
