import React, { useEffect, useState } from 'react';
import { clsx } from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBoxesPacking,
  faClone,
  faFloppyDisk,
  faMemoCircleInfo,
  faPen,
  faSquareMinus,
  faTrashCan,
} from '@fortawesome/pro-light-svg-icons';
import { IProjectProps } from './types';
import IProjectInfo from './ProjectInfo';
import { motion } from 'framer-motion';
import IconButton from '../../../../../common/IconButton';

function Project({
  id,
  name,
  projectData,
  onClick,
  setProjectData,
  disabled,
  clickEditProject,
  saveProject,
  setOpenDuplicateModal,
  setOpenMoveModal,
  setDeleteProjectModal,
  inactive,
  disabledDelete,
}: IProjectProps) {
  const [focusEdit, setFocusEdit] = useState(false);

  useEffect(() => {
    if (focusEdit) {
      const timer = setTimeout(() => setFocusEdit(false), 2000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [focusEdit]);

  return (
    <div>
      <div className="text-mine-shaft flex justify-between mb-1">
        {inactive ? (
          <>
            <button
              onClick={() => onClick(id)}
              className={clsx(
                projectData ? 'text-ubblue' : 'text-scorpion',
                'text-lg font-semibold betterhover:hover:text-ubblue group',
              )}
            >
              {projectData ? (
                <>
                  <span className="inline-block betterhover:group-hover:hidden">
                    <FontAwesomeIcon icon={faMemoCircleInfo} />
                  </span>
                  <span className="hidden betterhover:group-hover:inline-block mr-[4.5px]">
                    <FontAwesomeIcon icon={faSquareMinus} />
                  </span>
                </>
              ) : (
                <FontAwesomeIcon icon={faMemoCircleInfo} />
              )}
              <span className={clsx('ml-2', 'font-source text-base')}>
                {name}
              </span>
            </button>
            <div className="ml-2 font-source text-xs bg-silver-alto text-ugray px-2 rounded-sm my-auto">
              Inactive
            </div>
          </>
        ) : (
          <>
            <button
              onClick={() => onClick(id)}
              className={clsx(
                projectData ? 'text-ubblue' : 'text-scorpion',
                'text-lg font-semibold betterhover:hover:text-ubblue group',
              )}
            >
              {projectData ? (
                <>
                  <span className="inline-block betterhover:group-hover:hidden">
                    <FontAwesomeIcon icon={faMemoCircleInfo} />
                  </span>
                  <span className="hidden betterhover:group-hover:inline-block mr-[4.5px]">
                    <FontAwesomeIcon icon={faSquareMinus} />
                  </span>
                </>
              ) : (
                <FontAwesomeIcon icon={faMemoCircleInfo} />
              )}
              <span className={clsx('ml-2', 'font-source text-base')}>
                {name}
              </span>
            </button>
            <div className="ml-2">
              {projectData && projectData.id === id && !disabled ? (
                <IconButton
                  helperText="Save project"
                  onClick={saveProject}
                  className="text-lg text-scorpion"
                >
                  <FontAwesomeIcon icon={faFloppyDisk} />
                </IconButton>
              ) : (
                <IconButton
                  helperText="Edit project"
                  className="text-lg text-scorpion"
                  onClick={() => {
                    if (
                      !projectData ||
                      (projectData && projectData.id !== id)
                    ) {
                      onClick(id);
                    }
                    clickEditProject();
                  }}
                >
                  {focusEdit && (
                    <span className="animate-ping absolute h-5 w-5 bg-ubblue opacity-75 rounded-full" />
                  )}
                  <FontAwesomeIcon icon={faPen} />
                </IconButton>
              )}
              <IconButton
                helperText="Duplicate project"
                onClick={() => setOpenDuplicateModal({ open: true, id })}
                className="text-lg text-scorpion"
              >
                <FontAwesomeIcon icon={faClone} />
              </IconButton>
              <IconButton
                helperText="Move project"
                onClick={() => setOpenMoveModal({ open: true, id })}
                className="text-lg text-scorpion"
              >
                <FontAwesomeIcon icon={faBoxesPacking} />
              </IconButton>
              <IconButton
                helperText="Delete project"
                onClick={() => setDeleteProjectModal({ open: true, id, name })}
                className={clsx(
                  disabledDelete ? 'text-usilver' : 'text-scorpion',
                  'text-lg pr-0',
                )}
                disabled={disabledDelete}
              >
                <FontAwesomeIcon icon={faTrashCan} />
              </IconButton>
            </div>
          </>
        )}
      </div>
      <motion.div
        layout
        transition={{ ease: projectData ? 'easeIn' : 'easeOut', duration: 0.3 }}
      >
        {projectData && (
          <IProjectInfo
            project={projectData}
            setProject={setProjectData}
            disabled={disabled}
            setFocusEdit={setFocusEdit}
          />
        )}
      </motion.div>
    </div>
  );
}

export default Project;
