/* eslint-disable max-len */
import React, { useState } from 'react';
import {
  registerShortcut,
  unregisterShortcut,
} from '@todesktop/client-selected-text';
import { FiRepeat } from 'react-icons/fi';
import { IoExpand } from 'react-icons/io5';
import { RiPulseLine } from 'react-icons/ri';
import { getActiveWin } from '@todesktop/client-active-win';
import { app, nativeWindow } from '@todesktop/client-core';
import {
  Route, Switch, useHistory, useRouteMatch,
} from 'react-router-dom';
import { ShortcutContext } from '../context';
import LogoB from '../../../assets/img/SmartCopyWordmark.svg';
import CopyCard from './CopyCard';

function ShortcutContainer() {
  const [currentWord, setCurrentWord] = useState('');
  const history = useHistory();
  const match = useRouteMatch();

  const goToPage = (route) => () => history.push(`${match.path}${route}`);

  React.useEffect(() => {
    //     setCurrentWord('Writer is very good'); // comment this to try actual function
    //

    app.once('bindToDesktopPlugins', (reset) => {
      console.log('bind to desktop');
      registerShortcut('CommandOrControl+Shift+.', async (text) => {
        const selectedText = text.trim();
        if (!document.hidden) {
          nativeWindow.hide();
        }
        if (selectedText) {
          history.replace('/shortcut');
          const { owner } = await getActiveWin();
          app.focus({ steal: true });
          nativeWindow.show();
          nativeWindow.focus();
          setCurrentWord(selectedText);
          console.log(selectedText); // log the text.
          console.log(owner.name); // log the name of the app where text was capture. Let's send this to request and add to database.
        }
      });

      window.addEventListener('unload', () => {
        unregisterShortcut('CommandOrControl+Shift+.');
        reset();
      });
    });
  }, [history]);

  return (
    <ShortcutContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        currentWord,
      }}
    >
      <section className="panel w-600 h-360 min-w-screen min-h-screen flex flex-col bg-white drag">
        <div className="flex flex-col flex-grow items-center justify-center newcontent">
          <Switch>
            <Route path={match.path} exact>
              <div className="flex flex-col text-2xl xlg:text-3xl font-barlow font-medium w-full">
                <div className="flex-grow flex items-center p-8 justify-around text-center">
                  <div
                    role="button"
                    tabIndex={0}
                    onKeyDown={goToPage('/expand')}
                    onClick={goToPage('/expand')}
                    className="flex flex-col items-center justify-center space-y-4 text-gray-400 hover:text-ubblue "
                  >
                    <IoExpand className="w-12 h-12 stroke-gray-400 hover:text-ubblue" />
                    <p className="text-lg">Expand</p>
                  </div>
                  <div
                    role="button"
                    tabIndex={0}
                    onKeyDown={goToPage('/remix')}
                    onClick={goToPage('/remix')}
                    className="flex flex-col items-center justify-center space-y-4 text-gray-400 hover:text-ubblue"
                  >
                    <FiRepeat className="w-12 h-12 stroke-gray-400 hover:text-ubblue" />
                    <p className="text-lg">Remix</p>
                  </div>
                  <div
                    role="button"
                    tabIndex={0}
                    onKeyDown={goToPage('/next')}
                    onClick={goToPage('/next')}
                    className="flex flex-col items-center justify-center space-y-4 text-gray-400 hover:text-ubblue"
                  >
                    <RiPulseLine className="w-12 h-12 stroke-gray-400 hover:text-ubblue" />

                    <p className="text-lg">Next Sentence</p>
                  </div>
                </div>
                <div className="p-4 w-full flex items-center justify-between border-t">
                  <img src={LogoB} alt="smart-copy" className="w-24" />
                  <a
                    href="https://smartcopy.productlift.dev/new"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-sm font-gray-300 text-gray-500"
                  >
                    Feedback
                  </a>
                </div>
              </div>
            </Route>
            <Route path={`${match.path}/:type`} component={CopyCard} />
          </Switch>
        </div>
      </section>
    </ShortcutContext.Provider>
  );
}

export default ShortcutContainer;
