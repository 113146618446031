import React from 'react';

import { AlertMarker } from '../../common/Alerts';
import Container from '../../common/Container';
import ForgotPasswordForm from '../forms/ForgotPasswordForm';

import { forgotPassword } from '../actions';

function ForgotPassword() {
  const onSubmit = (payload, setSubmitting, resetForm) => {
    forgotPassword(payload, resetForm, setSubmitting);
  };

  return (
    <div>
      <section>
        <Container>
          <div
            className="mx-auto mt-24 w-full bg-white px-8 pt-8 pb-16"
            style={{ maxWidth: 500 }}
          >
            <h1 className="text-3xl font-bold text-gray-700">
              Forgot Password
            </h1>
            <AlertMarker name="forgot-password" />
            <h3 className="mt-4 text-base font-semibold mb-4">
              Enter your email and reset your password
            </h3>
            <ForgotPasswordForm onSubmit={onSubmit} />
          </div>
        </Container>
      </section>
    </div>
  );
}

export default ForgotPassword;
