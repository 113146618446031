/* eslint-disable react/prop-types */
import React from 'react';

import useTemplateState from '../../hooks/useTemplateState';
import { BUTTON_STATUS } from '../partials/pagelayout/constants';
import TemplateFooter from '../templates/components/footer/TemplateFooter';
import getSkeletons from '../templates/components/skeletonss';
import { useApiData } from '../../redux-setup';

function ContentCard({ data }) {
  const { handleOnChange } = useTemplateState(data);
  const content = Array.isArray(data.content)
    ? data.content
    : data.content
        .replaceAll(/^\n+/g, '')
        .split('')
        .map((l, i) => (l === '\n' ? <br key={i} /> : l));
  return (
    <div className="mb-4">
      <li className="w-full bg-white rounded-lg p-4 mb-4 shadow">
        {React.Children.toArray(content)}
      </li>
      <TemplateFooter
        data={data}
        handleOnChange={handleOnChange}
        type="V2 Template"
        isDemo
      />
    </div>
  );
}

export default function NotAuthoringTemplate({ cards = [] }) {
  const buttonStatus = useApiData(BUTTON_STATUS, '');
  const showSkeleton = React.useMemo(
    () => buttonStatus === 'loading',
    [buttonStatus],
  );
  const Skeleton = getSkeletons.default;

  return (
    <div className="mr-auto flex-grow">
      {showSkeleton && <Skeleton />}
      {cards.length > 0 && (
        <ul className="w-full">
          {cards.map((c) => (
            <ContentCard key={c.id} data={c} />
          ))}
        </ul>
      )}
    </div>
  );
}
