import { IDefaultSelect } from './props';

export const industryValues = [
  {
    value: 'advertising-marketing',
    label: 'Advertising & Marketing Agency',
  },
  { value: 'saas', label: 'SaaS' },
  { value: 'ecommerce', label: 'Ecommerce' },
  { value: 'business-services', label: 'Business Services' },
  { value: 'education', label: 'Education' },
  { value: 'media-publishing', label: 'Media & Publishing' },
  { value: 'healthcare', label: 'Healthcare' },
  { value: 'finance-insurance', label: 'Finance & Insurance' },
  { value: 'fitness-nutrition', label: 'Fitness & Nutrition' },
  { value: 'games-entertainment', label: 'Games & Entertainment' },
  { value: 'real-estate', label: 'Real Estate' },
  { value: 'home-improvement', label: 'Home Improvement' },
  { value: 'travel', label: 'Travel' },
  { value: 'catering-restaurants', label: 'Catering & Restaurants' },
  { value: 'events-leisure', label: 'Events & Leisure' },
  { value: 'legal', label: 'Legal' },
  { value: 'other', label: 'Other' },
] as IDefaultSelect[];

export const businessSize = [
  { value: '1', label: '1' },
  { value: '2-3', label: '2-3' },
  { value: '4-6', label: '4-6' },
  { value: '7-10', label: '7-10' },
  { value: '11-25', label: '11-25' },
  { value: '26-50', label: '26-50' },
  { value: '51-250', label: '51-250' },
  { value: '250+', label: '250+' },
];

export const needs = [
  { value: 'paid-ads', label: 'Paid ads' },
  { value: 'website-copy', label: 'Website copy' },
  { value: 'social-media', label: 'Social media Content' },
  { value: 'emails', label: 'Emails' },
  { value: 'blog-posts', label: 'Blog posts' },
  { value: 'case-study', label: 'Case study' },
  { value: 'landingpage-copy', label: 'Landing page copy' },
  { value: 'brainstorm-ideas', label: 'Brainstorm ideas' },
  { value: 'all-sorts', label: 'All sorts of copy' },
  { value: 'no-listed', label: 'Not listed above' },
];

export const usageFrequency = [
  { value: 'daily', label: 'Daily' },
  { value: '1-2-times-week', label: '1 - 2 times a week' },
  { value: 'few-times-month', label: 'A few times a month' },
  { value: 'every-now-and-then', label: 'Every now and then' },
];
