import React from 'react';
import { clsx } from 'clsx';
import IInputFieldProps from './props';
import Tooltip from '../Tooltip';

function InputField({
  name,
  placeholder,
  label,
  value,
  onChange,
  error,
  required,
  errorMsg,
  maxCounter,
  inputFocus,
  wrapperClass,
  borderError,
  onKeyUp,
  onPaste,
  disabled,
  disabledOnClick,
  classNameLabel,
  tooltip,
  classNameTooltip,
  onBlur,
}: IInputFieldProps) {
  return (
    <div className={clsx(wrapperClass, disabled && 'select-none font-source')}>
      {label && (
        <label
          className={clsx(
            classNameLabel ? classNameLabel : 'text-sm font-medium',
            disabled ? 'text-usilver' : 'text-ugray',
          )}
          htmlFor={name}
        >
          {`${label}${required ? '*' : ''}`}
          {tooltip && <Tooltip text={tooltip} className={classNameTooltip} />}
        </label>
      )}
      <div
        className={clsx(
          'w-full',
          !!maxCounter && 'inline-block relative overflow-hidden',
        )}
        onClick={disabled ? disabledOnClick : undefined}
      >
        <input
          className={clsx(
            disabled && 'text-silver-chalice',
            'w-full rounded border border-alto py-1 px-2 font-source text-sm',
            !borderError &&
              inputFocus &&
              'focus:outline-none focus:border-dodger-blue focus:border-2',
            borderError && 'border border-radical-red',
          )}
          type="text"
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={
            disabled
              ? undefined
              : (evt) => {
                  onChange(evt, name);
                }
          }
          onKeyUp={disabled ? undefined : onKeyUp}
          onPaste={disabled ? undefined : onPaste}
          disabled={disabled}
          onBlur={disabled ? undefined : onBlur}
        />
        {maxCounter && (
          <span
            className={clsx(
              'text-ugray text-xs absolute bottom-1 right-2 font-source',
              value.length >= maxCounter && 'text-red-600',
            )}
          >
            {`${value.length}/${maxCounter}`}
          </span>
        )}
      </div>
      {error && (
        <p className="text-sm text-amaranth">
          {errorMsg ? errorMsg : `The field ${label} is required`}
        </p>
      )}
    </div>
  );
}

export default InputField;
