import React, { useState } from 'react';

import { Formik, Field } from 'formik';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import { Button } from '@unbounce/ui-components';
import { AlertMarker } from '../../common/Alerts';
import { Input } from '../../common/Formik/FormInputs';
import { storeStorage } from '../../helpers';

import { login } from '../actions';

const rqdmsg = 'This field is required.';
const validateForm = Yup.object().shape({
  email: Yup.string().required(rqdmsg).max(50).email()
    .label('Email'),
  password: Yup.string().required(rqdmsg).label('Password'),
});

function LoginForm() {

  const [showPassword, setshowPassword] = useState(false)
  return (
    <Formik
      validationSchema={validateForm}
      initialValues={{
        email: '',
        password: '',
      }}
      onSubmit={(values, { setSubmitting }) => login(
        values,
        () => {
          storeStorage('expandProfile', true);
          // history.push(redirect || '/templates');
        },
        setSubmitting,
      )}
    >
      {({ values, handleSubmit, isSubmitting }) => (
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
        
        <form
          onSubmit={handleSubmit}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSubmit();
            }
          }}
        >
          <AlertMarker name="login" />
          <Field
            label="Email"
            component={Input}
            name="email"
            type="email"
            value={values.email}
            placeholder="Email"
            wrapperClass="font-bold text-black font-source"
          />
          <Field
            label="Password"
            component={Input}
            name="password"
            type={showPassword ? "text" : "password"}
            isPassword={true}
            show={showPassword}
            changeShow={setshowPassword}
            value={values.password}
            placeholder="Password"
            wrapperClass="mt-4 font-bold text-black font-source"
          />
          <Link
            to="forgot-password"
            className="text-ubblue hover:text-ubblue-hover "
          >
            Forgot your password?
          </Link>
          <Button
            primary
            large
            className="mt-6"
            fullWidth
            disabled={isSubmitting}
            onClick={handleSubmit}
          >
            Sign In
          </Button>
        </form>
      )}
    </Formik>
  );
}
export default LoginForm;
