import React from 'react';

import { v4 } from 'uuid';

// eslint-disable-next-line import/no-cycle
import PageLayout from '../../partials/pagelayout/containers/PageLayout';

import BlogIdeasTemplates from '../components/templates/BlogIdeasTemplate';

function BlogIdeas() {
  return (
    <PageLayout template="blog-ideas" title="Blog Ideas">
      {({ contents }) => (
        <>
          {React.Children.toArray(
            contents.map((data) => <BlogIdeasTemplates key={v4()} data={data} />),
          )}
        </>
      )}
    </PageLayout>
  );
}

export default BlogIdeas;
