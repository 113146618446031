import React, {
  Component,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { isEmpty } from 'lodash';
import { clsx } from 'clsx';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useCurrentTemplate } from '../../hooks';
import { checkIfTools } from '../../../tools/actions';
import {
  TAB_PROMPT,
  TAB_RESULTS,
} from '../../../partials/pagelayout/constants';
import { storeRecent } from '../../helpers';
import { NotificationManager } from 'react-notifications';
import { setOpenUpgradeModal } from '../../../../redux-setup/actions/upgradeModal';
import { getGeneratorId } from '../../../partials/pagelayout/actions';
import { PageLayoutContext } from '../../../partials/pagelayout/hooks';
import { InlineLoader } from '@unbounce/ui-components/esm';
import Button from '../../../../common/Button';
import Language from '../../../../common/Language';
import SelectProject from '../../../../common/Profile/SelectProject';
import FineTune from '../../../partials/pagelayout/containers/FineTune';
import Loader from '../../../common/Loader/Loader';
import { IProjectDataProps } from '../../../Settings/ProjectsTab/props';
import {
  IContentsV2Template,
  IInitialValuesObj,
} from '../../../../common/props';
import FormHeader from '../../../common/FormHeader';
import { FormikProps } from 'formik';
import CreditsBar from '../../components/CreditsBar';
import Skeletons from '../../components/Skeletons';
import TemplateResult from './TemplateResult';
import { setFineTuneVariable } from '../../../../config/localStorage';
import ManageProjects from '../../../partials/pagelayout/containers/ManageProjects';

class ScrollToTopOnMount extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return null;
  }
}

function DynamicTemplate() {
  const location = useLocation();
  const dispatch = useDispatch();
  const templateData = useCurrentTemplate();

  const template = templateData?.type || '';
  const templateName = templateData?.title || '';
  const title = templateData?.title || '';
  const templateCode = templateData?.template || '';

  const isTools = checkIfTools(template);
  const { remaining_credits, pack_credits, is_unlimited: isUnlimited } = useSelector(
    (state: RootStateOrAny) => state['PERSONALIZE/DATA'],
  );
  const loading = useSelector((state: RootStateOrAny) => state.loading);
  const buttonStatus = useSelector(
    (state: RootStateOrAny) => state.BUTTON_STATUS,
  );
  const templateValues = useSelector(
    (state: RootStateOrAny) => state.TEMPLATE_VALUES,
  );
  const formRef = useRef<FormikProps<IInitialValuesObj>>(null);

  const selectedProfile = useSelector(
    (state: RootStateOrAny) => state['PROJECT'],
  );
  const profileId = selectedProfile.id || 0;
  const [project, setProject] = useState<IProjectDataProps | null>(null);
  const [inclusivity, setInclusivity] = useState(false);

  const [viewCustomizeForm, setCustomizeForm] = useState(true);
  const [selectedTab, setSelectedTab] = useState(TAB_PROMPT);
  // TODO
  const contentValue = useMemo(
    () =>
      templateValues[isEmpty(templateData) ? template : templateData.code] ||
      {},
    [templateData, templateValues, template],
  ) as IContentsV2Template;

  // GENERATE CONTENT FUNCTION
  const generateContent = (values?: IInitialValuesObj) => {
    if (buttonStatus === 'loading') {
      return;
    }
    if (!profileId) {
      NotificationManager.error(
        'Please select the project you will generate content for.',
      );
      return;
    }
    if (
      templateData?.fine_tune === 'ideas' &&
      selectedProfile &&
      (!selectedProfile.audience?.length ||
        !selectedProfile.brand_keywords?.length ||
        !selectedProfile.description)
    ) {
      let string = '';
      string += !selectedProfile.description ? '"Description"' : '';
      string += !selectedProfile.audience.length ? ', "Audience"' : '';
      string += !selectedProfile.brand_keywords.length ? ', "Keywords"' : '';
      NotificationManager.error(
        `Please update project with ${string} or select another project`,
      );
      return;
    }

    if (!isUnlimited && (remaining_credits + pack_credits) < 1) {
      dispatch(setOpenUpgradeModal(true));
      return;
    }

    const payload = { ...values };

    getGeneratorId(
      inclusivity,
      isEmpty(templateData) ? template : templateData.code,
      isEmpty(templateData)
        ? templateCode || title
        : {
            template_id: templateData?.templateId,
            template: templateData?.template,
          },
      profileId,
      payload,
      () => {
        setSelectedTab(TAB_RESULTS);
      },
      addRecent,
    );
  };

  const addRecent = () => {
    storeRecent(
      title,
      `${location?.pathname}`,
      isTools ? 'recent-tools' : 'recent-templates',
    );
  };

  // FORM SUBMIT ENTER OR BUTTON
  const handleFormSubmit = (values: IInitialValuesObj) => {
    for (const [key, value] of Object.entries(values)) {
      setFineTuneVariable(
        `${templateData.name.replaceAll(' ', '')}${key}`,
        value,
      );
    }

    generateContent(values);
  };

  // BUTTON WRITE FOR ME / WRITE MORE
  const handleCreate = () => {
    if (formRef && formRef.current) {
      formRef.current?.handleSubmit();
    } else {
      // NO FORM TO SUBMIT so we generate content
      generateContent();
    }
  };

  useEffect(() => {
    if (selectedProfile.id > 0) {
      setProject(selectedProfile);
    }
  }, [selectedProfile]);

  return (
    <PageLayoutContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        formRef,
        template,
        templateCode: templateCode || title,
        templateTitle: title,
        viewCustomizeForm,
        setCustomizeForm,
        isTools,
      }}
    >
      <div className="flex flex-wrap justify-between items-center w-full gap-2 sm:mb-4 lg:h-16 pl-10">
        <FormHeader title={title} />
        <div className="flex flex-wrap gap-4 justify-center sm:justify-between w-full lg:w-auto pr-10">
          {!isUnlimited && (
            <div className="h-6 w-64 mb-4 md:mb-0 group">
              <CreditsBar />
            </div>
          )}
          {buttonStatus === 'loading' ? (
            <InlineLoader className="text-3xl" />
          ) : (
            <Button type="primary" onClick={handleCreate}>
              Write For Me
            </Button>
          )}
        </div>
      </div>
      <div className="lg:grid lg:grid-cols-12 lg:grid-rows-[32px_calc(100vh_-_152px)] pl-10 pr-10 lg:pr-0">
        <div className="-mb-px pt-4 sm:pt-0 flex space-x-8 border-b border-gray-200 lg:grid lg:grid-cols-12 lg:space-x-0 lg:col-span-12">
          <button
            className={clsx(
              'pb-2 px-1 border-b-2 font-medium text-sm w-min col-span-5',
              selectedTab === TAB_PROMPT
                ? 'border-ubblue text-ubblue'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
              'lg:border-ubblue lg:text-ubblue',
            )}
            onClick={() => setSelectedTab(TAB_PROMPT)}
          >
            Prompt
          </button>
          <button
            className={clsx(
              'pb-2 px-1 border-b-2 font-medium text-sm w-min',
              selectedTab === TAB_RESULTS
                ? 'border-ubblue text-ubblue'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
              !isEmpty(contentValue) || buttonStatus === 'loading'
                ? 'visible'
                : 'invisible',
              'lg:border-ubblue lg:text-ubblue lg:visible',
            )}
            onClick={() => setSelectedTab(TAB_RESULTS)}
          >
            Results
          </button>
        </div>
        <div
          className={clsx(
            selectedTab === TAB_PROMPT ? 'block' : 'hidden',
            'lg:block lg:pr-10 pt-4 col-span-5 overflow-y-auto',
          )}
        >
          <div className="">
            <div className="lg:grid lg:grid-cols-2 gap-4">
              <Language />
              <SelectProject />
            </div>
            <FineTune
              className="mt-8"
              isHidden={templateData?.fine_tune === 'no-ideas'}
              onSubmitForm={handleFormSubmit}
              inclusivity={inclusivity}
              setInclusivity={setInclusivity}
            />
            <div className="my-8" />
            {buttonStatus === 'loading' ? (
              <InlineLoader className="text-3xl" />
            ) : (
              <Button type="primary" onClick={handleCreate}>
                Write For Me
              </Button>
            )}
            <div className="my-8" />
            {!(templateData?.fine_tune === 'no-ideas') && selectedProfile && (
              <ManageProjects />
            )}
          </div>
        </div>
        <div
          className={clsx(
            selectedTab === TAB_RESULTS ? 'block' : 'hidden',
            'lg:block lg:bg-pampas pt-4 sm:p-6 md:p-10 col-span-7 overflow-y-auto',
          )}
        >
          <div>
            {buttonStatus === 'loading' && (
              <Skeletons templateName={templateName} />
            )}
            {contentValue.contents &&
              contentValue.contents.map((cont) => (
                <TemplateResult key={cont.id} data={cont} />
              ))}
          </div>
        </div>
      </div>
      <ScrollToTopOnMount />
      {loading && <Loader />}
    </PageLayoutContext.Provider>
  );
}

export default DynamicTemplate;
