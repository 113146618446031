import React from 'react';
import { clsx } from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPen,
  faTrashCan,
  faXmark,
  faCheck,
  faBoxArchive,
} from '@fortawesome/pro-light-svg-icons';
import { ICollectionProps } from './props';
import IconButton from '../../../../../common/IconButton';
import InputField from '../../../../../common/InputField';

function Collection({
  id,
  name,
  selected,
  onClickCollection,
  showButtons,
  onEditMode,
  onClickCancel,
  setObjEdit,
  objEdit,
  onSubmitChange,
  onClickDeleteCollection,
  inactive,
  disabledDelete,
}: ICollectionProps) {
  const onChangeEditObj = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const val = evt.target.value;
    if (val === '' || (val.length < 26 && /^[A-Za-z0-9_-]+$/.test(val))) {
      setObjEdit((pre) => (pre ? { ...pre, name: val, error: false } : null));
    } else {
      setObjEdit((pre) => (pre ? { ...pre, error: true } : null));
    }
  };

  return onEditMode ? (
    <div className="font-source group betterhover:hover:bg-link-water flex justify-between items-center">
      <InputField
        name={`input-edit-${id}`}
        placeholder=""
        value={objEdit?.name || ''}
        onChange={onChangeEditObj}
        borderError={!!objEdit?.error}
        error={false}
        inputFocus
        wrapperClass="my-auto"
        onKeyUp={(e) => {
          if (e.key === 'Enter') {
            onSubmitChange();
          }
        }}
      />
      <div className="min-w-fit">
        <IconButton
          className="text-scorpion text-lg"
          type="primary"
          onClick={onSubmitChange}
        >
          <FontAwesomeIcon icon={faCheck} />
        </IconButton>
        <IconButton
          className="text-scorpion text-lg"
          type="primary"
          onClick={onClickCancel}
        >
          <FontAwesomeIcon icon={faXmark} />
        </IconButton>
      </div>
    </div>
  ) : (
    <div
      className={clsx(
        'font-source flex w-full justify-between items-center',
        !inactive &&
          'betterhover:hover:text-ubblue betterhover:hover:bg-link-water',
      )}
    >
      <button
        onClick={() => (inactive ? null : onClickCollection(id, name))}
        className={clsx(
          'font-semibold px-2 py-1.5 flex items-center text-lg',
          selected
            ? 'text-ubblue'
            : inactive
            ? 'text-usilver'
            : 'text-scorpion',
        )}
        style={{ width: 'calc(100% - 60px)' }}
      >
        <FontAwesomeIcon icon={faBoxArchive} />
        <span
          className={clsx(
            'ml-2 font-source truncate text-base',
            selected ? 'font-semibold' : 'font-medium',
          )}
        >
          {name}
        </span>
        {inactive && (
          <div className="ml-2 font-source text-xs bg-silver-alto text-ugray px-2 rounded-sm">
            Inactive
          </div>
        )}
      </button>
      {!inactive && showButtons && (
        <div className="min-w-fit">
          <IconButton
            className="text-scorpion"
            type="primary"
            onClick={() =>
              setObjEdit({
                id,
                name,
                default: 1,
                error: false,
              })
            }
          >
            <FontAwesomeIcon icon={faPen} />
          </IconButton>
          <IconButton
            className={disabledDelete ? 'text-usilver' : 'text-scorpion'}
            type="primary"
            onClick={onClickDeleteCollection}
            disabled={disabledDelete}
          >
            <FontAwesomeIcon icon={faTrashCan} />
          </IconButton>
        </div>
      )}
    </div>
  );
}

export default Collection;
