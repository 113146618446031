/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';

import PropTypes from 'prop-types';
import ContentEditable from 'react-contenteditable';

import useTemplateState from '../../../../hooks/useTemplateState';
import { editTemplate } from '../../../savedcontent/actions';

import TemplateFooter from '../footer/TemplateFooter';

function TaglinesTemplate({ data, onEditMode, hideFooter }) {
  const { templateValue, handleOnChange } = useTemplateState(data);
  const { content } = templateValue;
  return (
    <div
      style={{
        position: 'relative',
        marginBottom: 25,
      }}
    >
      <div
        className="bg-white py-6 px-6 mb-2 shadow-md font-source"
        style={{
          boxShadow: '0px 4px 18px rgba(0, 0, 0, 0.06)',
          borderRadius: '20px',
        }}
      >
        <div className="font-semibold text-base xlg:text-xl">
          <ContentEditable
            disabled={!onEditMode}
            html={content}
            onBlur={({ target: { innerHTML } }) =>
              editTemplate(innerHTML, data, 'content')
            }
            onChange={(e) => handleOnChange(e.target.value, 'content')}
            spellCheck={false}
          />
        </div>
      </div>
      {!hideFooter && (
        <TemplateFooter
          data={templateValue}
          onEditMode={onEditMode}
          handleOnChange={handleOnChange}
          type="taglines"
        />
      )}
    </div>
  );
}
TaglinesTemplate.defaultProps = {
  data: {},
  onEditMode: false,
  hideFooter: false,
};

TaglinesTemplate.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.objectOf(PropTypes.any),
  onEditMode: PropTypes.bool,
  hideFooter: PropTypes.bool,
};
export default TaglinesTemplate;
