/* eslint-disable @typescript-eslint/no-empty-function */
import { NotificationManager } from 'react-notifications';

import { displayError } from '../../config/API';
import req from '../../redux-setup';

export const changePassword = (payload, callback = () => { }) => {
  req
    .post({
      url: '/v2/change_password',
      payload,
    })
    .then(() => {
      NotificationManager.success('Success', 'Password updated');
    })
    .catch((err) => displayError('change-password', err))
    .finally(() => callback());
};
