import { getStorage, storeStorage } from '../helpers';
import { RECENTS_LIST } from '../layout/constants';
import req from '../../redux-setup';

export const storeRecent = (template = '', link = '', name = '') => {
  const current = getStorage(name);
  const newTemplate = {
    template,
    link,
  };

  if (!current) {
    storeStorage(name, [newTemplate]);
    req.set(RECENTS_LIST, [newTemplate]);
    return;
  }
  const newTemplates = [
    newTemplate,
    ...current.filter((v) => v.template !== template),
  ];
  req.set(RECENTS_LIST, newTemplates);
  storeStorage(name, newTemplates.slice(0, 5));
};

export const getRecents = (name = '') => {
  const recents = getStorage(name);
  req.set(RECENTS_LIST, recents || []);
};
