import React from 'react';

import { clsx } from 'clsx';
import PropTypes from 'prop-types';

function Tooltip({
  children,
  position,
  text,
  width,
  fontSize,
  lineHeight,
  className,
}) {
  const [hover, setHover] = React.useState(false);

  const positions = {
    right: {
      left: 0,
    },
    left: {
      right: 0,
    },
    bottom: {
      bottom: -60,
      right: -100,
    },
    top: {
      top: -60,
      right: -100,
    },
  };

  return (
    <div
      className={clsx('relative w-max', className)}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {children}
      {hover && (
        <div
          className="absolute p-4 bg-white text-gray-700 rounded-lg text-sm md:text-base shadow-xl border"
          style={{
            zIndex: 9999,
            ...(positions[position] || {}),
            width,
            fontSize,
            lineHeight,
          }}
        >
          {text}
        </div>
      )}
    </div>
  );
}
Tooltip.defaultProps = {
  text: '',
  position: 'right',
  children: '',
  className: '',
  width: 'max-content',
  fontSize: null,
  lineHeight: null,
};
Tooltip.propTypes = {
  position: PropTypes.oneOf(['right', 'left', 'top', 'bottom']),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  text: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.object,
    PropTypes.string,
  ]),
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.object,
  ]),
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lineHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
export default React.memo(Tooltip);
