import React from 'react';

import BenefitsTemplate from '../templates/components/templates/BenefitsTemplate';
import BlogIdeasTemplates from '../templates/components/templates/BlogIdeasTemplate';
import ColdEmailTemplate from '../templates/components/templates/ColdEmailTemplate';
import ContentExpanderTemplate from '../templates/components/templates/ContentExpanderTemplate';
import FacebookAdsTemplate from '../templates/components/templates/FacebookAdsTemplate';
import GoogleAdsTemplate from '../templates/components/templates/GoogleAdsTemplate';
import HeaderDescriptionTemplate from '../templates/components/templates/HeaderDescriptionTemplate';
import LandingPagesTemplate from '../templates/components/templates/LandingPagesTemplate';
import ProdDescriptionTemplate from '../templates/components/templates/ProdDescriptionTemplate';
import RemixerTemplate from '../templates/components/templates/RemixerTemplates';
import TaglinesTemplate from '../templates/components/templates/TaglinesTemplate';
import WebsiteFeatureTemplate from '../templates/components/templates/WebsiteFeatureTemplate';
import AngelEmail from '../templates/containers/AngelEmail';
import BlogIdeas from '../templates/containers/BlogIdeas';
import ColdEmail from '../templates/containers/ColdEmail';
import FacebookAds from '../templates/containers/FacebookAds';
import GoogleAds from '../templates/containers/GoogleAds';
import LandingPages from '../templates/containers/LandingPages';
import ProductionDescriptions from '../templates/containers/ProductionDescriptions';
import Taglines from '../templates/containers/Taglines';
import TiktokIdeas from '../templates/containers/TiktokIdeas';
import VcEmail from '../templates/containers/VcEmail';
import Benefits from '../templates/containers/itemize/Benefits';
import HeaderDescription from '../templates/containers/itemize/HeaderDescription';
import WebsiteFeature from '../templates/containers/itemize/WebsiteFeature';
import ContentExpander from '../tools/containers/ContentExpander';
import Remixer from '../tools/containers/Remixer';
import ToneChanger from '../tools/containers/ToneChanger';

export const getTemplateByName = {
  'Google Ads': GoogleAdsTemplate,
  Taglines: TaglinesTemplate,
  'Landing Page Copy': LandingPagesTemplate,
  'Product Description': ProdDescriptionTemplate,
  'Facebook Ads': FacebookAdsTemplate,
  Features: WebsiteFeatureTemplate,
  Benefits: BenefitsTemplate,
  'Header + Description': HeaderDescriptionTemplate,
  'Blog Ideas': BlogIdeasTemplates,
  'TikTok Video Ideas': BlogIdeasTemplates,
  'Content Expander': ContentExpanderTemplate,
  Remix: RemixerTemplate,
  'Sales Outreach': ColdEmailTemplate,
  'Pitch a VC': ColdEmailTemplate,
  'Pitch an Angel': ColdEmailTemplate,
  default: () => (
    <div className="font-bold uppercase text-xl">
      We couldn&apos;t find this template. Please reach out to our support team.
    </div>
  ),
};

export const getTemplateContainerByType = {
  'google-ads': GoogleAds,
  taglines: Taglines,
  'landing-pages': LandingPages,
  'product-descriptions': ProductionDescriptions,
  'facebook-ads': FacebookAds,
  features: WebsiteFeature,
  benefits: Benefits,
  'header-description': HeaderDescription,
  'blog-ideas': BlogIdeas,
  'tiktok-ideas': TiktokIdeas,
  'content-expander': ContentExpander,
  remixer: Remixer,
  'cold-email': ColdEmail,
  'vc-email': VcEmail,
  'angel-email': AngelEmail,
  'tone-changer': ToneChanger,
  default: () => <div className="font-bold uppercase text-xl">NO TEMPLATE</div>,
};
