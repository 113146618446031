import React, { useEffect, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { SingleValue } from 'react-select';
import Button from '../../../../common/Button';
import {
  businessSize,
  usageFrequency,
  industryValues,
  needs,
} from '../../../../common/constants';
import InputField from '../../../../common/InputField';
import Keywords from '../../../../common/Keywords';
import { IDefaultSelect } from '../../../../common/props';
import SelectField from '../../../../common/SelectField';
import { sendPost } from '../../../../common/sendInfo';
import TextField from '../../../../common/TextField';
import {
  welcomeStep1URL,
  welcomeStep2URL,
  welcomeStep3URL,
} from '../../../../common/URLS';
import {
  setCollections,
  setDefaultProject,
} from '../../../../redux-setup/actions/common';
import {
  updateWelcome1,
  updateWelcome2,
  updateWelcome3,
} from '../../../../redux-setup/actions/profile';
import { getAvailableTemplates } from '../../../templates/actions';
import { IWelcomeFormProps } from './props';

function WelcomeForm({ step, setStep }: IWelcomeFormProps) {
  const dispatch = useDispatch();
  const userReduxInfo = useSelector(
    (state: RootStateOrAny) => state['PERSONALIZE/DATA'],
  );
  const [step1Input, setStep1Input] = useState({
    whatNeedToWrite: { value: '', label: 'Select...' },
    howOftenWrite: { value: '', label: 'Select...' },
  });
  const [step2Input, setStep2Input] = useState({
    companyName: { value: '', error: false },
    website: { value: '', error: false },
    industry: { value: '', label: 'Select...' },
    businessSize: { value: '', label: 'Select...' },
  });
  const [step3Input, setStep3Input] = useState({
    productService: { value: '', error: false },
    description: { value: '', error: false },
    audience: [] as string[],
    keywords: [] as string[],
  });
  const [audience, setAudience] = useState('');
  const [brandKeywords, setBrandKeywords] = useState('');
  const onChangeAudition = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const val = evt.target.value;
    if (val.length <= 50) {
      setAudience(val);
    }
  };
  const onChangeBrandKeywords = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const val = evt.target.value;
    if (val.length <= 50) {
      setBrandKeywords(val);
    }
  };

  const addBrandKeyword = () => {
    setStep3Input((prePr) => {
      const brkeys = brandKeywords.trim();
      setBrandKeywords('');
      if (brkeys) {
        return {
          ...prePr,
          keywords: [...prePr.keywords, brkeys],
        };
      } else {
        return prePr;
      }
    });
  };

  const addAudienceKeyword = () => {
    setStep3Input((prePr) => {
      const audi = audience.trim();
      setAudience('');
      if (audi) {
        return {
          ...prePr,
          audience: [...prePr.audience, audi],
        };
      } else {
        return prePr;
      }
    });
  };

  const handleRemoveAudience = (indx: number) => {
    setStep3Input((preP) => {
      if (preP) {
        const audience = preP.audience.filter((_val, inx) => inx !== indx);
        return { ...preP, audience: audience };
      }
      return preP;
    });
  };

  const handleRemoveBrandKeywords = (indx: number) => {
    setStep3Input((preP) => {
      if (preP) {
        const brandKeys = preP.keywords.filter((_val, inx) => inx !== indx);
        return { ...preP, keywords: brandKeys };
      }
      return preP;
    });
  };

  const handleChangeProduct = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    if (val === '' || val.length <= 25) {
      setStep3Input((pre) => ({
        ...pre,
        productService: { value: val, error: false },
      }));
    }
  };

  const handleChangeDescripton = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const val = e.target.value;
    if (val === '' || val.length <= 250) {
      setStep3Input((pre) => ({
        ...pre,
        description: { value: val, error: false },
      }));
    }
  };

  const checkDisabledButton = () => {
    if (step === 1) {
      return !(step1Input.howOftenWrite && step1Input.whatNeedToWrite);
    } else if (step === 2) {
      return !(
        step2Input.companyName.value &&
        step2Input.website.value &&
        step2Input.industry.value &&
        step2Input.businessSize.value &&
        !step2Input.companyName.error &&
        !step2Input.website.error
      );
    } else if (step === 3) {
      return !(
        step3Input.productService &&
        step3Input.description &&
        step3Input.audience.length &&
        step3Input.keywords.length &&
        !step3Input.productService.error &&
        !step3Input.description.error
      );
    }
  };

  const handleChangeStepTwoInput = (
    type: 'companyName' | 'website',
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const val = event.target.value;
    if (val === '' || val.length < 50) {
      if (type === 'companyName') {
        setStep2Input((pre) => ({
          ...pre,
          companyName: { value: val, error: false },
        }));
      } else if (type === 'website') {
        setStep2Input((pre) => ({
          ...pre,
          website: { value: val, error: false },
        }));
      }
    }
  };

  const handleChangeStepTwoSelect = (
    type: 'industry' | 'businessSize',
    newValue: SingleValue<IDefaultSelect>,
  ) => {
    if (newValue) {
      if (type === 'industry') {
        setStep2Input((pre) => ({ ...pre, industry: newValue }));
      } else if (type === 'businessSize') {
        setStep2Input((pre) => ({ ...pre, businessSize: newValue }));
      }
    }
  };

  const handleChangeStepOneSelect = (
    type: 'needWrite' | 'oftenWrite',
    newValue: SingleValue<IDefaultSelect>,
  ) => {
    if (newValue) {
      if (type === 'needWrite') {
        setStep1Input((pre) => ({ ...pre, whatNeedToWrite: newValue }));
      } else if (type === 'oftenWrite') {
        setStep1Input((pre) => ({ ...pre, howOftenWrite: newValue }));
      }
    }
  };

  const checkOnSubmitButton = async () => {
    if (step === 1) {
      const result = await sendPost(welcomeStep1URL(), {
        needs: step1Input.whatNeedToWrite.value,
        usageFrequency: step1Input.howOftenWrite.value,
      });
      if (result?.data) {
        dispatch(
          updateWelcome1({
            needs: result.data.needs,
            usageFrequency: result.data.usageFrequency,
          }),
        );
        const { company } = userReduxInfo;
        setStep2Input((pre) => ({
          ...pre,
          companyName: { value: company, error: false },
        }));
        setStep(2);
      } else {
        NotificationManager.error(result?.message);
      }
    } else if (step === 2) {
      const matchWebsite = step2Input.website.value.match(
        /^(https?:\/\/)?(www\.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)|(https?:\/\/)?(www\.)?(?!ww)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,10}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/,
      );
      const website = matchWebsite?.length ? matchWebsite[0] : '';
      if (
        !/^(https?:\/\/)?(www\.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)|(https?:\/\/)?(www\.)?(?!ww)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,10}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/.test(
          step2Input.website.value,
        )
      ) {
        setStep2Input((pre) => ({
          ...pre,
          website: { ...pre.website, error: true },
        }));
        return;
      } else if (step2Input.companyName.value.length < 3) {
        setStep2Input((pre) => ({
          ...pre,
          companyName: { ...pre.companyName, error: true },
        }));
        return;
      }
      const matchDomain = website
        .toLowerCase()
        .match(
          /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/,
        );
      const domain = matchDomain?.length
        ? matchDomain[0]
        : website.toLowerCase();
      const result = await sendPost(welcomeStep2URL(), {
        companyName: step2Input.companyName.value,
        website: website.replace(/^https?:\/\//, ''),
        domain: domain,
        industry: step2Input.industry.value,
        businessSize: step2Input.businessSize.value,
      });
      if (result?.data) {
        dispatch(
          updateWelcome2({
            companyName: result.data.companyName,
            website: result.data.website,
            industry: result.data.industry,
            businessSize: result.data.businessSize,
          }),
        );
        setStep(3);
      } else {
        NotificationManager.error(result?.message);
      }
    } else if (step === 3) {
      if (step3Input.productService.value.length < 3) {
        setStep3Input((pre) => ({
          ...pre,
          productService: { ...pre.productService, error: true },
        }));
        return;
      }
      if (step3Input.description.value.trim().split(' ').length < 10) {
        setStep3Input((pre) => ({
          ...pre,
          description: { ...pre.description, error: true },
        }));
        return;
      }
      const result = await sendPost(welcomeStep3URL(), {
        productService: step3Input.productService.value.trim(),
        description: step3Input.description.value.trim(),
        audience: step3Input.audience,
        keywords: step3Input.keywords,
      });
      if (result?.data) {
        const { collections, persona } = result.data;
        dispatch(setCollections(collections));
        dispatch(setDefaultProject(persona.id));
        dispatch(
          updateWelcome3({
            companyName: result.data.productService,
            description: result.data.description,
            audience: result.data.audience,
            Keywords: result.data.keywords,
          }),
        );
        getAvailableTemplates();
        setStep(3);
      } else {
        NotificationManager.error(result?.message);
      }
    }
  };

  useEffect(() => {
    const { needs, usage_frequency } = userReduxInfo;
    if (needs && usage_frequency) {
      const { company, company_name, website, industry, business_size } =
        userReduxInfo;
      setStep2Input((pre) => ({
        ...pre,
        companyName: { value: company, error: false },
      }));
      if (company_name && website && industry && business_size) {
        setStep(3);
      } else {
        setStep(2);
      }
    } else {
      setStep(1);
    }
  }, []);

  return (
    <div>
      {step === 1 && (
        <div>
          <SelectField
            label="What do you need to write?"
            required
            value={step1Input.whatNeedToWrite}
            options={needs}
            onChangeSelect={(nv) => handleChangeStepOneSelect('needWrite', nv)}
            classNameLabel="text-lg font-semibold text-ubblack leading-10"
          />
          <SelectField
            label="How often do you write this?"
            required
            value={step1Input.howOftenWrite}
            options={usageFrequency}
            onChangeSelect={(nv) => handleChangeStepOneSelect('oftenWrite', nv)}
            classNameLabel="text-lg font-semibold text-ubblack leading-10"
          />
        </div>
      )}
      {step === 2 && (
        <div>
          <InputField
            wrapperClass="mt-4"
            label="Company Name"
            name="Company Name"
            required
            placeholder="MyCompany"
            value={step2Input.companyName.value}
            onChange={(e) => handleChangeStepTwoInput('companyName', e)}
            error={step2Input.companyName.error}
            errorMsg={'Company name must have at least 3 characters'}
            tooltip="Enter the name of your company. It will also be the name of your first Collection of Projects."
            classNameLabel="text-lg font-semibold text-ubblack leading-10"
            classNameTooltip="text-sm ml-1"
          />
          <InputField
            wrapperClass="mt-4"
            label="Website"
            name="Website"
            required
            placeholder="mycompany.com"
            value={step2Input.website.value}
            onChange={(e) => handleChangeStepTwoInput('website', e)}
            error={step2Input.website.error}
            errorMsg={'The website must be valid'}
            tooltip="Enter the URL of your company website. We will use this to deliver a more personalized experience and better results."
            classNameLabel="text-lg font-semibold text-ubblack leading-10"
            classNameTooltip="text-sm ml-1"
          />
          <SelectField
            label="Industry"
            required
            value={step2Input.industry}
            options={industryValues}
            onChangeSelect={(nv) => handleChangeStepTwoSelect('industry', nv)}
            classNameLabel="text-lg font-semibold text-ubblack leading-10"
          />
          <SelectField
            label="Business size"
            required
            value={step2Input.businessSize}
            options={businessSize}
            onChangeSelect={(nv) =>
              handleChangeStepTwoSelect('businessSize', nv)
            }
            classNameLabel="text-lg font-semibold text-ubblack leading-10"
          />
        </div>
      )}
      {step === 3 && (
        <div>
          <InputField
            wrapperClass="mt-4"
            label="Project / Product / Service Name"
            name="product-service-name"
            required
            placeholder="Shopii"
            value={step3Input.productService.value}
            onChange={handleChangeProduct}
            error={step3Input.productService.error}
            errorMsg="Product/Service name must have at least 3 charactes"
            classNameLabel="text-lg font-semibold text-ubblack leading-10"
            tooltip="The name of your first Project. We will also use it in some of the templates for copy generations."
            classNameTooltip="text-sm ml-1"
          />
          <TextField
            wrapperClass="mt-4"
            name="description"
            label="Description"
            required
            placeholder="Our builder lets you quickly create an online marketplace to sell your goods — A no-code e-commerce platform for small businesses. Start your online shop now."
            rows={6}
            resize
            onChange={handleChangeDescripton}
            value={step3Input.description.value}
            error={step3Input.description.error}
            errorMsg="Description must have at least 10 words"
            onPaste={(ev) => {
              ev.preventDefault();
              const data = ev.clipboardData
                .getData('text')
                .trim()
                .substring(0, 249);
              setStep3Input((inputV) => ({
                ...inputV,
                description: { ...inputV.description, value: data },
              }));
            }}
            maxCounter={250}
            classNameLabel="text-lg font-semibold text-ubblack leading-10"
            tooltip='Describe what your Project is about in a few sentences. Take your time on this, as it will significantly impact the quality of the output.
            For example: "Rid yourself of pain conveniently with our affordable physiotherapy clinics. Insurance Reimbursable.'
            classNameTooltip="text-sm ml-1"
          />
          <Keywords
            label="Audience"
            required
            keywords={step3Input.audience}
            wrapperClass="my-2"
            onChangeInput={onChangeAudition}
            inputValue={audience}
            name="audience"
            addKeyword={addAudienceKeyword}
            handleRemoveKeyword={handleRemoveAudience}
            maxInput={25}
            classNameLabel="text-lg font-semibold text-ubblack leading-10"
            tooltip="Add keywords that best describe the intended target group for your content. For example, based on demographics (millennials, gen z), types of users (consumers, business owners) or something entirely different (industrial supplies purchasers)."
            classNameTooltip="text-sm ml-1"
            onPasteInput={(evt) => {
              evt.preventDefault();
              const data = evt.clipboardData
                .getData('text')
                .trim()
                .substring(0, 50);
              setAudience(data);
            }}
          />
          <Keywords
            wrapperClass="my-2"
            label="Keywords"
            required
            keywords={step3Input.keywords}
            onChangeInput={onChangeBrandKeywords}
            inputValue={brandKeywords}
            name="keywords"
            addKeyword={addBrandKeyword}
            handleRemoveKeyword={handleRemoveBrandKeywords}
            maxInput={25}
            classNameLabel="text-lg font-semibold text-ubblack leading-10"
            tooltip="Add keywords to describe your product, service, or brand. They can be keywords and phrases that people may be looking for or the names of products/services you offer."
            classNameTooltip="text-sm ml-1"
            onPasteInput={(evt) => {
              evt.preventDefault();
              const data = evt.clipboardData
                .getData('text')
                .trim()
                .substring(0, 50);
              setBrandKeywords(data);
            }}
          />
        </div>
      )}
      <Button
        type="primary"
        disabled={checkDisabledButton()}
        className="w-full mt-12 py-3"
        onClick={checkOnSubmitButton}
      >
        {step === 1 && 'Continue'}
        {step === 2 && 'Almost there...'}
        {step === 3 && "I'm ready to start writing"}
      </Button>
    </div>
  );
}

export default WelcomeForm;
