import { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import { getSharedContent } from './actions';

const errorMessage =
  "Hmm, there's nothing here. Please make sure you have the right link and sharing is enabled.";
// eslint-disable-next-line import/prefer-default-export
export const useSharedTemplate = () => {
  const { id } = useParams();
  const [error, setError] = useState();
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState();

  useEffect(() => {
    getSharedContent(
      id,
      (res) => {
        if (!res?.shared) setError(errorMessage);
        else setData(res);
      },
      () => setError(errorMessage),
      setLoading(false),
    );
  }, [id]);

  return {
    isLoading,
    error,
    data,
  };
};
