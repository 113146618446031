import React from 'react';
import ContentEditable from 'react-contenteditable';
import useTemplateState from '../../../../../../hooks/useTemplateState';
import { editTemplate } from '../../../../../savedcontent/actions';
import TemplateFooter from '../../../../components/footer/TemplateFooter';
import { IFacebookAdsResultProps } from './props';

function FacebookAdsResult({
  data,
  onEditMode = false,
  hideFooter = false,
}: IFacebookAdsResultProps) {
  const { templateValue, handleOnChange } = useTemplateState(data);
  // eslint-disable-next-line no-unsafe-optional-chaining
  const { companyName, description, headline, image } = templateValue?.content;
  const initial = companyName ? companyName.charAt(0) : 'C';

  return (
    <div className="relative mb-4 px-px">
      <div className="border lg:border-0 border-gallery bg-white py-4 px-6 mb-3 shadow-lg font-source rounded-lg">
        <div className="w-full flex justify-between p-6">
          <div className="flex">
            <div className="w-full flex mb-4">
              <div
                className="flex overflow-hidden items-center justify-center rounded-full w-12 h-12"
                style={{ backgroundColor: '#E6F7FF' }}
              >
                <span
                  className="font-semibold text-3xl"
                  style={{ color: '#91D5ff' }}
                >
                  {initial}
                </span>
              </div>
              <div className="flex-grow pl-3">
                <h6 className="font-bold text-blue-600 text-md mxl:text-lg">
                  <ContentEditable
                    html={companyName}
                    disabled={!onEditMode}
                    onBlur={({ target: { innerHTML } }) =>
                      editTemplate(innerHTML, data, 'content.companyName')
                    }
                    onChange={(e) =>
                      handleOnChange(e.target.value, 'content.companyName')
                    }
                    spellCheck={false}
                  />
                </h6>
                <p className="text-sm mxl:text-md font-bold">Sponsored</p>
              </div>
            </div>
          </div>
          <span className="px-2 text-gray-400 rounded">
            <i className="fas fa-ellipsis-h pt-2 text-lg" />
          </span>
        </div>
        <div className="flex pl-4">
          <div className="text-sm sm:text-base mxl:text-lg mb-5">
            <ContentEditable
              html={description}
              disabled={!onEditMode}
              onBlur={({ target: { innerHTML } }) =>
                editTemplate(innerHTML, data, 'content.description')
              }
              onChange={(e) =>
                handleOnChange(e.target.value, 'content.description')
              }
              spellCheck={false}
            />
          </div>
        </div>
        <img src={image} />
        <div className="w-full flex flex-col sm:flex-row justify-between p-5">
          <div className="flex flex-col">
            <h6 className="text-gray-500 text-xs mxl:text-md">
              WWW.
              {companyName?.toUpperCase()?.replace(/\s/g, '')}
              .COM
            </h6>
            <p className="text-xs lg:text-base mxl:text-lg mt-2 font-bold">
              {headline}
            </p>
          </div>
          <button
            type="button"
            className="text-sm mxl:text-base my-2 bg-gray-200 font-semibold py-2 px-4 border border-gray-400 rounded"
          >
            Learn More
          </button>
        </div>
      </div>
      {!hideFooter && (
        <TemplateFooter
          data={templateValue}
          onEditMode={onEditMode}
          handleOnChange={handleOnChange}
          type="facebook-ads"
        />
      )}
    </div>
  );
}

export default FacebookAdsResult;
