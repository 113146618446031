import React from 'react';
import Container from '../../../common/Container';
import Logo from '../../../../assets/img/UBSmartCopy.svg';
import { IFirstStepUBProps } from './props';
import Button from '../../../../common/Button';

function FirstStepUB({ setStep }: IFirstStepUBProps) {
  return (
    <div className="wrapper clearfix">
      <div className="auth-left-panel">
        <section>
          <Container>
            <div
              className="mx-auto my-auto max-w-screen-md mt-12 w-full bg-white px-12 pt-8 pb-16 "
              style={{ maxWidth: 600 }}
            >
              <div
                className="my-5 flex justify-center"
                style={{ marginBottom: 50 }}
              >
                <img src={Logo} height={40} />
              </div>
              <p className="font-source text-xl text-mine-shaft">
                As an Unbounce customer you also have access to{' '}
                <span className="font-source text-xl font-semibold">
                  Smart Copy
                </span>
                , our AI copy assistant.
                <br />
                Set up your password and let the AI write for you.
              </p>
              <Button
                type="primary"
                className="w-full mt-8"
                onClick={() => setStep(2)}
              >
                Get Started
              </Button>
            </div>
          </Container>
        </section>
      </div>

      <div className="auth-right-panel">
        <iframe
          frameBorder="0"
          height="100%"
          scrolling="no"
          src="https://smartcopy.ubpages.com/login/"
          width="100%"
          title="Login"
        />
      </div>
    </div>
  );
}

export default FirstStepUB;
