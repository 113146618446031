import React from 'react';
import { clsx } from 'clsx';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import { useLayoutContext } from '../../layout/hooks';
import ScrollToTop from '../../layout/components/ScrollToTop';
import TemplateCards from './MarketCards';
import * as c from '../constants';

function TemplateCategory({ category, data }) {
  const { showNotepad } = useLayoutContext();
  const match = useRouteMatch();
  const templatesList = Array.isArray(data) ? data : data?.templates || [];

  return (
    <div className="flex flex-col my-8 px-8">
      <h1 className="text-xl font-barlow">{category}</h1>
      <motion.div
        variants={c.templatesContainerVariants}
        initial="hide"
        animate="show"
        className={clsx(
          'grid gap-y-4 sm:gap-y-8 gap-x-8 mt-6',
          showNotepad
            ? 'grid-cols-1 lg:grid-cols-2 slg:grid-cols-3'
            : 'grid-cols-1 md:grid-cols-2 lg:grid-cols-3',
        )}
      >
        {templatesList.map((template) => (
          <TemplateCards
            prefix={match.url}
            key={template.id}
            template={{
              ...template,
              ...data?.config,
            }}
          />
        ))}
        <ScrollToTop />
      </motion.div>
      {/* <div className="
        bg-green-100
        text-green-100
        bg-green-200
        text-green-200
        bg-green-300
        text-green-300
        bg-green-400
        text-green-400
        bg-green-500
        text-green-500
        bg-green-600
        text-green-600
        bg-green-700
        text-green-700
        bg-green-800
        text-green-800
        bg-green-900
        text-green-900
        bg-blue-100
        text-blue-100
        bg-blue-200
        text-blue-200
        bg-blue-300
        text-blue-300
        bg-blue-400
        text-blue-400
        bg-blue-500
        text-blue-500
        bg-blue-600
        text-blue-600
        bg-blue-700
        text-blue-700
        bg-blue-800
        text-blue-800
        bg-blue-900
        text-blue-900
        bg-yellow-100
        text-yellow-100
        bg-yellow-200
        text-yellow-200
        bg-yellow-300
        text-yellow-300
        bg-yellow-400
        text-yellow-400
        bg-yellow-500
        text-yellow-500
        bg-yellow-600
        text-yellow-600
        bg-yellow-700
        text-yellow-700
        bg-yellow-800
        text-yellow-800
        bg-yellow-900
        text-yellow-900
        bg-indigo-100
        text-indigo-100
        bg-indigo-200
        text-indigo-200
        bg-indigo-300
        text-indigo-300
        bg-indigo-400
        text-indigo-400
        bg-indigo-500
        text-indigo-500
        bg-indigo-600
        text-indigo-600
        bg-indigo-700
        text-indigo-700
        bg-indigo-800
        text-indigo-800
        bg-indigo-900
        text-indigo-900
      "
      /> */}
    </div>
  );
}

TemplateCategory.propTypes = {
  category: PropTypes.string.isRequired,
  data: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.objectOf(PropTypes.any),
  ]).isRequired,
};
export default TemplateCategory;
