/* eslint-disable @typescript-eslint/no-empty-function */
import req from '../../redux-setup';

import { CONFIRMATION_DATA } from './constants';

export const showConfirmation = (
  message = '',
  onOk = () => { },
  onCancel = () => { },
) => req.set(CONFIRMATION_DATA, { message, onOk, onCancel });

export const sendFeedback = (
  payload = {},
  callback = () => { },
  errorCallback = () => { },
) => {
  req
    .post({
      url: '/v2/feedback',
      payload,
    })
    .then(({ data }) => {
      callback(data);
    })
    .catch(() => errorCallback());
};
