import React, { useState } from 'react';
import * as Yup from 'yup';
import { Button } from '@unbounce/ui-components/esm';
import { Field, Form, Formik } from 'formik';
import { AlertMarker } from '../../../common/Alerts';
import { InputSm } from '../../../common/Formik/FormInputs';
import LogoUBSC from '../LogoUBSC';
import { ISignupPasswordProps } from './props';
// eslint-disable-next-line import/no-named-as-default
import Axios from 'axios';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../../../redux-setup/actions/loading';
import { setToken } from '../../../../config/token';
import req from '../../../../redux-setup';
import { AUTHENTICATION } from '../../../auth/constants';
import { displayError, handleErrors } from '../../../../config/API';
import { API } from '../../../../common/URLS';

const rqdmsg = 'This field is required.';
const validateForm = Yup.object().shape({
  password: Yup.string()
    .required(rqdmsg)
    .min(8)
    .max(50)
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/,
      'Your password must be at least 8 characters long and must contain both uppercase and lowercase letters, at least one special character, and at least one number.',
    )
    .label('Password'),
  passwordConfirm: Yup.string()
    .required(rqdmsg)
    .oneOf([Yup.ref('password')], 'Passwords must match')
    .min(8)
    .max(50)
    .label('Password Confirm'),
});

function SignupPassword({ email }: ISignupPasswordProps) {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const handleSignup = async (
    values: { password: string; passwordConfirm: string },
    setSubmitting: (isSubmitig: boolean) => void,
  ) => {
    dispatch(setLoading(true));
    try {
      const res = await Axios.post(`${API}/sc/register`, {
        ...values,
        email,
      });
      setToken(res.data.access_token);
      req.set(AUTHENTICATION, true);
    } catch (error) {
      handleErrors(error);
      displayError('register', error);
      window.scrollTo(0, 0);
    } finally {
      dispatch(setLoading(false));
      setSubmitting(false);
    }
  };

  return (
    <div className="w-full">
      <section className="h-screen flex">
        <div
          className="w-full px-6 m-auto xl:w-10/12 md:w-11/12 md:px-0 box-border"
          style={{ maxWidth: 600 }}
        >
          <LogoUBSC className="h-10" />
          <p className="font-source text-xl text-mine-shaft">
            Create a password for{' '}
            <span className="font-source font-semibold">{email}</span>:
          </p>
          <Formik
            validationSchema={validateForm}
            initialValues={{
              password: '',
              passwordConfirm: '',
            }}
            onSubmit={(value, { setSubmitting }) => {
              handleSignup(value, setSubmitting);
            }}
          >
            {({ values, handleSubmit, isSubmitting }) => {
              return (
                <Form>
                  <AlertMarker name="register" />
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4">
                    <Field
                      label="Password"
                      component={InputSm}
                      name="password"
                      type={showPassword ? 'text' : 'password'}
                      value={values.password}
                      placeholder="Password"
                      wrapperClass="mt-6 font-bold"
                      isPassword={true}
                      show={showPassword}
                      changeShow={setShowPassword}
                      maxLength={51}
                      onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) =>
                        e.which === 13 ? handleSubmit() : null
                      }
                    />

                    <Field
                      label="Confirm Password"
                      component={InputSm}
                      name="passwordConfirm"
                      type={showConfirm ? 'text' : 'password'}
                      isPassword={true}
                      show={showConfirm}
                      changeShow={setShowConfirm}
                      value={values.passwordConfirm}
                      placeholder="Confirm Password"
                      wrapperClass="mt-6 font-bold"
                      maxLength={51}
                      onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) =>
                        e.which === 13 ? handleSubmit() : null
                      }
                    />
                  </div>
                  <div className="px-0 md:px-20">
                    <Button
                      primary
                      fullWidth
                      name="Continue"
                      className="mt-8"
                      large
                      onClick={() => {
                        handleSubmit();
                      }}
                      disabled={isSubmitting}
                    >
                      Continue
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </section>
    </div>
  );
}

export default SignupPassword;
