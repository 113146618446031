/* eslint-disable react/prop-types */
import React from 'react';

function SmallCard({
  title,
  paragraph,
  learnMoreLink,
  imageLink,
  buttonText,
  badge,
  buttonLink,
  id,
}) {
  return (
    <div className="border bg-pampas rounded-lg relative">
      <div
        className={`bg-shamrock absolute w-3 h-32 absolute top-6${badge === 'Coming Soon' ? ' opacity-50' : ''
          }`}
      />
      <div
        className={`bg-shamrock absolute w-3 h-14 absolute right-0 bottom-6${badge === 'Coming Soon' ? ' opacity-50' : ''
          }`}
      />
      {badge && (
        <div className="bg-shamrock absolute py-1 px-2 absolute right-0 top-4 text-white text-xs font-semibold">
          {badge}
        </div>
      )}
      <div
        className={`flex pl-9 py-9 pr-9 lg:pr-1${badge === 'Coming Soon' ? ' opacity-50' : ''
          }`}
      >
        <div className="w-full lg:w-2/3">
          <h2 className="font-source text-mine-shaft text-2xl font-semibold">
            {title}
          </h2>
          <p className="font-source text-mine-shaft text-sm my-6">
            {paragraph}
          </p>
          <div className="flex flex-wrap gap-y-4">
            {badge === 'Coming Soon' ? (
              <span className="mr-4 border border-ugray text-ugray font-source font-semibold text-sm px-4 py-2 rounded">
                {buttonText}
              </span>
            ) : (
              <a
                href={buttonLink}
                id={id}
                target="_blank"
                rel="noopener noreferrer"
                className="text-white font-source font-semibold text-sm bg-ubblue mr-4 px-4 py-2 rounded betterhover:hover:bg-klein-blue"
              >
                {buttonText}
              </a>
            )}
            {badge === 'Coming Soon' ? (
              <span className="border border-ugray text-ugray font-source font-semibold text-sm px-4 py-2 rounded">
                Learn More
              </span>
            ) : (
              <a
                href={learnMoreLink}
                target="_blank"
                rel="noopener noreferrer"
                className="border border-ubblue text-ubblue font-source font-semibold text-sm px-4 py-2 rounded betterhover:hover:bg-klein-blue betterhover:hover:text-white"
                disabled={badge === 'Coming Soon'}
              >
                Learn More
              </a>
            )}
          </div>
        </div>
        <div className="w-1/3 hidden lg:flex items-center justify-center h-auto">
          <img className="max-h-40" src={imageLink} alt={title} />
        </div>
      </div>
    </div>
  );
}

export default SmallCard;
