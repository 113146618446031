/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable camelcase */
import React from 'react';
import { clsx } from 'clsx';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { unsavedTemplate, updateTemplate } from '../../../../config/token';
import {
  getTemplateTypeByName,
  useGetTemplateByRoute,
} from '../../../../hooks/useGetTemplateByRoute';
import { useCurrentTemplate } from '../../hooks';
import req from '../../../../redux-setup';
import {
  copyContent,
  saveContent,
  deleteContent,
  updateContent,
} from '../../actions';
import { SHARE_DATA } from '../share/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBookmark,
  faCopy,
  faLink,
  faShareNodes,
} from '@fortawesome/pro-light-svg-icons';
import Tippy from '@tippyjs/react';

function TemplateActions({ data, onEditMode, isDemo }) {
  const infoData = useGetTemplateByRoute();
  const templateData = useCurrentTemplate();
  const templateInfo = React.useMemo(() => {
    if (onEditMode) {
      return getTemplateTypeByName(data?.template);
    }

    if (!isEmpty(templateData)) {
      return {
        template: templateData?.type,
        title: templateData?.name,
        useTitle: true,
      };
    }
    return infoData;
  }, [onEditMode, infoData, data, templateData]);
  const [isSaved, setSaved] = React.useState(data?.saved || false);
  const [saveId, setSaveId] = React.useState(data?.saveId || '');
  const [shared, setShared] = React.useState(data?.shared || false);
  const [sharingId, setSharingId] = React.useState(data?.sharing_id || '');
  const [showSaved, setShowSaved] = React.useState(false);
  const [showCopied, setShowCopied] = React.useState(false);

  const updateShareStatus = React.useCallback((isShared) => {
    setShared(isShared);
  }, []);

  const showSavedText = React.useCallback(() => {
    setShowSaved(true);
    setTimeout(() => setShowSaved(false), 3000);
  }, []);

  const handleSave = React.useCallback(
    (payload = {}, callback = () => {}) => {
      if (payload?.companyName && payload.website) {
        payload.content.companyName = payload.companyName;
        payload.content.website = payload.website;
      }
      setSaved(true);
      saveContent(payload, templateInfo, (id, res, sharing_id) => {
        setSaveId(id);
        if (!payload?.shared) {
          showSavedText();
        }
        if (sharing_id) setSharingId(sharing_id);
        callback(res);
      });
    },
    [showSavedText, templateInfo],
  );

  const handleClickShare = React.useCallback(() => {
    if (!isSaved && !onEditMode) {
      setShared(true);
      handleSave({ ...data, shared: true }, (res) => {
        req.set(SHARE_DATA, {
          ...res,
          templateCode: templateInfo?.template,
          updateShareStatus,
        });
      });
      return;
    }

    if (!shared) {
      setShared(true);
      updateContent(
        {
          shared: true,
          id: onEditMode ? data?.id : saveId,
        },
        () => {
          updateTemplate(
            templateInfo?.template,
            data?.id,
            {
              shared: true,
            },
            () => {},
            onEditMode ? 'saveId' : 'id',
          );
        },
      );
    }

    req.set(SHARE_DATA, {
      ...data,
      saveId,
      saved: isSaved,
      shared: true,
      sharing_id: sharingId,
      templateCode: templateInfo?.template,
      updateShareStatus,
      onEditMode,
    });
  }, [
    data,
    saveId,
    isSaved,
    handleSave,
    onEditMode,
    shared,
    sharingId,
    templateInfo,
    updateShareStatus,
  ]);

  const showCopiedText = React.useCallback(() => {
    setShowCopied(true);
    setTimeout(() => setShowCopied(false), 5000);
  }, []);

  const CopyButton = React.useMemo(
    () => (
      <Tippy theme="light-border" content="Copy to clipboard">
        <button
          type="button"
          style={{
            outline: 'none',
          }}
          onClick={() => copyContent(data, showCopiedText)}
          className="flex items-center"
        >
          <FontAwesomeIcon
            icon={faCopy}
            className="betterhover:hover:text-ubblue"
          />
          {showCopied && (
            <span className="text-ubblue text-sm font-source ml-1">
              Copied!
            </span>
          )}
        </button>
      </Tippy>
    ),
    [data, showCopied, showCopiedText],
  );

  const SaveButton = React.useMemo(
    () => (
      <Tippy theme="light-border" content="Create a shareable link">
        <button
          className="flex items-center betterhover:hover:text-ubblue"
          type="button"
          onClick={handleClickShare}
        >
          {shared ? (
            <FontAwesomeIcon icon={faLink} />
          ) : (
            <FontAwesomeIcon icon={faShareNodes} />
          )}
        </button>
      </Tippy>
    ),
    [handleClickShare, shared],
  );

  if (onEditMode) {
    return (
      <div className="flex justify-between items-start space-x-3">
        {SaveButton}
        {CopyButton}
      </div>
    );
  }

  return (
    <div className="flex justify-between items-start space-x-3">
      {data.words && data.caracters_count && (
        <div className="text-xs md:text-sm text-ugray font-light font-source mx-1">
          {data?.words || ''}{' '}
          <span className="hidden xs:inline-block">words</span>{' '}
          <span className="inline-block xs:hidden">w</span> /{' '}
          {data?.caracters_count || ''}{' '}
          <span className="hidden xs:inline-block">characters</span>{' '}
          <span className="inline-block xs:hidden">c</span>{' '}
        </div>
      )}
      <div className="flex flex-wrap gap-2 lg:gap-3">
        {SaveButton}
        {CopyButton}
        <Tippy
          theme="light-border"
          content={isSaved ? 'Remove from Saved' : 'Add to Saved'}
        >
          <button
            className={clsx(
              'flex items-center',
              isSaved
                ? 'betterhover:hover:text-amaranth text-ubblue'
                : 'betterhover:hover:text-ubblue',
            )}
            type="button"
            style={{
              outline: 'none',
            }}
            onClick={() => {
              if (!isDemo) {
                if (!isSaved) {
                  handleSave(data);
                } else {
                  setSaved(false);
                  deleteContent(saveId, () => {
                    unsavedTemplate(templateInfo?.template, data.id);
                  });
                }
              }
            }}
          >
            {isSaved ? (
              <FontAwesomeIcon icon={faBookmark} />
            ) : (
              <FontAwesomeIcon icon={faBookmark} />
            )}
            {showSaved && (
              <span className="text-ubblue text-sm font-source ml-1">
                Saved!
              </span>
            )}
          </button>
        </Tippy>
      </div>
    </div>
  );
}

TemplateActions.defaultProps = {
  data: {},
  onEditMode: false,
  isDemo: false,
};
TemplateActions.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.objectOf(PropTypes.any),
  onEditMode: PropTypes.bool,
  isDemo: PropTypes.bool,
};

export default TemplateActions;
