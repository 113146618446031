/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import BingAds from './BingAds';
import FacebookAds from './FacebookAds';
import GoogleAds from './GoogleAds';
import LinkedInAds from './LinkedInAds';
import TwitterAds from './TwitterAds';
import { ITemplateResultProps } from './props';
import BingAdsBrand from '../BrandAds/BingAdsBrand';
import GoogleAdsBrand from '../BrandAds/GoogleAdsBrand';
import TwitterAdsBrand from '../BrandAds/TwitterAdsBrand';
import FacebookAdsBrand from '../BrandAds/FacebookAdsBrand';
import LinkedInAdsBrand from '../BrandAds/LinkedInAdsBrand';
import TemplateFooter from '../../modules/templates/components/footer/TemplateFooter';
import { sendCopied } from '../sendInfo';

function TemplateResult({
  data,
  companyName,
  website,
  onEditMode,
}: ITemplateResultProps) {
  const onCopyHandler = () => {
    sendCopied({
      id: data.id,
      value: document.getSelection()?.toString(),
    });
  };

  switch (data.template.toLowerCase()) {
    case 'google ads v2':
      return (
        <div>
          <div className="flex justify-center my-8">
            <GoogleAdsBrand />
          </div>
          <GoogleAds
            content={data}
            onCopy={onCopyHandler}
          />
          <div className="mt-2">
            <TemplateFooter
              data={data}
              onEditMode={onEditMode}
              handleOnChange={() => null}
            />
          </div>
        </div>
      );
    case 'facebook ads v2':
      return (
        <div>
          <div className="flex justify-center my-8">
            <FacebookAdsBrand />
          </div>
          <FacebookAds
            content={data}
            companyName={companyName}
            website={website}
            onCopy={onCopyHandler}
          />
          <div className="mt-2 xl:w-[600px] mx-auto">
            <TemplateFooter
              data={data}
              onEditMode={onEditMode}
              handleOnChange={() => null}
            />
          </div>
        </div>
      );
    case 'linkedin ads v2':
      return (
        <div>
          <div className="flex justify-center my-8">
            <LinkedInAdsBrand />
          </div>
          <LinkedInAds
            content={data}
            companyName={companyName}
            website={website}
            onCopy={onCopyHandler}
          />
          <div className="mt-2 xl:w-[600px] mx-auto">
            <TemplateFooter
              data={data}
              onEditMode={onEditMode}
              handleOnChange={() => null}
            />
          </div>
        </div>
      );
    case 'bing ads v2':
      return (
        <div>
          <div className="flex justify-center my-8">
            <BingAdsBrand />
          </div>
          <BingAds
            content={data}
            onCopy={onCopyHandler}
          />
          <div className="mt-2">
            <TemplateFooter
              data={data}
              onEditMode={onEditMode}
              handleOnChange={() => null}
            />
          </div>
        </div>
      );
    case 'twitter ads v2':
      return (
        <div>
          <div className="flex justify-center my-8">
            <TwitterAdsBrand />
          </div>
          <TwitterAds
            content={data}
            companyName={companyName}
            website={website}
            onCopy={onCopyHandler}
          />
          <div className="mt-2 xl:w-[600px] mx-auto">
            <TemplateFooter
              data={data}
              onEditMode={onEditMode}
              handleOnChange={() => null}
            />
          </div>
        </div>
      );
    default:
      return null;
  }
}

export default TemplateResult;
