/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable default-param-last */
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
import * as dayjs from 'dayjs';
import * as Feather from 'react-icons/fi';
import { displayError } from '../../config/API';
import { updateTemplate } from '../../config/token';
import { createAlert } from '../common/Alerts';
import { convertToSlug, copyWords } from '../helpers';
import { SAVED_CONTENT } from '../savedcontent/constants';
import req from '../../redux-setup';
import { SHARE_DATA } from './components/share/constants';
import { sendCopied } from '../../common/sendInfo';

export const getContents = () =>
  req
    .get({
      url: '/saved-content',
    })
    .then(({ data }) => {
      req.set(SAVED_CONTENT, data);
    });

export const saveContent = async (
  itemToSave,
  templateInfo,
  callback = () => {},
) => {
  const { title, template, useTitle } = templateInfo;
  const { content, shared, persona_id, id: output_id } = itemToSave;
  const payload = {
    name: `${title} - ${dayjs().format('MM/DD/YYYY')}`,
    sub_template: 'Magic',
    content,
    template: useTitle ? title : itemToSave?.template || title,
    persona_id,
    output_id,
  };
  if (shared) payload.shared = true;
  req
    .post({
      url: '/saved-content/create',
      payload,
      useLoading: false,
    })
    .then(({ data }) => {
      const newPayload = {};

      if (data?.shared) {
        newPayload.shared = true;
        newPayload.sharing_id = data?.sharing_id || '';
      }

      updateTemplate(template, itemToSave.id, {
        saved: true,
        saveId: data?.id,
        ...newPayload,
      });

      callback(
        data.id,
        {
          ...data,
          saved: true,
          saveId: data?.id,
        },
        data?.sharing_id,
      );
    });
};

export const updateContent = (payload, callback = () => {}) =>
  req
    .post({
      url: `/saved-content/update/${payload.id}`,
      payload,
      useLoading: false,
    })
    .then(({ data }) => {
      callback(data);
      return data;
    });

export const deleteContent = (id, callback = () => {}, useLoading = false) =>
  req
    .post({
      url: `/saved-content/delete/${id}`,
      useLoading,
    })
    .then(() => {
      callback();
    });

export const updateShareTemplateStatus = (
  saveId,
  id,
  template = '',
  shared,
  idKey = 'id',
) => {
  req.set(SHARE_DATA, (state) => ({
    ...state[SHARE_DATA],
    shared,
  }));

  updateContent(
    {
      id: saveId,
      shared,
    },
    () => {
      updateTemplate(
        template,
        id,
        {
          shared,
        },
        () => {},
        idKey,
      );
    },
  );
};

export const sendSharedEmail = (payload, callback = () => {}) => {
  req
    .post({
      url: `/shared-content/email/${payload?.sharingId}`,
      payload,
    })
    .then(() => {
      createAlert.success('share-form', 'Email sent');
    })
    .catch((err) => {
      displayError('share-form', err);
    })
    .finally(() => callback());
};

const formatContent = (data) => {
  const { content, template } = data;
  const formats = {
    'Google Ads': `${content?.headline}\n\n${content?.description}`,
    'Facebook Ads': `${content?.companyName}\n\n${content?.headline}\n\n${content?.description}`,
    'Product Description': `${content?.companyName}\n\n${content?.productName}\n\n${content?.productDescription}`,
    'Landing Page': `${content?.companyName}\n\n${content?.headline}\n${
      content?.description
    }\n\nFeatures\n\n${content?.features
      ?.map((feature) => `${feature?.headline}\n${feature?.description}`)
      .join('\n\n')}\n\n${content?.benefit?.headline}\n${
      content?.benefit?.description
    }`,
    Tagline: `${content}`,
    'Blog Ideas': `${content}`,
    'TikTok Video Ideas': `${content}`,
    'V2 Template': `${content}`,
    Writer: `${content?.writerContent?.replace(/<\/?[^>]+(>|$)/g, '')}`,
    Remixer: (Array.isArray(content) ? content : [])
      .map((text) => text)
      .join('\n'),
    Expander: (Array.isArray(content) ? content : [])
      .map((text) => text)
      .join('\n'),
    'Cold Email': (Array.isArray(content) ? content : [])
      .map((text) => text)
      .join('\n'),
    'VC Email': (Array.isArray(content) ? content : [])
      .map((text) => text)
      .join('\n'),
    'Angel Email': (Array.isArray(content) ? content : [])
      .map((text) => text)
      .join('\n'),
    'Header + Description': `${content?.headline}\n\n${content?.description}`,
    'Landing Page Hero': `${content?.headline}\n\n${content?.description}`,
    'Landing Page Feature': `${content?.headline}\n\n${content?.description}`,
    'Landing Page Benefit': `${content?.headline}\n\n${content?.description}`,
    Features: `${content?.headline}\n\n${content?.description}`,
    Benefits: `${content?.headline}\n\n${content?.description}`,
    'Bing Ads v2': `${content?.headlines?.join(' | ')} -  ${content?.descriptions?.join(' ')}`,
    'LinkedIn ads v2' : `${content?.introductory_text} - ${content?.headline}`,
    'Google Ads v2' : `${content?.headlines?.join(' | ')} -  ${content?.descriptions?.join(' ')}`,
    'Twitter Ads v2' : `${content?.ad_text} - ${content?.headline}`,
    'Facebook ads v2' : `${content?.primary_text} - ${content?.headline} - ${content?.description}`,
  };
  return formats[template] || content || 'Cannot find templates to copy';
};

export const copyContent = (data = {}, callback = () => {}) => {
  const formatC = formatContent(data);
  copyWords(formatC).then(() => {
    callback();
    sendCopied({
      id: data.id.length > 10 ? data.id : data.output_id,
      value: formatC,
    });
  });
};

export const V3_TEMPLATES = 'V3_TEMPLATES';
const sectionColors = ['green', 'blue', 'yellow', 'indigo'];
export const getAvailableTemplates = () =>
  req
    .get({
      url: '/v3/available_templates',
      useLoading: false,
    })
    .then(({ data }) => {
      const sortedData = data?.sort((a, b) =>
        a.order > b.order ? 1 : a.order < b.order ? -1 : 0,
      );
      const transformedData = sortedData.map((v, index) => {
        const sectionColor = sectionColors[index % 4];
        return {
          ...v,
          content: [
            // eslint-disable-next-line no-unsafe-optional-chaining
            ...v.content?.map((template) => ({
              ...template,
              color: sectionColor,
              type: template.result_template,
              templateId: template.id,
              template: 'V2 Template',
              categoryId: v?.id,
              title: template.name,
              logo: template.card_icon
                ? Feather[template.card_icon]
                : Feather.FiSun,
              label: template.card_label,
              slug:
                template.id === '117'
                  ? '/writer'
                  : `/${convertToSlug(template.name)}`,
              code: `${convertToSlug(template.name)}`,
              collapse: v.name === 'Tools',
              custom: {
                defaultValue: {},
                fields: template?.custom?.map((f) => ({
                  field: f?.variable,
                  label: f?.label,
                  placeholder: f?.placeholder,
                  type: f?.type,
                })),
              },
            })),
          ],
        };
      });

      req.set(V3_TEMPLATES, transformedData);
      req.set('DYNAMIC/ISLOADED', true);
    })
    .catch(() => req.set('DYNAMIC/ISLOADED', false));

export const markAsFavorite = (id = '') =>
  req.put({
    url: `/v3/template/mark_as_favorite/${id}`,
    useLoading: false,
  });

export const unmarkAsFavorite = (id = '') =>
  req.put({
    url: `/v3/template/unmark_as_favorite/${id}`,
    useLoading: false,
  });
