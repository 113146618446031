/* eslint-disable @typescript-eslint/no-empty-function */
import { setToken } from '../../config/token';
import { createAlert } from '../common/Alerts';
import req from '../../redux-setup';

import { AUTHENTICATION } from './constants';
import { addDataLayer } from "../helpers";

// eslint-disable-next-line import/prefer-default-export
export const login = (
  payload = {},
  callback = () => { },
  finalCallback = () => { },
) => {
  req
    .post({
      url: '/login',
      payload,
      useLoading: false,
    })
    .then((res) => {
      setToken(res.data.access_token);
      addDataLayer({
        email_hash: res.data.email_hash,
      });
      req.set(AUTHENTICATION, true);
      callback();
    })
    .catch(({ response }) => {
      if (response?.status === 401) createAlert.error('login', "Hmm, that doesn't look right. Try again?");
    })
    .finally(() => finalCallback());
};
