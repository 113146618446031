/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable camelcase */
import React from 'react';
import * as Yup from 'yup';
import {
  safeFunction,
  storeStorage,
  removeStorage,
  hasStoredPersona,
} from '../../helpers';
import { PERSONALIZE } from '../../personalize/constants';
import req from '../../../redux-setup';
import { addMultiadsResult, set } from '../../../redux-setup/actions';
import { BUTTON_STATUS, TEMPLATE_VALUES, COLLECTIONS, MULTIADS } from './constants';
import store from '../../../redux-setup/store';
import { setOpenUpgradeModal } from '../../../redux-setup/actions/upgradeModal';
import {
  setDefaultProject,
  setOutputLanguage,
} from '../../../redux-setup/actions/common';

export const clearStoredTemplates = (sections) => {
  const templates = [];
  sections.forEach((template) => {
    templates.push(...template.content);
  });
  const templateValues = {
    ...templates?.reduce(
      (accu, val) => ({
        ...accu,
        [val?.code]: removeStorage(val?.code),
      }),
      {},
    ),
  };

  req.updateObject(TEMPLATE_VALUES, templateValues);
};

const generateContent = (id, template, templateState, callback = () => {}) => {
  req
    .get({
      url: `/v2/generator_request/${id}`,
      useLoading: false,
    })
    .then(({ data }) => {
      if (!data.complete) {
        setTimeout(() => {
          generateContent(id, template, templateState, callback);
        }, 2000);
      } else {
        if (template) {
          storeStorage(template, {
            ...data,
            templateState,
          });
          req.updateTemplateResult(template, data);
        }
        safeFunction(callback(data));
        req.set(BUTTON_STATUS, '');
        set('customtemplateresults', data.contents);
      }
    })
    .catch((err) => console.error(err));
};

export const getGeneratorId = (
  inclusivity = false,
  templateCode,
  template,
  persona_id,
  custom = {},
  callback = () => {},
  generatedContentCallback = () => {},
  template_id = null,
) => {
  req.set(BUTTON_STATUS, 'loading');
  const payload = {
    persona_id,
    custom,
    inclusivity,
  };

  if (typeof template === 'string') {
    payload.template = template;
  }
  if (typeof template === 'object') {
    Object.keys(template).forEach((key) => {
      payload[key] = template[key];
    });
  }

  if (templateCode === 'V2 Template') {
    payload.template_id = template_id;
    payload.template = templateCode;
  }
  req
    .post({
      url: '/v3/generator_request',
      payload,
      useLoading: false,
    })
    .then(({ data: { remaining_credits, pack_credits, id } }) => {
      req.updateObject(PERSONALIZE, {
        remaining_credits,
      });
      req.updateObject(PERSONALIZE, {
        pack_credits,
      });
      generateContent(id, templateCode, custom, generatedContentCallback);
      safeFunction(callback);
    })
    .catch(() => {
      store.dispatch(setOpenUpgradeModal(true));
      req.set(BUTTON_STATUS, '');
    });
};

const generateContentMultiads = (id, callback) => {
  req
    .get({
      url: `/v2/generator_request_multiads/${id}`,
      useLoading: false,
    })
    .then(({ data }) => {
      if (!data.complete) {
        setTimeout(() => {
          generateContentMultiads(id);
        }, 2000);
      } else {
        // if (template) {
        //   storeStorage(template, {
        //     ...data,
        //     templateState,
        //   });
        //   req.updateTemplateResult(template, data);
        // }
        req.set(BUTTON_STATUS, '');
        addMultiadsResult(data.contents.map((cont) => ({ ...cont, content: JSON.parse(cont.content) })));
      }
    })
    .catch((err) => console.error(err));
};

export const getGeneratorMultiAds = (
  persona_id,
  custom = {},
  callback = () => {},
  errorCallback,
) => {
  req.set(BUTTON_STATUS, 'loading');
  const payload = {
    persona_id,
    custom,
  };

  req
    .post({
      url: '/v2/generator_multiads',
      payload,
      useLoading: false,
    })
    .then(({ data: { remaining_credits, pack_credits, id } }) => {
      req.updateObject(PERSONALIZE, {
        remaining_credits,
      });
      req.updateObject(PERSONALIZE, {
        pack_credits,
      });
      generateContentMultiads(id);
      safeFunction(callback);
    })
    .catch((err) => {
      if (err.response.status === 403) {
        store.dispatch(setOpenUpgradeModal(true));
      }
      req.set(BUTTON_STATUS, '');
      errorCallback(err.response.data.message);
    });
};

// eslint-disable-next-line default-param-last
export const getCustomizeFeatureData = (template = '', hasTrial) => ({
  title: `Upgrade To Fine-Tune Your ${template} Copy`,
  feature: (
    <div className="w-full m-2 px-2 ">
      <div className="px-6 text-main text-base my-4 text-center font-semibold">
        {hasTrial ? '$49/month' : '$49/month'}
      </div>
      {/* <div className="md:px-6 lg:px-8">
        <FormSwitch
          showStar
          label={<span className="flex items-center">Customize</span>}
          labelWrapperClass="font-semibold text-lg"
          checked
          disabled
        />
      </div> */}
    </div>
  ),
  description:
    'Fine-Tune copy by adding specific inputs that will help you refine the results we give you. This is the ultimate functionality for narrowing down copy to your precise requirements.',
});

// Profile
// export const getProfiles = () => {
//   req
//     .get({
//       url: '/persona',
//       useLoading: false,
//     })
//     .then(({ data }) => {
//       req.set(PERSONAS, data);
//       if (hasStoredPersona()) {
//         const storedPersonaID = getStoredPersona().id;
//         const updatedPersona = data?.filter((v) => v.id === storedPersonaID);
//         if (updatedPersona.length > 0) {
//           // persona exists, update to current version
//           storeStorage('last-persona', updatedPersona[0]);
//           req.set(SELECTED_PERSONA, updatedPersona[0]);
//         } else {
//           // persona is gone, set to default
//           const defaultPersona = data?.filter((v) => v.default) || [];
//           if (defaultPersona?.length > 0) {
//             storeStorage('last-persona', defaultPersona[0]);
//             req.set(SELECTED_PERSONA, defaultPersona[0]);
//           }
//         }
//       } else {
//         const defaultPersona = data?.filter((v) => v.default) || [];
//         if (defaultPersona?.length > 0) {
//           storeStorage('last-persona', defaultPersona[0]);
//           req.set(SELECTED_PERSONA, defaultPersona[0]);
//         }
//       }
//     });
// };

// export const addProfile = (payload) =>
//   req
//     .post({
//       url: '/persona/create',
//       payload,
//       useLoading: false,
//     })
//     .then(({ data }) => {
//       req.append(PERSONAS, data);
//       req.updateObject(SELECTED_PERSONA, data);
//       storeStorage('last-persona', data);
//     });

// export const updateProfile = (payload, isSelected = true) => {
//   req.arrayUpdate(PERSONAS, payload);
//   if (isSelected) {
//     req.updateObject(SELECTED_PERSONA, payload);
//   }
//   return req
//     .post({
//       url: `/persona/update/${payload?.id}`,
//       payload,
//       useLoading: false,
//     })
//     .then(({ data }) => {
//       if (isSelected) storeStorage('last-persona', data);
//     });
// };

// export const deleteProfile = (id) =>
//   req
//     .post({
//       url: `/persona/delete/${id}`,
//       useLoading: false,
//     })
//     .then(() => {
//       req.arrayDelete(PERSONAS, id);
//     });

export const getYupValidationFormat = (type = 'string', label = '') => {
  const validation = {
    string: Yup.string()
      .required('This field is required.')
      .max(300)
      .label(label),
    textarea: Yup.string()
      .required('This field is required.')
      .max(300)
      .label(label),
    array: Yup.array()
      .min(1, `We need at least 1 ${label}`)
      .required('This field is required.')
      .label(label),
  };
  return validation[type] || validation.string;
};
export const getYupValidation = (type = 'string', label = '') => {
  switch (type) {
    case 'shortText':
      return Yup.string()
        .required(`The field ${label} is required.`)
        .max(250)
        .label(label);
    case 'mediumText':
      return Yup.string()
        .required(`The field ${label} is required.`)
        .max(500)
        .label(label);
    case 'longText':
      return Yup.string()
        .required(`The field ${label} is required.`)
        .max(1500)
        .label(label);
    case 'extraLongText':
      return Yup.string()
        .required(`The field ${label} is required.`)
        .max(3000)
        .label(label);
    case 'keywords':
      return Yup.array()
        .required(`The field ${label} is required.`)
        .min(1)
        .max(25)
        .label(label);
    case 'language':
    case 'length':
    case 'tone':
    case 'input':
    default:
      return Yup.string()
        .required(`The field ${label} is required.`)
        .max(75)
        .label(label);
  }
};

export const getYupValidationFormatAuthor = (type = 'string', label = '') => {
  const validation = {
    string: Yup.string()
      .required('This field is required.')
      .max(3000)
      .label(label),
    textarea: Yup.string()
      .required('This field is required.')
      .max(3000)
      .label(label),
    array: Yup.array()
      .min(1, `We need at least 1 ${label}`)
      .required('This field is required.')
      .label(label),
  };

  return validation[type] || validation.string;
};

export const formatValidation = (fields = []) => {
  const customFields = (Array.isArray(fields) ? fields : []).reduce(
    (accu, val) => ({
      ...accu,
      [val.field]: getYupValidationFormat(val.type, val.label),
    }),
    {},
  );

  return Yup.object().shape(customFields);
};

export const formatValidationAuthor = (fields = []) => {
  const customFields = (Array.isArray(fields) ? fields : []).reduce(
    (accu, val) => ({
      ...accu,
      [val.field]: getYupValidationFormatAuthor(val.type, val.label),
    }),
    {},
  );

  return Yup.object().shape(customFields);
};

// eslint-disable-next-line max-len
export const formatInitialValue = (fields = []) =>
  (Array.isArray(fields) ? fields : []).reduce((accu, val) => {
    const arrayLabel =
      val.type === 'array' ? { [`${val.field}_input`]: '' } : {};
    return {
      ...accu,
      ...arrayLabel,
      [val.field]: val.type === 'string' || val.type === 'textarea' ? '' : [],
    };
  }, {});

export const updateOutputLanguage = (payload) => {
  return req
    .post({
      url: `/user/output_language`,
      payload: { output_language: payload },
    })
    .then(({ data: result }) => {
      if (result.data) {
        store.dispatch(setOutputLanguage(result.data));
      }
    });
};

export const updateProjectDefault = (payload) => {
  return req
    .post({
      url: `/user/default_project`,
      payload: { default_project: payload },
    })
    .then(({ data: result }) => {
      if (result.data) {
        store.dispatch(setDefaultProject(result.data));
      }
    });
};

export const getCollections = () => {
  req
    .get({
      url: '/collection',
      useLoading: false,
    })
    .then(({ data: result }) => {
      req.set(COLLECTIONS, result.data);
      if (hasStoredPersona()) {
        // console.log(hasStoredPersona());
        // console.log(getStoredPersona());
        // const storedPersonaID = getStoredPersona().id;
        // const updatedPersona = data?.filter((v) => v.id === storedPersonaID);
        // if (updatedPersona.length > 0) {
        //   // persona exists, update to current version
        //   storeStorage('last-persona', updatedPersona[0]);
        //   req.set(SELECTED_PERSONA, updatedPersona[0]);
        // } else {
        //   // persona is gone, set to default
        //   const defaultPersona = data?.filter((v) => v.default) || [];
        //   if (defaultPersona?.length > 0) {
        //     storeStorage('last-persona', defaultPersona[0]);
        //     req.set(SELECTED_PERSONA, defaultPersona[0]);
        //   }
        // }
      } else {
        // const defaultPersona = data?.filter((v) => v.default) || [];
        // if (defaultPersona?.length > 0) {
        //   storeStorage('last-persona', defaultPersona[0]);
        //   req.set(SELECTED_PERSONA, defaultPersona[0]);
        // }
      }
    });
};


export const clearStoredMultiads = () => {
  req.updateObject(MULTIADS, { contents: [] });
};