import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useApiData } from '../../../redux-setup';
import { V3_TEMPLATES } from '../actions';
import TemplateCategory from '../components/TemplateCategory';
import FilterButtons from '../components/TemplateFilter';
import CreditsBar from '../components/CreditsBar';
import SearchBar from '../components/SearchBar';

function TemplatesContainer() {
  const templateData = useApiData(V3_TEMPLATES, []);
  const { is_unlimited: isUnlimited } = useSelector(
    (state) => state['PERSONALIZE/DATA'],
  );

  const [filter, setFilter] = React.useState([]);
  const [searchText, setSearchText] = React.useState('');

  // eslint-disable-next-line consistent-return
  const filteredItems = React.useMemo(() => {
    if (templateData) {
      if (filter.length > 0) {
        return templateData?.filter((val) =>
          filter?.some((f) => val?.name === f),
        );
      }
      if (searchText.length) {
        const filteredTemplate = templateData.map((templ) => ({
          ...templ,
          content: templ.content.filter(
            (sTem) =>
              sTem.name.toLowerCase().indexOf(searchText.toLowerCase()) >= 0 ||
              sTem.description
                .toLowerCase()
                .indexOf(searchText.toLowerCase()) >= 0,
          ),
        }));
        return filteredTemplate.filter(
          (categories) => categories.content.length,
        );
      }
      return templateData;
    }
  }, [filter, templateData, searchText]);

  const favorites = React.useMemo(
    () =>
      templateData
        ?.flatMap((tmp) => tmp?.content?.filter((dt) => dt?.is_favorited))
        .filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i),
    [templateData],
  );

  useEffect(() => {
    if (favorites.length === 0 && filter.length && filter[0] === 'Favorites') {
      setFilter([]);
    }
  }, [filter, favorites]);

  return (
    <section className="mx-auto w-full max-w-screen-xl">
      <div className="flex flex-wrap lg:flex-nowrap justify-center lg:justify-between mb-8 items-center px-8">
        <SearchBar
          setFilter={setFilter}
          setSearchText={setSearchText}
          searchText={searchText}
        />
        {!isUnlimited && (
          <div className="w-64 sm:w-72 md:w-96 group order-1 lg:order-2 my-8 lg:my-0 h-6">
            <CreditsBar />
          </div>
        )}
      </div>
      <FilterButtons
        filter={filter}
        setFilter={setFilter}
        setSearchText={setSearchText}
        menuItems={templateData?.map((val) => val?.name)}
        showFavorites={favorites.length > 0}
      />
      {!searchText.length &&
        favorites.length > 0 &&
        (filter.length === 0 ||
          filter.find((element) => element === 'Favorites')) && (
          <TemplateCategory category="Favorites" data={favorites} />
        )}
      {filteredItems.map((section) => (
        <TemplateCategory
          key={section.name}
          category={section.name}
          data={section.content}
        />
      ))}
    </section>
  );
}

export default TemplatesContainer;
