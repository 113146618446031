/* eslint-disable @typescript-eslint/no-empty-function */
import { createAlert } from '../common/Alerts';
import { getQueryParams } from '../helpers';
import req from '../../redux-setup';

export const resetPassword = (
  payload = {},
  callback = () => { },
  finallyCallback = () => { },
) => {
  const token = getQueryParams('token');
  req
    .post({
      url: `/v2/reset_password/${token}`,
      payload,
    })
    .then(() => {
      callback();
    })
    .catch(({ response }) => {
      if (response?.status === 422) createAlert.error('reset-password', 'Invalid Token');
    })
    .finally(() => finallyCallback());
};

export const forgotPassword = (
  payload = {},
  callback = () => { },
  finallyCallback = () => { },
) => {
  req
    .post({
      url: '/v2/forgot_password',
      payload,
    })
    .then(() => {
      createAlert.success(
        'forgot-password',
        `Success! Please check your email ${payload.email} to complete your password reset`,
      );
      callback();
    })
    .finally(() => {
      finallyCallback();
    });
};
