import React from 'react';
import Skeleton from 'react-loading-skeleton';

function SkeletonLandingPages() {
  return (
    <div
      style={{
        position: 'relative',
        marginBottom: 25,
      }}
    >
      <div
        className="bg-white p-8 my-4 shadow-md"
        style={{
          borderRadius: '16px',
        }}
      >
        <h1 className="text-2xl font-semibold break-all">
          <Skeleton />
        </h1>
        <div className="mt-6 grid grid-cols-1 lg:grid-cols-2 gap-y-4 gap-x-6 items-center">
          <div className="w-full">
            <h3 className="text-lg text-left font-thick">
              <Skeleton />
            </h3>
            <div className="mt-4 text-gray-600 text-justify">
              <Skeleton count={3} />
            </div>
          </div>
          <div className="w-full flex justify-center items-center bg-gray-200 h-48">
            <Skeleton />
          </div>
        </div>
        <div className="mt-16 grid grid-cols-1 lg:grid-cols-3 gap-y-4 gap-x-6">
          <div>
            <h3 className="text-md text-left font-semibold">
              <Skeleton />
            </h3>
            <div className="text-left mt-2 text-gray-600">
              <Skeleton />
            </div>
          </div>
        </div>
        <div className="mt-16 grid grid-cols-1 lg:grid-cols-2 gap-y-4 gap-x-6 items-center">
          <div className="w-full flex justify-center items-center bg-gray-200 h-48">
            <Skeleton />
          </div>
          <div className="w-full">
            <h3 className="text-lg text-left font-thick">
              <Skeleton />
            </h3>
            <div className="text-left mt-4 text-gray-600">
              <Skeleton />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SkeletonLandingPages;
