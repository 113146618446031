/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable camelcase */
import React from 'react';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import NotepadContainer from '../../notepad/containers/NotepadContainer';
import Loader from '../../common/Loader/Loader';
import SideNav from '../../partials/sidenav/containers/SideNavContainer';
import { MenuProvider } from '../../partials/sidenav/context/SideNavContext';
import usePersonalizeDetails from '../../personalize/hooks/usePersonalizeDetails';
import { SHARE_DATA } from '../../templates/components/share/constants';
import ShareModal from '../../templates/modals/ShareModal';
import { useApiData } from '../../../redux-setup';
import MobileNav from '../../partials/mobile-nav/containers/MobileNav';
import { LayoutContext } from '../hooks';
import UpgradeModal from '../../../common/UpgradeModal';
import './layout.css';

function Layout({ children }) {
  const [showNotepad, setShowNotepad] = React.useState(false);
  const { is_unlimited } = useSelector((state) => state['PERSONALIZE/DATA']);
  const sharedData = useApiData(SHARE_DATA, {});
  const { isDone, isLoaded, viewLoading } = usePersonalizeDetails();
  if (!isDone && isLoaded) return <Redirect to="/welcome" />;
  return (
    <div className="relative flex">
      <MenuProvider>
        <SideNav />
      </MenuProvider>
      <LayoutContext.Provider
        value={{
          showNotepad,
          setShowNotepad,
        }}
      >
        <main className="sm:pl-16 sm:pt-10 w-full">
          <MobileNav />
          {isLoaded && children}
        </main>
      </LayoutContext.Provider>
      {viewLoading && <Loader />}
      <NotepadContainer show={showNotepad} setShow={setShowNotepad} />
      {!isEmpty(sharedData) && <ShareModal />}
      {!is_unlimited && <UpgradeModal />}
    </div>
  );
}
Layout.defaultProps = {
  children: null,
};
Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.element,
  ]),
};

export default Layout;
