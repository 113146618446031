import React from 'react';

import { Formik, Field } from 'formik';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import { Input } from '../../common/Formik/FormInputs';

const rqdmsg = 'This field is required.';
const validateForm = Yup.object().shape({
  new_password: Yup.string().required(rqdmsg).max(50).label('New Password'),
});

function ResetPasswordForm({ onSubmit }) {
  return (
    <Formik
      validationSchema={validateForm}
      initialValues={{
        new_password: '',
      }}
      onSubmit={(values, { setSubmitting }) => onSubmit(values, setSubmitting)}
    >
      {({ values, handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <Field
            component={Input}
            name="new_password"
            type="password"
            value={values.new_password}
            placeholder="New Password"
            wrapperClass="font-bold text-black font-montserrat"
          />
          <div className="flex justify-between items-center mt-2">
            <Link className="btn clear" to="/" disabled={isSubmitting}>
              Back
            </Link>
            <button
              type="button"
              className="btn primary"
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              Submit
            </button>
          </div>
        </form>
      )}
    </Formik>
  );
}

ResetPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};
export default ResetPasswordForm;
