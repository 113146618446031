import React from 'react';
import { isFramed } from '../../helpers';
import ChangeEmailSection from './EmailSection';
import ChangePasswordSection from './PasswordSection';
import QuestionSection from './QuestionsSection';
import ReferFriendSection from './ReferFriendSection';

function PersonalTab() {
  return (
    <div>
      {!isFramed() && <ChangeEmailSection />}
      <ChangePasswordSection />
      <QuestionSection />
      <ReferFriendSection />
    </div>
  );
}

export default PersonalTab;
