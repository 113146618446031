import React, { useEffect, useState } from 'react';
import { clsx } from 'clsx';
import { default as dayjs } from 'dayjs';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import { loadStripe } from '@stripe/stripe-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpFromArc } from '@fortawesome/pro-light-svg-icons';
import {
  getBillingPortalURL,
  getStripeSubscriptionID,
} from '../../../../common/getInfo';
import {
  cancelCancelation,
  cancelSubscription,
  downgradeToEssential,
  requestCheckoutSesion,
  updateSubscription,
} from '../../../../common/sendInfo';
import { setLoading } from '../../../../redux-setup/actions/loading';
import { getRewardfulCode } from '../../../helpers';
import FullCardNew from '../../../plan/FullCardNew';
import HalfCardNew from '../../../plan/HalfCardNew';
import TwoOptionSwitch from '../../../../common/TwoOptionSwitch';
import { IBillingPlanProps } from './props';
import Button from '../../../../common/Button';
import CancelModal from './CancelModal';
import UpdateSubscriptionModal from './UpdateSubscriptionModal';

declare global {
  interface Window {
    profitwell: (
      flow: string,
      object: { subscription_id: string },
    ) => Promise<any>;
  }
}

function BillingPlan({ wrapperClass }: IBillingPlanProps) {
  const dispatch = useDispatch();
  const { isPaid, first_plan, current_tenure, canceled } = useSelector(
    (state: RootStateOrAny) => state['PERSONALIZE/DATA'],
  );

  const [annualSelected, setAnnualSelected] = useState(true);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [messageModal, setMessageModal] = useState<
    'cancelled' | 'downgraded' | 'reactivated'
  >('cancelled');
  const [updateDone, setUpdateDone] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [plan, setPlan] = useState<'essential' | 'unlimited'>('unlimited');
  const [tenure, setTenure] = useState<'monthly' | 'annual'>('monthly');

  const handleManageBilling = () => {
    dispatch(setLoading(true));
    getBillingPortalURL()
      .then((response) => response.json())
      .then((result) => {
        window.location.href = result.url;
      })
      .catch((err) => console.log(err));
  };

  const handleCheckout = (plan: string, tenure: string) => {
    const success_url = `${window.location.origin}/subscriptions/success?success=true&plan=${plan}`;
    const referalCode = getRewardfulCode();
    dispatch(setLoading(true));
    const payload = {
      success_url,
      cancel_url: `${window.location.href}`,
      rewardful: '',
      plan: plan,
      tenure: tenure,
    };
    const rewardful = referalCode;
    if (referalCode) {
      if (rewardful) payload.rewardful = rewardful;
    }
    requestCheckoutSesion(payload)
      .then((response) => response.json())
      .then(async (result) => {
        const stripe = await loadStripe(result.stripe_token);
        if (stripe) {
          const stripeRedirect = await stripe.redirectToCheckout({
            sessionId: result.stripe_session_id,
          });
          if (stripeRedirect.error) {
            NotificationManager.error('Something went wrong');
          }
        }
      })
      .catch(() => dispatch(setLoading(false)));
  };

  const cancelationFlow = () => {
    dispatch(setLoading(true));
    getStripeSubscriptionID()
      .then((response) => response.json())
      .then((result) => {
        const subscriptionID = result.data.subscription;
        window
          .profitwell('init_cancellation_flow', {
            subscription_id: subscriptionID,
          })
          .then((res: any) => {
            const {
              additionalFeedback,
              cancelReason,
              salvageAttemptIntended,
              salvageAttemptResult,
              salvageAttemptUsed,
              salvageOfferResult,
              satisfactionInsight,
              status,
            } = res;
            if (status === 'retained' || status === 'aborted') {
              dispatch(setLoading(false));
              if (
                res.status === 'retained' &&
                res.salvageAttemptResult?.resolution === 'accepted'
              ) {
                downgradeToEssential({
                  subscription_id: subscriptionID,
                  additionalFeedback: additionalFeedback || '',
                  cancelReason: cancelReason || '',
                  status,
                  salvageAttemptUsed: salvageAttemptUsed || '',
                  salvageAttemptResult: salvageAttemptResult?.resolution || '',
                  salvageOfferResult: salvageOfferResult?.decision || '',
                  salvageAttemptIntended: salvageAttemptIntended || '',
                  satisfactionInsight: satisfactionInsight || '',
                })
                  .then((resp) => resp.json())
                  .then(() => {
                    dispatch(setLoading(false));
                    setMessageModal('downgraded');
                    setOpenCancelModal(true);
                  })
                  .catch((err) => console.log(err));
              }
              return;
            }
            // we proceed to cancel
            cancelSubscription({
              subscription_id: subscriptionID,
              additionalFeedback: additionalFeedback || '',
              cancelReason: cancelReason || '',
              status,
              salvageAttemptUsed: salvageAttemptUsed || '',
              salvageAttemptResult: salvageAttemptResult?.resolution || '',
              salvageOfferResult: salvageOfferResult?.decision || '',
              salvageAttemptIntended: salvageAttemptIntended || '',
              satisfactionInsight: satisfactionInsight || '',
            })
              .then((resp) => resp.json())
              .then((resu) => {
                console.log(resu);
                dispatch(setLoading(false));
                setMessageModal('cancelled');
                setOpenCancelModal(true);
              })
              .catch((err) => console.log(err));
          });
      })
      .catch(() => dispatch(setLoading(false)));
  };

  const cancelCancel = () => {
    dispatch(setLoading(true));
    getStripeSubscriptionID()
      .then((response) => response.json())
      .then((result) => {
        const subscriptionID = result.data.subscription;
        cancelCancelation({ subscription_id: subscriptionID })
          .then((resp) => resp.json())
          .then(() => {
            setMessageModal('reactivated');
            setOpenCancelModal(true);
            dispatch(setLoading(false));
          })
          .catch();
      })
      .catch(() => dispatch(setLoading(false)));
  };

  const onUpdateSubscription = (cancelAtEnd: boolean) => {
    setUpdateLoading(true);
    getStripeSubscriptionID()
      .then((response) => response.json())
      .then((result) => {
        const subscriptionID = result.data.subscription;
        updateSubscription({
          subscription_id: subscriptionID,
          cancelAtEnd: cancelAtEnd,
          plan: plan,
          tenure: tenure,
          first_plan,
        })
          .then((resp) => resp.json())
          .then(() => {
            setUpdateDone(true);
          })
          .catch();
      })
      .catch(() => dispatch(setLoading(false)));
  };

  useEffect(() => {
    if (current_tenure === 'monthly') {
      setAnnualSelected(false);
    }
  }, [current_tenure]);

  return (
    <div className={clsx(wrapperClass)}>
      <CancelModal
        open={openCancelModal}
        setOpen={setOpenCancelModal}
        message={messageModal}
        endDate={dayjs.unix(canceled?.billingCycle).format('MMMM D, YYYY')}
      />
      <UpdateSubscriptionModal
        open={openUpdateModal}
        setOpen={setOpenUpdateModal}
        endPeriodDate={dayjs
          .unix(canceled?.billingCycle)
          .format('MMMM D, YYYY')}
        onUpdate={onUpdateSubscription}
        updateDone={updateDone}
        updateLoading={updateLoading}
        selectedPlan={plan}
      />
      <div className="flex items-center justify-between mb-4">
        <div>
          {canceled?.subscription ? (
            <h3 className="text-ugray font-semibold mb-2">
              Subscription
              {isPaid ? (
                canceled?.canceled ? (
                  <div className="text-radical-red text-sm bg-upink inline-block ml-4 px-2 rounded">
                    Cancels on{' '}
                    {dayjs.unix(canceled.billingCycle).format('MMMM D, YYYY')}
                  </div>
                ) : (
                  <div className="text-jungle-green text-sm bg-frosted-mint inline-block ml-4 px-2 rounded">
                    Active
                  </div>
                )
              ) : (
                <div className="text-pampas text-sm bg-silver-chalice inline-block ml-4 px-2 rounded">
                  Inactive
                </div>
              )}
            </h3>
          ) : (
            <h3 className="text-shamrock font-medium mb-2">
              <span>
                <FontAwesomeIcon
                  className="inline-block animate-bounce h-5 w-5 mr-2"
                  icon={faArrowUpFromArc}
                />
                Ready to upgrade?
              </span>
            </h3>
          )}
          <p className="text-sm mb-4 text-silver-chalice">
            {isPaid ? (
              <span>
                Here you can manage your subscription, invoice(s) and payment
                methods.{' '}
                {isPaid && !canceled.canceled && (
                  <span>
                    <button
                      type="button"
                      onClick={cancelationFlow}
                      className="underline text-ubblue font-medium"
                    >
                      Click here
                    </button>{' '}
                    if you wish to cancel your subscription.
                  </span>
                )}
              </span>
            ) : (
              'Save time writing without sacrificing quality. Our AI will help you write any kind of long-form content on different topics, with expert level understanding.'
            )}
          </p>
        </div>
        <div className="flex gap-x-2">
          <Button type="secondary" onClick={handleManageBilling}>
            {isPaid ? 'Billing Settings' : 'Billing History'}
          </Button>
          {/* {isPaid && canceled?.canceled && (
            <Button secondary onClick={cancelCancel}>
              Reactivate Subscription
            </Button>
          )} */}
        </div>
      </div>
      <div className="w-full">
        <FullCardNew
          className="hidden lg:grid"
          handleCheckout={handleCheckout}
          annual={annualSelected}
          handleChangeSwitch={() => setAnnualSelected((prev) => !prev)}
          onCanceling={isPaid && canceled?.canceled}
          onReactivateClick={cancelCancel}
          onUpgradeDowngrade={(
            plan: 'essential' | 'unlimited',
            tenure: 'monthly' | 'annual',
          ) => {
            setPlan(plan);
            setTenure(tenure);
            setOpenUpdateModal(true);
          }}
        />
        <TwoOptionSwitch
          rightText="Annual"
          leftText="Monthly"
          rightValue={annualSelected}
          textColor="text-ugray"
          textSelectedColor="text-shamrock"
          className="mt-10 mb-10 sm:mt-4 lg:hidden"
          handleChangeSwitch={() => setAnnualSelected((prev) => !prev)}
          outline
        />
        <HalfCardNew
          className="lg:hidden"
          annual={annualSelected}
          handleCheckout={handleCheckout}
          onCanceling={isPaid && canceled?.canceled}
          onReactivateClick={cancelCancel}
          onUpgradeDowngrade={(
            plan: 'essential' | 'unlimited',
            tenure: 'monthly' | 'annual',
          ) => {
            setPlan(plan);
            setTenure(tenure);
            setOpenUpdateModal(true);
          }}
        />
      </div>
    </div>
  );
}

export default BillingPlan;
