import React, { useState } from 'react';
import { clsx } from 'clsx';
import {
  faArrowUpArrowDown,
  faChevronDown,
  faChevronUp,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ISortByFilterProps } from './props';
import Option from './Options';

function SortByFilter({
  options,
  selected,
  handleClickOption,
  className: wrapperClass,
}: ISortByFilterProps) {
  const [expanded, setExpanded] = useState(false);

  return (
    <div
      className={clsx(wrapperClass || 'text-ubblue')}
      onClick={() => setExpanded((preE) => !preE)}
    >
      <FontAwesomeIcon icon={faArrowUpArrowDown} />
      <span className="mx-2">{selected.name}</span>
      <FontAwesomeIcon icon={expanded ? faChevronUp : faChevronDown} />
      {expanded && (
        <div className="absolute py-2 bg-white border rounded">
          {options.map((option) => (
            <Option
              key={option.id}
              text={option.name}
              id={option.id}
              clickOption={handleClickOption}
              selectedID={selected.id}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default SortByFilter;
