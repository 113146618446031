/* eslint-disable react/prop-types */
import React, { useState } from 'react';

import axios from 'axios';
import { useLocation } from 'react-router-dom';

import { API } from '../../config/API';
import Container from '../common/Container';
import FormSwitch from '../common/FormSwitch';

import AuthorResults from './AuthorResults';
import AuthoringTemplate from './AuthoringTemplate';

export default function AuthoringPage({ template }) {
  const [authoring, setAuthoring] = useState(true);
  const loc = useLocation();

  // Values to be saved.
  const [values, setValues] = useState({ ...template });

  const saveValues = async () => {
    if (authoring) {
      const id = loc.pathname.split('/')[2];
      const token = localStorage.getItem('tkn') || null;
      if (!token) return false;

      const toSave = {};

      Object.keys(values).forEach((k) => {
        if (values[k] !== template[k]) {
          toSave[k] = values[k];
        }
      });

      const path = `${API}/template/update/${id}`;
      const results = await axios
        .post(path, toSave, {
          headers: {
            authorization: `Bearer ${token}`,
          },
        })
        .then(() => true)
        .catch(() => false);
      return results;
    }

    return null;
  };

  if (!template) return <div />;
  return (
    <section>
      <Container>
        <div className="w-full mb-10 flex flex-wrap">
          <div className="h-20 w-full flex items-center justify-between">
            <h1 className="text-lg font-bold">
              {authoring ? '' : template?.name}
            </h1>
            <div className="flex">
              <p className="mr-4 font-semibold text-lg">Authoring</p>
              <FormSwitch onChange={setAuthoring} checked={authoring} />
            </div>
          </div>
          {authoring ? (
            <AuthoringTemplate
              savevalues={saveValues}
              values={values}
              setvalues={setValues}
              template={template}
            />
          ) : (
            <div className="flex w-full">
              {!authoring && <AuthorResults template={template} />}
            </div>
          )}
        </div>
      </Container>
    </section>
  );
}
