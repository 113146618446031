import React from 'react';

import { Formik, Form, Field } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import { TextArea } from '../../common/Formik/FormInputs';
import {
  setContentExpanderTopic,
  setContentExpanderSummary,
  getContentExpanderTopic,
  getContentExpanderSummary,
} from '../../../config/localStorage';

// validation
const rqdmsg = 'This field is required.';
const validateForm = Yup.object().shape({
  topic: Yup.string().required(rqdmsg).max(250).label('Topic'),
  summary: Yup.string().required(rqdmsg).max(500).label('Summary'),
});

const placeholder = 'This week we are announcing our new product: Widget X.';

// eslint-disable-next-line react/display-name
const ContentExpanderForm = React.forwardRef(
  ({ onSubmit, initialValues }, ref) => {
    const intValues = {
      topic: initialValues.topic || getContentExpanderTopic(),
      summary: initialValues.summary || getContentExpanderSummary(),
    };
    return (
      <Formik
        innerRef={ref}
        initialValues={intValues}
        validationSchema={validateForm}
        onSubmit={(values, { setSubmitting }) => {
          setContentExpanderTopic(values.topic);
          setContentExpanderSummary(values.summary);
          onSubmit(values, setSubmitting);
        }}
        enableReinitialize
      >
        {({ values }) => (
          <Form className="grid grid-cols grid-cols-1 sm:grid-cols-2 gap-4">
            <div className="w-full">
              <p className="text-gray-700 font-semibold text-md flex items-center">
                Topic
              </p>
              <Field
                component={TextArea}
                name="topic"
                value={values.topic}
                placeholder="Our latest press release"
                wrapperClass="my-4"
                rows={5}
              />
            </div>
            <div className="w-full">
              <p className="text-gray-700 font-semibold text-md flex items-center">
                Summary
              </p>
              <Field
                component={TextArea}
                name="summary"
                value={values.summary}
                placeholder={placeholder}
                wrapperClass="my-4"
                rows={5}
              />
            </div>
          </Form>
        )}
      </Formik>
    );
  },
);

ContentExpanderForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  initialValues: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default ContentExpanderForm;
