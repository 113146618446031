import React from 'react';
import { clsx } from 'clsx';
import { IOptionProps } from './props';

function Option({ id, text, clickOption, selectedID }: IOptionProps) {
  return (
    <div
      className={clsx(
        selectedID ? 'text-ubblue' : 'text-mine-shaft',
        'betterhover:hover:text-ubblue betterhover:hover:bg-link-water px-4 text-center',
      )}
      onClick={() => clickOption(id, text)}
    >
      <span className="">{text}</span>
    </div>
  );
}

export default Option;
