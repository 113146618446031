import React from 'react';

import { Redirect } from 'react-router-dom';
import { V3_TEMPLATES } from '../../templates/actions';
import { useApiData } from '../../../redux-setup';

import SortableSection from '../components/SortableSection';
import { PERSONALIZE } from '../../personalize/constants';

function ManagementContainer() {
  const v3Sections = useApiData(V3_TEMPLATES, []);
  const { author } = useApiData(PERSONALIZE, {});
  if (!author) return <Redirect to="/browse" />;
  return (
    <section className="px-6 mx-auto w-full max-w-screen-xl">
      {v3Sections?.map((section) => (
        <div key={section.name} className="my-8">
          <h1 className="font-normal text-lg font-semibold mb-2">
            {section.name}
          </h1>
          <SortableSection
            id={section?.id}
            initialTemplates={section.content}
          />
        </div>
      ))}
    </section>
  );
}

export default ManagementContainer;
