import React from 'react';
import { clsx } from 'clsx';
import Button from '../../../../../common/Button';
import CreditsRadioOption from './CreditsRadioOption';
import { ICreditsOptionProps } from './props';

function CreditsOption({
  onChangeCredits,
  creditsObj,
  onPurchaseCredits,
  className,
  wrapperButtonClass,
}: ICreditsOptionProps) {
  return (
    <div className={className}>
      {creditsObj.map((obj) => (
        <CreditsRadioOption
          key={obj.numberCredits}
          creditsNumber={obj.numberCredits}
          creditsPrice={obj.costCredits}
          selected={obj.selected}
          onChangeCredits={onChangeCredits}
          className="items-center"
        />
      ))}
      <Button
        type="primary"
        className={clsx('w-full', wrapperButtonClass)}
        onClick={() => onPurchaseCredits()}
      >
        {`Buy ${
          creditsObj.filter((credits) => credits.selected)[0]?.numberCredits
        } Credits`}
      </Button>
    </div>
  );
}

export default CreditsOption;
