import React from 'react';
import { isCouponPlan } from "../../common/functions";

export const CONFIRMATION_DATA = 'CONFIRMATION_DATA';

export const RECENTS_LIST = 'RECENTS/lists';
export const SUBMENU_TITLE = {
  templates: 'Templates',
  tools: 'Tools',
  writer: 'Writer',
};

export const noCreditsModalData = {
  title: (
    <h1 className="text-3xl text-black font-bold text-center">
      <span className="font-medium">Upgrade to </span>
      Growth
    </h1>
  ),
  // hideClose: true,
  feature: (
    <div className="mt-4 text-left px-6 text-main text-base">
      Starting at $40 per month
    </div>
  ),
  // description:
  //   'Unlimited content creation is just the start. Gain full access to our tools and templates,
  // along with new features before the release.',
  // closeRedirectPath: '/templates',
};

export const noCreditsModalDataNewPlan = (fPlan) => ({
  title: (
    <h1 className="text-3xl text-black font-bold text-center">
      <span className="font-medium">Upgrade </span>
      Today
    </h1>
  ),
  feature: (
    <div className="mt-4 text-left px-6 text-main text-base">{`Starting at ${isCouponPlan(fPlan) ? '$8' : '$15'} per month`}</div>
  ),
});
