import React, { useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { useDispatch } from 'react-redux';
import Button from '../../../../common/Button';
import InputField from '../../../../common/InputField';
import Keywords from '../../../../common/Keywords';
import Modal from '../../../../common/Modal';
import { createProject } from '../../../../common/sendInfo';
import TextField from '../../../../common/TextField';
import {
  addProjectInCollection,
  setDefaultProject,
  setProject,
} from '../../../../redux-setup/actions/common';
import { setLoading } from '../../../../redux-setup/actions/loading';
import { IModalNewProjectProps } from './props';

function ModalNewProject({
  openNewProject,
  setOpenNewProject,
  selectedCollObj,
}: IModalNewProjectProps) {
  const [projectInfo, setProjectInfo] = useState({
    productService: { value: '', error: false },
    description: { value: '', error: false },
    audience: [] as string[],
    keywords: [] as string[],
  });
  const [audience, setAudience] = useState('');
  const [brandKeywords, setBrandKeywords] = useState('');
  const dispatch = useDispatch();

  const handleRemoveAudience = (indx: number) => {
    setProjectInfo((preP) => {
      if (preP) {
        const audience = preP.audience.filter((_val, inx) => inx !== indx);
        return { ...preP, audience: audience };
      }
      return preP;
    });
  };

  const handleRemoveBrandKeywords = (indx: number) => {
    setProjectInfo((preP) => {
      if (preP) {
        const brandKeys = preP.keywords.filter((_val, inx) => inx !== indx);
        return { ...preP, keywords: brandKeys };
      }
      return preP;
    });
  };

  const handleChangeProduct = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    if (val === '' || val.length <= 25) {
      setProjectInfo((pre) => ({
        ...pre,
        productService: { value: val, error: false },
      }));
    }
  };

  const handleChangeDescripton = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const val = e.target.value;
    if (val === '' || val.length <= 250) {
      setProjectInfo((pre) => ({
        ...pre,
        description: { value: val, error: false },
      }));
    }
  };

  const addBrandKeyword = () => {
    setProjectInfo((prePr) => {
      const brkeys = brandKeywords.trim();
      setBrandKeywords('');
      if (brkeys) {
        return {
          ...prePr,
          keywords: [...prePr.keywords, brkeys],
        };
      } else {
        return prePr;
      }
    });
  };

  const addAudienceKeyword = () => {
    setProjectInfo((prePr) => {
      const audi = audience.trim();
      setAudience('');
      if (audi) {
        return {
          ...prePr,
          audience: [...prePr.audience, audi],
        };
      } else {
        return prePr;
      }
    });
  };

  const onChangeAudience = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const val = evt.target.value;
    if (val.length <= 50) {
      setAudience(val);
    }
  };
  const onChangeBrandKeywords = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const val = evt.target.value;
    if (val.length <= 50) {
      setBrandKeywords(val);
    }
  };

  const handleNewProject = () => {
    if (projectInfo.productService.value.length < 3) {
      setProjectInfo((pre) => ({
        ...pre,
        productService: { ...pre.productService, error: true },
      }));
      return;
    }
    if (projectInfo.description.value.trim().split(' ').length < 10) {
      setProjectInfo((pre) => ({
        ...pre,
        description: { ...pre.description, error: true },
      }));
      return;
    }
    dispatch(setLoading(true));

    createProject({
      name: projectInfo.productService.value,
      description: projectInfo.description.value,
      audience: projectInfo.audience,
      keywords: projectInfo.keywords,
      collection_id: selectedCollObj.id,
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.data) {
          dispatch(
            addProjectInCollection({
              collectionID: result.data.collection_id,
              project: result.data,
            }),
          );
          dispatch(
            setProject({
              ...result.data,
            }),
          );
          // setProjectData(result.data);
          dispatch(setDefaultProject(result.data.id));
          // setNewProjectObj({ error: '', value: '' });
          setOpenNewProject(false);
          // setDisabledProjectEdit(false);
          NotificationManager.success(`Project ${result.data.name} created`);
        } else {
          NotificationManager.error(result.message);
        }
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
        NotificationManager.error(err.toString());
      });
  };

  return (
    <Modal
      isOpen={openNewProject}
      onCloseModal={() => {
        setOpenNewProject(false);
        setProjectInfo({
          productService: { value: '', error: false },
          description: { value: '', error: false },
          audience: [] as string[],
          keywords: [] as string[],
        });
      }}
      minWidth={350}
      title="Create New Project"
      showHeader
    >
      <InputField
        wrapperClass="mt-4"
        label="Project / Product / Service Name"
        name="product-service-name"
        required
        placeholder="Shopii"
        value={projectInfo.productService.value}
        onChange={handleChangeProduct}
        error={projectInfo.productService.error}
        errorMsg="Product/Service name must have at least 3 charactes"
        classNameLabel="text-lg font-semibold text-ubblack leading-10"
        tooltip="The name of your first Project. We will also use it in some of the templates for copy generations."
        classNameTooltip="text-sm ml-1"
      />
      <TextField
        wrapperClass="mt-4"
        name="description"
        label="Description"
        required
        placeholder="Our builder lets you quickly create an online marketplace to sell your goods — A no-code e-commerce platform for small businesses. Start your online shop now."
        rows={6}
        resize
        onChange={handleChangeDescripton}
        value={projectInfo.description.value}
        error={projectInfo.description.error}
        errorMsg="Description must have at least 10 words"
        onPaste={(ev) => {
          ev.preventDefault();
          const data = ev.clipboardData
            .getData('text')
            .trim()
            .substring(0, 249);
          setProjectInfo((inputV) => ({
            ...inputV,
            description: { ...inputV.description, value: data },
          }));
        }}
        maxCounter={250}
        classNameLabel="text-lg font-semibold text-ubblack leading-10"
        tooltip='Describe what your Project is about in a few sentences. Take your time on this, as it will significantly impact the quality of the output.
            For example: "Rid yourself of pain conveniently with our affordable physiotherapy clinics. Insurance Reimbursable.'
        classNameTooltip="text-sm ml-1"
      />
      <Keywords
        label="Audience"
        required
        keywords={projectInfo.audience}
        wrapperClass="my-2 w-full sm:w-[400px] lg:w-[580px] xl:w-[700px]"
        onChangeInput={onChangeAudience}
        inputValue={audience}
        name="audience"
        addKeyword={addAudienceKeyword}
        handleRemoveKeyword={handleRemoveAudience}
        maxInput={25}
        classNameLabel="text-lg font-semibold text-ubblack leading-10"
        tooltip="Add keywords that best describe the intended target group for your content. For example, based on demographics (millennials, gen z), types of users (consumers, business owners) or something entirely different (industrial supplies purchasers)."
        classNameTooltip="text-sm ml-1"
        onPasteInput={(evt) => {
          evt.preventDefault();
          const data = evt.clipboardData
            .getData('text')
            .trim()
            .substring(0, 50);
          setAudience(data);
        }}
      />
      <Keywords
        wrapperClass="my-2 w-full sm:w-[400px] lg:w-[580px] xl:w-[700px]"
        label="Keywords"
        required
        keywords={projectInfo.keywords}
        onChangeInput={onChangeBrandKeywords}
        inputValue={brandKeywords}
        name="keywords"
        addKeyword={addBrandKeyword}
        handleRemoveKeyword={handleRemoveBrandKeywords}
        maxInput={25}
        classNameLabel="text-lg font-semibold text-ubblack leading-10"
        tooltip="Add keywords to describe your product, service, or brand. They can be keywords and phrases that people may be looking for or the names of products/services you offer."
        classNameTooltip="text-sm ml-1"
        onPasteInput={(evt) => {
          evt.preventDefault();
          const data = evt.clipboardData
            .getData('text')
            .trim()
            .substring(0, 50);
          setBrandKeywords(data);
        }}
      />

      <Button
        type="primary"
        className="w-full mt-4"
        onClick={handleNewProject}
        disabled={
          !(
            projectInfo.productService &&
            projectInfo.description &&
            projectInfo.audience.length &&
            projectInfo.keywords.length &&
            !projectInfo.productService.error &&
            !projectInfo.description.error
          )
        }
      >
        Create Project
      </Button>
    </Modal>
  );
}

export default ModalNewProject;
