import { useState, useCallback } from 'react';
import set from 'lodash/set';
const useTemplateState = (defaultValue) => {
  const [templateValue, setTemplateValue] = useState(defaultValue);
  const handleOnChange = useCallback(
    (value, path) => {
      setTemplateValue((prev) => {
        const copyState = { ...prev };
        set(copyState, path, value);
        return copyState;
      });
    },
    [setTemplateValue],
  );

  return {
    templateValue,
    handleOnChange,
  };
};

export default useTemplateState;
