import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import useSideNavData from '../hooks/useSidebarData';

const CustomDiv = styled.div.attrs(() => {
  return {
    className: `flex flex-col w-14 sm:w-16 invisible sm:visible fixed sidenav`,
  };
})`
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  bottom: 0;
`;

function Wrapper({ children }) {
  const { openMenu } = useSideNavData();
  return <CustomDiv openMenu={openMenu}>{children}</CustomDiv>;
}

Wrapper.defaultProps = {
  children: <div />,
};
Wrapper.propTypes = {
  children: PropTypes.node,
};
export default Wrapper;
