import React from 'react';
import { clsx } from 'clsx';
import Logo from '../../../../assets/img/UBSmartCopy.svg';
import { ILogoUBSCProps } from './props';

function LogoUBSC({ className }: ILogoUBSCProps) {
  return (
    <div className="my-5 flex justify-center" style={{ marginBottom: 50 }}>
      <img src={Logo} className={clsx(className)} />
    </div>
  );
}

export default LogoUBSC;
