import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleDollar } from '@fortawesome/pro-light-svg-icons';

import Button from '../../../../common/Button';

function ReferFriendSection() {
  return (
    <div className="bg-pampas p-10 flex justify-between items-center flex-wrap rounded-lg">
      <div>
        <h3 className="text-shamrock flex items-center font-semibold mb-4">
          <FontAwesomeIcon
            className="rounded-lg h-5 w-5 mr-2"
            icon={faCircleDollar}
          />
          Refer A Friend
        </h3>
        <p className="text-sm mb-3 text-gray-400 font-normal">
          Earn 30% for referring a friend
        </p>
      </div>
      <Button
        type="secondary"
        onClick={() =>
          window.open('https://smartcopy.firstpromoter.com/', '_blank')?.focus()
        }
        className="w-full md:w-auto"
      >
        Get your link
      </Button>
    </div>
  );
}

export default ReferFriendSection;
