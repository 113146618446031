/* eslint-disable camelcase */
import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { loadStripe } from '@stripe/stripe-js';
import { AiOutlineCheck } from 'react-icons/ai';
import { FaAngleLeft } from 'react-icons/fa';
import { NotificationManager } from 'react-notifications';
import { Link, Redirect, useHistory } from 'react-router-dom';
import LogoW from '../../../assets/img/UBSmartCopy-short-white.svg';
import { checkAuth } from '../../../config/token';
import {
  addDataLayer,
  getQueryParams,
  getRewardfulCode,
} from '../../helpers';
import PlanCard from '../components/PlanCard';
import { requestCheckoutSesion } from "../../../common/sendInfo";
import { setLoading } from "../../../redux-setup/actions/loading";

const plans = ['monthly', 'annual'];

export const PLAN_FEATURES = [
  {
    title: 'Access To Writer',
    description: 'Write any kind of long-form content',
  },
  {
    title: 'Unlimited Generations',
    description: 'Never stop creating',
  },
  {
    title: 'Unlimited Projects',
    description: 'Create and manage projects',
  },
  {
    title: 'Fine-tune Your Copy',
    description: 'Refine your results',
  },
];

function ConciergePlan() {
  const dispatch = useDispatch();
  const {
    first_plan,
  } = useSelector((state) => state['PERSONALIZE/DATA']);
  //   const hasExistingParams = getQueryParams('utm_source');
  //   const paramsDivider = hasExistingParams ? '&' : '?';
  const history = useHistory();
  const backLocation = getQueryParams('backLocation');

  const handleCheckout = (tenure) => {
    const success_url = `${window.location.origin
      }/subscriptions/success?success=true&plan=ubgrowth
      }`;
    const referalCode = getRewardfulCode();
    dispatch(setLoading(true));
    addDataLayer({
      event: 'feature_modal_click',
    });
    const payload = {
      success_url,
      cancel_url: `${window.location.href}`,
      rewardful: '',
      plan: 'ubgrowth',
      tenure: tenure,
    };
    const rewardful = referalCode;
    if (referalCode) {
      if (rewardful) payload.rewardful = rewardful;
    }
    requestCheckoutSesion(payload)
      .then((response) => response.json())
      .then(async (result) => {
        const stripe = await loadStripe(result.stripe_token);
        if (stripe) {
          const stripeRedirect = await stripe.redirectToCheckout({
            sessionId: result.stripe_session_id,
          });
          if (stripeRedirect.error) {
            NotificationManager.error('Something went wrong');
          }
        }
      })
      .catch(() => dispatch(setLoading(false)));
  };



  React.useEffect(() => {
    if (
      getQueryParams('success') === 'true'
      && checkAuth()
      && plans.includes(getQueryParams('plan'))
    ) {
      addDataLayer({
        event: 'paidsignup',
        plan: getQueryParams('plan'),
      });
    }
  }, []);

  if (getQueryParams('success') === 'true') {
    return (
      <div className="w-full flex flex-col items-center justify-center h-screen">
        <div className="flex flex-col items-center justify-center px-4 lg:px-16 w-11/12 sm:w-8/12 md:w-6/12 xl:w-4/12 bg-white rounded-xl">
          <AiOutlineCheck size={35} className="text-shamrock my-8" />
          <p className="text-2xl font-semibold mb-4">Subscription Created</p>
          <p className="text-gray-500 text-center">Thanks for signing up.</p>
          <div className="flex items-center w-full my-8">
            <Link
              className="rounded w-full border shadow text-center p-1 bg-indigo-600 text-white  mr-2"
              to="/browse"
            >
              Get Started
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return first_plan === 'starter' || first_plan === 'free' ? (
    <div className="w-full flex flex-col h-screen">
      <div
        className="bg-ubblack w-full h-full"
        style={{
          height: '60vh',
        }}
      >
        <div className="flex flex-col items-center w-full max-w-md sm:max-w-screen-sm lg:max-w-5xl  xl:max-w-screen-lg pt-4 mx-auto">
          <button
            className="text-white self-start flex items-center z-10"
            type="button"
            onClick={() => {
              if (!backLocation) {
                history.push('/templates');
                return;
              }
              history.push(backLocation);
            }}
          >
            <FaAngleLeft className="mr-1" />
            Back
          </button>
          <img
            src={LogoW}
            alt="logo"
            className="logo w-1/2 md:w-1/3 lg:w-1/4 mb-10"
          />
          <h2 className="text-3xl font-barlow text-white sm:text-4xl lg:text-5xl text-center w-11/12 capitalize">
            Redeem Your Special Offer
          </h2>
          <h2 className="font-source font-thin text-white text-base lg:text-xl text-center  mt-4">
            As a valued Unbounce customer, you deserve the best tools. Queue
            Smart Copy, the AI-powered copy assistant that will help you
            generate unlimited copy, everywhere you go.
          </h2>
        </div>
        <div className="relative flex w-full">
          <div
            className="w-full max-w-md sm:max-w-screen-sm lg:max-w-5xl
            space-y-12 lg:space-y-0
            xl:max-w-80 h-full absolute grid grid-cols-1 lg:grid-cols-2 gap-x-24"
            style={{
              top: 80,
              left: '50%',
              transform: 'translateX(-50%)',
              zIndex: 9999,
              maxHeight: 700,
            }}
          >
            <PlanCard
              type="Choose Annual"
              description="after $480/year"
              features={PLAN_FEATURES}
              price="Free"
              duration="year"
              cadence="first 3 months"
              handleCheckout={() => handleCheckout('annual')}
              buttonText={'Redeem Offer'}
            />
            <PlanCard
              type="Choose Monthly"
              description="after $49/month"
              features={PLAN_FEATURES}
              price="Free"
              duration="month"
              cadence="first 3 months"
              handleCheckout={() => handleCheckout('monthly')}
              buttonText={'Redeem Offer'}
            />
          </div>
        </div>
      </div>
    </div>
  ) : (<Redirect to={'/plan'} />);
}

export default ConciergePlan;
