/* eslint-disable camelcase */
/* eslint-disable react/no-array-index-key */
import React from 'react';

import PropTypes from 'prop-types';
import ContentEditable from 'react-contenteditable';

import useTemplateState from '../../../../hooks/useTemplateState';
import { SELECTED_PERSONA } from '../../../partials/pagelayout/constants';
import { editTemplate } from '../../../savedcontent/actions';
import { useApiData } from '../../../../redux-setup';

import TemplateFooter from '../footer/TemplateFooter';

function HeaderDescriptionTemplate({ data, onEditMode, hideFooter }) {
  const { templateValue, handleOnChange } = useTemplateState(data);
  // eslint-disable-next-line no-unsafe-optional-chaining
  const { headline, description } = templateValue?.content;
  const { company_name } = useApiData(SELECTED_PERSONA, {});

  // landing
  const landingHeadline = (
    <h3 className="text-center text-xl sm:text-2xl mxl:text-3xl mxl:text-4xl font-extrabold font-semibold text-black">
      <ContentEditable
        html={headline || ''}
        onBlur={({ target: { innerHTML } }) =>
          editTemplate(innerHTML, data, 'content.headline')
        }
        onChange={(e) => handleOnChange(e.target.value, 'content.headline')}
        spellCheck={false}
        disabled={!onEditMode}
      />
    </h3>
  );

  const landingDescription = (
    <div className="mt-4 text-gray-600 mt-3 max-w-md mx-auto text-center text-base sm:text-lg md:mt-5 mxl:text-xl md:max-w-3xl">
      <ContentEditable
        disabled={!onEditMode}
        html={description || ''}
        onBlur={({ target: { innerHTML } }) =>
          editTemplate(innerHTML, data, 'content.description')
        }
        onChange={(e) => handleOnChange(e.target.value, 'content.description')}
        spellCheck={false}
      />
    </div>
  );

  const landingContentNoPhotos = (
    <div className="mb-6">
      <div className="flex justify-between items-center">
        <h1 className="text-base sm:text-xl text-gray-600">
          {company_name || 'Company'}
        </h1>
        <p className="text-gray-600 text-sm sm:text-base">Login</p>
      </div>
      <div className="mt-12 flex flex-col justify-center items-center">
        {landingHeadline}
        {landingDescription}
        <div className="flex justify-center items-center gap-x-4 mt-4 w-full">
          <button
            type="button"
            className="cursor-default rounded-md shadow flex items-center justify-center px-4 py-2 border border-transparent text-xs sm:text-sm xlg:text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
          >
            Get Started
          </button>
          <button
            type="button"
            className="cursor-default rounded-md shadow flex items-center justify-center px-4 py-2  border border-transparent text-xs sm:text-sm xlg:text-base  font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-5"
          >
            Learn More
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <div
      style={{
        position: 'relative',
        marginBottom: 25,
      }}
    >
      <div
        className="bg-white mb-2 shadow-md font-source"
        style={{
          boxShadow: '0px 4px 18px rgba(0, 0, 0, 0.06)',
          borderRadius: '16px',
        }}
      >
        <div className="py-6 px-8">{landingContentNoPhotos}</div>
      </div>
      {!hideFooter && (
        <TemplateFooter
          data={templateValue}
          onEditMode={onEditMode}
          handleOnChange={handleOnChange}
          type="header-description"
        />
      )}
    </div>
  );
}

HeaderDescriptionTemplate.defaultProps = {
  data: {},
  onEditMode: false,
  hideFooter: false,
};

HeaderDescriptionTemplate.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.objectOf(PropTypes.any),
  onEditMode: PropTypes.bool,
  hideFooter: PropTypes.bool,
};
export default HeaderDescriptionTemplate;
