import React from 'react';
import PropTypes from 'prop-types';
import Editor from '@draft-js-plugins/editor';
import { useLocation } from 'react-router-dom';
import { EditorState, RichUtils } from 'draft-js';
import { convertFromHTML, convertToHTML } from 'draft-convert';
import createCounterPlugin from '@draft-js-plugins/counter';
import { clsx } from 'clsx';
import { getBlockStyle } from '../../writer/actions';
import { BLOCK_TYPES } from '../../writer/contants';
import { getStorage, storeStorage } from '../../helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileLines, faXmarkLarge } from '@fortawesome/pro-regular-svg-icons';

const counterPlugin = createCounterPlugin();
// Extract a counter from the plugin.
const { CharCounter, WordCounter } = counterPlugin;

function NotepadContainer({ setShow, show }) {
  const loc = useLocation();
  const firstPath = React.useMemo(
    () => loc?.pathname?.split('/')[1] || '',
    [loc],
  );
  const containerRef = React.useRef();
  const [editorState, setEditorState] = React.useState(
    EditorState.createWithContent(convertFromHTML(getStorage('notepad') || '')),
  );

  const editorRef = React.useRef();

  const onChange = (editorContent) => {
    setEditorState(editorContent);
    const html = convertToHTML(editorState.getCurrentContent());
    storeStorage('notepad', html);
  };

  const toggleInlineStyle = (inline) => (e) => {
    e.preventDefault();
    onChange(RichUtils.toggleInlineStyle(editorState, inline));
  };

  const toggleBlockType = (type) => (e) => {
    e.preventDefault();
    onChange(RichUtils.toggleBlockType(editorState, type));
  };

  const selection = editorState.getSelection();

  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  React.useEffect(() => {
    if (show) {
      editorRef.current.focus();
    }
  }, [show]);

  React.useEffect(() => {
    const escapeEvent = (e) => {
      if (e?.key === 'Escape') setShow(false);
    };
    if (show) {
      document.addEventListener('keyup', escapeEvent);
    } else document.removeEventListener('keyup', escapeEvent);

    if (show && firstPath !== 'browse')
      document.removeEventListener('keyup', escapeEvent);
  }, [show]);

  if (firstPath !== 'browse') return null;

  return (
    <>
      {!show && (
        <button
          type="button"
          className="notepad-button bg-shamrock cursor-pointer text-white py-2 px-4 rounded-tr-lg top-2/4 rounded-tl-lg items-center text-sm space-x-6 hidden lg:flex fixed"
          onClick={() => setShow(true)}
          style={{
            right: -35,
          }}
        >
          <FontAwesomeIcon icon={faFileLines} className="mr-2" />
          Notepad
        </button>
      )}
      {show && (
        <div
          className={clsx(
            'hidden lg:flex w-5/12 threek:w-2/12 threek:fixed threek:right-0 flex-col flex-grow threek:z-99',
            'bg-white',
          )}
          ref={containerRef}
          style={{
            height: 'calc(100vh - 136px)',
          }}
        >
          <div
            className="border-l-2 border-shamrock fixed flex flex-col h-full"
            style={{
              width: containerRef?.current?.getBoundingClientRect()?.width,
            }}
          >
            <button
              type="button"
              className="notepad-button bg-shamrock cursor-pointer text-white py-2 px-4 rounded-tr-lg top-2/4 rounded-tl-lg items-center text-sm space-x-6 hidden lg:flex absolute"
              onClick={() => setShow(false)}
              style={{
                left: -63,
              }}
            >
              <FontAwesomeIcon icon={faXmarkLarge} className="mr-2" />
              Close
            </button>
            <div className="flex flex-grow flex-col px-4 py-8 w-full">
              <div className="flex items-center justify-between mb-6">
                <div className="flex space-x-2 text-main">
                  {React.Children.toArray(
                    BLOCK_TYPES.map((block) => (
                      <button
                        type="button"
                        key={block?.style}
                        onMouseDown={toggleBlockType(block?.style)}
                        className={clsx(
                          'px-1 rounded hover:underline hover:bg-gray-100',
                          blockType === block.style && 'bg-gray-100',
                        )}
                      >
                        {block?.label}
                      </button>
                    )),
                  )}
                  <button
                    type="button"
                    onMouseDown={toggleInlineStyle('UNDERLINE')}
                    className="px-1 rounded underline hover:bg-gray-100"
                  >
                    U
                  </button>
                  <button
                    type="button"
                    onMouseDown={toggleInlineStyle('BOLD')}
                    className="px-1 font-bold rounded hover:underline hover:bg-gray-100"
                  >
                    B
                  </button>
                  <button
                    type="button"
                    onMouseDown={toggleInlineStyle('ITALIC')}
                    className="px-1 rounded hover:underline hover:bg-gray-100 italic"
                    style={{
                      fontFamily: 'Times New Roman',
                    }}
                  >
                    I
                  </button>
                </div>
              </div>

              <div className="flex flex-col flex-grow border-t pt-2 w-full">
                <Editor
                  ref={editorRef}
                  blockStyleFn={getBlockStyle}
                  editorState={editorState}
                  onChange={onChange}
                  plugins={[counterPlugin]}
                  className="w-full"
                  placeholder="Use this as a place to take notes and mix & match templates. This content is erased on every session. If you are looking for a place to write long-form content, try using Writer."
                />
              </div>
              <div className="flex items-center text-gray-500 text-sm pt-2 border-t">
                <CharCounter limit={200} />{' '}
                <span className="mx-1"> characters,</span>
                <WordCounter />
                <span className="ml-1"> words</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
NotepadContainer.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
};
export default NotepadContainer;
