import React from 'react';
import { clsx } from 'clsx';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IStepperProps } from './props';

function Stepper({ step }: IStepperProps) {
  return (
    <div>
      <div className="flex items-center">
        <div
          className={clsx(
            'h-8 w-8 rounded-full border-2 relative',
            step === 1 && 'border-ubblue',
            (step === 2 || step === 3) && 'border-shamrock bg-shamrock',
          )}
        >
          {step === 1 && (
            <div className="absolute h-2 w-2 rounded-full bg-ubblue -m-1 inset-1/2" />
          )}
          {(step === 2 || step === 3) && (
            <FontAwesomeIcon
              icon={faCheck}
              className="absolute text-white w-4 h-4 -m-2 inset-1/2"
            />
          )}
        </div>
        <div
          className={clsx(
            'flex-auto border-t-2',
            step === 1 && 'border-usilver',
            step === 2 && 'border-ubblue',
            step === 3 && 'border-shamrock',
          )}
        />
        <div
          className={clsx(
            'relative h-8 w-8 rounded-full border-2',
            step === 1 && 'border-usilver',
            step === 2 && 'border-ubblue',
            step === 3 && 'border-shamrock bg-shamrock',
          )}
        >
          {step === 2 && (
            <div className="absolute h-2 w-2 rounded-full bg-ubblue -m-1 inset-1/2" />
          )}
          {step === 3 && (
            <FontAwesomeIcon
              icon={faCheck}
              className="absolute text-white w-4 h-4 -m-2 inset-1/2"
            />
          )}
        </div>
        <div
          className={clsx(
            'flex-auto border-t-2',
            (step === 1 || step === 2) && 'border-usilver',
            step === 3 && 'border-ubblue',
          )}
        />
        <div
          className={clsx(
            'relative h-8 w-8 rounded-full border-2',
            (step === 1 || step === 2) && 'border-usilver',
            step === 3 && 'border-ubblue',
          )}
        >
          {step === 3 && (
            <div className="absolute h-2 w-2 rounded-full bg-ubblue -m-1 inset-1/2" />
          )}
        </div>
      </div>
      <h3 className="font-source text-center my-6 text-2xl text-ubblack">
        {step === 1 && 'How can we help you?'}
        {step === 2 && 'Tell us about yourself'}
        {step === 3 && (
          <>
            Let&apos;s create your first project,
            <br />
            we&apos;ll use this info to give you relevant copy.
          </>
        )}
      </h3>
    </div>
  );
}

export default Stepper;
