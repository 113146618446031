/* eslint-disable radix */
import React from 'react';

import { Formik, Form, Field } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import { TextArea } from '../../common/Formik/FormInputs';

// validation
const rqdmsg = 'This field is required.';
const validateForm = Yup.object().shape({
  original: Yup.string().required(rqdmsg).max(500).label('Content'),
});

const placeholder = 'This week we are announcing our new product: Widget X.';

// eslint-disable-next-line react/display-name
const RemixerForm = React.forwardRef(({ onSubmit, initialValues }, ref) => (
  <Formik
    innerRef={ref}
    initialValues={initialValues}
    validationSchema={validateForm}
    onSubmit={(values, { setSubmitting }) => onSubmit(values, setSubmitting)}
    enableReinitialize
  >
    {({ values }) => (
      <Form className="">
        <div className="w-full pr-0">
          <p className="text-gray-700 font-semibold text-md flex items-center">
            Your Content
          </p>
          <Field
            component={TextArea}
            name="original"
            value={values.original}
            placeholder={placeholder}
            wrapperClass="my-4"
            rows={5}
          />
        </div>
      </Form>
    )}
  </Formik>
));

RemixerForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  initialValues: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default RemixerForm;
