import req from '../../redux-setup';
import { arrayDelete, append } from '../../redux-setup/actions';

export const getTemplates = () => req
  .get({
    url: '/template',
    useLoading: false,
  })
  .then(({ data }) => {
    req.set('templates', data);
  })
  .catch((err) => console.error(err));

export const createTemplate = (payload) => req
  .post({
    url: '/template/create',
    payload,
    useLoading: false,
  })
  .then(({ data }) => {
    append('templates', data);
    return data;
  })
  .catch((err) => console.error(err));

export const deleteTemplate = (id) => req
  .remove({
    url: `/template/delete/${id}`,
    useLoading: false,
  })
  .then(() => arrayDelete('templates', id))
  .catch((err) => console.error(err));
