import React from 'react';
import { IKeywordInputProps } from './props';

function KeywordInput({
  value,
  onChange,
  name,
  insertInput,
  disabled,
  onPaste,
}: IKeywordInputProps) {
  return (
    <div className="w-full">
      <input
        className="p-2 pl-2 rounded-lg font-source text-sm disabled:hidden w-full"
        placeholder={`＋ Add ${name}...`}
        autoComplete="off"
        name={name}
        value={value}
        onChange={disabled ? undefined : onChange}
        onBlur={disabled ? undefined : insertInput}
        onPaste={disabled ? undefined : onPaste}
        onKeyDown={
          disabled
            ? undefined
            : (e) => {
                if (
                  e.key === 'Tab' ||
                  e.key === 'Enter' ||
                  e.key === ',' ||
                  e.key === '.'
                ) {
                  e.preventDefault();
                  insertInput();
                }
              }
        }
        disabled={disabled}
      />
    </div>
  );
}

export default KeywordInput;
