import React from 'react';
import BigCard from './BigCard';
import Card from './SmallCard';
import desktopImage from '../../assets/img/banners/desktopImage.png';
import smartBuilder from '../../assets/img/banners/smart-builder.png';
import chromeExtension from '../../assets/img/banners/chromeExtension.png';

function ToolsPage() {
  return (
    <div className="mx-auto container px-8 max-w-screen-xl">
      <h1 className="font-barlow text-2xl text-mine-shaft mb-8">
        Take
        {' '}
        <span className="font-barlow font-semibold">Smart Copy</span>
        {' '}
        With
        You
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
        <Card
          title="Chrome Extension"
          paragraph="The Smart Copy browser companion that writes your next sentence, rewrites it, or expands it into a paragraph."
          learnMoreLink="http://unbounce.com/product/smart-copy/google-chrome-extension?utm_medium=tools&utm_source=smartcopy"
          imageLink={chromeExtension}
          buttonText="Add To Chrome - It's Free"
          buttonLink="https://chrome.google.com/webstore/detail/smart-copy-write-better-m/elheiaagkmeiekjhkidoabinjajnohgo?utm_medium=tools&utm_source=smartcopy"
        />
        <Card
          title="Desktop App"
          paragraph="All the power of Smart Copy, on your desktop. Stay focused with a distraction-free workspace, across your favorite tools."
          learnMoreLink="https://unbounce.com/product/smart-copy/desktop-app/"
          imageLink={desktopImage}
          buttonText="Download Now"
          buttonLink="https://desktopapp.unbounce.com"
        />
      </div>
      <h1 className="font-barlow text-2xl text-mine-shaft my-8">
        More From
        {' '}
        <span className="font-barlow font-semibold">Unbounce</span>
      </h1>
      <BigCard
        title="Smart Builder"
        subtitle="Turn Your Copy Into High-Converting Landing Pages"
        paragraph="Landing pages are the best way to get more leads, sales, and signups out of your marketing campaigns. When you create pages with Smart Builder, you get data-backed recommendations on what works—so you can create high-performing campaigns in a fraction of the time."
        buttonText="Start Building For Free"
        buttonLink="https://unbounce.com/sc-sb/?utm_medium=tools&utm_source=smartcopy"
        imageLink={smartBuilder}
      />
    </div>
  );
}

export default ToolsPage;
