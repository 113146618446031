import React from 'react';

import { Formik, Field } from 'formik';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import { Input } from '../../common/Formik/FormInputs';

const rqdmsg = 'This field is required.';
const validateForm = Yup.object().shape({
  email: Yup.string().required(rqdmsg).max(50).email()
    .label('Email'),
});

function ForgotPasswordForm({ onSubmit }) {
  return (
    <Formik
      validationSchema={validateForm}
      initialValues={{
        email: '',
      }}
      // eslint-disable-next-line max-len
      onSubmit={(values, { setSubmitting, resetForm }) => onSubmit(values, setSubmitting, resetForm)}
    >
      {({ values, handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          <Field
            component={Input}
            name="email"
            type="email"
            value={values.email}
            placeholder="Email"
            wrapperClass="font-bold text-black font-montserrat"
          />
          <div className="flex justify-between items-center mt-2">
            <Link className="btn clear" to="/" disabled={isSubmitting}>
              Back
            </Link>
            <button
              type="button"
              className="btn primary"
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              Submit
            </button>
          </div>
        </form>
      )}
    </Formik>
  );
}

ForgotPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};
export default ForgotPasswordForm;
