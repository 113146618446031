import React from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import { FacebookShareButton, TwitterShareButton } from 'react-share';

import ShareEmailForm from '../../forms/ShareEmailForm';

import { activeButtonProps, actionsList } from './constants';

const actionButtons = ['twitter', 'facebook'];

function ShareActions({ shareLink, isShared }) {
  const [action, setAction] = React.useState('email');
  const facebookRef = React.useRef();
  const twitterRef = React.useRef();
  const ActiveLogo = React.useMemo(
    () => get(activeButtonProps, `${action}.logo`, <span />),
    [action],
  );

  const currentAction = React.useMemo(
    () => (
      <div className="flex items-center">
        <ActiveLogo
          color={get(activeButtonProps, `${action}.color`, '-')}
          className="mr-2"
          size={21}
        />
        {' '}
        <span
          className="text-sm font-semibold"
          style={{ color: get(activeButtonProps, `${action}.color`, '-') }}
        >
          {get(activeButtonProps, `${action}.text`, '-')}
        </span>
      </div>
    ),
    [action],
  );

  return (
    <div className="w-full flex flex-col text-gray-400">
      <FacebookShareButton
        url={shareLink}
        hashtag="#smartcopy#futureofcontent"
        ref={facebookRef}
      />
      <TwitterShareButton
        url={shareLink}
        hashtag="#smartcopy#futureofcontent"
        ref={twitterRef}
      />

      <div className="flex items-center justify-between w-full">
        {currentAction}
        <div className="flex space-x-2 ">
          {actionsList
            ?.filter((act) => act.name !== action)
            ?.map((act) => (
              <button
                key={act.name}
                type="button"
                disabled={!isShared}
                onClick={() => setAction(act.name)}
              >
                <act.logo size={21} />
              </button>
            ))}
        </div>
        {/* <button
          type="button"
          onClick={() => setAction('code')}
          className={clsx(
            action === 'code' && 'text-shamrock',
            'flex items-center'
          )}
        >
          <IoMdCode size={26} className="mr-2" />
          Embed on your site
        </button> */}
      </div>
      <div className="flex items-center mt-4 space-x-4">
        {action === 'email' && <ShareEmailForm />}
        {actionButtons.includes(action) && (
          <button
            type="button"
            className="btn primary"
            disabled={!isShared}
            onClick={() => {
              if (action === 'facebook') {
                facebookRef.current.click();
              } else if (action === 'twitter') {
                twitterRef.current.click();
              }
            }}
          >
            Share on
            {' '}
            <span className="capitalize ml-1">{action}</span>
          </button>
        )}
        {action === 'code' && (
          <div className="flex flex-col w-full space-y-2">
            <textarea
              className="w-full rounded-xl bg-gray-100 p-1"
              rows={4}
              disabled={!isShared}
              readOnly
            />
            <button type="button" disabled={!isShared} className="btn primary">
              Copy to clipboard
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

ShareActions.propTypes = {
  isShared: PropTypes.bool.isRequired,
  shareLink: PropTypes.string.isRequired,
};
export default ShareActions;
