import React from 'react';

import { Link, useLocation } from 'react-router-dom';

import Menu from '../components/Menu';
import Wrapper from '../components/Wrapper';
import { menuItems, userItems } from '../constants';
//import { useApiData } from '../../../../redux-setup';
//import { WRITER_SIDEBAR } from '../../../writer/contants';

function SideNav() {
  const location = useLocation();
  //  const sideBarData = useApiData(WRITER_SIDEBAR, {});
  const renderLinks = React.useCallback(
    (lists = []) =>
      React.Children.toArray(
        lists?.map((val, i) => (
          <Menu
            // eslint-disable-next-line react/no-array-index-key
            key={String(i)}
            favicon={val?.icon}
            tooltip={val?.tooltip}
            label={val?.label}
            data={
              typeof val?.data === 'function'
                ? val?.data(location.pathname)
                : val?.data
            }
            disableActive={val?.disableActive}
            onClick={val?.onClick}
          />
        )),
      ),
    [location],
  );

  return (
    <Wrapper>
      <div className="flex flex-col flex-grow justify-between text-main bg-pampas">
        <div className="flex flex-col flex-grow ">
          <Link
            to="/browse"
            className="flex items-center py-5 px-3 leading-4  font-semibold justify-center relative betterhover:hover:text-main-active"
            id="logo"
          >
            <svg
              className="fill-ubblack betterhover:hover:fill-raven w-7"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 30 30"
            >
              <path d="M0 3.47A3.47 3.47 0 0 1 3.469 0h25.587c.456 0 .826.37.826.826v5.49c0 .457-.37.827-.826.827H.826A.826.826 0 0 1 0 6.317V3.469Z" />
              <path
                fillRule="evenodd"
                d="M.005 10.949c0-.456.37-.826.826-.826h21.797c.456 0 .826.37.826.826v1.473a10.906 10.906 0 0 0-1.259-.073c-3.82 0-7.159 1.982-8.978 4.943H.831a.826.826 0 0 1-.826-.826V10.95ZM12.224 20.364A9.843 9.843 0 0 0 12 22.45c0 1.854.515 3.59 1.413 5.082H.834a.826.826 0 0 1-.827-.826V21.19c0-.456.37-.826.826-.826h11.39ZM22.225 14.952c-4.297 0-7.775 3.366-7.775 7.524 0 4.158 3.479 7.524 7.775 7.524s7.774-3.366 7.774-7.524c0-4.158-3.478-7.524-7.774-7.524Zm-.278 10.48-.57 1.626a.436.436 0 0 1-.073.141.676.676 0 0 1-.277.255.696.696 0 0 1-.57.042c-.322-.113-.526-.424-.483-.735-1.607-.792-2.703-2.419-2.703-4.286 0-2.659 2.221-4.822 4.983-4.822.322 0 .629.028.935.084a.754.754 0 0 1 .848-.297.718.718 0 0 1 .468.891l-.57 1.627-1.988 5.473Zm1.314-.155-.686 2.008c2.586-.184 4.617-2.263 4.632-4.808 0-1.471-.687-2.787-1.754-3.664l-.686 2.009c.336.466.526 1.046.526 1.655 0 1.3-.848 2.39-2.032 2.8Z"
                clipRule="evenodd"
              />
              <path d="M19.158 22.475c0 1.047.57 1.966 1.417 2.503l1.93-5.459c-.088-.014-.19-.014-.278-.014-1.696 0-3.07 1.33-3.07 2.97Z" />
            </svg>
          </Link>

          {renderLinks(menuItems, 2)}
        </div>
        {renderLinks(userItems)}
      </div>
    </Wrapper>
  );
}

export default SideNav;
