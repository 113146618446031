import React from 'react';

import { v4 } from 'uuid';

// eslint-disable-next-line import/no-cycle
import PageLayout from '../../partials/pagelayout/containers/PageLayout';
import FacebookAdsTemplate from '../components/templates/FacebookAdsTemplate';

export default function FacebookAds() {
  return (
    <PageLayout template="facebook-ads" title="Facebook Ads">
      {({ contents }) => (
        <>
          {React.Children.toArray(
            contents.map((facebookads) => <FacebookAdsTemplate key={v4()} data={facebookads} />),
          )}
        </>
      )}
    </PageLayout>
  );
}
