import React, { useEffect, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { SingleValue } from 'react-select';
import { updateOutputLanguage } from '../../modules/partials/pagelayout/actions';
import { IDefaultSelect } from '../props';
import SelectForm from '../SelectForm';

export const languageOptions = [
  {
    value: 'en',
    label: '🇺🇸 English',
  },
  {
    value: 'es',
    label: '🇪🇸 Spanish',
  },
  {
    value: 'fr',
    label: '🇫🇷 French',
  },
  {
    value: 'de',
    label: '🇩🇪 German',
  },
  {
    value: 'pt',
    label: '🇧🇷 Portuguese',
  },
  {
    value: 'bg',
    label: '🇧🇬 Bulgarian',
  },
  {
    value: 'zh',
    label: '🇨🇳 Chinese (Simplified)',
  },
  {
    value: 'hr',
    label: '🇭🇷 Croatian',
  },
  /* {
    value: 'zh-TW',
    label: '🇹🇼 Chinese (Traditional)',
    
  }, */
  {
    value: 'cs',
    label: '🇨🇿 Czech',
  },
  {
    value: 'da',
    label: '🇩🇰 Danish',
  },
  {
    value: 'nl',
    label: '🇳🇱 Dutch',
  },
  {
    value: 'et',
    label: '🇪🇪 Estonian',
  },
  {
    value: 'fi',
    label: '🇫🇮 Finnish',
  },
  {
    value: 'el',
    label: '🇬🇷 Greek',
  },
  {
    value: 'he',
    label: '🇮🇱 Hebrew',
  },
  {
    value: 'hi',
    label: '🇮🇳 Hindi',
  },
  {
    value: 'hu',
    label: '🇭🇺 Hungarian',
  },
  {
    value: 'in',
    label: '🇮🇩 Indonesian',
  },
  {
    value: 'it',
    label: '🇮🇹 Italian',
  },
  {
    value: 'ja',
    label: '🇯🇵 Japanese',
  },
  {
    value: 'ko',
    label: '🇰🇷 Korean',
  },
  {
    value: 'lv',
    label: '🇱🇻 Latvian',
  },
  {
    value: 'lt',
    label: '🇱🇹 Lithuanian',
  },
  {
    value: 'no',
    label: '🇳🇴 Norwegian',
  },
  {
    value: 'pl',
    label: '🇵🇱 Polish',
  },
  {
    value: 'ro',
    label: '🇷🇴 Romanian',
  },
  {
    value: 'ru',
    label: '🇷🇺 Russian',
  },
  {
    value: 'sk',
    label: '🇸🇰 Slovak',
  },
  {
    value: 'sl',
    label: '🇸🇮 Slovenian',
  },
  {
    value: 'sv',
    label: '🇸🇪 Swedish',
  },
  {
    value: 'tl',
    label: '🇵🇭 Tagalog',
  },
  {
    value: 'th',
    label: '🇹🇭 Thai',
  },
  {
    value: 'tr',
    label: '🇹🇷 Turkish',
  },
  {
    value: 'uk',
    label: '🇺🇦 Ukrainian',
  },
  {
    value: 'vi',
    label: '🇻🇳 Vietnamese',
  },
];

function Language() {
  const outLang = useSelector(
    (state: RootStateOrAny) => state['PERSONALIZE/DATA'].output_language,
  );
  const [defaultValue, setDefaultValue] = useState<IDefaultSelect | undefined>(
    undefined,
  );
  const [firstTimeRender, setFirstTimeRender] = useState(false);
  const [renderKey, setRenderKey] = useState(Math.random() * 9999);
  const handleChangeSelect = (newValue: SingleValue<IDefaultSelect>) => {
    updateOutputLanguage(newValue?.value);
  };

  useEffect(() => {
    if (outLang && !firstTimeRender) {
      setDefaultValue(languageOptions.find((lo) => lo.value === outLang));
      setRenderKey(Math.random() * 9999);
      setFirstTimeRender(true);
    }
  }, [outLang, firstTimeRender]);

  return (
    <SelectForm
      key={renderKey}
      wrapperClass="w-full mt-0"
      labelClass="font-source text-ugray text-sm font-semibold"
      label="Language"
      options={languageOptions}
      onChangeSelect={handleChangeSelect}
      defaultValue={defaultValue}
    />
  );
}

export default Language;
