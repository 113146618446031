import React from 'react';

function BenefitsSkeleton() {
  return (
    <div className="flex flex-col justify-center items-center mb-4 p-6 bg-gray-300 rounded">
      <div className="h-5 bg-gray-200 rounded-full w-2/3 mb-4" />
      <div className="h-4 bg-gray-200 rounded-full w-2/3 mb-4" />
      <div className="h-4 bg-gray-200 rounded w-24" />
    </div>
  );
}

export default BenefitsSkeleton;
