/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable default-param-last */
/* eslint-disable max-len */
import {
  convertFromRaw,
  convertToRaw,
  EditorState,
  Modifier,
  SelectionState,
} from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { OrderedSet } from 'immutable';
import debounce from 'lodash/debounce';
import { v4 } from 'uuid';

import { updateContent } from '../templates/actions';
import req from '../../redux-setup';

import { writerToSavedPayload } from './transform';

export const getStateBlocks = (editorState) => {
  const { blocks } = convertToRaw(editorState.getCurrentContent());
  return blocks;
};

export const autoSave = debounce(
  (
    newValue,
    lastSavedContent = '',
    payload = {},
    setLoading,
    callback = () => { },
    writerState,
  ) => {
    const convertedState = stateToHTML(newValue.getCurrentContent());
    if (lastSavedContent === convertedState) return;
    setLoading();
    updateContent({
      ...payload,
      content: {
        writerContent: convertedState,
        writerState,
      },
    }).then((res) => callback(convertedState, res));
  },
  1000,
);

export const autoSaveV2 = debounce(
  (
    newContent,
    lastSavedContent = '',
    payload = {},
    setLoading,
    callback = () => { },
    writerState,
  ) => {
    if (lastSavedContent === newContent || !payload?.id) return;
    setLoading();
    updateContent({
      ...payload,
      content: {
        isTinyMCE: true,
        writerContent: newContent,
        writerState,
      },
    }).then(callback);
  },
  1000,
);

export const saveDoc = async (
  title,
  content,
  personaId,
  customPayload = {},
) => {
  const payload = writerToSavedPayload(
    title,
    content,
    personaId,
    customPayload,
  );
  return req
    .post({
      url: '/saved-content/create',
      payload,
      useLoading: false,
    })
    .then(({ data }) => data);
};

export const generateBlockObject = (
  text = '',
  type = 'unstyled',
  options = {},
) => ({
  type,
  depth: 0,
  inlineStyleRanges: [],
  entityRanges: [],
  ...options,
  text,
  key: v4(),
});

export const checkGrayText = (b) => b?.inlineStyleRanges.length > 0
  && b?.inlineStyleRanges?.some((is) => is.style === 'GRAYTEXT');

export const addNewLine = async (newText, currentEditorState, handleChange) => new Promise((res) => {
  let startIndex = 999999;
  let endIndex = 9999999;

  let selection = currentEditorState.getSelection();

  // Set focus to end of text
  if (!selection.getHasFocus()) {
    const currentStateBlocks = getStateBlocks(currentEditorState);
    const lastBlock = currentStateBlocks.slice(-1);
    selection = SelectionState.createEmpty(lastBlock[0].key);
    selection = selection.merge({
      focusKey: lastBlock[0].key,
      focusOffset: lastBlock[0].text.length,
      anchorOffset: lastBlock[0].text.length,
      hasFocus: true,
    });
  }

  const contentState = currentEditorState.getCurrentContent();
  const newContent = Modifier.insertText(
    contentState,
    selection,
    `\n@_CUTOFF_TEXT_START_@${newText?.trimEnd() || newText
    }⚡@_CUTOFF_TEXT_END_@\n`,
    OrderedSet.of('GRAYTEXT'),
  );
  const finalEditorState = EditorState.push(
    currentEditorState,
    newContent,
    'insert-characters',
  );
  const stateBlocks = getStateBlocks(finalEditorState);
  const blocks = stateBlocks.flatMap((v) => v.text?.split('\n').map((txt, index) => {
    const getOffset = () => {
      const i = txt.indexOf('@_CUTOFF_TEXT_START_@');
      if (i > -1) {
        startIndex = index;
        return i;
      }

      if (index >= startIndex) return 0;
      return 0;
    };

    const getLength = () => {
      const i = txt.indexOf('@_CUTOFF_TEXT_END_@');

      if (i > -1) {
        endIndex = index;
        return i;
      }
      if (endIndex >= index) return txt.length;
      return 0;
    };

    const formattedText = txt
      ?.replace('@_CUTOFF_TEXT_START_@', '')
      .replace('@_CUTOFF_TEXT_END_@', '');

    return generateBlockObject(formattedText, v?.type, {
      ...v,
      inlineStyleRanges:
        v?.inlineStyleRanges.length > 0
          ? [
            {
              offset: getOffset(),
              length: getLength(),
              style:
                index >= startIndex && index <= endIndex
                  ? 'GRAYTEXT'
                  : '',
            },
          ]
          : [],
    });
  }));
  handleChange(
    EditorState.createWithContent(
      convertFromRaw({
        entityMap: {},
        blocks,
      }),
    ),
  );
  // handleChange(finalEditorState);
  res(finalEditorState);
});

export const getLastParagraphs = (blocks = []) => {
  const value = blocks
    .filter((block) => block?.text?.trim?.() !== '')
    .map((block) => block?.text);
  const sentenceCount = value.length;
  if (sentenceCount > 0 && value[0]?.trim() !== '') {
    if (sentenceCount > 1) return `${value[sentenceCount - 2]}\n${value[sentenceCount - 1]}`;
    return `${value[0]}`;
  }
  return '';
};

export const getBlockStyle = (block) => {
  switch (block.getType()) {
    case 'header-one':
      return 'text-3xl font-medium text-black';
    case 'header-two':
      return 'text-2xl font-medium text-black';
    case 'header-three':
      return 'text-xl font-medium text-black';
    case 'header-four':
      return 'text-lg text-black';
    case 'header-five':
      return 'text-base text-black';
    case 'header-six':
      return 'text-sm text-black';
    case 'unstyled':
      return 'text-base text-black';
    case 'blockquote':
      return 'custom-quote';
    default:
      return null;
  }
};
