/* eslint-disable react/no-array-index-key */
import React from 'react';
// eslint-disable-next-line import/no-cycle
import PageLayout from '../../partials/pagelayout/containers/PageLayout';
import V2Templates from "../../templates/components/templates/V2Template";

export default function ToneChanger() {
  return (
    <PageLayout
      template="tone-changer"
      title="Tone Changer"
      runInitValues={false}
      useEqualizer
    >
      {({ contents }) => (
        <>
          {React.Children.toArray(
            contents.map((data) => <V2Templates key={data.id} data={data} />),
          )}
        </>
      )}
    </PageLayout>
  );
}
// Expander
