import React from 'react';
import { clsx } from 'clsx';
import { ICreditsInfoCardProps } from './props';

function CreditsInfoCard({
  title,
  numberOfCredits,
  className,
}: ICreditsInfoCardProps) {
  return (
    <div
      className={clsx(
        'border border-gallery rounded-md p-4 text-center',
        className,
      )}
    >
      <h2 className="font-barlow font-medium text-xl text-ugray">{title}</h2>
      <h3 className="font-barlow font-medium text-2xl">
        {numberOfCredits || '-'}
      </h3>
    </div>
  );
}

export default CreditsInfoCard;
