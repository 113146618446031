import React, { Suspense } from 'react';

import { NotificationContainer } from 'react-notifications';
import { BrowserRouter as Router } from 'react-router-dom';

import PublicRoutes from './PublicRoutes';
// eslint-disable-next-line import/no-cycle
import SecuredRoutes from './SecuredRoutes';
import { useCheckAuth } from './modules/auth/hooks';
import Loader from './modules/common/Loader/Loader';

function App() {
  window.dataLayer.push({
    event: 'pageview',
  });

  const auth = useCheckAuth();
  if (auth === undefined) return null;
  return (
    <div className="flex flex-col min-w-screen min-h-screen">
      <div className="absolute h-12 drag w-full" />
      <Router>
        <NotificationContainer />
        <Suspense fallback={<Loader />}>
          {auth ? <SecuredRoutes /> : <PublicRoutes />}
        </Suspense>
      </Router>
    </div>
  );
}

export default App;
