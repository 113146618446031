import React from 'react';

import { Formik, Form, Field } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import { Input, TextArea } from '../../common/Formik/FormInputs';

// validation
const rqdmsg = 'This field is required.';
const inputRules = (label) => Yup.string().trim().required(rqdmsg).max(400)
  .label(label);
const validateForm = Yup.object().shape({
  title: inputRules('Title'),
  description: inputRules('Description'),
  benefitHeadline: inputRules('Benefit'),
  benefitDescription: inputRules('Benefit Description'),
  firstFeature: inputRules('Feature'),
  secondFeature: inputRules('Feature'),
  thirdFeature: inputRules('Feature'),
  firstFeatureDescription: inputRules('Feature Description'),
  secondFeatureDescription: inputRules('Feature Description'),
  thirdFeatureDescription: inputRules('Feature Description'),
});

// eslint-disable-next-line react/display-name
const LandingPagesForm = React.forwardRef(
  ({ initialValues, onSubmit }, ref) => (
    <Formik
      innerRef={ref}
      initialValues={initialValues}
      validationSchema={validateForm}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values, setSubmitting);
      }}
      enableReinitialize
    >
      {({ values }) => (
        <Form>
          <div className="flex flex-wrap justify-between">
            <div className="w-full pr-0">
              <p className="text-gray-700 font-bold text-lg flex items-center">
                Landing Page Headline
              </p>
              <Field
                component={Input}
                name="title"
                value={values.title}
                placeholder="The easiest way to..."
                wrapperClass="my-4"
              />
              <p className="text-gray-700 font-bold text-lg flex items-center">
                Landing Page Description
              </p>
              <Field
                component={TextArea}
                name="description"
                value={values.description}
                placeholder="This is the first sentence in the..."
                wrapperClass="my-4"
                rows={4}
              />
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
            <div>
              <Field
                component={Input}
                name="firstFeature"
                value={values.firstFeature}
                placeholder="First feature"
                wrapperClass="my-4"
              />
              <Field
                component={TextArea}
                name="firstFeatureDescription"
                value={values.firstFeatureDescription}
                placeholder="First feature description"
                wrapperClass="w-full"
                rows={4}
              />
            </div>
            <div>
              <Field
                component={Input}
                name="secondFeature"
                value={values.secondFeature}
                placeholder="Second feature"
                wrapperClass="my-4"
              />
              <Field
                component={TextArea}
                name="secondFeatureDescription"
                value={values.secondFeatureDescription}
                placeholder="Second feature description"
                wrapperClass="w-full"
                rows={4}
              />
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
            <div>
              <Field
                component={Input}
                name="thirdFeature"
                value={values.thirdFeature}
                placeholder="Third feature"
                wrapperClass="mb-4 mt-6"
              />
              <Field
                component={TextArea}
                name="thirdFeatureDescription"
                value={values.thirdFeatureDescription}
                placeholder="Third feature description"
                wrapperClass="w-full"
                rows={4}
              />
            </div>
            <div>
              <Field
                component={Input}
                name="benefitHeadline"
                value={values.benefitHeadline}
                placeholder="Benefit"
                wrapperClass="mb-4 mt-6"
              />
              <Field
                component={TextArea}
                name="benefitDescription"
                value={values.benefitDescription}
                placeholder="Benefit Description"
                wrapperClass="w-full"
                rows={4}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  ),
);

LandingPagesForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  initialValues: PropTypes.objectOf(PropTypes.any).isRequired,
  onSubmit: PropTypes.func.isRequired,
};
export default LandingPagesForm;
