// eslint-disable-next-line import/no-cycle
import { buttonSettings } from './settings';
export const WRITER_DETAILS = 'WRITER_DETAILS';
export const WRITER_SIDEBAR = 'WRITER_SIDEBAR';

export const writerTEmplates = [
  'Help Article',
  'Product Description',
  'Ideas',
  'Notes',
  'Documentation',
  'Custom',
];

export const temporaryTitle = [
  'If you want to forget something or someone, never hate it, or never hate him/her. Everything and everyone that you hate is engraved upon your heart; if you want to let go of something, if you want to forget, you cannot hate.',
  "We have to allow ourselves to be loved by the people who really love us, the people who really matter. Too much of the time, we are blinded by our own pursuits of people to love us, people that don't even matter, while all that time we waste and the people who do love us have to stand on the sidewalk and watch us beg in the streets! It's time to put an end to this. It's time for us to let ourselves be loved.",
  `Maybe you can afford to wait. Maybe for you there's a tomorrow. Maybe for you there's one thousand tomorrows, or three thousand, or ten, so much time you can bathe in it, roll around it, let it slide like coins through you fingers. So much time you can waste it.
  But for some of us there's only today. And the truth is, you never really know`,
];

export const BLOCK_TYPES = [
  { label: 'H1', style: 'header-one' },
  { label: 'H2', style: 'header-two' },
  { label: 'H3', style: 'header-three' },
  { label: 'H4', style: 'header-four' },
  { label: 'H5', style: 'header-five' },
  { label: 'H6', style: 'header-six' },
  { label: 'Quote', style: 'blockquote' },
];

export const writerInitSettings = (writerInitSettings, width, height) => ({
  height: '100%',
  min_height: height,
  selector: 'textarea',
  // menubar: false,
  width: '100%',
  skin: 'smartcopy',
  skin_url: 'https://copy.unbounce.com/skins/ui/smartcopy',
  spellchecker_ignore_list: ['Ephox', 'Moxiecode'],
  contextmenu_avoid_overlap: '.mce-spelling-word',
  contextmenu_never_use_native: true,
  a11y_advanced_options: true,
  branding: false,
  plugins:
    'autoresize image powerpaste casechange  searchreplace autolink autosave save directionality link charmap pagebreak nonbreaking insertdatetime advlist lists checklist wordcount tinymcespellchecker a11ychecker help formatpainter charmap quickbars emoticons export',
  mobile: {
    plugins:
      'image powerpaste casechange searchreplace autolink autosave save directionality link charmap pagebreak nonbreaking insertdatetime advlist lists checklist wordcount tinymcespellchecker a11ychecker help formatpainter charmap quickbars emoticons',
  },
  menubar: 'file edit view insert format tools tc',
  quickbars_selection_toolbar:
    'bold italic | quicklink h2 h3 | expand summarize remix next | wordcount spellcheckdialog',
  quickbars_insert_toolbar: width > 1000 ? 'writemore templates' : false,
  menu: {
    file: {
      title: 'File',
      items: 'preview | print ',
    },
    edit: {
      title: 'Edit',
      items: 'undo redo | cut copy paste | selectall | searchreplace',
    },
    view: {
      title: 'View',
      items:
        'code | visualaid visualchars visualblocks | spellchecker | preview fullscreen',
    },
    insert: {
      title: 'Insert',
      items: `${
        width > 1000 ? 'templates writemore | ' : ''
      }image link media template codesample inserttable | charmap emoticons hr | pagebreak nonbreaking anchor toc | insertdatetime`,
    },
    format: {
      title: 'Format',
      items:
        'bold italic underline strikethrough superscript subscript codeformat | formats blockformats fontformats fontsizes align lineheight | forecolor backcolor | casechange removeformat',
    },
    tools: {
      title: 'Tools',
      items: 'spellchecker spellcheckerlanguage | code wordcount',
    },
  },
  toolbar_sticky: true,
  toolbar_mode: 'sliding',
  toolbar: `${
    width > 1000 ? 'templatesButton writemoreButton | ' : ''
  }undo redo print spellcheckdialog formatpainter link | blocks fontfamily fontsize | bold italic underline forecolor backcolor casechange | alignleft aligncenter alignright alignjustify | numlist bullist checklist outdent indent ltr rtl | removeformat`,
  setup: buttonSettings(writerInitSettings),
});
