import React from 'react';

import { Formik, Form, Field } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import { Input, TextArea } from '../../common/Formik/FormInputs';

// validation
const rqdmsg = 'This field is required.';
const inputRules = (label) => Yup.string().trim().required(rqdmsg).max(400)
  .label(label);
const validateForm = Yup.object().shape({
  productName: inputRules('Product Name'),
  productTags: inputRules('Product Tags'),
});

// eslint-disable-next-line react/display-name
const ProdDescriptionForm = React.forwardRef(
  ({ initialValues, onSubmit }, ref) => (
    <Formik
      innerRef={ref}
      initialValues={initialValues}
      validationSchema={validateForm}
      onSubmit={(values, { setSubmitting }) => onSubmit(values, setSubmitting)}
      enableReinitialize
    >
      {({ values }) => (
        <Form>
          <div className="flex flex-wrap justify-between">
            <div className="w-full pr-0">
              <p className="text-gray-700 font-bold text-lg flex items-center">
                Product Name
              </p>
              <Field
                component={Input}
                type="text"
                name="productName"
                value={values.productName}
                placeholder="Product Name"
                wrapperClass="my-6"
              />
              <p className="text-gray-700 font-bold text-lg flex items-center">
                Product Tags
              </p>
              <Field
                component={TextArea}
                name="productTags"
                value={values.productTags}
                placeholder="Product Tags"
                wrapperClass="my-4"
                rows={8}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  ),
);

ProdDescriptionForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  initialValues: PropTypes.objectOf(PropTypes.any).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ProdDescriptionForm;
