// eslint-disable-next-line import/no-cycle
import { getToken } from '../config/token';
import {
  collectionsURL,
  projectURL,
  updateProjectAudienceURL,
  updateProjectDescriptionURL,
  updateProjectKeywordsURL,
  updateProjectNameURL,
} from './URLS';

const sendFetch = (url = '', data = {}) =>
  fetch(url, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
      'Access-Control-Allow-Origin': '*',
    },
    body: JSON.stringify(data),
  });

export const updateColletion = (body) => sendFetch(collectionsURL(), body);
export const updateProject = (body) => sendFetch(projectURL(), body);
export const updateProjectName = (body) =>
  sendFetch(updateProjectNameURL(), body);
export const updateProjectDescription = (body) =>
  sendFetch(updateProjectDescriptionURL(), body);
export const updateProjectAudience = (body) =>
  sendFetch(updateProjectAudienceURL(), body);
export const updateProjectKeywords = (body) =>
  sendFetch(updateProjectKeywordsURL(), body);
