import React from 'react';

import { Link, Redirect } from 'react-router-dom';

import { AlertMarker } from '../../common/Alerts';
import Container from '../../common/Container';
import { getQueryParams } from '../../helpers';
import ResetPasswordForm from '../forms/ResetPasswordForm';

import { resetPassword } from '../actions';

function LoginPage() {
  const [showLoginRedirect, setShow] = React.useState(false);

  const onSubmit = (payload, setSubmitting) => {
    resetPassword(payload, () => setShow(true), setSubmitting);
  };
  if (getQueryParams('token') === '') return <Redirect to="/" />;
  return (
    <div>
      <section>
        <Container>
          <div
            className="mx-auto mt-24 w-full bg-white px-8 pt-8 pb-16"
            style={{ maxWidth: 380 }}
          >
            <h1 className="text-4xl font-bold text-gray-700">
              Change Password
            </h1>
            <AlertMarker name="reset-password" />
            {!showLoginRedirect ? (
              <>
                <h3 className="mt-4 text-base font-semibold mb-4">
                  Enter your new password
                </h3>
                <ResetPasswordForm onSubmit={onSubmit} />
              </>
            ) : (
              <Link
                to="/"
                className="text-center mt-4 w-full block text-blue-400"
              >
                Password reset successfully! Click here to login
              </Link>
            )}
          </div>
        </Container>
      </section>
    </div>
  );
}

export default LoginPage;
