/* eslint-disable default-param-last */
import Axios from 'axios';

import { API, handleErrors, config } from '../../config/API';
import {
  REMOVE_VALUE,
  RESET_STATE,
  SET_LOADING,
  SET_ROOT_VALUE,
  SET_VALUE,
  ADD_MULTIADS_RESULT,
  UPDATE_TEMPLATE_RESULT,
} from '../constants';
import store from '../store';

const getToken = () => localStorage.getItem('tkn') || null;

export const omit = (key = '') => {
  store.dispatch({
    type: REMOVE_VALUE,
    key,
  });
};

export const append = (key = '', data = {}) => {
  const currentState = store.getState();
  const keyState = currentState[key];
  if (!Array.isArray(keyState) || typeof data === 'function') return;
  const payload = [...keyState, data];
  store.dispatch({
    type: SET_VALUE,
    data: {
      key,
      payload,
    },
  });
};

export const unshift = (key = '', data = {}) => {
  const currentState = store.getState();
  const keyState = currentState[key];
  if (!Array.isArray(keyState) || typeof data === 'function') return;
  const payload = [data, ...keyState];
  store.dispatch({
    type: SET_VALUE,
    data: {
      key,
      payload,
    },
  });
};

export const arrayUpdate = (key = '', data = {}, idKey = 'id') => {
  const currentState = store.getState();
  if (!Array.isArray(currentState[key]) || typeof data === 'function') return;
  const payload = [...currentState[key]];
  const index = payload.findIndex((v) => v[idKey] === data[idKey]);
  if (index === -1) return;
  payload[index] = { ...payload[index], ...data };
  store.dispatch({
    type: SET_VALUE,
    data: {
      key,
      payload,
    },
  });
};

export const arrayDelete = (key = '', id, idKey = 'id') => {
  const currentState = store.getState();
  if (!Array.isArray(currentState[key])) return;
  const payload = currentState[key].filter((v) => v[idKey] !== id);
  store.dispatch({
    type: SET_VALUE,
    data: {
      key,
      payload,
    },
  });
};

export const setRootData = (payload = {}) =>
  store.dispatch({
    type: SET_ROOT_VALUE,
    data: {
      payload:
        typeof payload === 'function' ? payload(store.getState()) : payload,
    },
  });

export const set = (key = '', payload = {}) => {
  store.dispatch({
    type: SET_VALUE,
    data: {
      key,
      payload:
        typeof payload === 'function' ? payload(store.getState()) : payload,
    },
  });
};

export const updateObject = (key = '', payload = {}) => {
  const currentState = store.getState();
  const keyState = currentState[key];
  store.dispatch({
    type: SET_VALUE,
    data: {
      key,
      payload: {
        ...keyState,
        ...(typeof payload === 'object' ? payload : {}),
      },
    },
  });
};

export const reset = () =>
  store.dispatch({
    type: RESET_STATE,
  });

export const setLoading = () => {
  store.dispatch({
    type: SET_LOADING,
  });
};

export const unsetLoading = () => {
  store.dispatch({
    type: SET_LOADING,
  });
};

export const setIsLoading = () => {
  return {
    type: SET_LOADING,
  };
};

export const get = async ({ url = '', params = '', useLoading = true }) => {
  const newParams = Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&');
  if (useLoading) setLoading();
  try {
    const res = await Axios.get(
      `${API}${url}?${newParams}`,
      config(getToken()),
    );
    return res;
  } catch (error) {
    handleErrors(error);
    throw error;
  } finally {
    if (useLoading) unsetLoading();
  }
};

export const post = async ({ url = '', payload = {}, useLoading = true }) => {
  if (useLoading) setLoading();
  try {
    const res = await Axios.post(`${API}${url}`, payload, config(getToken()));
    return res;
  } catch (error) {
    handleErrors(error);
    throw error;
  } finally {
    if (useLoading) unsetLoading();
  }
};

export const put = async ({ url = '', payload = {}, useLoading = true }) => {
  if (useLoading) setLoading();
  try {
    const res = await Axios.put(`${API}${url}`, payload, config(getToken()));
    return res;
  } catch (error) {
    handleErrors(error);
    throw error;
  } finally {
    if (useLoading) unsetLoading();
  }
};

export const remove = async ({ url = '', useLoading = true }) => {
  if (useLoading) setLoading();
  try {
    const res = await Axios.delete(`${API}${url}`, config(getToken()));
    return res;
  } catch (error) {
    handleErrors(error);
    throw error;
  } finally {
    if (useLoading) unsetLoading();
  }
};

export const updateTemplateResult = (key = '', payload) => {
  store.dispatch({
    type: UPDATE_TEMPLATE_RESULT,
    payload: {
      key,
      payload,
    },
  });
};

export const addMultiadsResult = (payload) => {
  store.dispatch({
    type: ADD_MULTIADS_RESULT,
    payload,
  })
}
