/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo, useState } from 'react';
import { clsx } from 'clsx';
import { IFacebookAdsProps } from './props';

function FacebookAds({
  content,
  companyName,
  website,
  onCopy,
}: IFacebookAdsProps) {
  const { description, headline, primary_text: primaryText } = content.content;
  const [imgError, setImgError] = useState(false);
  const companyFL = useMemo(
    () =>
      companyName
        .split(' ')
        .map((word) => word[0])
        .join(''),
    [companyName],
  );

  return (
    <div
      className="shadow-xl border border-usilver rounded-lg text-mine-shaft relative xl:w-[600px] mx-auto bg-white"
      onCopy={onCopy}
    >
      <div className="flex items-center px-6 pt-5">
        <div className="flex h-9 w-9 rounded-full overflow-hidden items-center">
          <span className={clsx(imgError ? 'inline-block mx-auto' : 'hidden')}>
            {companyFL}
          </span>
          <img
            className={clsx(imgError ? 'hidden' : 'self-center')}
            src={`https://logo.clearbit.com/${website}`}
            alt={website}
            onError={() => setImgError(true)}
          />
        </div>
        <div className="ml-1 grow">
          <div className="flex items-center">
            <h6 className="font-semibold font-source text-sm">{companyName}</h6>
            <svg
              className="ml-1"
              width="14"
              height="15"
              viewBox="0 0 14 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 7.75C0 3.89453 3.11719 0.75 7 0.75C10.8555 0.75 14 3.89453 14 7.75C14 11.6328 10.8555 14.75 7 14.75C3.11719 14.75 0 11.6328 0 7.75ZM10.1445 6.54688C10.4453 6.24609 10.4453 5.78125 10.1445 5.48047C9.84375 5.17969 9.37891 5.17969 9.07812 5.48047L6.125 8.43359L4.89453 7.23047C4.59375 6.92969 4.12891 6.92969 3.82812 7.23047C3.52734 7.53125 3.52734 7.99609 3.82812 8.29688L5.57812 10.0469C5.87891 10.3477 6.34375 10.3477 6.64453 10.0469L10.1445 6.54688Z"
                fill="#4982F9"
              />
            </svg>
          </div>
          <p className="font-source text-xs">Sponsored</p>
        </div>
        <div>
          <svg
            fill="currentColor"
            viewBox="0 0 20 20"
            width="1em"
            height="1em"
            className="x1lliihq x1k90msu x2h7rmj x1qfuztq xcza8v6 x1qx5ct2 xw4jnvo"
          >
            <g fillRule="evenodd" transform="translate(-446 -350)">
              <path d="M458 360a2 2 0 1 1-4 0 2 2 0 0 1 4 0m6 0a2 2 0 1 1-4 0 2 2 0 0 1 4 0m-12 0a2 2 0 1 1-4 0 2 2 0 0 1 4 0" />
            </g>
          </svg>
        </div>
      </div>
      <p className="font-source font-sm px-8 py-4">{primaryText}</p>
      <div className="bg-gray-200 opacity-80 flex justify-center items-center py-6 w-full aspect-[1.91/1]">
        <span
          className={clsx(
            imgError ? 'inline-block mx-auto text-8xl font-barlow' : 'hidden',
          )}
        >
          {companyFL}
        </span>
        <div className={clsx(imgError ? 'hidden' : 'inline-block')}>
          <img
            className="grayscale mix-blend-multiply brightness-110 contrast-125"
            src={`https://logo.clearbit.com/${website}`}
            alt={website}
          />
        </div>
      </div>
      <div className="bg-[#F0F2F5] text-xs py-2 px-7 flex justify-between items-center border-b border-silver-alto">
        <div className="font-source">
          <span className="text-manatee uppercase">{website}</span>
          <p className="font-semibold text-sm">{headline}</p>
          <p className="text-manatee">{description}</p>
        </div>
        <div>
          <div className="bg-[#E4E6EB] text-ubblack rounded-sm font-source font-semibold text-sm p-2 px-4">
            Learn More
          </div>
        </div>
      </div>
      <div className="flex pl-4 py-3  ">
        <svg
          width="20"
          height="20"
          viewBox="0 0 16 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.85358 15C12.191 15 15.7072 11.6421 15.7072 7.49999C15.7072 3.35785 12.191 0 7.85358 0C3.51617 0 0 3.35785 0 7.49999C0 11.6421 3.51617 15 7.85358 15Z"
            fill="#F4415F"
          />
          <path
            d="M13.4825 6.5603C13.4825 5.00554 12.1617 3.75391 10.5439 3.75391C9.34585 3.75391 8.31168 4.43839 7.85091 5.426C7.39014 4.43839 6.3662 3.75391 5.15796 3.75391C3.52991 3.75391 2.21927 5.01531 2.21927 6.5603C2.21927 6.5603 1.85065 9.47427 7.84067 12.8283C12.049 10.4814 13.1242 8.33998 13.3904 7.27414C13.4518 7.04923 13.4825 6.80476 13.4825 6.5603Z"
            fill="white"
          />
        </svg>
        <svg
          width="20"
          height="20"
          viewBox="0 0 16 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.2406 0.35801C6.11196 -0.904067 1.68622 1.27766 0.374888 5.22043C-0.946688 9.1632 1.3379 13.3897 5.46654 14.642C9.59518 15.9041 14.0209 13.7223 15.3323 9.77957C16.6538 5.8368 14.3692 1.6103 10.2406 0.35801Z"
            fill="#4982F9"
          />
          <path
            d="M5.17853 6.63867H3.58034C3.43889 6.63867 3.32422 6.74818 3.32422 6.88326V11.2565C3.32422 11.3916 3.43889 11.5011 3.58034 11.5011H5.17853C5.31998 11.5011 5.43465 11.3916 5.43465 11.2565V6.88326C5.43465 6.74818 5.31998 6.63867 5.17853 6.63867Z"
            fill="white"
          />
          <path
            d="M12.1055 8.18436C12.1055 7.91042 11.9314 7.67561 11.6855 7.56799C12.0338 7.52886 12.3002 7.24514 12.3002 6.90272C12.3002 6.53095 11.9826 6.22765 11.5933 6.22765H9.04236C9.04236 6.22765 9.48288 5.50368 9.6468 4.49598C9.81071 3.48827 9.5341 2.68602 8.86819 2.59797C8.86819 2.59797 7.92568 2.53927 8.12033 4.03615C8.12033 4.03615 6.98316 5.56238 5.96893 6.81467V10.3074C5.96893 10.3074 5.93819 11.002 6.68606 11.0412C6.70655 11.0412 6.72704 11.0412 6.74753 11.0412H11.3064C11.624 11.0412 11.8802 10.7966 11.8802 10.4933C11.8802 10.19 11.624 9.94542 11.3064 9.94542H11.4089C11.7265 9.94542 11.9826 9.70083 11.9826 9.39754C11.9826 9.09425 11.7265 8.84967 11.4089 8.84967H11.3884C11.7777 8.84967 12.0953 8.54637 12.0953 8.1746L12.1055 8.18436Z"
            fill="white"
          />
        </svg>

        <svg
          width="20"
          height="20"
          viewBox="0 0 16 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.6104 8.54651C15.2161 4.98284 12.682 1.62497 8.95034 1.04651C5.21867 0.468042 1.70252 2.88803 1.09678 6.4517C0.491046 10.0154 3.0251 13.3732 6.75678 13.9517C10.4885 14.5302 14.0046 12.1102 14.6104 8.54651Z"
            fill="#FAE361"
          />
          <path
            d="M7.84964 12.8682C8.87979 12.8682 9.7149 11.7272 9.7149 10.3198C9.7149 8.91241 8.87979 7.77148 7.84964 7.77148C6.81948 7.77148 5.98438 8.91241 5.98438 10.3198C5.98438 11.7272 6.81948 12.8682 7.84964 12.8682Z"
            fill="#1E1E1E"
          />
          <path
            d="M3.62723 5.60789C3.71648 4.89196 4.21626 4.35502 4.74282 4.41468C5.26937 4.47434 5.61743 5.10503 5.52819 5.82096C5.43894 6.53688 4.93916 7.07382 4.4126 7.01416C3.88604 6.9545 3.53798 6.32381 3.62723 5.60789Z"
            fill="#1E1E1E"
          />
          <path
            d="M5.71237 2.82068C5.64097 2.82068 5.56957 2.78658 5.52494 2.72692C5.52494 2.71839 5.31968 2.43714 4.92699 2.43714C4.50753 2.43714 4.15055 2.76102 4.15055 2.76102C4.0613 2.84624 3.9185 2.83772 3.82926 2.76102C3.74001 2.67579 3.74894 2.53942 3.82926 2.46271C3.84711 2.44566 4.31119 2.01953 4.91807 2.01953C5.55172 2.01953 5.88193 2.47976 5.89086 2.49681C5.96225 2.59908 5.92656 2.72693 5.82838 2.79511C5.79269 2.82068 5.74806 2.82919 5.70344 2.82919L5.71237 2.82068Z"
            fill="#1E1E1E"
          />
          <path
            d="M11.9266 5.60789C11.8374 4.89196 11.3376 4.35502 10.811 4.41468C10.2845 4.47434 9.93642 5.10503 10.0257 5.82096C10.1149 6.53688 10.6147 7.07382 11.1413 7.01416C11.6678 6.9545 12.0159 6.32381 11.9266 5.60789Z"
            fill="#1E1E1E"
          />
          <path
            d="M9.83714 2.82068C9.90854 2.82068 9.97993 2.78658 10.0246 2.72692C10.0246 2.71839 10.2298 2.43714 10.6225 2.43714C11.042 2.43714 11.399 2.76102 11.399 2.76102C11.4882 2.84624 11.631 2.83772 11.7203 2.76102C11.8095 2.67579 11.8006 2.53942 11.7203 2.46271C11.7024 2.44566 11.2383 2.01953 10.6314 2.01953C9.99778 2.01953 9.66757 2.47976 9.65865 2.49681C9.58725 2.59908 9.62295 2.72693 9.72112 2.79511C9.75682 2.82068 9.80144 2.82919 9.84606 2.82919L9.83714 2.82068Z"
            fill="#1E1E1E"
          />
        </svg>
      </div>
      <div className=" border-t border-silver-alto flex justify-around py-3">
        <svg
          width="16"
          height="16"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.60574 6.64844H1.3582C1.16154 6.64844 1 6.78929 1 6.96369V12.6851C1 12.8595 1.16154 13.0003 1.3582 13.0003H3.60574C3.8024 13.0003 3.96394 12.8595 3.96394 12.6851V6.96369C3.96394 6.78929 3.8024 6.64844 3.60574 6.64844Z"
            stroke="#606060"
            strokeWidth="0.808481"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.7292 8.93445C12.7292 8.54693 12.4798 8.207 12.1235 8.05743C12.6152 7.99624 13 7.59514 13 7.11244C13 6.58895 12.5511 6.16065 12.0024 6.16065H8.36829C8.36829 6.16065 9.00248 5.12725 9.2305 3.69955C9.45853 2.27185 9.06661 1.12969 8.12602 1.00052C8.12602 1.00052 6.79351 0.912138 7.06429 3.04009C7.06429 3.04009 5.43963 5.20885 4.00023 6.99007V11.953C4.00023 11.953 3.95748 12.9456 5.02634 13C5.05484 13 5.09047 13 5.11897 13H11.6105C12.0594 13 12.4299 12.6465 12.4299 12.2181C12.4299 11.7898 12.0594 11.4363 11.6105 11.4363H11.7601C12.209 11.4363 12.5796 11.0828 12.5796 10.6545C12.5796 10.2262 12.209 9.87266 11.7601 9.87266H11.7245C12.2732 9.87266 12.7221 9.44435 12.7221 8.92086L12.7292 8.93445Z"
            stroke="#606060"
            strokeWidth="0.808481"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <svg
          width="16"
          height="16"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.2832 1H2.87206C1.76372 1 0.859375 1.86365 0.859375 2.92209V8.83118C0.859375 9.88962 1.76372 10.7533 2.87206 10.7533H4.80316V13L7.31221 10.7533H11.29C12.3983 10.7533 13.3027 9.88962 13.3027 8.83118V2.92209C13.3027 1.86365 12.3983 1 11.29 1H11.2832Z"
            stroke="#606060"
            strokeWidth="0.808481"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <svg
          width="18"
          height="16"
          viewBox="0 0 17 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.7321 6.74902L9.71201 1V4.5739H9.45972C4.58901 4.5739 1.24609 8.34858 1.24609 13C1.88384 12.2236 3.25044 10.4902 5.50709 9.51311C7.37127 8.70999 8.48557 8.83714 9.71201 8.85722V12.5114L15.7321 6.7624V6.74902Z"
            stroke="#606060"
            strokeWidth="0.808481"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    </div>
  );
}

export default FacebookAds;
