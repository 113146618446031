export const SET_VALUE = 'SET_VALUE';
export const SET_LOADING = 'SET_LOADING';
export const RESET_STATE = 'RESET_STATE';
export const REMOVE_VALUE = 'REMOVE_VALUE';
export const SET_ROOT_VALUE = 'SET_ROOT_VALUE';
export const UPDATE_TEMPLATE_RESULT = 'UPDATE_TEMPLATE_RESULT';
export const ADD_MULTIADS_RESULT = 'ADD_MULTIADS_RESULT';

export const STORE_DEFAULT = {
  'PERSONALIZE/DATA': {
    user_id: '',
    name: '',
    company: '',
    brand_keywords: [],
    audience: [],
    payment_details: {},
    description: '',
    generations_used: '',
    last_generation: '',
    last_credited: '',
    isDone: true,
    isEmailValidated: false,
    isPaid: false,
    isLoaded: false,
    isTrial: false,
    first_plan: '',
    first_tenure: '',
  },
  loading: false,
  isLoading: false,
  upgradeModal: {
    open: false,
  },
  COLLECTIONS: [],
  PROJECT: {
    id: 0,
    audience: [],
    brand_keywords: [],
    company_name: '',
    description: '',
    name: '',
    created_at: '',
    updated_at: '',
    collection_id: '',
  },
  TEMPLATE_VALUES: {},
  multiAds: { contents: [] },
};
