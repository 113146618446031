/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Button } from '@unbounce/ui-components';
import { VscPass } from 'react-icons/vsc';
import { AiOutlineWarning } from 'react-icons/ai';
import Axios from 'axios';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import {
  API, config, displayError, handleErrors,
} from '../../../config/API';
import { getToken } from '../../../config/token';
import { createAlert } from '../../common/Alerts';
import { setLoading } from '../../../redux-setup/actions/loading';
import { postValidateEmail } from '../../../common/sendInfo';
import { getProfile } from '../../personalize/actions';

const borderInputs = (error, verification) => {
  if (verification) {
    return 'border border-shamrock text-shamrock w-10 h-10 text-center rounded';
  } if (error) {
    return 'border border-radical-red text-radical-red w-10 h-10 text-center rounded';
  }
  return 'border border-gray-500 w-10 h-10 text-center rounded';
};

function CodeVerification({ userEmail }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [number, setNumber] = useState({
    num1: '',
    num2: '',
    num3: '',
    num4: '',
    num5: '',
    num6: '',
  });
  const [verified, setVerified] = useState(false);
  const [errorVerified, setErrorVerified] = useState(false);

  const handleChange = (option, event) => {
    const val = event.target.value;
    if (/^$|^[0-9]$/.test(val)) {
      setNumber((preS) => ({ ...preS, [option]: val }));
      setErrorVerified(false);
    }
  };

  const handleKeyUp = (event) => {
    const keyP = event.key;
    if (keyP === 'ArrowRight') {
      const next = event.target.tabIndex;
      if (next < 6) {
        event.target.form.elements[next].focus();
      }
    } else if (keyP === 'ArrowLeft') {
      const next = event.target.tabIndex - 2;
      if (next > -1) {
        event.target.form.elements[next].focus();
      }
    } else if (keyP === 'Delete' || keyP === 'Backspace') {
      if (number[event.target.name] === '') {
        const next = event.target.tabIndex - 2;
        if (next > -1) {
          event.target.form.elements[next].focus();
        }
      }
    } else if (
      keyP === '1'
      || keyP === '2'
      || keyP === '3'
      || keyP === '4'
      || keyP === '5'
      || keyP === '6'
      || keyP === '7'
      || keyP === '8'
      || keyP === '9'
      || keyP === '0'
    ) {
      const next = event.target.tabIndex;
      if (next < 6) {
        event.target.form.elements[next].focus();
      }
    }
  };

  const handleClickResendCode = async () => {
    // WE NEED CHANGE LOGIC AND REFACTOR CODE HERE
    dispatch(setLoading(true));
    try {
      const res = await Axios.post(
        `${API}/v2/resend_validate_email`,
        {},
        config(getToken()),
      );
      createAlert.success('verify-email', res.data.message);
    } catch (error) {
      handleErrors(error);
      displayError('register', error);
      window.scrollTo(0, 0);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleClickVerifyCode = () => {
    dispatch(setLoading(true));
    postValidateEmail({
      code: Object.values(number).join(''),
      email: userEmail,
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.errors) {
          setErrorVerified(true);
          setNumber({
            num1: '',
            num2: '',
            num3: '',
            num4: '',
            num5: '',
            num6: '',
          });
          createAlert.error('verify-email', result.message);
        } else {
          setVerified(true);
          getProfile();
          setTimeout(() => {
            history.push('/welcome');
          }, 1500);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => dispatch(setLoading(false)));
  };

  const handlePaste = (event) => {
    event.preventDefault();
    const data = event.clipboardData.getData('text').replaceAll(/\s|\t|\n|\r/g, '');
    if (/\d+/.test(data)) {
      if (data.length === 6) {
        setNumber({
          num1: data[0],
          num2: data[1],
          num3: data[2],
          num4: data[3],
          num5: data[4],
          num6: data[5],
        });
        setErrorVerified(false);
      } else {
        NotificationManager.warning('The verification code must be 6 digits length.');
      }
    } else {
      NotificationManager.warning('There is no info to paste.');
    }
  };

  return (
    <div className="mt-16">
      <div className="md:px-4 text-center">
        {errorVerified && (
          <div className="text-center">
            <AiOutlineWarning className="text-radical-red text-8xl inline-block mb-4" />
            <h3 className="font-bold mb-6">
              Oops! Looks like the verification code is invalid or expired.
              <br />
              Please try again.
            </h3>
          </div>
        )}
        {verified && (
          <div className="text-center">
            <VscPass className="text-shamrock text-8xl inline-block mb-4" />
            <h3 className="font-bold mb-6">Successfully Verified!</h3>
          </div>
        )}
        {!errorVerified && !verified && (
          <>
            <h2 className="font-bold text-lg mb-4">
              Please verify your email.
            </h2>
            <p>We have sent a verification code to the email below</p>
            <h3 className="font-bold mt-4 mb-6">{userEmail}</h3>
          </>
        )}
        <form className="flex justify-between md:px-32">
          <input
            name="num1"
            type="text"
            autoComplete="off"
            className={borderInputs(errorVerified, verified)}
            value={number.num1}
            onChange={(e) => handleChange('num1', e)}
            onPaste={handlePaste}
            onKeyUp={handleKeyUp}
            tabIndex="1"
            maxLength="1"
            disabled={verified}
          />
          <input
            name="num2"
            type="text"
            autoComplete="off"
            className={borderInputs(errorVerified, verified)}
            value={number.num2}
            onChange={(e) => handleChange('num2', e)}
            onPaste={handlePaste}
            onKeyUp={handleKeyUp}
            tabIndex="2"
            maxLength="1"
            disabled={verified}
          />
          <input
            name="num3"
            type="text"
            autoComplete="off"
            className={borderInputs(errorVerified, verified)}
            value={number.num3}
            onChange={(e) => handleChange('num3', e)}
            onPaste={handlePaste}
            onKeyUp={handleKeyUp}
            tabIndex="3"
            maxLength="1"
            disabled={verified}
          />
          <input
            name="num4"
            type="text"
            autoComplete="off"
            className={borderInputs(errorVerified, verified)}
            value={number.num4}
            onChange={(e) => handleChange('num4', e)}
            onPaste={handlePaste}
            onKeyUp={handleKeyUp}
            tabIndex="4"
            maxLength="1"
            disabled={verified}
          />
          <input
            name="num5"
            type="text"
            autoComplete="off"
            className={borderInputs(errorVerified, verified)}
            value={number.num5}
            onChange={(e) => handleChange('num5', e)}
            onPaste={handlePaste}
            onKeyUp={handleKeyUp}
            tabIndex="5"
            maxLength="1"
            disabled={verified}
          />
          <input
            name="num6"
            type="text"
            autoComplete="off"
            className={borderInputs(errorVerified, verified)}
            value={number.num6}
            onChange={(e) => handleChange('num6', e)}
            onPaste={handlePaste}
            onKeyUp={handleKeyUp}
            tabIndex="6"
            maxLength="1"
            disabled={verified}
          />
        </form>
      </div>
      {!verified && (
        <>
          <button
            type="button"
            className="w-full text-unbounce-blue mt-6 mb-6"
            onClick={handleClickResendCode}
          >
            Resend Code
          </button>
          <div className="px-0 md:px-20">
            <Button
              fullWidth
              primary
              large
              onClick={handleClickVerifyCode}
              disabled={
                number.num1 === ''
                || number.num2 === ''
                || number.num3 === ''
                || number.num4 === ''
                || number.num5 === ''
                || number.num6 === ''
              }
            >
              Verify Code
            </Button>
          </div>
          <h3 className="mt-6 text-center">
            Please also check your spam folder
          </h3>
        </>
      )}
    </div>
  );
}

export default CodeVerification;
