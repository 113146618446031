/* eslint-disable react/no-array-index-key */
import React from 'react';

import Skeleton from 'react-loading-skeleton';

const SkeletonTaglines = () => React.Children.toArray(
  Array.from(Array(5)).map((a, key) => (
    <div
      style={{
        position: 'relative',
        marginBottom: 25,
      }}
      key={key}
    >
      <div
        className="bg-white py-8 px-4 my-2 shadow-md"
        style={{
          borderRadius: '20px',
        }}
      >
        <div className="font-semibold text-xl">
          <Skeleton />
        </div>
      </div>
    </div>
  )),
);

export default SkeletonTaglines;
