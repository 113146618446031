/* eslint-disable react/no-array-index-key */
import React from 'react';

// eslint-disable-next-line import/no-cycle
import PageLayout from '../../../partials/pagelayout/containers/PageLayout';
import BenefitsTemplate from '../../components/templates/BenefitsTemplate';

function Benefits() {
  return (
    <PageLayout template="benefits" title="Benefits">
      {({ contents }) => React.Children.toArray(
        contents.map((content, index) => (
          <BenefitsTemplate key={index} data={content} />
        )),
      )}
    </PageLayout>
  );
}
export default Benefits;
