/* eslint-disable camelcase */
import { useEffect, useState } from 'react';
import { FaAngleLeft } from 'react-icons/fa';
import { Redirect, useHistory } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import { loadStripe } from '@stripe/stripe-js';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import planImage from '../../assets/img/banners/plan_image.png';
import bgCircle from '../../assets/img/bg_circle.svg';
import SCLogoShort from '../../assets/img/UBSmartCopy-short.svg';
import SCLogoShortWhite from '../../assets/img/UBSmartCopy-short-white.svg';
import TwoOptionSwitch from '../../common/TwoOptionSwitch';
import { setLoading } from '../../redux-setup/actions/loading';
import { getRewardfulCode } from '../helpers';
import {
  cancelCancelation,
  requestCheckoutSesion,
  updateSubscription,
} from '../../common/sendInfo';
import Loader from '../common/Loader/Loader';
import FullCardNew from './FullCardNew';
import HalfCardNew from './HalfCardNew';
import UpdateSubscriptionModal from '../Settings/BillingTab/BillingPlan/UpdateSubscriptionModal';
import dayjs from 'dayjs';
import { getStripeSubscriptionID } from '../../common/getInfo';
import CancelModal from '../Settings/BillingTab/BillingPlan/CancelModal';

function Plan() {
  const isLoading = useSelector((state: RootStateOrAny) => state.isLoading);
  const { is_unlimited, current_tenure, canceled, first_plan, isPaid } =
    useSelector((state: RootStateOrAny) => state['PERSONALIZE/DATA']);
  const history = useHistory();
  const dispatch = useDispatch();
  const [annualSelected, setAnnualSelected] = useState(true);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [updateDone, setUpdateDone] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [plan, setPlan] = useState<'essential' | 'unlimited'>('unlimited');
  const [tenure, setTenure] = useState('');

  const handleCheckout = (plan: string, tenure: string) => {
    const success_url = `${window.location.origin}/subscriptions/success?success=true&plan=${plan}`;
    const referalCode = getRewardfulCode();
    dispatch(setLoading(true));
    const payload = {
      success_url,
      cancel_url: `${window.location.href}`,
      rewardful: '',
      plan: plan,
      tenure: tenure,
    };
    const rewardful = referalCode;
    if (referalCode) {
      if (rewardful) payload.rewardful = rewardful;
    }
    requestCheckoutSesion(payload)
      .then((response) => response.json())
      .then(async (result) => {
        const stripe = await loadStripe(result.stripe_token);
        if (stripe) {
          const stripeRedirect = await stripe.redirectToCheckout({
            sessionId: result.stripe_session_id,
          });
          if (stripeRedirect.error) {
            NotificationManager.error('Something went wrong');
          }
        }
      })
      .catch(() => dispatch(setLoading(false)));
  };

  const onUpdateSubscription = (cancelAtEnd: boolean) => {
    setUpdateLoading(true);
    getStripeSubscriptionID()
      .then((response) => response.json())
      .then((result) => {
        const subscriptionID = result.data.subscription;
        updateSubscription({
          subscription_id: subscriptionID,
          cancelAtEnd: cancelAtEnd,
          plan: plan,
          tenure: tenure,
          first_plan,
        })
          .then((resp) => resp.json())
          .then(() => {
            setUpdateDone(true);
          })
          .catch();
      })
      .catch(() => dispatch(setLoading(false)));
  };

  const cancelCancel = () => {
    dispatch(setLoading(true));
    getStripeSubscriptionID()
      .then((response) => response.json())
      .then((result) => {
        const subscriptionID = result.data.subscription;
        cancelCancelation({ subscription_id: subscriptionID })
          .then((resp) => resp.json())
          .then(() => {
            setOpenCancelModal(true);
            dispatch(setLoading(false));
          })
          .catch();
      })
      .catch(() => dispatch(setLoading(false)));
  };

  useEffect(() => {
    if (current_tenure === 'monthly') {
      setAnnualSelected(false);
    }
  }, [current_tenure]);

  return is_unlimited ? (
    <Redirect to="/settings" />
  ) : (
    <div
      className="min-h-screen h-fit bg-no-repeat bg-bottom  bg-[length:2000px_2000px] md:bg-[center_left_-80rem] lg:bg-[center_left_-70rem] slg:bg-[center_left_-88rem] xl:bg-[center_left_-85rem] xlg:bg-[center_left_-80rem] 2xl:bg-[center_left_-70rem] mxl:bg-[center_left_-60rem] hres:bg-[center_left_-50rem] threek:bg-[center_left_-40rem]"
      style={{
        backgroundImage: `url(${bgCircle})`,
      }}
    >
      <div className="absolute w-full xxl:mt-10 px-4">
        <div className="max-w-7xl mx-auto flex justify-between ">
          <button
            type="button"
            className="flex items-center cursor-pointer m-4 z-10"
            onClick={() => {
              history.push('/browse');
            }}
          >
            <FaAngleLeft className="mr-1" />
            Back to Smart Copy
          </button>
          <img
            src={SCLogoShort}
            alt="SC Logo Short"
            className="h-16 mr-10 mt-4 hidden slg:inline-block"
          />
        </div>
      </div>
      <CancelModal
        open={openCancelModal}
        setOpen={setOpenCancelModal}
        message="reactivated"
        endDate={dayjs.unix(canceled?.billingCycle).format('MMMM D, YYYY')}
      />
      <UpdateSubscriptionModal
        open={openUpdateModal}
        setOpen={setOpenUpdateModal}
        endPeriodDate={dayjs
          .unix(canceled?.billingCycle)
          .format('MMMM D, YYYY')}
        onUpdate={onUpdateSubscription}
        updateDone={updateDone}
        updateLoading={updateLoading}
        selectedPlan={plan}
      />
      <div className="flex flex-wrap sm:flex-no-wrap items-center slg:h-full max-w-7xl mx-auto slg:pt-20 min-h-screen">
        <div className="w-full slg:w-1/3 text-center md:text-left md:grid md:grid-cols-3 lg:grid-cols-4 slg:grid-cols-1 h-full">
          <div className="md:col-span-2 lg:col-span-3 pt-12 px-12 threek:bg-shamrock threek:rounded-xl">
            <img
              alt="SC LOGO White"
              src={SCLogoShortWhite}
              className="inline-block mx-auto h-16 mr-10 my-4 slg:hidden"
            />
            <h1 className="font-barlow text-2xl md:text-4xl text-white font-medium sm:font-semibold">
              Upgrade to unlock unlimited copy
            </h1>
            <p className="hidden text-xl text-white font-base md:font-semibold my-4 md:my-10 sm:inline-block">
              All the content you will ever need for an unbeatable price.
            </p>
            <TwoOptionSwitch
              rightText="Annual"
              leftText="Monthly"
              rightValue={annualSelected}
              textColor="text-white"
              textSelectedColor="text-white"
              className="mt-10 mb-10 sm:mt-4 md:hidden"
              handleChangeSwitch={() => setAnnualSelected((prev) => !prev)}
            />
          </div>
          <div className="hidden md:flex items-end">
            <img
              alt="sc lady"
              src={planImage}
              className="hidden md:inline-block mt-auto"
            />
          </div>
        </div>
        <div className="mx-auto w-120 md:w-full md:px-10 lg:px-12 slg:px-4 slg:w-2/3">
          <FullCardNew
            className="hidden md:grid"
            handleCheckout={handleCheckout}
            annual={annualSelected}
            handleChangeSwitch={() => setAnnualSelected((prev) => !prev)}
            onCanceling={isPaid && canceled?.canceled}
            onReactivateClick={cancelCancel}
            onUpgradeDowngrade={(
              plan: 'essential' | 'unlimited',
              tenure: 'monthly' | 'annual',
            ) => {
              setPlan(plan);
              setTenure(tenure);
              setOpenUpdateModal(true);
            }}
          />
          <HalfCardNew
            className="md:hidden"
            annual={annualSelected}
            handleCheckout={handleCheckout}
            onCanceling={isPaid && canceled?.canceled}
            onReactivateClick={cancelCancel}
            onUpgradeDowngrade={(
              plan: 'essential' | 'unlimited',
              tenure: 'monthly' | 'annual',
            ) => {
              setPlan(plan);
              setTenure(tenure);
              setOpenUpdateModal(true);
            }}
          />
        </div>
      </div>
      {isLoading && <Loader />}
    </div>
  );
}

export default Plan;
