import React from 'react';
import { clsx } from 'clsx';
import PropTypes from 'prop-types';
import DynamicIcon from '../../common/DynamicIcon';
import { useTemplateSidebar } from '../context';

function WriterTemplateDetails({ data }) {
  const { setViewProfile } = useTemplateSidebar();
  return (
    <div
      role="button"
      tabIndex={0}
      onKeyDown={() => {
        setViewProfile(data);
      }}
      className="flex items-center pl-2 py-2 hover:bg-gray-50"
      onClick={() => {
        setViewProfile(data);
      }}
    >
      <div className={clsx('p-2 rounded mr-3', `bg-${data?.color}-100`)}>
        <DynamicIcon
          name={data?.card_icon}
          size={13}
          className={`p-0 m-0 text-${data?.color}-700`}
        />
      </div>
      <p className="text-base text-black mr-2 font-barlow leading-tight">
        {data?.name}
      </p>
    </div>
  );
}

WriterTemplateDetails.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default WriterTemplateDetails;
