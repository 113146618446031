import React, { ChangeEvent, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleDollarToSlot,
  faArrowUpFromArc,
} from '@fortawesome/pro-light-svg-icons';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-responsive-modal';
import { useHistory, useLocation } from 'react-router-dom';
import { setOpenUpgradeModal } from '../../redux-setup/actions/upgradeModal';
import Button from '../Button';
import Features from './Features';
import { setLoading } from '../../redux-setup/actions/loading';
import { requestCreditsCheckout } from '../sendInfo';
import { loadStripe } from '@stripe/stripe-js';
import { NotificationManager } from 'react-notifications';
import { ICreditsObj } from '../../modules/Settings/BillingTab/CreditsPackSection/types';
import CreditsOption from '../../modules/Settings/BillingTab/CreditsPackSection/CreditsOption';

const feautesObj = [
  {
    title: 'Access to Writer',
    subtitle: 'Write any kind of long-form content',
  },
  { title: 'More Generations', subtitle: 'Never stop creating' },
  { title: 'More Projects', subtitle: 'Create and manage projects' },
];

const closeIcon = (
  <svg
    width="28"
    height="28"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.15234 6.15234C6.46875 5.83594 6.99609 5.83594 7.3125 6.15234L8.96484 7.83984L10.6523 6.15234C10.9688 5.83594 11.4961 5.83594 11.8125 6.15234C12.1641 6.50391 12.1641 7.03125 11.8125 7.34766L10.1602 9L11.8125 10.6523C12.1641 11.0039 12.1641 11.5312 11.8125 11.8477C11.4961 12.1992 10.9688 12.1992 10.6523 11.8477L8.96484 10.1953L7.3125 11.8477C6.99609 12.1992 6.46875 12.1992 6.15234 11.8477C5.80078 11.5312 5.80078 11.0039 6.15234 10.6523L7.80469 9L6.15234 7.34766C5.80078 7.03125 5.80078 6.50391 6.15234 6.15234ZM18 9C18 13.9922 13.957 18 9 18C4.00781 18 0 13.9922 0 9C0 4.04297 4.00781 0 9 0C13.957 0 18 4.04297 18 9ZM9 1.6875C4.95703 1.6875 1.6875 4.99219 1.6875 9C1.6875 13.043 4.95703 16.3125 9 16.3125C13.0078 16.3125 16.3125 13.043 16.3125 9C16.3125 4.99219 13.0078 1.6875 9 1.6875Z"
      fill="black"
    />
  </svg>
);

function UpgradeModal() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { open } = useSelector((state: RootStateOrAny) => state.upgradeModal);
  const { remaining_credits, pack_credits } = useSelector(
    (state: RootStateOrAny) => state['PERSONALIZE/DATA'],
  );
  const [creditsObject, setCreditsObject] = useState<ICreditsObj[]>([
    {
      numberCredits: 50,
      costCredits: 10,
      selected: true,
    },
    {
      numberCredits: 100,
      costCredits: 15,
      selected: false,
    },
  ]);

  const onChangeCredits = (event: ChangeEvent<HTMLInputElement>) => {
    const val = parseInt(event.target.value, 10) || 0;
    setCreditsObject((prev) =>
      prev.map((crObj) => {
        if (crObj.numberCredits === val) {
          return { ...crObj, selected: true };
        }
        return { ...crObj, selected: false };
      }),
    );
  };
  const onPurchaseCredits = (credits?: number) => {
    dispatch(setLoading(true));
    requestCreditsCheckout({
      success_url: `${
        window.location.origin
      }/credits/success?success=true&credits=${
        creditsObject.filter((obj) => obj.selected)[0].numberCredits || 100
      }`,
      cancel_url: `${window.location.origin}${pathname}`,
      option: credits
        ? credits
        : creditsObject.filter((obj) => obj.selected)[0].numberCredits || 100,
    })
      .then((response) => response.json())
      .then(async (result) => {
        const stripe = await loadStripe(result.stripe_token);
        if (stripe) {
          const stripeRedirect = await stripe.redirectToCheckout({
            sessionId: result.stripe_session_id,
          });
          if (stripeRedirect.error) {
            NotificationManager.error('Something went wrong');
          }
        }
      })
      .catch(() => dispatch(setLoading(false)));
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        dispatch(setOpenUpgradeModal(false));
      }}
      center
      styles={{ modal: { maxWidth: '680px', borderRadius: '10px' } }}
      closeIcon={closeIcon}
    >
      {(remaining_credits + pack_credits) <= 0 && (
        <h3 className="text-radical-red text-center font-semibold">
          You&apos;re all out of free credits!
        </h3>
      )}
      <h1 className="text-mine-shaft text-2xl font-medium font-barlow text-center my-4 sm:px-30">
        Keep up the good work!
        <br />
        Continue writing with one of the options below
      </h1>
      <div className="flex flex-wrap md:flex-nowrap gap-6 justify-center">
        <div className="text-center p-6 border border-usilver rounded-lg">
          <FontAwesomeIcon
            className="rounded-lg h-20 w-20 my-6 text-shamrock mx-auto block"
            icon={faArrowUpFromArc}
          />
          <div className="my-6">
            <h1 className="font-medium font-barlow text-2xl">
              Do you write more frequently?
            </h1>
            <h3 className="font-barlow text-lg ">
              {`Subscribe to a plan starting starting at $8 per month`}
            </h3>
          </div>
          <div className="my-6">
            {feautesObj.map((feature) => (
              <Features
                key={feature.title}
                title={feature.title}
                subtitle={feature.subtitle}
              />
            ))}
          </div>
          <Button
            type="secondary"
            className="w-full"
            onClick={() => {
              history.push('/plan');
              dispatch(setOpenUpgradeModal(false));
            }}
          >
            Subscribe Today
          </Button>
        </div>
        <div className="flex flex-wrap p-6 border border-usilver rounded-lg max-w-[287px] justify-center">
          <FontAwesomeIcon
            className="rounded-lg h-20 w-20 my-6 text-shamrock mx-auto block"
            icon={faCircleDollarToSlot}
          />
          <h2 className="my-auto">
            Keep up the good work writing, top up your credits!
          </h2>
          <CreditsOption
            onChangeCredits={onChangeCredits}
            creditsObj={creditsObject}
            onPurchaseCredits={onPurchaseCredits}
            className="w-full self-end"
            wrapperButtonClass="sm:mt-10"
          />
        </div>
      </div>
    </Modal>
  );
}

export default UpgradeModal;
