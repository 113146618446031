import React from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { changePassword } from '../../../admin/actions';
import { AlertMarker } from '../../../common/Alerts';
import { Input } from '../../../common/Formik/FormInputs';
import Button from '../../../../common/Button';

const validateForm = Yup.object().shape({
  old_password: Yup.string()
    .required('This field is required.')
    .min(5)
    .max(50)
    .label('Old Password'),
  new_password: Yup.string()
    .required('This field is required.')
    .min(5)
    .max(50)
    .label('New Password'),
});

function ChangePasswordSection() {
  return (
    <Formik
      initialValues={{
        old_password: '',
        new_password: '',
      }}
      validationSchema={validateForm}
      onSubmit={(values, { setSubmitting, resetForm }) =>
        changePassword(
          { ...values, confirm_password: values.new_password },
          () => {
            setSubmitting(false);
            resetForm();
          },
        )
      }
      enableReinitialize
    >
      {({ values, handleSubmit, isSubmitting }) => (
        <Form className="my-8">
          <h3 className="text-ugray font-semibold pb-2">Password</h3>
          <AlertMarker name="change-password" />
          <div className="grid grid-cols-1 gap-x-4 gap-y-6 md:grid-cols-3 items-center">
            <Field
              component={Input}
              name="old_password"
              value={values.old_password}
              placeholder="Old Password"
              type="password"
              wrapperClass="w-full text-silver-chalice"
            />
            <Field
              component={Input}
              name="new_password"
              value={values.new_password}
              placeholder="New Password"
              type="password"
              wrapperClass="w-full text-silver-chalice"
            />
            <div className="text-right">
              <Button
                className="w-full md:w-44"
                type="grayOutline"
                disabled={isSubmitting}
                onClick={handleSubmit}
              >
                Update password
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default ChangePasswordSection;
