/* eslint-disable react/no-array-index-key */
import React from 'react';

import Skeleton from 'react-loading-skeleton';

const SkeletonGoogleAds = () => React.Children.toArray(
  Array.from(Array(4)).map((a, key) => (
    <div
      style={{
        position: 'relative',
        marginBottom: 25,
      }}
      key={key}
    >
      <div className="bg-white py-8 p-8 my-2 shadow-md rounded-lg">
        <h3
          className="text-xl font-semibold"
          style={{
            color: '#1D39C4',
          }}
        >
          <Skeleton />
        </h3>
        <div className="mt-2 text-gray-700 text-md">
          <Skeleton count={3} />
        </div>
      </div>
    </div>
  )),
);

export default SkeletonGoogleAds;
