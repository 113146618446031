import React from 'react';

// eslint-disable-next-line import/no-cycle
import PageLayout from '../../partials/pagelayout/containers/PageLayout';

import ColdEmailTemplate from '../components/templates/ColdEmailTemplate';

export default function AngelEmail() {
  return (
    <PageLayout template="angel-email" title="Pitch an Angel">
      {({ contents }) => React.Children.toArray(
        contents.map((content) => (
          <ColdEmailTemplate key={content.id} data={content} />
        )),
      )}
    </PageLayout>
  );
}
