import React, { useEffect, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { useDispatch } from 'react-redux';
import { updateProject } from '../../../../common/patchInfo';
import {
  setDefaultProject,
  setProject,
  updateProject as updateProjectInCollectionRedux,
} from '../../../../redux-setup/actions/common';
import { setLoading } from '../../../../redux-setup/actions/loading';
import { getProject } from '../../../../redux-setup/actions/project';
import { IBasicProjectDataProps } from '../props';
import Project from './Project';
import { IProjectSectionProps } from './props';
import ToolsBar from './ToolsBar';

function ProjectSection({
  projectsList,
  setOpenDuplicateModal,
  setOpenMoveModal,
  setOpenDeleteProjectModal,
  projectData,
  setProjectData,
  className,
  disabledProjectEdit,
  setDisabledProjectEdit,
}: IProjectSectionProps) {
  const dispatch = useDispatch();
  const [renderProjects, setRenderProjects] = useState<
    IBasicProjectDataProps[]
  >([]);

  const handleClickExpandProject = (id: number) => {
    setDisabledProjectEdit(true);
    // to collapse project
    if (projectData?.id === id) {
      setProjectData(null);
      return;
    }
    dispatch(getProject(id));
  };

  const toggleEditProject = () => {
    setDisabledProjectEdit((pre) => !pre);
  };

  const saveProject = () => {
    dispatch(setLoading(true));
    updateProject(projectData)
      .then((response) => response.json())
      .then((result) => {
        if (result.data) {
          dispatch(setProject(result.data));
          dispatch(setDefaultProject(result.data.id));
          dispatch(
            updateProjectInCollectionRedux({
              collectionID: result.data.collection_id,
              project: {
                id: result.data.id,
                name: result.data.name,
                collection_id: result.data.collection_id,
                created_at: result.datacreated_at,
                inactive: false,
              },
            }),
          );
          NotificationManager.success('Project saved!');
        } else {
          NotificationManager.error(result.message);
        }
        dispatch(setLoading(false));
      })
      .catch((err) => {
        NotificationManager.error(err.toString());
        dispatch(setLoading(false));
      });
  };

  useEffect(() => {
    setDisabledProjectEdit(true);
    setRenderProjects(projectsList);
  }, [projectsList]);

  return (
    <div className={className}>
      <ToolsBar
        wrapperClass="sticky top-0 pb-2 bg-white z-10"
        projects={renderProjects}
        setProjects={setRenderProjects}
        projectsSelected={projectsList}
      />
      <>
        {renderProjects.map((prL) => (
          <Project
            key={prL.id}
            name={prL.name}
            id={prL.id}
            projectData={
              projectData && projectData.id === prL.id && projectData
            }
            setProjectData={setProjectData}
            onClick={handleClickExpandProject}
            disabled={disabledProjectEdit}
            clickEditProject={toggleEditProject}
            saveProject={saveProject}
            setOpenMoveModal={setOpenMoveModal}
            setOpenDuplicateModal={setOpenDuplicateModal}
            setDeleteProjectModal={setOpenDeleteProjectModal}
            inactive={prL.inactive}
            disabledDelete={renderProjects.length < 2}
          />
        ))}
      </>
    </div>
  );
}

export default ProjectSection;
