/* eslint-disable react/no-array-index-key */
import React from 'react';

import PropTypes from 'prop-types';
import ContentEditable from 'react-contenteditable';

import useTemplateState from '../../../../hooks/useTemplateState';
import { editTemplate } from '../../../savedcontent/actions';

import TemplateFooter from '../footer/TemplateFooter';

function BenefitsTemplate({ data, onEditMode, hideFooter }) {
  const { templateValue, handleOnChange } = useTemplateState(data);
  const { headline, description } = templateValue.content;
  return (
    <div
      style={{
        position: 'relative',
        marginBottom: 25,
      }}
    >
      <div
        className="bg-white mb-2 shadow-md font-source "
        style={{
          boxShadow: '0px 4px 18px rgba(0, 0, 0, 0.06)',
          borderRadius: '16px',
        }}
      >
        <div className="py-6 px-8">
          <div className="max-w-2xl mx-auto text-center py-8 px-4 sm:px-6 lg:px-8">
            <h2 className="text-xl xlg:text-2xl font-extrabold text-white ">
              <ContentEditable
                html={headline || ''}
                disabled={!onEditMode}
                onBlur={({ target: { innerHTML } }) =>
                  editTemplate(innerHTML, data, 'content.headline')
                }
                onChange={(e) =>
                  handleOnChange(e.target.value, 'content.headline')
                }
                spellCheck={false}
              />
            </h2>
            <div className="mt-4 text-base xlg:text-lg leading-6 text-indigo-200">
              <ContentEditable
                html={description || ''}
                disabled={!onEditMode}
                onBlur={({ target: { innerHTML } }) =>
                  editTemplate(innerHTML, data, 'content.description')
                }
                onChange={(e) =>
                  handleOnChange(e.target.value, 'content.description')
                }
                spellCheck={false}
              />
            </div>
            <button
              type="button"
              className="cursor-default mt-6 mx-auto rounded-md shadow flex items-center justify-center px-4 py-2  border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-5"
            >
              Learn More
            </button>
          </div>
        </div>
      </div>
      {!hideFooter && (
        <TemplateFooter
          data={templateValue}
          handleOnChange={handleOnChange}
          onEditMode={onEditMode}
          type="benefits"
        />
      )}
    </div>
  );
}

BenefitsTemplate.defaultProps = {
  data: {},
  onEditMode: false,
  hideFooter: false,
};

BenefitsTemplate.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.objectOf(PropTypes.any),
  onEditMode: PropTypes.bool,
  hideFooter: PropTypes.bool,
};
export default BenefitsTemplate;
