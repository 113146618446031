import React from 'react';

import times from 'lodash/times';
import Skeleton from 'react-loading-skeleton';

function SkeletonColdEmail() {
  return React.Children.toArray(
    times(3).map(() => (
      // eslint-disable-next-line react/jsx-key
      <div className="bg-white w-full p-4 rounded-xl shadow-md my-4">
        <div className="flex space-x-4 items-center">
          <div className="w-16">
            <Skeleton
              className="h-14 w-16"
              style={{
                borderRadius: '50%',
              }}
            />
          </div>
          <div className="flex flex-col space-y-2 w-40">
            <Skeleton count={2} />
          </div>
        </div>
        <div className="flex flex-col space-y-2 mt-4">
          <Skeleton count={3} />
        </div>
        <div className="flex flex-col space-y-2 mt-4">
          <Skeleton count={3} />
        </div>
      </div>
    )),
  );
}

export default SkeletonColdEmail;
