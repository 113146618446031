/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import Container from '../common/Container';
import { useApiData } from '../../redux-setup';

import { createTemplate, deleteTemplate, getTemplates } from './actions';

function Card({ card, showRemoveTemplateModal }) {
  const history = useHistory();
  return (
    <div
      role="button"
      tabIndex={0}
      onKeyDown={() => history.push(`/author/${card.id}`)}
      onClick={() => history.push(`/author/${card.id}`)}
      className="w-full bg-white rounded p-4 mb-4 flex justify-between items-center cursor-pointer"
    >
      <p className="text-gray-800">{card.name}</p>
      <button
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          showRemoveTemplateModal(card.id);
        }}
        className="bg-red-100 text-red-700 px-4 py-2 rounded cursor-pointer"
      >
        Delete
      </button>
    </div>
  );
}

function NewTemplateOverlay() {
  const [name, setName] = React.useState('');
  const history = useHistory();
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!name.trim() || name.trim() === '') return;
    createTemplate({ name })
      .then((data) => {
        const { id } = data;
        history.push(`/author/${id}`);
      })
      .catch((err) => console.error(err));
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="z-50 fixed w-screen h-screen top-0 left-0 flex justify-center items-center"
    >
      <div className="absolute top-0 left-0 bg-black opacity-40 w-full h-full" />

      <div className="relative z-50 bg-white rounded-lg flex flex-col p-8 w-96">
        <h2
          htmlFor="template_name"
          className="block text-sm font-medium text-gray-700"
        >
          Template Name
        </h2>
        <div className="mt-1">
          <input
            type="text"
            name="template_name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
          />
          <button
            type="submit"
            className="w-full mt-2 p-2 bg-royalblue-400 rounded text-white font-bold"
          >
            Create
          </button>
        </div>
      </div>
    </form>
  );
}

function DeleteTemplateOverlay({ removeTemplate, hideRemoveTemplateModal }) {
  return (
    <form
      onSubmit={(e) => e.preventDefault()}
      className="z-50 fixed w-screen h-screen top-0 left-0 flex justify-center items-center"
    >
      <div className="absolute top-0 left-0 bg-black opacity-40 w-full h-full" />
      <div className="relative z-50 bg-white rounded-lg flex flex-col p-8 w-96">
        <button
          onClick={() => removeTemplate()}
          type="button"
          className="w-full p-2 bg-red-400 rounded text-white font-bold"
        >
          Confirm Remove
        </button>
        <button
          onClick={() => hideRemoveTemplateModal()}
          type="button"
          className="w-full p-2 mt-2 bg-gray-100 rounded text-gray-400 font-bold"
        >
          Cancel
        </button>
      </div>
    </form>
  );
}

function AuthorRoutesContainer() {
  // Saved templates in Redux store.
  const templates = useApiData('templates');

  // New Template Overlay
  const [showOverlay, setShowOverlay] = React.useState(false);
  // Delete Template Overlay
  const [showDeleteOverlay, setDeleteShowOverlay] = React.useState(false);
  const [templateToDelete, setTemplateToDelete] = React.useState(null);

  // On component load - fetch the list of templates from API for loggedin user.
  useEffect(() => {
    getTemplates();
  }, []);

  const showRemoveTemplateModal = (id) => {
    setDeleteShowOverlay(true);
    setTemplateToDelete(id);
  };

  const hideRemoveTemplateModal = () => {
    setDeleteShowOverlay(false);
    setTemplateToDelete(null);
  };

  // eslint-disable-next-line consistent-return
  const removeTemplate = () => {
    if (Number.isNaN(parseInt(templateToDelete, 10))) return false;
    deleteTemplate(templateToDelete).finally(() => hideRemoveTemplateModal());
  };

  return (
    <>
      {showOverlay && <NewTemplateOverlay />}
      {showDeleteOverlay && (
        <DeleteTemplateOverlay
          removeTemplate={removeTemplate}
          hideRemoveTemplateModal={hideRemoveTemplateModal}
        />
      )}
      <section>
        <Container>
          <div className="flex flex-col">
            <h3 className="mt-10 mb-4 text-black text-2xl font-extrabold">
              Templates
            </h3>
            {templates?.map((temp) => (
              <Card
                key={temp.id}
                showRemoveTemplateModal={showRemoveTemplateModal}
                card={temp}
              />
            ))}
            <button
              type="button"
              className="bg-royalblue-400 text-white font-bold w-56 h-12 rounded ml-auto"
              onClick={() => setShowOverlay(true)}
            >
              New Template
            </button>
          </div>
        </Container>
      </section>
    </>
  );
}

export default AuthorRoutesContainer;
