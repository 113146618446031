export const setFineTuneVariable = (variable, value) =>
  sessionStorage.setItem(variable, value);
export const getFineTuneVariable = (variable) =>
  sessionStorage.getItem(variable) || '';
export const getFineTuneVariableArray = (variable) =>
  sessionStorage.getItem(variable) || [];

// WRITER
export const setWriterTypeOfContent = (typeOfContent) =>
  sessionStorage.setItem('writerTypeOfContent', typeOfContent);
export const setWriterTopic = (topic) =>
  sessionStorage.setItem('writerTopic', topic);
export const setWriterAudience = (audience) =>
  sessionStorage.setItem('writerAudience', audience);
export const setWriterTone = (tone) =>
  sessionStorage.setItem('writerTone', tone);
export const getWriterTypeOfContent = () =>
  sessionStorage.getItem('writerTypeOfContent') || '';
export const getWriterTopic = () => sessionStorage.getItem('writerTopic') || '';
export const getWriterAudience = () =>
  sessionStorage.getItem('writerAudience') || '';
export const getWriterTone = () => sessionStorage.getItem('writerTone') || '';
// CONTENT EXPANDER
export const setContentExpanderSummary = (summary) =>
  sessionStorage.setItem('contentExpanderSummary', summary);
export const setContentExpanderTopic = (topic) =>
  sessionStorage.setItem('contentExpanderTopic', topic);
export const getContentExpanderSummary = () =>
  sessionStorage.getItem('contentExpanderSummary') || '';
export const getContentExpanderTopic = () =>
  sessionStorage.getItem('contentExpanderTopic') || '';
