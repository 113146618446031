import React from 'react';

import { Editor, EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import PropTypes from 'prop-types';

import { getBlockStyle } from '../../writer/actions';

import { getTemplateByName } from '../constants';

function Template({ data }) {
  const isWriter = data?.template === 'Writer';
  const TemplateView = getTemplateByName[data?.template] || getTemplateByName['Blog Ideas'];
  const [editorState] = React.useState(
    isWriter
      ? EditorState.createWithContent(
        stateFromHTML(data?.content?.writerContent),
      )
      : '',
  );

  return (
    <div>
      {data?.template === 'Writer' ? (
        <div className="bg-white p-4 rounded-md">
          <Editor
            blockStyleFn={getBlockStyle}
            editorState={editorState}
            readOnly
          />
        </div>
      ) : (
        <TemplateView data={data} hideFooter />
      )}
    </div>
  );
}

Template.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};
export default Template;
