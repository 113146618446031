import React from 'react';

import {
  Field, Form, Formik, FieldArray,
} from 'formik';
import has from 'lodash/has';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import * as yup from 'yup';

import { AlertMarker } from '../../common/Alerts';
import { useApiData } from '../../../redux-setup';

import { sendSharedEmail } from '../actions';
import { SHARE_DATA } from '../components/share/constants';

const validationSchema = yup.object().shape({
  emails: yup.array().of(yup.string().email()).min(1).max(5),
  email: yup
    .string()
    .email('Please input valid email')
    .label('Email')
    .nullable(),
});

function ShareEmailForm() {
  const { sharing_id: sharingId = '' } = useApiData(SHARE_DATA, {});
  return (
    <Formik
      initialValues={{
        emails: [],
        email: '',
        sharingId,
      }}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        sendSharedEmail(values, () => {
          setSubmitting(false);
          resetForm();
        });
      }}
      validationSchema={validationSchema}
    >
      {({
        values, errors, setFieldValue, handleSubmit, isSubmitting,
      }) => (
        <Form className="w-full">
          <FieldArray name="emails">
            {({ push, remove }) => (
              <>
                <AlertMarker name="share-form" />
                <div className="flex items-center w-full  space-x-4">
                  <div className="flex flex-col w-full">
                    <Field
                      type="input"
                      name="email"
                      onKeyPress={(e) => {
                        const val = e.target.value;
                        if (
                          ['Enter', ',', ' '].includes(e.key)
                            && val
                            && val.trim() !== ''
                            && val.trim() !== ','
                            && !has(errors, 'email')
                        ) {
                          e.preventDefault();
                          push(val.trim());
                          setFieldValue('email', '');
                        }
                      }}
                      value={values.email}
                      className="rounded-xl flex-1 bg-gray-100 w-full p-2 outline-none"
                      autoComplete="off"
                      placeholder="Add multiple emails with commas"
                    />
                  </div>
                  <button
                    onClick={handleSubmit}
                    className="btn primary"
                    type="button"
                    disabled={isSubmitting}
                  >
                    Send
                  </button>
                </div>
                {has(errors, 'email') && (
                  <p className="text-red-500 text-sm">{errors?.email}</p>
                )}
                <div className="flex items-center gap-x-2 flex-wrap mt-2">
                  {values.emails
                      && values?.emails?.length > 0
                      && React.Children.toArray(
                        values?.emails?.map((email, key) => (
                          <div
                            // eslint-disable-next-line react/no-array-index-key
                            key={key}
                            className="flex space-x-2 mx-1 mt-2 flex-wrap bg-indigo-100 text-gray-600 py-1 px-2 rounded-full"
                          >
                            <p className="text-xs p-0 m-0">{email}</p>
                            <AiOutlineCloseCircle
                              onClick={() => remove(key)}
                              size={15}
                              className="ml-1 cursor-pointer"
                            />
                          </div>
                        )),
                      )}
                </div>
              </>
            )}
          </FieldArray>
        </Form>
      )}
    </Formik>
  );
}

export default ShareEmailForm;
