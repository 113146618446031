import React, { useMemo, useState } from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { ICustomizeFormProps } from './props';
import { useCurrentTemplate } from '../../modules/templates/hooks';
import { usePageLayoutContext } from '../../modules/partials/pagelayout/hooks';
import { getFineTuneVariable } from '../../config/localStorage';
import { getYupValidation } from '../../modules/partials/pagelayout/actions';
import { IFullTemplateData, IInitialValuesObj } from '../props';
import CustomizeFormField from './CustomizeFormField';
import { useTemplateSidebar } from '../../modules/writer/context';

interface ITemplateSidebar {
  editor: any;
  setViewProfile: () => void;
  viewProfile: IFullTemplateData;
}

function CustomizeForm({ isSidebar, onSubmitForm }: ICustomizeFormProps) {
  const [renderAgain, setRenderAgain] = useState(Math.random());
  const { formRef } = usePageLayoutContext();
  const { viewProfile } = useTemplateSidebar() as ITemplateSidebar;
  const templateData = useCurrentTemplate() as IFullTemplateData;
  const fields = isSidebar
    ? viewProfile.custom.fields
    : templateData.custom.fields;
  const initialValues = useMemo(() => {
    const objRes = {} as IInitialValuesObj;
    fields.forEach((field) => {
      if (field.type === 'keywords') {
        objRes[field.field] = getFineTuneVariable(
          `${templateData.name.replaceAll(' ', '')}${field.field}`,
        )
          .split(',')
          .filter((elm) => elm);
      } else {
        objRes[field.field] = getFineTuneVariable(
          `${templateData.name.replaceAll(' ', '')}${field.field}`,
        );
      }
    });
    return objRes;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  const validationSchema = useMemo(() => {
    const customFields = fields.reduce(
      (accu, val) => ({
        ...accu,
        [val.field]: getYupValidation(val.type, val.label),
      }),
      {},
    );
    setRenderAgain(Math.random());
    return Yup.object().shape(customFields);
  }, [fields]);

  const maxLengthField = (type: string) => {
    switch (type) {
      case 'shortText':
        return 250;
      case 'mediumText':
        return 500;
      case 'longText':
        return 1500;
      case 'extraLongText':
        return 3000;
      case 'keywords':
        return 25;
      case 'language':
      case 'length':
      case 'tone':
      case 'input':
      default:
        return 75;
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      innerRef={formRef}
      validationSchema={validationSchema}
      onSubmit={onSubmitForm}
      key={renderAgain}
    >
      {({ values }) => (
        <Form className="flex flex-wrap justify-between gap-4">
          {fields.map((field) => (
            <CustomizeFormField
              key={field.field}
              name={field.field}
              value={values[field.field]}
              title={field.label}
              placeholder={field.placeholder || field.label}
              type={field.type}
              maxCounter={maxLengthField(field.type)}
            />
          ))}
          <button type="submit" className="hidden" />
        </Form>
      )}
    </Formik>
  );
}

export default CustomizeForm;
