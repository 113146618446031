import React from 'react';
import ContentEditable from 'react-contenteditable';
import useTemplateState from '../../../../../../hooks/useTemplateState';
import { editTemplate } from '../../../../../savedcontent/actions';
import TemplateFooter from '../../../../components/footer/TemplateFooter';
import { IGoogleAdsResultProps } from './props';

function GoogleAdsResult({
  data,
  hideFooter = false,
  onEditMode = false,
}: IGoogleAdsResultProps) {
  const { templateValue, handleOnChange } = useTemplateState(data);
  const { headline, description } = templateValue.content;
  return (
    <div className="relative mb-4 px-px">
      <div className="border lg:border-0 border-gallery bg-white py-4 px-6 mb-3 shadow-lg font-source rounded-lg">
        <h3
          className="text-md sm:text-lg xlg:text-xl font-semibold"
          style={{
            color: '#1D39C4',
          }}
        >
          <ContentEditable
            html={headline}
            onBlur={({ target: { innerHTML } }) =>
              editTemplate(innerHTML, data, 'content.headline')
            }
            onChange={(e) => handleOnChange(e.target.value, 'content.headline')}
            disabled={!onEditMode}
            spellCheck={false}
          />
        </h3>

        <div className="mt-2 text-mine-shaft text-xs xs:text-sm sm:text-md">
          <ContentEditable
            html={description}
            disabled={!onEditMode}
            onBlur={({ target: { innerHTML } }) =>
              editTemplate(innerHTML, data, 'content.description')
            }
            onChange={(e) =>
              handleOnChange(e.target.value, 'content.description')
            }
            spellCheck={false}
          />
        </div>
      </div>
      {!hideFooter && (
        <TemplateFooter
          data={templateValue}
          onEditMode={onEditMode}
          handleOnChange={handleOnChange}
          type="header-description"
        />
      )}
    </div>
  );
}

export default GoogleAdsResult;
