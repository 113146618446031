import React from 'react';

import { Formik, Form, Field } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import { Input, TextArea } from '../../common/Formik/FormInputs';

// validation
const rqdmsg = 'This field is required.';
const inputRules = (label) => Yup.string().trim().required(rqdmsg).max(400)
  .label(label);
const validateForm = Yup.object().shape({
  headline: inputRules('Headline'),
  description: inputRules('Description'),
});

// eslint-disable-next-line react/display-name
const ItemizeForm = React.forwardRef(({ initialValues, onSubmit }, ref) => (
  <Formik
    innerRef={ref}
    initialValues={initialValues}
    validationSchema={validateForm}
    onSubmit={(values, { setSubmitting }) => {
      onSubmit(values, setSubmitting);
    }}
    enableReinitialize
  >
    {({ values }) => (
      <Form>
        <div className="flex flex-wrap justify-between">
          <div className="w-full pr-0">
            <p className="text-gray-700 font-bold text-lg flex items-center">
              Headline
            </p>
            <Field
              component={Input}
              name="headline"
              value={values.headline}
              placeholder="The easiest way to..."
              wrapperClass="my-4"
            />
            <p className="text-gray-700 font-bold text-lg flex items-center">
              Description
            </p>
            <Field
              component={TextArea}
              name="description"
              value={values.description}
              placeholder="This is the first sentence in the..."
              wrapperClass="my-4"
              rows={4}
            />
          </div>
        </div>
      </Form>
    )}
  </Formik>
));

ItemizeForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  initialValues: PropTypes.objectOf(PropTypes.any).isRequired,
  onSubmit: PropTypes.func.isRequired,
};
export default ItemizeForm;
