import { useEffect } from 'react';

import { checkAuth } from '../../../config/token';
import { useApiData } from '../../../redux-setup';

import { getProfile } from '../actions';
import { PERSONALIZE } from '../constants';

export default function (initializeLoad = true) {
  const { isLoaded, ...res } = useApiData(PERSONALIZE, {});
  useEffect(() => {
    if (!isLoaded && initializeLoad && checkAuth()) {
      getProfile();
    }
  }, [isLoaded, initializeLoad]);
  return { ...res, isLoaded };
}
