import { FaFacebookF } from 'react-icons/fa';
import { FiTwitter, FiSend } from 'react-icons/fi';
// import { IoMdCode } from 'react-icons/io';
import { RiTwitterFill } from 'react-icons/ri';

export const SHARE_DATA = 'SHARE_DATA';

export const activeButtonProps = {
  twitter: {
    color: '#1DA1F2',
    logo: RiTwitterFill,
    text: 'Twitter',
  },
  facebook: {
    color: '#3B5998',
    logo: FaFacebookF,
    text: 'Facebook',
  },
  email: {
    color: '#6366F1',
    logo: FiSend,
    text: 'Send via email',
  },
};

export const actionsList = [
  {
    logo: FiSend,
    name: 'email',
  },
  {
    logo: FiTwitter,
    name: 'twitter',
  },
  {
    logo: FaFacebookF,
    name: 'facebook',
  },
];
