/* eslint-disable react/prop-types */
import React from 'react';
import { clsx } from 'clsx';

function FilterButtons({
  filter,
  setFilter,
  menuItems,
  showFavorites,
  setSearchText,
}) {
  return (
    <div className="flex md:block md:px-8 overflow-auto touch-pan-x no-scrollbar md:overflow-hidden justify-content-center">
      <button
        type="button"
        className="inline-flex items-center bg-frosted-mint betterhover:hover:bg-jungle-green text-jungle-green betterhover:hover:text-white text-sm py-1 px-4 rounded-xl md:ml-0 m-1"
        onClick={() => {
          setFilter([]);
          setSearchText('');
        }}
      >
        All
      </button>
      {showFavorites && (
        <button
          type="button"
          className={clsx(
            'whitespace-nowrap items-center betterhover:hover:bg-jungle-green  betterhover:hover:text-white text-sm py-1 px-4 rounded-xl m-1',
            filter?.some((f) => f === 'Favorites')
              ? 'bg-shamrock text-white'
              : 'bg-frosted-mint text-jungle-green',
          )}
          onClick={() => {
            const isActive = filter?.some((f) => f === 'Favorites');
            setSearchText('');
            if (!isActive) {
              setFilter(['Favorites']);
              return;
            }
            setFilter([]);
          }}
        >
          Favorites
        </button>
      )}
      {menuItems.map((section) => {
        const isActive = filter?.some((f) => f === section);
        return (
          <button
            type="button"
            className={clsx(
              'whitespace-nowrap items-center betterhover:hover:bg-jungle-green  betterhover:hover:text-white text-sm py-1 px-4 rounded-xl m-1',
              isActive
                ? 'bg-shamrock text-white'
                : 'bg-frosted-mint text-jungle-green',
            )}
            onClick={() => {
              setSearchText('');
              if (!isActive) {
                setFilter([section]);
                return;
              }
              setFilter([]);
            }}
            key={section}
          >
            {section}
          </button>
        );
      })}
    </div>
  );
}

export default FilterButtons;
