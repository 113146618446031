import React from 'react';
import { Formik, Form, Field } from 'formik';
import { object, string } from 'yup';
import { RootStateOrAny, useSelector } from 'react-redux';
import { updateProfile } from '../../../personalize/actions';
import { AlertMarker, createAlert } from '../../../common/Alerts';
import { Input } from '../../../common/Formik/FormInputs';
import Button from '../../../../common/Button';

const validateForm = object().shape({
  email: string().required('This field is required.').email().label('Email'),
});

function ChangeEmailSection() {
  const { email } = useSelector(
    (state: RootStateOrAny) => state['PERSONALIZE/DATA'],
  );

  return (
    <Formik
      initialValues={{
        email: '',
      }}
      validationSchema={validateForm}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        updateProfile(
          values,
          false,
          () => {
            createAlert.success('change-email', 'Email updated');
            setSubmitting(false);
            resetForm();
          },
          {},
          'change-email',
        );
      }}
    >
      {({ values, handleSubmit, isSubmitting }) => (
        <Form className="my-8">
          <h3 className="text-ugray font-semibold pb-2">Email</h3>
          <AlertMarker name="change-email" />
          <div className="grid grid-cols-1 md:grid-cols-3 gap-x-4 gap-y-6 items-center">
            <p className="min-w-fit text-sm text-silver-chalice">
              {email || '-'}
            </p>
            <Field
              component={Input}
              name="email"
              value={values.email}
              wrapperClass="w-full"
              placeholder="New Email"
            />
            <div className="text-right">
              <Button
                type="grayOutline"
                className="w-full md:w-44"
                disabled={isSubmitting}
                onClick={handleSubmit}
              >
                Update email
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default ChangeEmailSection;
