/* eslint-disable react/no-array-index-key */
import React from 'react';
// eslint-disable-next-line import/no-cycle
import PageLayout from '../../../partials/pagelayout/containers/PageLayout';
import HeaderDescriptionTemplate from '../../components/templates/HeaderDescriptionTemplate';

function HeaderDescription() {
  return (
    <PageLayout template="header-description" title="Header + Description">
      {({ contents }) => React.Children.toArray(
        contents.map((content) => (
          <HeaderDescriptionTemplate key={content.id || Math.random()} data={content} />
        )),
      )}
    </PageLayout>
  );
}
export default HeaderDescription;
