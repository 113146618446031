import React, { useState } from 'react';
import { SingleValue } from 'react-select';
import Button from '../../../../common/Button';
import Modal from '../../../../common/Modal';
import SelectForm from '../../../../common/SelectForm';
import { IModalMoveProjectProps } from './props';
import { IDefaultSelect } from '../../../../common/props';
import { RootStateOrAny, useSelector } from 'react-redux';
import { ICollectionDataProps } from '../props';

function ModalMoveProject({
  openMoveProject,
  setOpenMoveProject,
  handleMoveProject,
}: IModalMoveProjectProps) {
  const collections = useSelector(
    (state: RootStateOrAny) => state.COLLECTIONS,
  ) as ICollectionDataProps[];
  const [selectValue, setSelectValue] = useState<IDefaultSelect>({
    value: '',
    label: '',
  });

  const onChangeSelect = (newValue: SingleValue<IDefaultSelect>) => {
    if (newValue) {
      setSelectValue(newValue);
    }
  };

  return (
    <Modal
      isOpen={openMoveProject.open}
      onCloseModal={() => {
        setOpenMoveProject({ open: false, id: 0 });
      }}
      minWidth={350}
    >
      <h1 className="font-source text-usilver text-center my-4 w-full">
        Choose a collection below
      </h1>
      <SelectForm
        options={collections.map((coll) => ({
          label: coll.name,
          value: coll.id.toString(),
        }))}
        onChangeSelect={onChangeSelect}
      />
      <Button
        type="primary"
        className="w-full mt-4"
        onClick={() => handleMoveProject(selectValue)}
      >
        Move to Collection
      </Button>
    </Modal>
  );
}

export default ModalMoveProject;
