import React, { useCallback } from 'react';
import { clsx } from 'clsx';
import Button from '../../../common/Button';
import IExampleCardProps from './props';
import { current } from '@reduxjs/toolkit';
import Tooltip from '../../common/Tooltip';
import { MdInfo } from 'react-icons/md';
import { RootStateOrAny, useSelector } from 'react-redux';

function ExampleCard({
  planName,
  annual,
  priceYear,
  priceMonthly,
  className,
  selected,
  ribbonText,
  onCheckout,
  creditsPerMonth,
  projects,
  writer,
  seo,
  support,
  selectedPlan,
  currentPlan,
  disabled,
  onCanceling,
  onReactivateClick,
  fromOriginalPrice,
  creditsTooltip,
  onUpgradeDowngrade,
}: IExampleCardProps) {
  const word = useCallback(() => {
    switch (planName) {
      case 'essential':
        if (currentPlan === 'growth' || currentPlan === 'unlimited') {
          return 'Downgrade';
        }
        return 'Upgrade';
      case 'growth':
      case 'unlimited':
      default:
        return 'Upgrade';
    }
  }, []);

  const { isPaid } = useSelector(
    (state: RootStateOrAny) => state['PERSONALIZE/DATA'],
  );
  return (
    <div
      className={clsx(
        'bg-uwhite text-center py-6',
        !annual && 'rounded-r-xl',
        selected
          ? `border-4 ${
              selectedPlan ? 'border-alto' : 'border-shamrock'
            } rounded-xl drop-shadow-lg -my-6`
          : 'border-l',
        className,
        ribbonText && 'relative overflow-hidden',
      )}
    >
      {/* {selected && (
        <span className="animate-ping absolute -top-2 -right-2 h-6 w-6 rounded-full bg-shamrock opacity-75" />
      )} */}
      {ribbonText && (
        <span
          className="text-sm bg-shamrock absolute text-white font-semibold py-.5"
          style={{
            transform: 'rotate(45deg)',
            width: '150px',
            height: '18px',
          }}
        >
          <span className="w-full">{ribbonText}</span>
        </span>
      )}
      {selected && <div className="h-5" />}
      <h3
        className={clsx(
          'font-barlow font-medium',
          selectedPlan || (disabled && 'text-ugray'),
        )}
      >
        {planName.toUpperCase()}
      </h3>
      <div className="text-center">
        {fromOriginalPrice && (
          <h2
            className={clsx(
              'font-barlow font-semibold text-3xl text-shamrock line-through inline-block mr-1',
              (selectedPlan || disabled) && 'text-jungle-green',
            )}
          >{`$${fromOriginalPrice}`}</h2>
        )}
        <h2
          className={clsx(
            'font-barlow font-semibold text-3xl inline-block',
            (selectedPlan || disabled) && 'text-ugray',
          )}
        >
          {`$${priceMonthly}`}
          <span className="text-base font-normal">/month</span>
        </h2>
      </div>
      {annual ? (
        <span className="text-silver-chalice text-xs">
          {`$${priceYear}`} Billed Annually
        </span>
      ) : (
        <span className="text-xs invisible">Billed</span>
      )}
      <ul className="text-center mt-2">
        <li className="mb-4 text-sm">
          <svg
            className="inline-block"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
            fill={selectedPlan || disabled ? '#808080' : '#27CC8D'}
            width={16}
          >
            <path d="M443.3 100.7C449.6 106.9 449.6 117.1 443.3 123.3L171.3 395.3C165.1 401.6 154.9 401.6 148.7 395.3L4.686 251.3C-1.562 245.1-1.562 234.9 4.686 228.7C10.93 222.4 21.06 222.4 27.31 228.7L160 361.4L420.7 100.7C426.9 94.44 437.1 94.44 443.3 100.7H443.3z" />
          </svg>
        </li>
        <li className="mb-4 text-sm">
          <svg
            className="inline-block"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
            fill={selectedPlan || disabled ? '#808080' : '#27CC8D'}
            width={16}
          >
            <path d="M443.3 100.7C449.6 106.9 449.6 117.1 443.3 123.3L171.3 395.3C165.1 401.6 154.9 401.6 148.7 395.3L4.686 251.3C-1.562 245.1-1.562 234.9 4.686 228.7C10.93 222.4 21.06 222.4 27.31 228.7L160 361.4L420.7 100.7C426.9 94.44 437.1 94.44 443.3 100.7H443.3z" />
          </svg>
        </li>
        <li className="mb-4 text-sm">
          <svg
            className="inline-block"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
            fill={selectedPlan || disabled ? '#808080' : '#27CC8D'}
            width={16}
          >
            <path d="M443.3 100.7C449.6 106.9 449.6 117.1 443.3 123.3L171.3 395.3C165.1 401.6 154.9 401.6 148.7 395.3L4.686 251.3C-1.562 245.1-1.562 234.9 4.686 228.7C10.93 222.4 21.06 222.4 27.31 228.7L160 361.4L420.7 100.7C426.9 94.44 437.1 94.44 443.3 100.7H443.3z" />
          </svg>
        </li>
        <li className="mb-4 text-sm">
          <svg
            className="inline-block"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
            fill={selectedPlan || disabled ? '#808080' : '#27CC8D'}
            width={16}
          >
            <path d="M443.3 100.7C449.6 106.9 449.6 117.1 443.3 123.3L171.3 395.3C165.1 401.6 154.9 401.6 148.7 395.3L4.686 251.3C-1.562 245.1-1.562 234.9 4.686 228.7C10.93 222.4 21.06 222.4 27.31 228.7L160 361.4L420.7 100.7C426.9 94.44 437.1 94.44 443.3 100.7H443.3z" />
          </svg>
        </li>
        <li
          className={clsx(
            'mb-4 font-semibold text-sm',
            (selectedPlan || disabled) && 'text-ugray',
          )}
        >
          {creditsPerMonth}
          {creditsTooltip && (
            <Tooltip
              className="inline-block"
              position="right"
              text={creditsTooltip}
              fontSize={12}
              width={200}
            >
              <MdInfo className="text-gray-300" />
            </Tooltip>
          )}
        </li>
        <li
          className={clsx(
            'mb-4 font-semibold text-sm',
            (selectedPlan || disabled) && 'text-ugray',
          )}
        >
          {projects}
        </li>
        <li className="mb-4 text-sm">
          {writer === 'yes' && (
            <svg
              className="inline-block"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              fill={selectedPlan || disabled ? '#808080' : '#27CC8D'}
              width={16}
            >
              <path d="M443.3 100.7C449.6 106.9 449.6 117.1 443.3 123.3L171.3 395.3C165.1 401.6 154.9 401.6 148.7 395.3L4.686 251.3C-1.562 245.1-1.562 234.9 4.686 228.7C10.93 222.4 21.06 222.4 27.31 228.7L160 361.4L420.7 100.7C426.9 94.44 437.1 94.44 443.3 100.7H443.3z" />
            </svg>
          )}
          {writer === 'trial' && (
            <span
              className={clsx(
                'mb-4 text-sm',
                (selectedPlan || disabled) && 'text-ugray',
              )}
            >
              3-Day trial
            </span>
          )}
          {!writer && '-'}
        </li>
        <li
          className={clsx(
            'mb-4 text-sm italic',
            (selectedPlan || disabled) && 'text-ugray',
          )}
        >
          {seo ? 'Coming soon' : '-'}
        </li>
        <li
          className={clsx(
            'mb-4 font-semibold text-sm',
            (selectedPlan || disabled) && 'text-ugray',
          )}
        >
          {support ? 'Priority' : 'Included'}
        </li>
        <li
          className={clsx(
            'mb-4 font-semibold text-sm',
            (selectedPlan || disabled) && 'text-ugray',
          )}
        >
          Included
        </li>
      </ul>
      {/* {((planName !== 'free' && !disabled) || selectedPlan) &&
        (selectedPlan ? (
          <Button secondary disabled>
            Your Current Plan
          </Button>
        ) : (
          <Button primary onClick={onCheckout}>
            {isNewPlan
              ? `Upgrade to ${planName[0].toUpperCase()}${planName.slice(1)}`
              : `Upgrade to ${annual ? 'Annual' : 'Monthly'}`}
          </Button>
        ))} */}
      <div className="px-0.5">
        {selectedPlan && !onCanceling && (
          <Button type="secondary" disabled>
            Your Current Plan
          </Button>
        )}
        {selectedPlan && onCanceling && (
          <Button
            type="alternative"
            onClick={onReactivateClick}
            className="relative"
          >
            <span
              className="animate-ping absolute -top-2 -right-2 h-6 w-6 rounded-full opacity-75"
              style={{ backgroundColor: '#FFCE00' }}
            />
            Reactivate Subscription
          </Button>
        )}
        {/* {planName !== 'free' && !disabled && !selectedPlan && (
          <Button primary onClick={onCheckout}>
            {`${word()} to ${planName[0].toUpperCase()}${planName.slice(1)}`}
          </Button>
        )} */}
        {planName !== 'free' &&
          !disabled &&
          !selectedPlan &&
          (isPaid ? (
            <Button type="primary" onClick={onUpgradeDowngrade}>
              {`${word()} to ${planName[0].toUpperCase()}${planName.slice(1)}`}
            </Button>
          ) : (
            <Button type="primary" onClick={onCheckout}>
              {`Upgrade to ${planName[0].toUpperCase()}${planName.slice(1)}`}
            </Button>
          ))}
      </div>
    </div>
  );
}

export default ExampleCard;
