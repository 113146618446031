import React from 'react';
import PropTypes from 'prop-types';
import { IoSparklesSharp } from 'react-icons/io5';
import FormSwitch from '../../../common/FormSwitch';
import { usePageLayoutContext } from '../hooks';
import { useApiData } from '../../../../redux-setup';
import { BUTTON_STATUS } from '../constants';
import CustomizeFormAuthor from "../forms/CustomizeFormAuthor";

function CustomizeAuthor({ isDemo, isHidden = false }) {
  const {
    viewCustomizeForm,
    setCustomizeForm,
    isWriter,
  } = usePageLayoutContext();
  const buttonStatus = useApiData(BUTTON_STATUS, '');
  return (
    <div
      className="border border-gray-200 rounded-lg w-full divide-y divide-gray-200 my-10"
      style={{ backgroundColor: '#FDFDFD' }}
    >
      <div className="flex flex-wrap justify-between items-center w-full gap-x-6 gap-y-4 px-4 sm:px-6 py-5">
        <div className="flex flex-col text-sm tracking-tight align-middle">
          <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <IoSparklesSharp size={18} />
            <span className="font-semibold"> &nbsp;Fine-tune</span>
          </div>
          {!isHidden && (
            <span className="text-gray-500">
              {viewCustomizeForm
                ? 'Disable to write random ideas/inspiration'
                : 'Enable to write copy specific to your needs'}
            </span>
          )}
        </div>
        {!isHidden && (
          <div className="flex gap-x-4">
            {!isWriter && (
              <FormSwitch
                label={viewCustomizeForm ? 'Enabled' : 'Disabled'}
                labelWrapperClass="text-sm font-semibold mr-1"
                onChange={() => {
                  setCustomizeForm((prev) => !prev);
                }}
                checked={viewCustomizeForm}
                disabled={buttonStatus === 'loading'}
              />
            )}
          </div>
        )}
      </div>
      <div className="bg-white px-4 sm:px-6 pb-5 pt-2 rounded-b-lg">
        {viewCustomizeForm && <CustomizeFormAuthor isDemo={isDemo} /> }
      </div>
    </div>
  );
}
CustomizeAuthor.defaultProps = {
  isDemo: false,
  isHidden: false,
};
CustomizeAuthor.propTypes = {
  isHidden: PropTypes.bool,
  isDemo: PropTypes.bool,
};
export default CustomizeAuthor;
