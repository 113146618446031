import React, { useState } from 'react';
import { faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '../../../../common/Button';
import InputField from '../../../../common/InputField';
import Modal from '../../../../common/Modal';
import { IModalDeleteProjectProps } from './props';

function ModalDeleteProject({
  openDeleteModal,
  setOpenDeleteModal,
  onClickDeleteProject,
}: IModalDeleteProjectProps) {
  const [inputToDelete, setInputToDelete] = useState('');

  return (
    <Modal
      isOpen={openDeleteModal.open}
      onCloseModal={() => {
        setInputToDelete('');
        setOpenDeleteModal({ open: false, id: 0, name: '' });
      }}
    >
      <div className="text-3xl text-uorange w-full text-center">
        <FontAwesomeIcon icon={faTrashCan} />
      </div>
      <h2 className="font-barlow text-lg text-mine-shaft my-4 text-center">
        Are you sure you want to delete this project?
      </h2>
      <p className="text-xs text-tundora text-center my-4">
        This action <span className="font-medium">CANNOT</span> be undone.
        <br />
        This will permanently delete the{' '}
        <span className="font-medium">{`${openDeleteModal.name || ''}`}</span>{' '}
        project and content generated.
      </p>
      <p className="font-source text-tundora my-4 text-center">
        Please type in{' '}
        <span className="font-semibold text-ubblue">{`${
          openDeleteModal.name.toUpperCase() || ''
        }`}</span>{' '}
        to confirm.
      </p>
      <InputField
        name=""
        placeholder={openDeleteModal.name.toUpperCase() || ''}
        value={inputToDelete}
        onChange={(e) => {
          const val = e.target.value;
          setInputToDelete(val);
        }}
        error={false}
        wrapperClass="my-4"
        onPaste={(e) => {
          e.preventDefault();
          setInputToDelete(e.clipboardData.getData('text').trim());
        }}
      />
      <div className="flex gap-x-4">
        <Button
          type="danger"
          onClick={() => {
            setInputToDelete('');
            onClickDeleteProject();
          }}
          className="w-full"
          disabled={inputToDelete !== openDeleteModal.name.toUpperCase()}
        >
          Delete
        </Button>
        <Button
          type="secondary"
          onClick={() => {
            setInputToDelete('');
            setOpenDeleteModal({ open: false, id: 0, name: '' });
          }}
          className="w-full"
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
}

export default ModalDeleteProject;
