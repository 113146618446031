import React from 'react';

function FooterOptionSkeleton() {
  return (
    <div className="w-full flex flex-wrap gap-1.5">
      <div className="h-2.5 bg-gray-200 rounded-full w-full mb-2" />
      <div className="h-2 bg-gray-200 rounded-full w-11/12" />
      <div className="h-2 bg-gray-200 rounded-full w-10/12" />
      <div className="h-2 bg-gray-200 rounded-full w-10/12" />
      <div className="h-2 bg-gray-200 rounded-full w-10/12" />
    </div>
  );
}

export default FooterOptionSkeleton;
