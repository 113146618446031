import React from 'react';
import { ISkeletonProps } from './props';
import SkeletonDefault from './SkeletonDefault';
import LandingPageSkeleton from './LandingPageSkeleton';
import TitleDescriptionSkeleton from './LandingPageSkeleton/TitleDescriptionSkeleton';
import FeaturesSkeleton from './LandingPageSkeleton/FeaturesSkeleton';
import BenefitsSkeleton from './LandingPageSkeleton/BenefitSkeleton';
import SkeletonLongText from './SkeletonLongText';
import SkeletonMediumText from './SkeletonMediumText';
import EmailSkeleton from './EmailSkeleton';
import NoteSkeleton from './NoteSkeleton';
import QuestionsResponseSkeleton from './QuestionsResponseSkeleton';
import FacebookAdsSkeleton from './FacebookAdSkeleton';
import EmailSubjectLinesSkeleton from './EmailSubjectLinesSkeleton';

function Skeletons({ templateName }: ISkeletonProps) {
  switch (templateName.toLowerCase()) {
    case 'facebook ads':
      return (
        <>
          <FacebookAdsSkeleton />
        </>
      );
    case 'landing page copy':
      return (
        <>
          <LandingPageSkeleton />
        </>
      );
    case 'header + description':
      return (
        <>
          <div className="border border-gallery bg-white py-4 px-6 shadow-md font-source rounded-lg animate-pulse mb-4">
            <TitleDescriptionSkeleton />
          </div>
          <div className="border border-gallery bg-white py-4 px-6 shadow-md font-source rounded-lg animate-pulse mb-4">
            <TitleDescriptionSkeleton />
          </div>
          <div className="border border-gallery bg-white py-4 px-6 shadow-md font-source rounded-lg animate-pulse mb-4">
            <TitleDescriptionSkeleton />
          </div>
          <div className="border border-gallery bg-white py-4 px-6 shadow-md font-source rounded-lg animate-pulse mb-4">
            <TitleDescriptionSkeleton />
          </div>
        </>
      );
    case 'features':
      return (
        <>
          <div className="border border-gallery bg-white shadow-md font-source rounded-lg animate-pulse">
            <FeaturesSkeleton />
          </div>
          <div className="border border-gallery bg-white shadow-md font-source rounded-lg animate-pulse">
            <FeaturesSkeleton />
          </div>
          <div className="border border-gallery bg-white shadow-md font-source rounded-lg animate-pulse">
            <FeaturesSkeleton />
          </div>
          <div className="border border-gallery bg-white shadow-md font-source rounded-lg animate-pulse">
            <FeaturesSkeleton />
          </div>
        </>
      );
    case 'benefits':
      return (
        <>
          <div className=" shadow-md font-source rounded-lg animate-pulse">
            <BenefitsSkeleton />
          </div>
          <div className=" shadow-md font-source rounded-lg animate-pulse">
            <BenefitsSkeleton />
          </div>
          <div className=" shadow-md font-source rounded-lg animate-pulse">
            <BenefitsSkeleton />
          </div>
        </>
      );
    case 'google-ads':
      return (
        <>
          <SkeletonDefault />
        </>
      );

    case 'email subject lines':
      return (
        <>
          <EmailSubjectLinesSkeleton />
          <EmailSubjectLinesSkeleton />
          <EmailSubjectLinesSkeleton />
          <EmailSubjectLinesSkeleton />
          <EmailSubjectLinesSkeleton />
        </>
      );
    case 'lead nurture email':
    case 'sales outreach':
    case 'pitch an angel':
    case 'pitch a vc':
      return (
        <>
          <EmailSkeleton />
          <EmailSkeleton />
        </>
      );

    case 'positive feedback':
      return (
        <>
          <NoteSkeleton />
        </>
      );

    case 'multiple choice quiz':
      return (
        <>
          <QuestionsResponseSkeleton />
        </>
      );
    case 'video script':
    case 'market research survey':
    case 'press release':
      return (
        <>
          <SkeletonLongText />
        </>
      );
    case 'essay outline':
    case 'recruitment interview questions':
    case 'pros & cons':
      return (
        <>
          <SkeletonMediumText />
        </>
      );
    case 'listicle + description':
    case 'paragraph generator':
    case 'describe the problem':
    case 'personal linkedin post':
    case 'company bio':
    case 'online course outline':
      return (
        <>
          <SkeletonMediumText />
          <SkeletonMediumText />
        </>
      );
    case 'keyword generator':
      return (
        <>
          <SkeletonDefault />
          <SkeletonDefault />
        </>
      );
    case 'tone changer':
    case 'content expander':
      return (
        <>
          <SkeletonDefault />
          <SkeletonDefault />
          <SkeletonDefault />
        </>
      );
    default:
      return (
        <>
          <SkeletonDefault />
          <SkeletonDefault />
          <SkeletonDefault />
          <SkeletonDefault />
          <SkeletonDefault />
        </>
      );
  }
}

export default Skeletons;
