/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import { FaEdit } from 'react-icons/fa';
import { updateContent } from '../../../templates/actions';
import { getCurrentDoc, hasStoredDoc, saveCurrentDoc } from '../../helpers';
import IWriterTitleProps from './props';

function WriterTitle({ text }: IWriterTitleProps) {
  const [isLoading, setLoading] = useState(false);
  const [defaultInput, setDefaultInput] = useState('');
  const [titleText, setTitle] = useState('');
  const [showInput, setShowInput] = useState(false);

  return showInput ? (
    <div className="flex w-full items-center space-x-2">
      <input
        value={titleText}
        onChange={(e) => setTitle(e.target.value)}
        className="text-black font-semibold text-2xl truncate border-b w-8/12"
        autoFocus
      />
      <button
        type="button"
        disabled={isLoading}
        className="text-gray-500 hover:text-ubblue-hover font-semibold text-sm"
        onClick={() => {
          setTitle(defaultInput);
          setShowInput(false);
        }}
      >
        Cancel
      </button>
      <button
        type="button"
        disabled={isLoading}
        onClick={() => {
          if (hasStoredDoc()) {
            setLoading(true);
            const { contents, ...docData } = getCurrentDoc();
            const { id } = contents[0];
            updateContent({ name: titleText, id }, () => {
              saveCurrentDoc({
                ...docData,
                contents: [
                  {
                    ...contents[0],
                    name: titleText,
                  },
                ],
              });
              setLoading(false);
              setDefaultInput(titleText);
              setShowInput(false);
            });

            return;
          }
          alert('This doc is not saved!');
        }}
        className="text-ubblue hover:text-ubblue-hover font-semibold text-sm"
      >
        {isLoading ? 'Saving' : 'Save'}
      </button>
    </div>
  ) : (
    <div
      className="flex w-full items-center space-x-2 cursor-pointer group"
      onClick={() => {
        if (!defaultInput) {
          setTitle(text);
          setDefaultInput(text);
        }
        setShowInput(true);
      }}
    >
      <h1 className="text-black font-semibold group-hover:text-ubblue text-xl sm:text-2xl truncate">
        {defaultInput || text}
      </h1>
      <FaEdit
        style={{
          minHeight: 15,
          minWidth: 15,
        }}
        className="text-gray-400 group-hover:text-ubblue ml-1"
      />
    </div>
  );
}

export default WriterTitle;
