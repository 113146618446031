import { clsx } from 'clsx';
import ITwoOptionSwitchProps from './props';

function TwoOptionSwitch({
  rightValue,
  leftText,
  rightText,
  textColor,
  textSelectedColor,
  handleChangeSwitch,
  className,
  wrapperClass,
  circleColor,
  outline,
}: ITwoOptionSwitchProps) {
  return (
    <div className={clsx(wrapperClass)}>
      <div className={clsx('flex items-center justify-center', className)}>
        <span
          role="button"
          tabIndex={0}
          onKeyDown={() => rightValue && handleChangeSwitch()}
          className={clsx(
            'mr-4 cursor-pointer',
            rightValue ? textColor : textSelectedColor,
            !rightValue && 'font-bold',
          )}
          onClick={() => rightValue && handleChangeSwitch()}
        >
          {leftText}
        </span>
        <label
          htmlFor="default-toggle-size"
          className="inline-flex relative cursor-pointer"
        >
          <input
            type="checkbox"
            checked={rightValue}
            id="default-toggle-size"
            className="sr-only peer"
            onChange={handleChangeSwitch}
          />
          <div
            className={clsx(
              "w-11 h-6 peer-focus:outline-none peer-focus:ring-1 rounded-full peer peer-checked:after:translate-x-full after:content-[''] after:absolute after:top-[2px] after:left-[2px]  after:rounded-full after:h-5 after:w-5 after:transition-all",
              outline ? 'border-2 border-silver-alto' : 'bg-usilver',
              circleColor
                ? `peer-focus:ring-${circleColor} after:bg-${circleColor}`
                : 'peer-focus:ring-ubblue after:bg-ubblue',
            )}
          />
        </label>
        <span
          role="button"
          tabIndex={0}
          onKeyDown={() => !rightValue && handleChangeSwitch()}
          className={clsx(
            'ml-4 cursor-pointer',
            rightValue ? textSelectedColor : textColor,
            rightValue && 'font-bold',
          )}
          onClick={() => !rightValue && handleChangeSwitch()}
        >
          {rightText}
        </span>
      </div>
    </div>
  );
}

export default TwoOptionSwitch;
