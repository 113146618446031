/* eslint-disable react/prop-types */
import React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AuthorRoutes from './modules/author';
import ContextProviders from './modules/context/containers/ContextProviders';
import { getStoredPersona, hasStoredPersona } from './modules/helpers';
import Layout from './modules/layout/containers/Layout';
import { getCollections } from './modules/partials/pagelayout/actions';
import { SELECTED_PERSONA } from './modules/partials/pagelayout/constants';
import usePersonalizeDetails from './modules/personalize/hooks/usePersonalizeDetails';
import ConciergePlan from './modules/plan/containers/ConciergePlan';
import SuccessPage from './modules/plan/containers/SuccessPage';
import SharedTemplate from './modules/shared/containers/SharedTemplate';
import TemplateRoutes from './modules/templates/TemplateRoutes';
import { getAvailableTemplates } from './modules/templates/actions';
// eslint-disable-next-line import/no-cycle
import WriterContainer from './modules/writer/containers/WriterContainer';
import req from './redux-setup';
import RegisterContainer from './modules/register/containers/RegisterContainer';
import Welcome from './modules/register/Welcome';
import ToolsPage from './modules/tools';
import ManagementContainer from './modules/management';
import ShortcutContainer from './modules/shortcuts/containers/ShortcutContainer';
import Plan from './modules/plan';
import Settings from './modules/Settings';
import CreditsSuccess from './modules/plan/containers/CreditsSuccess';
import SecuredUnbounceRoute from './modules/Unbounce/SecuredUnbounceRoute';
import URL2Ads from "./modules/Url2Ads";

const SavedContents = React.lazy(() =>
  import('./modules/savedcontent/containers/SavedContentPage'),
);
const pathnameWithoutLayout = ['/personalize', 'plan'];

function Empty({ children }) {
  return children;
}

function SecuredRoutes() {
  const location = useLocation();
  usePersonalizeDetails();
  const { isLoaded } = useSelector((state) => state['PERSONALIZE/DATA']);
  const Wrapper = pathnameWithoutLayout.includes(location?.pathname)
    ? Empty
    : Layout;
  React.useEffect(() => {
    // getProfiles();
    getCollections();
    getAvailableTemplates();
    if (hasStoredPersona()) {
      req.set(SELECTED_PERSONA, getStoredPersona());
    }
  }, []);
  if (!isLoaded) return null;
  return (
    <ContextProviders>
      <Switch>
        <Route path="/personalize" component={Welcome} />
        <Route path="/validate" component={RegisterContainer} />
        <Route path="/welcome" component={RegisterContainer} />
        <Route path="/plan" component={Plan} />
        <Route path="/concierge-offer" component={ConciergePlan} />
        <Route exact path="/share/:id" component={SharedTemplate} />
        <Route path="/subscriptions/success" component={SuccessPage} exact />
        <Route path="/credits/success" component={CreditsSuccess} exact />
        <Route path="/shortcut" component={ShortcutContainer} />
        <Route path="/unbounce/page" component={SecuredUnbounceRoute} />
        {/* <Route path="/sho rtcut-remix" component={ShortcutRemix} />
        <Route path="/shortcut-expand" component={ShortcutExpand} />
        <Route path="/shortcut-next" component={ShortcutNext} />
        <Route path="/shortcut-summarize" component={ShortcutSummarize} /> */}

        <Wrapper>
          <Switch>
            <Route path="/browse/writer">
              <Redirect to="/writer" />
            </Route>
            <Route path="/management" component={ManagementContainer} />
            <Route path="/browse" component={TemplateRoutes} />
            <Route path="/writer" component={WriterContainer} />
            <Route path="/url-to-ads" component={URL2Ads} />
            <Route path="/tools" component={ToolsPage} />
            <Route path="/author" component={AuthorRoutes} />
            <Route path="/saved" component={SavedContents} />
            <Route path="/settings" component={Settings} />
            <Route to="/" exact>
              <Redirect to="/browse" />
            </Route>
          </Switch>
        </Wrapper>
        <Route path="/login">
          <Redirect to="/browse" />
        </Route>
        <Route path="/register">
          <Redirect to="/browse" />
        </Route>
      </Switch>
    </ContextProviders>
  );
}

export default SecuredRoutes;
