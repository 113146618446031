import React, { useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { clsx } from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPenToSquare,
  faPlusLarge,
  faTrashCan,
  faLightbulbExclamation,
} from '@fortawesome/pro-light-svg-icons';
import Button from '../../../../common/Button';
import { IAllProjectsSectionProps, ICollectionEditObj } from './props';
import Collection from './Collection';
import IconButton from '../../../../common/IconButton';
import { updateColletion } from '../../../../common/patchInfo';
import { IDataCollectionProps } from '../props';
import Modal from '../../../../common/Modal';
import { deleteCollection } from '../../../../common/deleteInfo';
import InputField from '../../../../common/InputField';
import { setLoading } from '../../../../redux-setup/actions/loading';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import {
  removeCollection,
  setDefaultProject,
  updateCollectionName,
} from '../../../../redux-setup/actions/common';
import { useHistory } from 'react-router-dom';

function AllProjectsSection({
  onNewCollection,
  wrapperClass,
  selectedCollObj,
  setSelectedCollObj,
}: IAllProjectsSectionProps) {
  const collections = useSelector(
    (state: RootStateOrAny) => state.COLLECTIONS,
  ) as IDataCollectionProps[];
  const dispatch = useDispatch();
  const history = useHistory();
  const currentPlan =
    useSelector(
      (state: RootStateOrAny) => state['PERSONALIZE/DATA'].current_plan,
    ) || 'free';
  const [showButtons, setShowButtons] = useState(false);
  const [objEdit, setObjEdit] = useState<ICollectionEditObj | null>(null);
  const [toDeleteID, setToDeleteID] = useState<{
    id: number;
    name: string;
  } | null>(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [inputToDelete, setInputToDelete] = useState('');

  const onSubmitUpdateCollectionObj = () => {
    if (objEdit && objEdit.name.length < 1) {
      setObjEdit((obj) => (obj ? { ...obj, error: true } : null));
      return;
    }
    updateColletion({ ...objEdit })
      .then((response) => response.json())
      .then((result) => {
        if (result.data) {
          dispatch(
            updateCollectionName({
              id: result.data.collection.id,
              name: result.data.collection.name,
            }),
          );
          setObjEdit(null);
          NotificationManager.success(
            `Collection ${result.data.collection.name} updated!`,
          );
        } else {
          NotificationManager.error(result.data.message);
        }
      })
      .catch((err) => {
        NotificationManager.error(err.toString());
      });
  };

  const onClickCancelEdit = () => {
    setObjEdit(null);
  };

  const onClickShowButtons = () => {
    setShowButtons((pre) => {
      if (pre) {
        setObjEdit(null);
      }
      return !pre;
    });
  };

  const onClickDeleteCollection = () => {
    dispatch(setLoading(true));
    deleteCollection(toDeleteID?.id)
      .then((response) => response.json())
      .then((result) => {
        if (result.data) {
          dispatch(removeCollection(result.data.deleted_id));
          dispatch(setDefaultProject(result.data.default_project));
          if (result.data.deleted_id === selectedCollObj.id) {
            setSelectedCollObj(
              collections[0]?.id
                ? { id: collections[0].id, name: collections[0].name }
                : { id: 0, name: '' },
            );
          }
          NotificationManager.success(
            `Collection ${toDeleteID?.name} deleted Successfully`,
          );
          onClickCloseDeleteModal();
        } else {
          NotificationManager.error(result.message);
        }
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
        NotificationManager.error(err.toString());
      });
  };

  const onClickCloseDeleteModal = () => {
    setToDeleteID(null);
    setOpenDeleteModal(false);
    setInputToDelete('');
  };

  const onClickCollection = (id: number, name: string) => {
    setSelectedCollObj({ id, name });
  };

  const checkIfInactiveCollection = (indx: number) => {
    if (indx === 0) {
      return false;
    } else {
      if (currentPlan === 'unlimited') {
        return false;
      } else {
        return true;
      }
    }
  };
  return (
    <div className={clsx('relative', wrapperClass)}>
      <Modal isOpen={openDeleteModal} onCloseModal={onClickCloseDeleteModal}>
        <div className="text-3xl text-uorange w-full text-center">
          <FontAwesomeIcon icon={faTrashCan} />
        </div>
        <h2 className="font-barlow text-lg text-mine-shaft my-4 text-center">
          Are you sure you want to delete this collection?
        </h2>
        <p className="text-xs text-tundora text-center my-4">
          This action <span className="font-medium">CANNOT</span> be undone.
          <br />
          This will permanently delete the{' '}
          <span className="font-medium">{`${toDeleteID?.name || ''}`}</span>{' '}
          collection, projects and content generated.
        </p>
        <p className="font-source text-tundora my-4 text-center">
          Please type in{' '}
          <span className="font-semibold">{`${
            toDeleteID?.name.toUpperCase() || ''
          }`}</span>{' '}
          to confirm.
        </p>
        <InputField
          name=""
          placeholder={toDeleteID?.name.toUpperCase() || ''}
          value={inputToDelete}
          onChange={(e) => {
            const val = e.target.value;
            setInputToDelete(val);
          }}
          error={false}
          wrapperClass="my-4"
          onPaste={(e) => {
            e.preventDefault();
            setInputToDelete(e.clipboardData.getData('text').trim());
          }}
        />
        <div className="flex gap-x-4">
          <Button
            type="danger"
            onClick={onClickDeleteCollection}
            className="w-full"
            disabled={inputToDelete !== toDeleteID?.name.toUpperCase()}
          >
            Delete
          </Button>
          <Button
            type="secondary"
            onClick={onClickCloseDeleteModal}
            className="w-full"
          >
            Cancel
          </Button>
        </div>
      </Modal>
      <div className="flex w-full justify-between">
        <h3 className="text-scorpion font-semibold font-source">{`All Collections (${collections.length})`}</h3>
        <div>
          <IconButton
            onClick={onNewCollection}
            className="betterhover:hover:text-klein-blue"
            disabled={
              (currentPlan === 'free' && collections.length > 0) ||
              (currentPlan === 'essential' && collections.length > 0)
            }
          >
            <FontAwesomeIcon
              icon={faPlusLarge}
              className={
                (currentPlan === 'free' && collections.length > 0) ||
                (currentPlan === 'essential' && collections.length > 0)
                  ? 'text-ugray'
                  : 'inherit'
              }
            />
          </IconButton>
          <IconButton
            onClick={onClickShowButtons}
            className={clsx(
              'betterhover:hover:text-klein-blue',
              showButtons && 'text-ubblue',
            )}
          >
            <FontAwesomeIcon icon={faPenToSquare} />
          </IconButton>
        </div>
      </div>
      {collections.map((collection, i) => (
        <Collection
          id={collection.id}
          key={collection.id}
          name={collection.name}
          selected={collection.id === selectedCollObj.id}
          onClickCollection={onClickCollection}
          showButtons={showButtons}
          onEditMode={objEdit?.id === collection.id}
          onClickCancel={onClickCancelEdit}
          setObjEdit={setObjEdit}
          onClickEdit={() => null}
          objEdit={objEdit}
          onSubmitChange={onSubmitUpdateCollectionObj}
          onClickDeleteCollection={() => {
            setToDeleteID({ id: collection.id, name: collection.name });
            setOpenDeleteModal(true);
          }}
          inactive={checkIfInactiveCollection(i)}
          disabledDelete={collections.length < 2}
        />
      ))}
      {(currentPlan === 'free' || currentPlan === 'essential') && (
        <div className="flex mt-8">
          <FontAwesomeIcon
            icon={faLightbulbExclamation}
            className="text-uorange text-2xl"
          />
          <p className="font-source text-xs ml-2 pr-4">
            {currentPlan == 'free'
              ? 'Your Free plan only includes 1 project, and 1 Collection'
              : 'Your Essential plan only includes 3 projects, and 1 Collection'}{' '}
            <button
              className="underline text-ubblue"
              onClick={() => history.push('/plan')}
            >
              Click here
            </button>{' '}
            to upgrade and add more projects and collections.
          </p>
        </div>
      )}
    </div>
  );
}
export default AllProjectsSection;
