import React from 'react';

import Skeleton from 'react-loading-skeleton';

function SkeletonFacebookAds() {
  return (
    <div
    className="w-full md:w-7/12 mx-auto"
      style={{
        position: 'relative',
        marginBottom: 25,
      }}
    >
      <div
        className="bg-white my-4 shadow-md"
        style={{
          borderRadius: '16px',
        }}
      >
        <div className="w-full flex justify-between p-6">
          <div className="flex">
            <div className="w-full">
              <div className="overflow-hidden">
                <Skeleton circle height={50} width={50} />
              </div>
            </div>
          </div>
          <span className="px-2 text-gray-400 rounded">
            <i className="fas fa-ellipsis-h pt-2 text-lg" />
          </span>
        </div>
        <div className="p-5">
          <Skeleton count={3} />
        </div>
        <div className="w-full flex justify-center items-center bg-gray-200 h-64">
          <Skeleton />
        </div>
        <div className="w-full  justify-between p-5">
          <div className="">
            <div className="w-full  mb-4">
              <div className="pl-3">
                <h6 className="text-gray-500 text-md">
                  <Skeleton width={250} />
                </h6>
                <p className="text-lg mt-2 font-bold">
                  <Skeleton width={250} />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SkeletonFacebookAds;
