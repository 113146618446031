import dayjs from 'dayjs';
import React from 'react';
import ContentEditable from 'react-contenteditable';
import { RootStateOrAny, useSelector } from 'react-redux';
import useTemplateState from '../../../../../../hooks/useTemplateState';
import { editTemplate } from '../../../../../savedcontent/actions';
import TemplateFooter from '../../../../components/footer/TemplateFooter';
import { IPitchAVCProps } from './props';

function PitchAVCResult({
  data,
  hideFooter = false,
  onEditMode = false,
}: IPitchAVCProps) {
  const { templateValue, handleOnChange } = useTemplateState(data);
  const { name, email } = useSelector(
    (state: RootStateOrAny) => state['PERSONALIZE/DATA'],
  );
  const content = typeof templateValue.content === 'string' ? [templateValue.content] : templateValue.content;

  return (
    <div className="relative mb-4 px-px">
      <div className="border lg:border-0 border-gallery bg-white px-4 sm:px-10 pt-4 sm:pt-8 pb-4 sm:pb-10 mb-3 shadow-lg font-source rounded-lg">
        <div className="flex gap-4 flex-wrap sm:flex-nowrap items-end">
          <div className="hidden text-xl h-10 w-10 xs:flex items-center justify-center bg-varden rounded-full text-uorange">
            {name ? name?.toString()?.slice(0, 1) : 'D'}
          </div>
          <div className="w-full">
            <div className="flex gap-1 items-center flex-wrap sm:flex-nowrap">
              <h3 className="font-semibold">{name}</h3>
              <p className="text-sm text-ugray text-ellipsis overflow-hidden">
                &lt;{email}&gt;
              </p>
            </div>
            <h3 className="text-xs">{dayjs().format('hh:mm a')}</h3>
          </div>
        </div>
        <div className="ml-6 xs:ml-14 my-6 text-xs xs:text-sm md:text-base text-mine-shaft mr-4">
          {content.map((val: string, index: number) => (
            <div key={index} className="mt-4 text-mine-shaft">
              <ContentEditable
                key={index}
                html={val.replaceAll('\n', '<br />')}
                disabled={!onEditMode}
                onBlur={({ target: { innerHTML } }) =>
                  editTemplate(innerHTML, data, `content.${index}`)
                }
                onChange={(e) =>
                  handleOnChange(e.target.value, `content.${index}`)
                }
                spellCheck={false}
              />
            </div>
          ))}
        </div>
      </div>
      {!hideFooter && (
        <TemplateFooter
          data={templateValue}
          onEditMode={onEditMode}
          handleOnChange={handleOnChange}
          type="header-description"
        />
      )}
    </div>
  );
}

export default PitchAVCResult;
