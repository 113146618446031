import { v4 } from 'uuid';
import {
  expandIcon,
  remixIcon,
  nextIcon,
  templateIcon,
} from '../../common/icons';
import req from '../../redux-setup';
// eslint-disable-next-line import/no-cycle
import { WRITER_SIDEBAR } from './contants';

// eslint-disable-next-line import/prefer-default-export
export const buttonSettings = (writerMore) => (editor) => {
  editor.ui.registry.addIcon('expand', expandIcon);
  editor.ui.registry.addButton('expand', {
    // tooltip: 'Expand',
    text: 'Expand',
    icon: 'expand',
    onAction() {
      const el = document.querySelector('.tox-pop');
      if (el) {
        el?.remove();
      }
      req.set(WRITER_SIDEBAR, {
        type: 'expand',
        selected: editor?.selection?.getContent(),
        uuid: v4(),
      });
    },
  });

  editor.ui.registry.addIcon('remix', remixIcon);
  editor.ui.registry.addButton('remix', {
    // tooltip: 'Remix',
    text: 'Remix',
    icon: 'remix',
    onAction() {
      const el = document.querySelector('.tox-pop');
      if (el) {
        el?.remove();
      }
      req.set(WRITER_SIDEBAR, {
        type: 'remix',
        selected: editor?.selection?.getContent(),
        uuid: v4(),
      });
    },
  });
  editor.ui.registry.addIcon('next', nextIcon);
  editor.ui.registry.addButton('next', {
    // tooltip: 'Next Sentence',
    text: 'Next Sentence',
    icon: 'next',
    onAction: () => {
      const el = document.querySelector('.tox-pop');
      if (el) {
        el?.remove();
      }
      req.set(WRITER_SIDEBAR, {
        type: 'next',
        uuid: v4(),
        selected: editor?.selection?.getContent(),
      });
    },
  });

  editor.ui.registry.addButton('writemore', {
    text: 'Write More',
    icon: 'line',
    onAction: () => {
      const el = document.querySelector('.tox-pop');
      if (el) {
        el?.remove();
      }
      writerMore(true);
    },
  });

  editor.ui.registry.addIcon('templates', templateIcon);
  editor.ui.registry.addButton('templates', {
    text: 'Templates',
    icon: 'templates',
    onAction: () => {
      req.set(WRITER_SIDEBAR, {
        type: 'template',
        uuid: v4(),
      });
    },
  });

  editor.ui.registry.addButton('templatesButton', {
    icon: 'templates',
    tooltip: 'Add from Templates',
    text: 'Templates',
    onAction: () => {
      req.set(WRITER_SIDEBAR, {
        type: 'template',
        uuid: v4(),
      });
    },
  });

  editor.ui.registry.addButton('writemoreButton', {
    tooltip: 'Write More',
    text: 'Write More',
    icon: 'line',
    onAction: () => {
      writerMore(true);
    },
  });

  editor.ui.registry.addMenuItem('templates', {
    text: 'Templates',
    icon: 'templates',
    onAction: () => {
      req.set(WRITER_SIDEBAR, {
        type: 'template',
        uuid: v4(),
      });
    },
  });

  editor.ui.registry.addMenuItem('writemore', {
    tooltip: 'Write More',
    text: 'Write More',
    icon: 'line',
    onAction: () => {
      writerMore(true);
    },
  });
};
