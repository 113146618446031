import React from 'react';
import {
  faBolt,
  faGlobe,
  faScaleBalanced,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ContentEditable from 'react-contenteditable';
import { FaFacebookF, FaInstagramSquare, FaTwitter } from 'react-icons/fa';
import { ILandingPageResultProps } from './props';
import TemplateFooter from '../../../../components/footer/TemplateFooter';
import useTemplateState from '../../../../../../hooks/useTemplateState';

const featureIcons = [
  <FontAwesomeIcon key="globe" icon={faGlobe} />,
  <FontAwesomeIcon key="scale" icon={faScaleBalanced} />,
  <FontAwesomeIcon key="bolt" icon={faBolt} />,
];
function LandingPageResult({ data, onEditMode = false }: ILandingPageResultProps) {
  const { templateValue, handleOnChange } = useTemplateState(data);

  const { headline, description, button, benefit, features, companyName } =
    data.content;

  // landing
  const landingHeadline = (
    <h3 className="text-center text-xl sm:text-2xl mxl:text-3xl font-semibold text-black">
      <ContentEditable
        html={headline || ''}
        onChange={() => null}
        spellCheck={false}
        disabled
      />
    </h3>
  );

  const landingDescription = (
    <div className="mt-4 text-gray-600 max-w-md mx-auto text-center text-base sm:text-lg md:mt-5 mxl:text-xl md:max-w-3xl">
      <ContentEditable
        disabled
        html={description || ''}
        onChange={() => null}
        spellCheck={false}
      />
    </div>
  );

  const landingContentNoPhotos = (
    <div className="mb-4">
      <div className="flex justify-between items-center">
        <h1 className="font-source sm:text-lg xlg:text-xl text-mine-shaft">
          {companyName || '-'}
        </h1>
        <h1 className="font-source sm:text-lg xlg:text-xl text-mine-shaft">
          {button}
        </h1>
      </div>
      <div className="mt-12 flex flex-col justify-center items-center">
        {landingHeadline}
        {landingDescription}
        <div className="flex justify-center items-center gap-x-4 mt-4 w-full">
          <button
            type="button"
            className="cursor-default shadow flex items-center justify-center px-4 py-2 border border-transparent text-xs sm:text-sm xlg:text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
          >
            Get Started
          </button>
          <button
            type="button"
            className="cursor-default shadow flex items-center justify-center px-4 py-2  border border-transparent text-xs sm:text-sm xlg:text-base  font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-5"
          >
            Learn More
          </button>
        </div>
      </div>
    </div>
  );

  // features
  const featuresContent = (
    <div className="mt-16 grid grid-cols-1 md:grid-cols-3 gap-y-4 gap-x-6">
      {features &&
        features.map((feature, key) => (
          <div key={key}>
            <div className="flex items-center justify-center h-8 w-8 xlg:h-10 xlg:w-10 rounded-md bg-indigo-500 text-white">
              {featureIcons[key]}
            </div>
            <div className="mt-5">
              <dt className="text-sm sm:text-lg leading-6 font-medium text-gray-900">
                <ContentEditable
                  disabled
                  html={feature.headline || ''}
                  spellCheck={false}
                  onChange={() => null}
                />
              </dt>
              <dd className="mt-2 text-xs sm:text-sm text-gray-500">
                <ContentEditable
                  disabled
                  html={feature.description || ''}
                  spellCheck={false}
                  onChange={() => null}
                />
              </dd>
            </div>
          </div>
        ))}
    </div>
  );

  const benefitNoPhotos = (
    <div className="bg-indigo-700 mt-6">
      <div className="max-w-2xl mx-auto text-center py-8 px-4 sm:px-6 lg:px-8">
        <h2 className="text-xl xlg:text-2xl font-extrabold text-white ">
          <ContentEditable
            html={(benefit && benefit.headline) || ''}
            disabled
            spellCheck={false}
            onChange={() => null}
          />
        </h2>
        <div className="mt-4 text-base xlg:text-lg leading-6 text-indigo-200">
          <ContentEditable
            html={(benefit && benefit.description) || ''}
            disabled
            onChange={() => null}
            spellCheck={false}
          />
        </div>
        <button
          type="button"
          className="cursor-default mt-6 mx-auto shadow flex items-center justify-center px-4 py-2  border border-transparent text-xs sm:text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-5"
        >
          Learn More
        </button>
      </div>
    </div>
  );

  return (
    <div className="relative mb-4">
      <div className="bg-white py-4 px-6 mb-3 shadow-md font-source rounded-lg">
        <div className="py-6 px-8">
          {landingContentNoPhotos}
          {featuresContent}
        </div>
        {benefitNoPhotos}
        <footer className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-y-4 gap-x-8 px-6 py-8 text-sm text-gray-500 w-full justify-center">
          <ul className="flex flex-col gap-y-4">
            <li className="text-gray-300 font-semibold uppercase truncate">
              {companyName || 'My Company'}
            </li>
            <li className="overflow-hidden ellipsis">
              {description || 'Description'}
            </li>
            <li className="flex items-center">
              <FaFacebookF size={16} className="mr-2" />
              <FaInstagramSquare size={16} className="mr-2" />
              <FaTwitter size={16} className="mr-2" />
            </li>
          </ul>
          <ul className="flex flex-col gap-y-2">
            <li className="text-gray-300 font-semibold uppercase truncate">
              Solutions
            </li>
            <li>Marketing</li>
            <li>Analytics</li>
            <li>Commerce</li>
            <li>Insights</li>
          </ul>
          <ul className="flex flex-col gap-y-2">
            <li className="text-gray-300 font-semibold uppercase truncate">
              Support
            </li>
            <li>Pricing</li>
            <li>Documentation</li>
            <li>Guides</li>
            <li>API Status</li>
          </ul>
          <ul className="flex flex-col gap-y-2">
            <li className="text-gray-300 font-semibold uppercase truncate">
              Company
            </li>
            <li>About</li>
            <li>Blog</li>
            <li>Jobs</li>
            <li>Press</li>
            <li>Partners</li>
          </ul>
          <ul className="flex flex-col gap-y-2">
            <li className="text-gray-300 font-semibold uppercase truncate">
              Legal
            </li>
            <li>Claim</li>
            <li>Privacy</li>
            <li>Terms</li>
          </ul>
        </footer>
      </div>
      <TemplateFooter
        data={templateValue}
        onEditMode={onEditMode}
        handleOnChange={handleOnChange}
        type="landing-page"
      />
    </div>
  );
}

export default LandingPageResult;
