import React from 'react';
import { clsx } from 'clsx'
import { NavLink, useRouteMatch } from 'react-router-dom';
import { RootStateOrAny, useSelector } from 'react-redux';
import { isFramed } from '../../helpers';

function SettingsTab() {
  const { url } = useRouteMatch();
  const { isPaid, is_unlimited, payment_details: { provider } } = useSelector(
    (state: RootStateOrAny) => state['PERSONALIZE/DATA'],
  );
  const isComp = (provider === 'Free') && isPaid && is_unlimited;

  return (
    <div className="border-b border-gray-200 dark:border-gray-700">
      <ul className="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">
        <li className="mr-2">
          <NavLink
            to={`${url}/personal`}
            className={(isActive) => {
              return clsx(
              'inline-flex p-4 rounded-t-lg border-b-2 group',
              !isActive
                ? 'hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 border-transparent'
                : 'text-blue-600 border-blue-600 active dark:text-blue-500 dark:border-blue-500')
            }}
          >
            Personal
          </NavLink>
        </li>
        <li className="mr-2">
          <NavLink
            to={`${url}/projects`}
            className={(isActive) => {
              return clsx(
              'inline-flex p-4 rounded-t-lg border-b-2 group',
              !isActive
                ? 'hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 border-transparent'
                : 'text-blue-600 border-blue-600 active dark:text-blue-500 dark:border-blue-500')
            }}
          >
            Projects
          </NavLink>
        </li>
        {(!isComp || isFramed()) && (<li className="mr-2">
          <NavLink
            to={`${url}/billing`}
            className={(isActive) => {
              return clsx(
              'inline-flex p-4 rounded-t-lg border-b-2 group',
              !isActive
                ? 'hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 border-transparent'
                : 'text-blue-600 border-blue-600 active dark:text-blue-500 dark:border-blue-500')
            }}
          >
            Billing
          </NavLink>
        </li>)}
        {/* <li>
          <NavLink
            to={`${url}/api`}
            className={(isActive) => {
              return clsx(
              'inline-flex p-4 rounded-t-lg border-b-2 group',
              !isActive
                ? 'hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 border-transparent'
                : 'text-blue-600 border-blue-600 active dark:text-blue-500 dark:border-blue-500')
            }}
          >
            API
          </NavLink>
        </li> */}
      </ul>
    </div>
  );
}

export default SettingsTab;
