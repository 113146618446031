import React, { Component, useEffect, useRef, useState } from "react";
import * as Yup from 'yup';
import { clsx } from "clsx";
import { Field, Form, Formik, FormikProps } from "formik";
import { isEmpty } from "lodash";
import { NotificationManager } from "react-notifications";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import Button from "../../common/Button";
import Language from "../../common/Language";
import SelectProject from "../../common/Profile/SelectProject";
import { IAdObj, IInitialValuesObj } from "../../common/props";
import { getFineTuneVariable, setFineTuneVariable } from "../../config/localStorage";
import { setOpenUpgradeModal } from "../../redux-setup/actions/upgradeModal";
import Loader from "../common/Loader/Loader";
import { getGeneratorMultiAds } from "../partials/pagelayout/actions";
import { PageLayoutContext } from "../partials/pagelayout/hooks";
import { IProjectDataProps } from "../Settings/ProjectsTab/props";
import CreditsBar from "../templates/components/CreditsBar";
import Skeletons from "../templates/components/Skeletons";
import { TAB_PROMPT, TAB_RESULTS } from "../partials/pagelayout/constants";
import { useLocation } from "react-router-dom";
import { useCurrentTemplate } from "../templates/hooks";
import { InputStandard } from "../common/Formik/FormInputs";
import { InlineLoader } from "@unbounce/ui-components/esm";
import { resultWebsite } from "../../common/functions";
import TemplateResult from "../../common/TemplateResult";

class ScrollToTopOnMount extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return null;
  }
}

function URL2Ads() {
  const { isPaid } = useSelector(
    (state: RootStateOrAny) => state['PERSONALIZE/DATA'],
  );
  const location = useLocation();
  const dispatch = useDispatch();
  const templateData = useCurrentTemplate();

  const template = templateData?.type || '';
  const templateName = templateData?.title || '';
  const title = templateData?.title || '';
  const templateCode = templateData?.template || '';

  const { remaining_credits, pack_credits, is_unlimited: isUnlimited } = useSelector(
    (state: RootStateOrAny) => state['PERSONALIZE/DATA'],
  );
  const loading = useSelector((state: RootStateOrAny) => state.loading);
  const buttonStatus = useSelector(
    (state: RootStateOrAny) => state.BUTTON_STATUS,
  );
  const multiAdsValues = useSelector(
    (state: RootStateOrAny) => state.multiAds?.contents,
  );
  const formRef = useRef<FormikProps<IInitialValuesObj>>(null);

  const selectedProfile = useSelector(
    (state: RootStateOrAny) => state['PROJECT'],
  );
  const profileId = selectedProfile.id || 0;
  const [project, setProject] = useState<IProjectDataProps | null>(null);

  const [viewCustomizeForm, setCustomizeForm] = useState(true);
  const [selectedTab, setSelectedTab] = useState(TAB_PROMPT);
  

  const validationSchema = 
    Yup.object().shape({
      url: Yup.string()
      .trim()
      .required(`The field URL is required.`)
      .max(250)
      .matches(/^(https?:\/\/)?(www\.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)|(https?:\/\/)?(www\.)?(?!ww)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,10}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/, 'Is not in correct format')
      .label('Website / Landing Page URL')
    });

    const initialValues = {
      'url': getFineTuneVariable('AdsFromURLUrl'),
    } as IInitialValuesObj;

  // GENERATE CONTENT FUNCTION
  const generateContent = (values?: IInitialValuesObj) => {
    if (buttonStatus === 'loading') {
      return;
    }
    if (!profileId) {
      NotificationManager.error(
        'Please select the project you will generate content for.',
      );
      return;
    }

    if (!isUnlimited && (remaining_credits + pack_credits) < 5) {
      dispatch(setOpenUpgradeModal(true));
      return;
    }

    const payload = { url: resultWebsite(values!.url) };
    getGeneratorMultiAds(
      profileId,
      payload,
      () => {
        setSelectedTab(TAB_RESULTS);
      },
      (msg: string) => {
        NotificationManager.error(msg);
      },
    );
  };

  // FORM SUBMIT ENTER OR BUTTON
  const handleFormSubmit = (values: IInitialValuesObj) => {
    setFineTuneVariable('AdsFromURLUrl', values.url);
    generateContent(values);
  };

  // BUTTON WRITE FOR ME / WRITE MORE
  const handleCreate = () => {
    if (formRef && formRef.current) {
      formRef.current?.handleSubmit();
    } else {
      // NO FORM TO SUBMIT so we generate content
      generateContent();
    }
  };

  useEffect(() => {
    if (selectedProfile.id > 0) {
      setProject(selectedProfile);
    }
  }, [selectedProfile]);

  return (
    <PageLayoutContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        formRef,
        template,
        templateCode: templateCode || title,
        templateTitle: title,
        viewCustomizeForm,
        setCustomizeForm,
        isTools: false,
      }}
    >
      <div className="flex flex-wrap justify-between items-center w-full gap-2 sm:mb-4 lg:h-16 pl-10">
        <h2 className="text-3xl font-barlow font-medium">
          URL to ads
        </h2>
        <div className="flex flex-wrap gap-4 justify-center sm:justify-between w-full lg:w-auto pr-10">
          {!isUnlimited && (
            <div className="h-6 w-64 mb-4 md:mb-0 group">
              <CreditsBar />
            </div>
          )}
          {buttonStatus === 'loading' ? (
            <InlineLoader className="text-3xl" />
          ) : (
            <Button type="primary" onClick={handleCreate}>
              Write For Me
              <span className={clsx(isUnlimited && 'hidden')}>
                {' '}
                (5 credits)
                </span>
            </Button>
          )}
        </div>
      </div>
      <div className="lg:grid lg:grid-cols-12 lg:grid-rows-[32px_calc(100vh_-_152px)] pl-10 pr-10 lg:pr-0">
        <div className="-mb-px pt-4 sm:pt-0 flex space-x-8 border-b border-gray-200 lg:grid lg:grid-cols-12 lg:space-x-0 lg:col-span-12">
          <button
            className={clsx(
              'pb-2 px-1 border-b-2 font-medium text-sm w-min col-span-5',
              selectedTab === TAB_PROMPT
                ? 'border-ubblue text-ubblue'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
              'lg:border-ubblue lg:text-ubblue',
            )}
            onClick={() => setSelectedTab(TAB_PROMPT)}
          >
            Prompt
          </button>
          <button
            className={clsx(
              'pb-2 px-1 border-b-2 font-medium text-sm w-min',
              selectedTab === TAB_RESULTS
                ? 'border-ubblue text-ubblue'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
              !isEmpty(multiAdsValues) || buttonStatus === 'loading'
                ? 'visible'
                : 'invisible',
              'lg:border-ubblue lg:text-ubblue lg:visible',
            )}
            onClick={() => setSelectedTab(TAB_RESULTS)}
          >
            Results
          </button>
        </div>
        <div
          className={clsx(
            selectedTab === TAB_PROMPT ? 'block' : 'hidden',
            'lg:block lg:pr-10 pt-4 col-span-5 overflow-y-auto',
          )}
        >
          <div className="">
            <div className="lg:grid lg:grid-cols-2 gap-4">
              <Language />
              <SelectProject />
            </div>
            <div className="mt-4">
              <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleFormSubmit}
              innerRef={formRef}
              validateOnBlur={false}
              validateOnChange={false}
              >
              {({ values }) => (
                <Form className="flex flex-wrap justify-between gap-4">
                    <Field
                    component={InputStandard}
                    name='url'
                    label='Website / Landing Page URL'
                    value={values}
                    placeholder="unbounce.com"
                    wrapperClass="w-full order-2"
                    maxCounter={250}
                  />
                  <button type="submit" className="hidden" />
                </Form>
              )}
              </Formik>
            </div>
            <div className="my-8" />
            {buttonStatus === 'loading' ? (
              <InlineLoader className="text-3xl" />
            ) : (
              <Button type="primary" onClick={handleCreate}>
                Write For Me
                <span className={clsx(isUnlimited && 'hidden')}>
                {' '}
                (5 credits)
                </span>
              </Button>
            )}
          </div>
        </div>
        <div
          className={clsx(
            selectedTab === TAB_RESULTS ? 'block' : 'hidden',
            'lg:block lg:bg-pampas pt-4 sm:p-6 md:p-10 col-span-7 overflow-y-auto',
          )}
        >
          <div>
            {buttonStatus === 'loading' && (
              <Skeletons templateName={templateName} />
            )}
            {multiAdsValues &&
            (<div className="w-full">
              {multiAdsValues.map((cont : IAdObj) => (
                <TemplateResult key={cont.id} data={cont} companyName={cont.companyName} website={cont.website} onEditMode={false} />
              ))}
              {!!multiAdsValues.length && (<div className="w-full text-center">
                <span className="mt-6 mx-auto font-source text-sm text-mine-shaft">
                  Logos provided by <a className="font-source text-ubblue" href="https://clearbit.com/"> Clearbit</a>
                  </span>
              </div>)}
              </div>
            )}
          </div>
        </div>
      </div>
      <ScrollToTopOnMount />
      {loading && <Loader />}
    </PageLayoutContext.Provider>
  );
}

export default URL2Ads;
