import React from 'react';
import Select from 'react-select';
import { clsx } from 'clsx';
import { ISelectFormProps } from './props';

function SelectForm({
  wrapperClass,
  label,
  options,
  onChangeSelect,
  required,
  defaultValue,
  placeholder,
  labelClass,
}: ISelectFormProps) {
  return (
    <div className={clsx('mt-4 font-source', wrapperClass)}>
      {label && (
        <label
          className={clsx(labelClass || 'text-sm font-medium text-ugray')}
        >{`${label}${required ? '*' : ''}`}</label>
      )}
      <Select
        options={options}
        onChange={(nv) => onChangeSelect(nv)}
        className="font-source"
        styles={{
          menu: (provided) => ({
            ...provided,
            zIndex: 2,
            fontFamily: 'Source Sans Pro',
            fontSize: '14px',
          }),
          control: (provided) => ({
            ...provided,
            borderColor: '#dedede',
            borderRadius: '.25rem',
            minHeight: '32px',
            fontFamily: 'Source Sans Pro',
            fontSize: '14px',
          }),
          input: (provided) => ({
            ...provided,
            margin: 0,
            padding: 0,
            fontSize: '14px',
          }),
          singleValue: (provided) => ({
            ...provided,
            fontFamily: `${'Source Sans Pro'}, sans-serif`,
          }),
          option: (provided) => ({
            ...provided,
            fontFamily: `${'Source Sans Pro'}, sans-serif`,
          }),
          dropdownIndicator: (provided) => ({ ...provided, padding: '6px' }),
        }}
        defaultValue={
          defaultValue
            ? defaultValue
            : { label: placeholder ? placeholder : 'Select...', value: '' }
        }
      />
    </div>
  );
}

export default SelectForm;
