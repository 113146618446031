import React, { useState } from 'react';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { login } from '../../../auth/actions';
import { storeStorage } from '../../../helpers';
import { AlertMarker } from '../../../common/Alerts';
import { Input } from '../../../common/Formik/FormInputs';
import { Link } from 'react-router-dom';
import { IUBLoginFormProps } from './props';
import { Button } from '@unbounce/ui-components/esm';

const rqdmsg = 'This field is required.';
const validateForm = Yup.object().shape({
  password: Yup.string().required(rqdmsg).label('Password'),
});

function UBLoginForm({ email }: IUBLoginFormProps) {
  const [showPassword, setshowPassword] = useState(false);
  return (
    <>
      <h2 className="font-source text-xl font-semibold text-mine-shaft">
        <span className="font-normal font-source">Welcome </span>
        {email}
      </h2>
      <Formik
        validationSchema={validateForm}
        initialValues={{
          email: '',
          password: '',
        }}
        onSubmit={(values, { setSubmitting }) => {
          login(
            { email, password: values.password },
            () => {
              storeStorage('expandProfile', true);
              // history.push(redirect || '/templates');
            },
            () => setSubmitting(false),
          );
        }}
      >
        {({ values, handleSubmit, isSubmitting }) => (
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
          <form
            onSubmit={handleSubmit}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSubmit();
              }
            }}
          >
            <AlertMarker name="login" />
            <Field
              label="Smart Copy Password"
              component={Input}
              name="password"
              type={showPassword ? 'text' : 'password'}
              isPassword={true}
              show={showPassword}
              changeShow={setshowPassword}
              value={values.password}
              placeholder="Password"
              wrapperClass="mt-4 font-bold text-black font-source"
            />
            <Link
              to="forgot-password"
              className="text-ubblue hover:text-ubblue-hover"
            >
              Forgot your password?
            </Link>
            <Button
              primary
              fullWidth
              name="Continue"
              className="mt-8"
              large
              onClick={() => {
                handleSubmit();
              }}
              disabled={isSubmitting}
            >
              Log In
            </Button>
          </form>
        )}
      </Formik>
    </>
  );
}

export default UBLoginForm;
