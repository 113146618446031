import {
  getDefaultProject,
  getProjectsFromCollection,
  getSingleProject,
} from '../../common/getInfo';
import { SET_PROJECT, SET_PROJECTS_IN_COLLECTION } from './common';
import { setLoading } from './loading';

const getProject = (id) => (dispatch) => {
  dispatch(setLoading(true));
  getSingleProject(id)
    .then((response) => response.json())
    .then((result) => {
      if (result.data) {
        dispatch({
          type: SET_PROJECT,
          payload: result.data,
        });
      }
      dispatch(setLoading(false));
    })
    .catch(() => dispatch(setLoading(false)));
};

const getProjectDefault = () => (dispatch) => {
  dispatch(setLoading(true));
  getDefaultProject()
    .then((response) => response.json())
    .then((result) => {
      if (result.data) {
        dispatch({
          type: SET_PROJECT,
          payload: result.data,
        });
      }
      dispatch(setLoading(false));
    })
    .catch(() => dispatch(setLoading(false)));
};

const getAllProjectsFromCollectionID = (collID) => (dispatch) => {
  dispatch(setLoading(true));
  getProjectsFromCollection(collID)
    .then((response) => response.json())
    .then((result) => {
      if (result.data) {
        dispatch({
          type: SET_PROJECTS_IN_COLLECTION,
          payload: {
            projects: result.data.projects,
            collectionID: result.data.collection_id,
          },
        });
      }
      dispatch(setLoading(false));
    })
    .catch(() => dispatch(setLoading(false)));
};

export { getProject, getProjectDefault, getAllProjectsFromCollectionID };
