/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable camelcase */
import { displayError } from '../../config/API';
import { addDataLayer } from '../helpers';
import req from '../../redux-setup';

import { PERSONALIZE } from './constants';

export const getProfile = () => {
  req
    .get({
      url: '/personalize',
    })
    .then(({ data }) => {
      const isPaid = data?.payment_details?.provider
        && data?.payment_details.provider !== 'None';
      const {
        email,
        name,
        remaining_credits,
        pack_credits,
        generations_used,
        last_generation,
        last_credited,
        user_id,
        stripe_id,
        org_type,
      } = data;
      addDataLayer({
        event: 'profile-info',
        user_id,
        stripe_id,
        name,
        email,
        org_type,
        paying_customer: isPaid,
        remaining_credits,
        pack_credits,
        generations_used,
        last_generation,
        last_credited,
      });
      req.set(PERSONALIZE, {
        isLoaded: true,
        company: data.company_name,
        isEmailValidated: !!data?.email_validated,
        isPaid,
        isDone: !!data.description,
        ...data,
      });
    });
};

export const updateProfile = (
  payload,
  saveLocalFirst = false,
  callback = () => { },
  updatePayload = {},
  markerName = '',
) => {
  if (saveLocalFirst) {
    req.set(PERSONALIZE, (state) => ({
      ...state[PERSONALIZE],
      ...payload,
    }));
  }

  req
    .post({
      url: '/personalize',
      payload,
      useLoading: !saveLocalFirst,
    })
    .then(({ data }) => {
      callback();
      if (!saveLocalFirst) {
        req.set(PERSONALIZE, (state) => ({
          ...state[PERSONALIZE],
          ...data,
          ...updatePayload,
        }));
      }
    })
    .catch((err) => {
      if (markerName) displayError(markerName, err);
    });
};
