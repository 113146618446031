import React, { useState } from 'react';
import { NotificationManager } from 'react-notifications';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tippy from '@tippyjs/react';
import { faThumbsDown, faThumbsUp } from '@fortawesome/pro-light-svg-icons';
import { InlineLoader } from '@unbounce/ui-components/esm';
import InformationContext from '../../../../context/Personalize';

function TemplateFeedback({ data }) {
  const { sendFeedback } = React.useContext(InformationContext);
  const [thumbsWait, setThumbsWait] = useState(false);
  const onThumbsSubmit = async (feedback) => {
    const payload = {
      feedback_id: data.id,
      is_good: feedback,
    };
    await sendFeedback(payload);
    NotificationManager.success('Thank you for your feedback');
    setThumbsWait(false);
  };

  return thumbsWait ? (
    <motion.div
      initial={{ opacity: 0, y: -10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -10 }}
      className="-mt-1.5"
    >
      <InlineLoader />
    </motion.div>
  ) : (
    <>
      <Tippy
        content={
          <span>
            Provide <span className="font-medium text-shamrock">positive</span>{' '}
            feedback on content.
          </span>
        }
        theme="light-border"
      >
        <FontAwesomeIcon
          icon={faThumbsUp}
          onClick={() => {
            setThumbsWait(true);
            onThumbsSubmit(true);
          }}
          className="betterhover:hover:text-shamrock"
        />
      </Tippy>
      <Tippy
        content={
          <span>
            Provide <span className="font-medium text-red-500">negative</span>{' '}
            feedback on content.
          </span>
        }
        theme="light-border"
      >
        <FontAwesomeIcon
          icon={faThumbsDown}
          onClick={() => {
            setThumbsWait(true);
            onThumbsSubmit(false);
          }}
          className="betterhover:hover:text-amaranth ml-2"
        />
      </Tippy>
    </>
  );
}

TemplateFeedback.defaultProps = {
  data: {},
};

TemplateFeedback.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.objectOf(PropTypes.any),
};

export default TemplateFeedback;
