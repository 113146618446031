import dayjs from 'dayjs';
import React from 'react';
import ContentEditable from 'react-contenteditable';
import { RootStateOrAny, useSelector } from 'react-redux';
import useTemplateState from '../../../../../../hooks/useTemplateState';
import { editTemplate } from '../../../../../savedcontent/actions';
import TemplateFooter from '../../../../components/footer/TemplateFooter';
import { IStringTemplateResultProps } from '../StringTemplateResult/props';

function EmailSubjectResult({
  data,
  onEditMode = false,
  hideFooter = false,
}: IStringTemplateResultProps) {
  const { templateValue, handleOnChange } = useTemplateState(data);
  const { name, email } = useSelector(
    (state: RootStateOrAny) => state['PERSONALIZE/DATA'],
  );

  return (
    <div className="relative mb-4 px-px">
      <div className="border lg:border-0 border-gallery bg-white px-10 pt-8 pb-10 mb-3 shadow-lg font-source rounded-lg">
        <div className="font-source text-xl font-semibold mb-6 text-mine-shaft">
          <ContentEditable
            html={templateValue.content.replaceAll('\n', '<br>')}
            disabled={!onEditMode}
            onBlur={({ target: { innerHTML } }) =>
              editTemplate(innerHTML, data, 'content')
            }
            onChange={(e) => handleOnChange(e.target.value, 'content')}
            spellCheck={false}
            // onCopy={() => {
            //   sendCopied({
            //     id: data.id,
            //     value: document.getSelection().toString(),
            //   });
            // }}
          />
        </div>
        <div className="flex gap-4 items-end">
          <div className="text-xl h-10 w-10 flex items-center justify-center bg-varden rounded-full text-uorange">
            {name ? name?.toString()?.slice(0, 1) : 'J'}
          </div>
          <div>
            <div className="flex gap-1 items-center">
              <h3 className="font-semibold">{name}</h3>
              <span className="text-sm text-ugray">&lt;{email}&gt;</span>
            </div>
            <h3 className="text-xs">{dayjs().format('hh:mm a')}</h3>
          </div>
        </div>
      </div>
      {!hideFooter && (
        <TemplateFooter
          data={templateValue}
          onEditMode={onEditMode}
          handleOnChange={handleOnChange}
          type="header-description"
        />
      )}
    </div>
  );
}

export default EmailSubjectResult;
