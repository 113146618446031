/* eslint-disable default-param-last */
import React from 'react';

import { clsx } from 'clsx';
import PropTypes from 'prop-types';
import { render } from 'react-dom';

export function AlertMarker({ name, className }) {
  return <div className={clsx('w-full', className)} id={`alert-marker-${name}`} />;
}
AlertMarker.defaultProps = {
  className: '',
};
AlertMarker.propTypes = {
  name: PropTypes.oneOfType([PropTypes.node, PropTypes.object]).isRequired,
  className: PropTypes.string,
};

export const destroyAlert = (name = '') => {
  const marker = document.querySelector(`#alert-marker-${name}`);
  if (!marker) {
    return;
  }
  render('', marker);
};

const renderAlert = (
  name = '',
  message = '',
  type = 'success',
  duration = 5000,
  doNotDestroy,
) => {
  const marker = document.querySelector(`#alert-marker-${name}`);
  if (!marker) return;

  const alertView = (
    <div
      className={clsx(
        'rounded-md p-2 text-sm my-2 flex justify-between items-center space-x-2 w-full',
        type === 'success' && 'bg-frosted-mint text-jungle-green',
        type === 'warning' && 'bg-yellow-100 text-yellow-600',
        type === 'error' && 'bg-red-100 text-red-600',
      )}
    >
      <h1 className="text-sm w-11/12">{message}</h1>
      <button
        type="button"
        className={clsx(
          'p-1 rounded-full w-6 h-6 flex items-center justify-center',
          type === 'success' && 'bg-shamrock ',
          type === 'warning' && 'bg-yellow-300 ',
          type === 'error' && 'bg-red-300 ',
        )}
        onClick={() => destroyAlert(name)}
      >
        <i className="fa fa-times" />
      </button>
    </div>
  );

  render(alertView, marker);
  if (!doNotDestroy) {
    setTimeout(() => {
      destroyAlert(name);
    }, duration);
  }
};

export const createAlert = {
  success: (name = '', message = '', duration = 5000, doNotDestroy = false) => {
    renderAlert(name, message, 'success', duration, doNotDestroy);
  },
  error: (name = '', message = '', duration = 5000, doNotDestroy = false) => {
    renderAlert(name, message, 'error', duration, doNotDestroy);
  },
  warning: (name = '', message = '', duration = 5000, doNotDestroy = false) => {
    renderAlert(name, message, 'warning', duration, doNotDestroy);
  },
};
