import { useLocation } from 'react-router-dom';

export const useGetTemplateByRoute = () => {
  const location = useLocation();

  const templateByRoutes = {
    '/browse/google-ads': 'google-ads',
    '/browse/landing-pages': 'landing-pages',
    '/browse/tagline': 'taglines',
    '/browse/product-descriptions': 'product-descriptions',
    '/browse/facebook-ads': 'facebook-ads',
    '/browse/header-description': 'header-description',
    '/browse/features': 'features',
    '/browse/benefits': 'benefits',
    '/browse/cold-email': 'cold-email',
    '/browse/vc-email': 'vc-email',
    '/browse/angel-email': 'angel-email',
    '/browse/content-expander': 'content-expander',
    '/browse/blog-ideas': 'blog-ideas',
    '/browse/remix': 'remixer',
    '/writer': 'document',
  };

  const titleByRoutes = {
    '/browse/google-ads': 'Google Ads',
    '/browse/landing-pages': 'Landing Page',
    '/browse/tagline': 'Taglines',
    '/browse/product-descriptions': 'Product Description',
    '/browse/facebook-ads': 'Facebook Ads',
    '/browse/header-description': 'Header + Description',
    '/browse/features': 'Features',
    '/browse/benefits': 'Benefits',
    '/browse/content-expander': 'Content Expander',
    '/browse/blog-ideas': 'Blog Ideas',
    '/browse/tiktok-ideas': 'TikTok Video Ideas',
    '/browse/cold-email': 'Cold Email',
    '/browse/vc-email': 'VC Email',
    '/browse/angel-email': 'Angel Email',
    '/browse/remix': 'Remix',
    '/writer': 'Writer',
  };

  return {
    template: templateByRoutes[location.pathname] || '',
    title: titleByRoutes[location.pathname] || '',
  };
};

export const getTemplateTypeByName = (template) => {
  const code = {
    'Google Ads': 'google-ads',
    'Landing Page': 'landing-pages',
    Taglines: 'taglines',
    'Product Description': 'product-descriptions',
    'Facebook Ads': 'facebook-ads',
    'Header + Description': 'header-description',
    Features: 'features',
    Benefits: 'benefits',
    'Content Expander': 'content-expander',
    'Blog Ideas': 'blog-ideas',
    'TikTok Video Ideas': 'tiktok-ideas',
    Remix: 'remixer',
    'Cold Email': 'cold-email',
    'VC Email': 'vc-email',
    'Angel Email': 'angel-email',
    Writer: 'document',
  };

  return {
    template: code[template] || '',
  };
};
