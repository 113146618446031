import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faArrowRightFromBracket, faBookmark, faCircleQuestion, faFontAwesome, faGear, faLayerGroup, faPenToSquare, faPuzzlePiece, faRectangleAd } from '@fortawesome/pro-light-svg-icons';

export const WriterLogo = (
  <div className='mr-3 sm:mr-0 text-xl w-fit'><FontAwesomeIcon icon={faPenToSquare} className=""/></div>
);

export const SavedLogo = (
  <div className='mr-3 sm:mr-0 text-xl w-fit'><FontAwesomeIcon icon={faBookmark} className=""/></div>
);

export const TemplatesLogo = (
<div className='mr-3 sm:mr-0 text-xl w-fit'><FontAwesomeIcon icon={faLayerGroup} className=""/></div>
);

export const ToolsLogo = (
  <div className='mr-3 sm:mr-0 text-xl w-fit'><FontAwesomeIcon icon={faPuzzlePiece} className=""/></div>
);

export const UserIcon = (
  <div className='mr-3 sm:mr-0 text-xl w-fit'><FontAwesomeIcon icon={faGear} className=""/></div>
);

export const HelpIcon = (
  <div className='mr-3 sm:mr-0 text-xl w-fit'><FontAwesomeIcon icon={faCircleQuestion} className=""/></div>
);

export const LogoutIcon = (
  <div className='mr-3 sm:mr-0 text-xl w-fit'><FontAwesomeIcon icon={faArrowRightFromBracket} className=""/></div>
);

export const Flag = (
  <div className='mr-3 sm:mr-0 text-xl w-fit'><FontAwesomeIcon icon={faFontAwesome} className=""/></div>
);

export const AdsIcon = (
  <div className='mr-3 sm:mr-0 text-xl w-fit'><FontAwesomeIcon icon={faRectangleAd} /></div>
);
