import React, { useEffect, useState } from 'react';
import { faArchive } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import Select, { components, GroupHeadingProps } from 'react-select';
import { clsx } from 'clsx';
import { ICollectionDataProps } from '../../../modules/Settings/ProjectsTab/props';
import {
  IDefaultSelect,
  IDefaultSelectDisabled,
  IGroupOptionsDisabledObj,
  IGroupOptionsObj,
} from '../../props';
import { getProject } from '../../../redux-setup/actions/project';
import {
  updateProjectDefault,
  clearStoredTemplates,
  clearStoredMultiads,
} from '../../../modules/partials/pagelayout/actions';
import { ISelectProjectProps } from './props';
import { useApiData } from '../../../redux-setup';
import { V3_TEMPLATES } from '../../../modules/templates/actions';

function SelectProject({ label, className }: ISelectProjectProps) {
  const dispatch = useDispatch();
  const collections = useSelector((state: RootStateOrAny) => state.COLLECTIONS);
  const v3Templates = useApiData(V3_TEMPLATES);
  const { project_default: defaultProject } = useSelector(
    (state: RootStateOrAny) => state['PERSONALIZE/DATA'],
  );
  const [groupOptions, setGroupOptions] = useState<IGroupOptionsObj[]>([]);
  const [optValue, setOptValue] = useState({
    value: '',
    label: 'Select...',
  });

  useEffect(() => {
    if (collections.length) {
      const newOptions = [] as IGroupOptionsDisabledObj[];
      collections.forEach((coll: ICollectionDataProps) => {
        const options = [] as IDefaultSelectDisabled[];
        coll.projects.forEach((prj) => {
          if (prj.id === defaultProject && !prj.inactive) {
            setOptValue({
              value: prj.id.toString(),
              label: prj.name,
            });
          }
          options.push({
            value: prj.id.toString(),
            label: prj.name,
            isDisabled: prj.inactive,
          });
        });
        newOptions.push({
          label: coll.name,
          options,
        });
      });
      setGroupOptions(newOptions);
    }
  }, [collections, defaultProject]);

  const GroupHeading = (props: GroupHeadingProps<IDefaultSelect, false>) => (
    <div className=" mx-4 flex">
      <FontAwesomeIcon icon={faArchive} />
      <components.GroupHeading {...props} />
    </div>
  );

  useEffect(() => {
    if (parseInt(optValue.value, 10) || 0 > 0) {
      dispatch(getProject(optValue.value));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optValue]);

  return (
    <div className={clsx(className || 'mt-4')}>
      <label className="font-source text-ugray text-sm font-semibold">
        {label || 'Writing for'}
      </label>
      <Select
        components={{ GroupHeading }}
        options={groupOptions}
        onChange={(newv) => {
          if (newv) {
            updateProjectDefault(newv.value);
            clearStoredTemplates(v3Templates);
            clearStoredMultiads();
            setOptValue(newv);
          }
        }}
        styles={{
          menu: (provided) => ({
            ...provided,
            zIndex: 2,
            fontFamily: `${'Source Sans Pro'}, sans-serif`,
            fontSize: '14px',
          }),
          control: (provided) => ({
            ...provided,
            borderColor: '#dedede',
            borderRadius: '.25rem',
            minHeight: '32px',
            fontFamily: `${'Source Sans Pro'}, sans-serif`,
            fontSize: '14px',
          }),
          input: (provided) => ({
            ...provided,
            margin: 0,
            padding: 0,
            fontFamily: `${'Source Sans Pro'}, sans-serif`,
            fontSize: '14px',
          }),
          dropdownIndicator: (provided) => ({ ...provided, padding: '6px' }),
          option: (provided) => ({
            ...provided,
            marginLeft: '25px',
            width: 'calc(100% - 25px)',
            fontFamily: `${'Source Sans Pro'}, sans-serif`,
          }),
          singleValue: (provided) => ({
            ...provided,
            fontFamily: `${'Source Sans Pro'}, sans-serif`,
          }),
        }}
        value={optValue}
      />
    </div>
  );
}

export default SelectProject;
