import React from 'react';
import { Field } from 'formik';
import { ICustomizeFormFieldProps } from './props';
import {
  InputStandard,
  KeywordsStandard,
  LengthSelectStandard,
  StyleTextSelectStandard,
  TextAreaStandard,
  ToneSelectStandard,
} from '../../../modules/common/Formik/FormInputs';

function CustomizeFormField({
  value,
  title,
  placeholder,
  type,
  name,
  maxCounter,
}: ICustomizeFormFieldProps) {
  const rowsforText = (formType: string) => {
    switch (formType) {
      case 'shortText':
        return 3;
      case 'mediumText':
        return 5;
      case 'longText':
        return 8;
      case 'extraLongText':
        return 11;
      default:
        return 4;
    }
  };

  switch (type) {
    case 'textarea':
    case 'shortText':
    case 'mediumText':
    case 'longText':
    case 'extraLongText':
      return (
        <Field
          component={TextAreaStandard}
          name={name}
          label={title}
          value={value}
          placeholder={placeholder}
          wrapperClass="w-full order-1"
          maxCounter={maxCounter}
          rows={rowsforText(type)}
        />
      );
    case 'tone':
      return (
        <Field
          component={ToneSelectStandard}
          name={name}
          label={title}
          value={value}
          placeholder={placeholder}
          wrapperClass="w-5/12 grow order-11"
        />
      );
    case 'textStyle':
      return (
        <Field
          component={StyleTextSelectStandard}
          name={name}
          label={title}
          value={value}
          placeholder={placeholder}
          wrapperClass="w-5/12 grow order-11"
        />
      );
    case 'length':
      return (
        <Field
          component={LengthSelectStandard}
          name={name}
          label={title}
          value={value}
          placeholder={placeholder}
          wrapperClass="w-5/12 grow order-12"
        />
      );
    case 'keywords':
      return (
        <Field
          component={KeywordsStandard}
          name={name}
          label={title}
          value={value}
          placeholder={placeholder}
          wrapperClass="w-5/12 grow order-12"
          maxCounter={maxCounter}
        />
      );
    case 'string':
    case 'input':
    default:
      return (
        <Field
          component={InputStandard}
          name={name}
          label={title}
          value={value}
          placeholder={placeholder}
          wrapperClass="w-full order-2"
          maxCounter={maxCounter}
        />
      );
  }
}

export default CustomizeFormField;
