/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React, { useState, useRef, useEffect } from 'react';
import { Button } from '@unbounce/ui-components';
import { loadStripe } from '@stripe/stripe-js';
import { NotificationManager } from 'react-notifications';
import { BiChevronUpCircle, BiChevronDownCircle } from 'react-icons/bi';
import { useDispatch } from 'react-redux';
import GrowPlanFeatures from '../../plan/components/GrowPlanFeatures';
import { getRewardfulCode } from '../../helpers';
import { setLoading } from '../../../redux-setup/actions/loading';
import { checkPlan, checkPrice, isUnlimitedPlan, totalDue } from "../../../common/functions";
import EssentialPlanFeatures from "../../plan/components/EssentialPlanFeatures";
import { requestCheckoutSesion } from "../../../common/sendInfo";
import { logout } from "../../../config/token";
import { getCouponInfo } from "../../../common/getInfo";

function Checkout({ first_plan, first_tenure, first_coupon }) {
  const [active, setActive] = useState(false);
  const [height, setHeight] = useState('0px');
  const [price, setPrice] = useState(checkPrice(first_plan, first_tenure));
  const [pricingPlan, setPricingPlan] = useState(checkPlan(first_plan, first_tenure));
  const dispatch = useDispatch();

  const contentSpace = useRef(null);

  function toggleAccordion() {
    setActive((preV) => !preV);
    setHeight(active ? '0px' : `${contentSpace.current.scrollHeight}px`);
  }

  const handleCheckout = () => {
    const success_url = `${window.location.origin
      }/subscriptions/success?success=true&plan=${first_plan}`;
    const referalCode = getRewardfulCode();
    dispatch(setLoading(true));
    const payload = {
      success_url,
      cancel_url: `${window.location.href}`,
      rewardful: '',
      plan: first_plan,
      tenure: first_tenure,
    };
    const rewardful = referalCode;
    if (referalCode) {
      if (rewardful) payload.rewardful = rewardful;
    }
    if (first_coupon) {
      payload.coupon = first_coupon;
    }
    requestCheckoutSesion(payload)
      .then((response) => response.json())
      .then(async (result) => {
        const stripe = await loadStripe(result.stripe_token);
        if (stripe) {
          const stripeRedirect = await stripe.redirectToCheckout({
            sessionId: result.stripe_session_id,
          });
          if (stripeRedirect.error) {
            NotificationManager.error('Something went wrong');
          }
        }
      })
      .catch(() => dispatch(setLoading(false)));
  };

  useEffect(() => {
    async function obtainData() {
      const response = await getCouponInfo(first_coupon)
      const result = await response.json();
      if (result.data) {
        setPricingPlan(result.data.option.label);
        setPrice(`$${result.data.price}`);
      }
    }
    if (first_coupon) {
      obtainData();
    }
  }, [first_coupon]);

  return (
    <div>
      <h3 className="font-bold mb-4 mt-12 text-xl md:text-base text-scorpion">
        Order Summary
      </h3>
      <div className="border border-silver-alto p-4 rounded bg-pampas">
        <h4 className="text-mine-shaft font-medium mb-4 text-sm md:text-base">
          Your Pricing Plan
        </h4>
        <div className="text-lg md:text-xl my-4 p-2 font-medium text-mine-shaft">
          {pricingPlan}
        </div>
        <button
          type="button"
          className="cursor-pointer focus:outline-none flex items-center justify-between w-full"
          onClick={toggleAccordion}
        >
          <span className="text-mine-shaft font-medium text-sm md:text-base">
            Plan Features
          </span>
          <span className="text-xl">
            {active ? <BiChevronDownCircle /> : <BiChevronUpCircle />}
          </span>
        </button>
        <div
          ref={contentSpace}
          style={{ maxHeight: `${height}` }}
          className="overflow-auto transition-max-height duration-700 ease-in-out mb-4"
        >
          {isUnlimitedPlan(first_plan) ? <GrowPlanFeatures textSize="text-sm md:text-base" /> : <EssentialPlanFeatures textSize="text-sm md:text-base" />}
        </div>

        <hr />
        <h3 className="text-base md:text-lg font-bold text-tundora flex justify-between w-full my-4">
          {totalDue(first_plan)}
          <span className="text-shamrock">
            {price}
          </span>
        </h3>
        <hr />
        <p className="text-xs md:text-base text-tundora mt-4 text-center">
          If you have a coupon code, you can apply it on the next step.
        </p>
      </div>
      <div className="px-0 md:px-20">
        <Button
          primary
          fullWidth
          large
          name="Continue"
          className="mt-10"
          onClick={handleCheckout}
        >
          Continue to Checkout
        </Button>
      </div>
      <div className="w-full text-right mt-4">
        <button
          type="button"
          className="underline text-ubblue"
          onClick={logout}
        >
          Back to Register
        </button>
      </div>
    </div>
  );
}

export default Checkout;
