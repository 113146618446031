import React from 'react';
import ContentEditable from 'react-contenteditable';
import useTemplateState from '../../../../../../hooks/useTemplateState';
import { editTemplate } from '../../../../../savedcontent/actions';
import TemplateFooter from '../../../../components/footer/TemplateFooter';
import { IArrayTemplateResultProps } from './props';

function ArrayTemplateResult({
  data,
  onEditMode = false,
  hideFooter = false,
}: IArrayTemplateResultProps) {
  const { templateValue, handleOnChange } = useTemplateState(data);
  return (
    <div className="relative mb-4 px-px">
      <div className="border lg:border-0 border-gallery bg-white py-4 px-6 mb-3 shadow-lg font-source rounded-lg">
        <div className="font-source font-medium text-sm sm:text-base xlg:text-lg text-ubblack">
          {templateValue.content.map((val: string, index: number) => (
            <ContentEditable
              key={index}
              html={val.replaceAll('\n', '<br>')}
              disabled={!onEditMode}
              onBlur={({ target: { innerHTML } }) =>
                editTemplate(innerHTML, data, 'content')
              }
              onChange={(e) => handleOnChange(e.target.value, 'content')}
              spellCheck={false}
              // onCopy={() => {
              //   sendCopied({
              //     id: data.id,
              //     value: document.getSelection().toString(),
              //   });
              // }}
            />
          ))}
        </div>
      </div>
      {!hideFooter && (
        <TemplateFooter
          data={templateValue}
          handleOnChange={handleOnChange}
          onEditMode={onEditMode}
          type="blog-ideas"
        />
      )}
    </div>
  );
}

export default ArrayTemplateResult;
