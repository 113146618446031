import React from 'react';

// eslint-disable-next-line react/prop-types
function StepperRegister({ step, paidAccount }) {
  return (
    <div className="flex items-center mb-6">
      <div
        className={`rounded-full transition duration-500 ease-in-out h-11 w-11 py-3 text-center relative ${
          step > 1 ? 'bg-ubblue text-white' : 'bg-link-water text-scorpion'
        }`}
      >
        <div>1</div>
        <div className="absolute top-0 -ml-10 text-center mt-12 w-32 text-gray-600 text-sm">
          Register
        </div>
      </div>
      <div className="flex-auto border-t-2 transition duration-500 ease-in-out border-teal-600" />
      <div
        className={`rounded-full transition duration-500 ease-in-out h-11 w-11 py-3 text-center relative ${
          // eslint-disable-next-line no-nested-ternary
          step === 1
            ? 'bg-pampas text-scorpion'
            : step === 2
              ? 'bg-link-water text-scorpion'
              : 'bg-ubblue text-white'
        }`}
      >
        <div>2</div>
        <div className="absolute top-0 -ml-10 text-center mt-12 w-32 text-gray-600 text-sm">
          Verify
        </div>
      </div>
      <div className="flex-auto border-t-2 transition duration-500 ease-in-out border-teal-600" />
      <div
        className={`rounded-full transition duration-500 ease-in-out h-11 w-11 py-3 text-center relative ${
          step === 3 ? 'bg-link-water text-scorpion' : 'bg-pampas text-scorpion'
        }`}
      >
        <div>3</div>
        <div className="absolute top-0 -ml-10 text-center mt-12 w-32 text-gray-600 text-sm">
          {paidAccount ? 'Checkout' : 'Explore'}
        </div>
      </div>
    </div>
  );
}

export default StepperRegister;
