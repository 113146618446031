import React from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import CreditsInfoCard from './CreditsInfoCard';

function RemainingCreditsSection() {
  const { isPaid, last_credited, pack_credits, remaining_credits, canceled } =
    useSelector((state: RootStateOrAny) => state['PERSONALIZE/DATA']);
  return (
    <div className="my-8">
      <h2 className="text-ugray font-semibold pb-2">
        Credits Remaining This Month
      </h2>
      <div className="flex flex-wrap items-center justify-center lg:justify-between lg:flex-nowrap">
        {isPaid ? (
          <p className="text-sm text-silver-chalice mb-4 lg:mb-0">
            You get 200 credits per month. Your next refresh is on{' '}
            <span className="text-shamrock">
              {dayjs.unix(canceled.billingCycle).format('MMMM D, YYYY')}
            </span>
          </p>
        ) : (
          <p className="text-sm text-silver-chalice mb-4 lg:mb-0">
            You get up to 10 credits every week, up to 40 credits per month.
            Your next refresh is on{' '}
            <span className="text-shamrock">
              {dayjs(last_credited).add(7, 'day').format('MMMM D, YYYY')}
            </span>
          </p>
        )}

        <div className="md:ml-4 flex gap-6 flex-wrap lg:flex-nowrap lg:min-w-[385px] justify-center">
          <CreditsInfoCard
            title={isPaid ? 'Remaining Credits' : 'Free Credits'}
            numberOfCredits={remaining_credits}
            className="min-w-[178px]"
          />
          {!isPaid && (
            <CreditsInfoCard
              title="Purchased Credits"
              numberOfCredits={pack_credits}
              className="min-w-[178px]"
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default RemainingCreditsSection;
