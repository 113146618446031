/* eslint-disable react/no-array-index-key */
import React from 'react';

import { v4 } from 'uuid';

// eslint-disable-next-line import/no-cycle
import PageLayout from '../../partials/pagelayout/containers/PageLayout';
import GoogleAdsTemplate from '../components/templates/GoogleAdsTemplate';

const itemizeMenu = [
  {
    value: 'headline',
    label: 'headline',
  },
  {
    value: 'description',
    label: 'description',
  },
];

export default function GoogleAds() {
  return (
    <PageLayout
      template="google-ads"
      title="Google Ads"
      itemizeMenu={itemizeMenu}
    >
      {({ contents, filter }) => (
        <>
          {React.Children.toArray(
            contents.map((googlead) => (
              <GoogleAdsTemplate key={v4()} data={googlead} filter={filter} />
            )),
          )}
        </>
      )}
    </PageLayout>
  );
}
