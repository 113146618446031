import React from 'react';
import 'draft-js/dist/Draft.css';
// eslint-disable-next-line import/no-cycle
import WriterEditorTinyMCE from './WriterEditorTinyMCE';
import { usePageLayoutContext } from '../../partials/pagelayout/hooks';
import { getCurrentDoc, hasStoredDoc } from '../helpers';
import { useApiData } from '../../../redux-setup';

import { WRITER_DETAILS } from '../contants';
import { useSelector } from 'react-redux';

function WriterEditor() {
  const hasDoc = hasStoredDoc();
  const savedDoc = getCurrentDoc();
  const data = useApiData(WRITER_DETAILS, {});
  const { intro, topic, template } = data;
  const id = useSelector((state) => state['PERSONALIZE/DATA'].project_default);
  const { writeContent } = usePageLayoutContext();
  const formatDefaultValue = React.useMemo(() => {
    if (hasDoc) {
      return savedDoc?.contents[0].content?.writerContent;
    }
    return `<h1>${topic}</h1>
    <p>${intro}</p>`;
  }, [hasDoc, savedDoc]);

  return (
    <WriterEditorTinyMCE
      defaultValue={formatDefaultValue}
      generateNewContent={writeContent}
      currentDoc={savedDoc}
      topic={hasDoc ? savedDoc?.writerState?.topic : topic}
      contentType={template}
      personaId={id}
      writerState={hasDoc ? savedDoc?.writerState : data}
    />
  );
}

export default WriterEditor;
