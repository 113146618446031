/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import './author.css';

function Br() {
  return <div className="w-full h-px bg-gray-200 rounded-full my-5" />;
}

function Input({
  value,
  setvalue,
  title = 'email',
  placeholder = '',
  type = 'text',
  header,
}) {
  return (
    <div className="w-full grid grid-cols-2 grid-rows-1 items-center">
      <p>{header}</p>
      <div className="max-w-xs">
        <label htmlFor={title} className="sr-only">
          {title}
        </label>
        <input
          type={type}
          name={title}
          value={value}
          onChange={(e) =>
            setvalue(
              title,
              Number.isNaN(+e.target.value) ? e.target.value : +e.target.value,
            )
          }
          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full px-4 py-2 sm:text-sm border border-gray-300 rounded"
          placeholder={placeholder}
        />
      </div>
    </div>
  );
}

function Select({
  title,
  header,
  options,
  defaultOption = options[0],
  setvalue,
}) {
  return (
    <div className="w-full grid grid-cols-2 grid-rows-1 items-center">
      <p>{header}</p>
      <div className="max-w-xs relative author-select">
        <label htmlFor={title} className="sr-only">
          {title}
        </label>
        <select
          name={title}
          defaultValue={defaultOption}
          className="appearance-none mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          onChange={(e) => setvalue(title, e.target.value)}
        >
          {options?.length &&
            React.Children.toArray(
              options.map((o, i) => <option key={i}>{o}</option>),
            )}
        </select>
      </div>
    </div>
  );
}

function Textarea({ title, setvalue, value, header }) {
  return (
    <div className="w-full grid grid-cols-2 grid-rows-1">
      <p>{header}</p>
      <textarea
        name="message"
        rows="4"
        value={value}
        onChange={(e) => setvalue(title, e.target.value)}
        className="py-3 px-4 block max-w-lg h-96 resize-none shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border border-gray-300 rounded-md"
      />
    </div>
  );
}

function ListInput({
  header,
  title,
  addvalue,
  removevalue,
  values,
  inputchange,
  inputvalue,
}) {
  return (
    <div className="w-full grid grid-cols-2 grid-rows-1">
      <p>{header}</p>
      <form
        className="max-w-xs"
        onSubmit={(e) => {
          e.preventDefault();
          addvalue(title, inputvalue);
          inputchange(title, '');
        }}
      >
        <label htmlFor={title} className="sr-only">
          {title}
        </label>
        <input
          type="text"
          name={title}
          value={inputvalue}
          onChange={(e) => inputchange(title, e.target.value)}
          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full px-4 py-2 sm:text-sm border border-gray-300 rounded"
        />
        <div
          className={`flex flex-wrap max-w-xs ${
            values.length <= 0 ? 'hidden' : 'block'
          }`}
        >
          {values?.length >= 0 &&
            React.Children.toArray(
              values.map((v, i) => (
                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                <p
                  onKeyDown={() => removevalue(title, v)}
                  className="inputlistitem relative mr-4 mt-2 bg-gray-400 py-1 pl-2 pr-6 rounded cursor-pointer"
                  key={i}
                  onClick={() => removevalue(title, v)}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: v.trim() === '' ? '\\n' : v,
                  }}
                >
                  {/* {v.trim() === '' ? '↵' : v} */}
                  {/* {'↵'} */}
                </p>
              )),
            )}
        </div>
      </form>
    </div>
  );
}

export default function AuthoringTemplate({
  template,
  values,
  savevalues,
  setvalues: setValues,
}) {
  // Successful Save Checkmark
  const [saved, setSaved] = React.useState(false);
  const [failedSave, setFailedSave] = React.useState(false);
  // Input values for the inputs that have lists below them.
  const [ListInputValues, setListInputValues] = useState({
    ...Object.keys(template || {})
      .filter((k) => Array.isArray(template[k]))
      .reduce(
        (res, key) => ({
          ...res,
          [key]: '',
        }),
        {},
      ),
  });

  React.useEffect(() => {
    setListInputValues({
      ...Object.keys(template || {})
        .filter((k) => Array.isArray(template[k]))
        .reduce(
          (res, key) => ({
            ...res,
            [key]: '',
          }),
          {},
        ),
    });
  }, [template]);

  const setValue = (k, v) => {
    setValues({
      ...values,
      [k]: v,
    });
  };

  const setListInputValue = (k, v) => {
    setListInputValues({
      ...ListInputValues,
      [k]: v,
    });
  };

  const addListInputValue = (k, v) => {
    if (!v || v.trim() === '') return;
    setValues({
      ...values,
      [k]: [...values[k], v],
    });
  };

  const removeListInputValue = (k, v) => {
    setValues({
      ...values,
      [k]: [...values[k].filter((value) => value !== v)],
    });
  };

  const handleSave = async () => {
    const saved = await savevalues();
    if (saved) {
      setSaved(true);
      setTimeout(() => setSaved(false), 1000);
    } else {
      setFailedSave(true);
      setTimeout(() => setFailedSave(false), 1000);
    }
  };

  return (
    <div className="flex flex-col">
      <Input
        header="Template Name"
        title="name"
        value={values?.name || ''}
        setvalue={setValue}
      />
      <Br />
      <Textarea
        title="card_description"
        header="Card Description"
        setvalue={setValue}
        value={values?.card_description || ''}
      />
      <Br />
      <Input
        header="Card Tag"
        title="card_tag"
        value={values?.card_tag || ''}
        setvalue={setValue}
      />
      <Br />
      <Input
        header="Card Icon URL"
        title="card_icon"
        value={values?.card_icon || ''}
        setvalue={setValue}
      />
      <Br />
      <Select
        title="template_type"
        setvalue={setValue}
        // eslint-disable-next-line no-unsafe-optional-chaining
        options={[...values?.template_type.split(',')]}
        header="Template Type"
      />
      <Br />
      <Select
        header="Fine Tune"
        title="fine_tune"
        setvalue={setValue}
        defaultOption={values?.fine_tune}
        options={['ideas', 'no-ideas']}
      />
      <Br />
      <Input
        header="Engine"
        title="engine"
        value={values?.engine || ''}
        setvalue={setValue}
      />
      <Br />
      <Input
        header="Endpoint"
        title="endpoint"
        value={values?.endpoint || ''}
        setvalue={setValue}
      />
      <Br />
      <Textarea
        title="prompt_text"
        header="Prompt Text"
        setvalue={setValue}
        value={values?.prompt_text || ''}
      />
      <Br />
      <Textarea
        title="custom_prompt_text"
        header="Custom Prompt Text"
        setvalue={setValue}
        value={values?.custom_prompt_text || ''}
      />
      <Br />
      <Input
        header="Minimum Length for Response"
        title="min_length"
        setvalue={setValue}
        value={values?.min_length || ''}
      />
      <Br />
      <Input
        header="Maximum Length for Response"
        title="max_length"
        setvalue={setValue}
        value={values?.max_length || ''}
      />
      <Br />
      <Input
        header="Max Tokens (generally max length / 4ish)"
        title="max_tokens"
        setvalue={setValue}
        value={values?.max_tokens || ''}
      />
      <Br />
      <Input
        header="Temperature"
        title="temperature"
        setvalue={setValue}
        value={values?.temperature || ''}
      />
      <Br />
      <Input
        header="Frequency Penalty"
        title="frequency_penalty"
        setvalue={setValue}
        value={values?.frequency_penalty || ''}
      />
      <Br />
      <Input
        header="Presence Penalty"
        title="presence_penalty"
        setvalue={setValue}
        value={values?.presence_penalty || ''}
      />
      <Br />
      <Input header="n" title="n" setvalue={setValue} value={values?.n} />
      <Br />
      <Input
        header="Max Responses to Return"
        title="n_return"
        setvalue={setValue}
        value={values?.n_return || ''}
      />
      {ListInputValues &&
        React.Children.toArray(
          Object.keys(ListInputValues).map((k, i) => {
            const getHeader = () => {
              switch (k) {
                case 'filter_phrases':
                  return 'Filter Phrases';
                case 'replace_companies':
                  return 'Company Names to Replace';
                case 'parameters':
                  return 'Custom Parameters';
                case 'parameter_labels':
                  return 'Custom Parameter Labels';
                case 'parameter_placeholders':
                  return 'Custom Parameter Placeholders';
                case 'stop_token':
                  return 'Stop Tokens';
                case 'parameter_type':
                  return 'Custom Parameter Type';
                default:
                  return 'No name.';
              }
            };
            return (
              <div key={i}>
                <Br />
                <ListInput
                  header={getHeader()}
                  title={k}
                  values={values[k]}
                  addvalue={addListInputValue}
                  removevalue={removeListInputValue}
                  inputchange={setListInputValue}
                  inputvalue={ListInputValues[k]}
                />
              </div>
            );
          }),
        )}
      <button
        type="button"
        className={`cursor-pointer ml-auto mt-8 rounded h-9 w-24 bg-${
          saved ? 'shamrock' : failedSave ? 'red-400' : 'blue-500'
        } text-white font-medium flex justify-center items-center`}
        onClick={handleSave}
      >
        {saved ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 12 12"
          >
            <title>check</title>
            <g fill="#ffffff">
              <path
                d="M10.293,1.293,4,7.586,1.707,5.293A1,1,0,0,0,.293,6.707l3,3a1,1,0,0,0,1.414,0l7-7a1,1,0,0,0-1.414-1.414Z"
                fill="#ffffff"
              />
            </g>
          </svg>
        ) : failedSave ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 12 12"
          >
            <title>e-remove</title>
            <g fill="#ffffff">
              <path
                d="M10.707,1.293a1,1,0,0,0-1.414,0L6,4.586,2.707,1.293A1,1,0,0,0,1.293,2.707L4.586,6,1.293,9.293a1,1,0,1,0,1.414,1.414L6,7.414l3.293,3.293a1,1,0,0,0,1.414-1.414L7.414,6l3.293-3.293A1,1,0,0,0,10.707,1.293Z"
                fill="#ffffff"
              />
            </g>
          </svg>
        ) : (
          'Save'
        )}
      </button>
    </div>
  );
}
