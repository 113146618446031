import React from 'react';
import { clsx } from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/pro-light-svg-icons';
import { IKeywordProps } from './props';

function Keyword({
  index,
  name,
  handleRemoveKeyword,
  disabled,
}: IKeywordProps) {
  return (
    <div
      className={clsx(
        'm-1 mt-2.5 mb-1.5 pl-3 py-1 flex items-center rounded-lg font-medium font-source',
        disabled ? 'bg-gray-50' : 'bg-pampas',
      )}
    >
      <span
        className={clsx(
          'whitespace-nowrap font-semibold font-source',
          disabled ? 'text-usilver' : 'text-scorpion',
        )}
      >
        {name}
      </span>
      <div
        className={clsx(
          'rounded-full h-5 w-5 flex items-center justify-center mx-1',
          disabled ? 'text-alto' : 'hover:bg-usilver text-scorpion',
        )}
        onClick={disabled ? undefined : () => handleRemoveKeyword(index)}
      >
        <FontAwesomeIcon icon={faClose} />
      </div>
    </div>
  );
}

export default Keyword;
