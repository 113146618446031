import React from 'react';

import PropTypes from 'prop-types';
import 'react-responsive-modal/styles.css';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { Modal } from 'react-responsive-modal';

function CustomModal({
  isOpen,
  closeModal,
  children,
  title,
  width,
  showHeader,
}) {
  return (
    <Modal
      showCloseIcon={false}
      styles={{
        modal: {
          width: '100%',
          maxWidth: width,
          padding: 0,
          top: 50,
          borderRadius: '10px',
        },
      }}
      open={isOpen}
      onClose={closeModal}
      contentLabel="Example Modal"
    >
      {/* header */}
      {showHeader && (
      <div className="flex justify-between items-center border-b border-b-gray-400 p-4">
        <p className="text-ubblack font-semibold text-xl font-barlow">
          {title}
        </p>
        <AiOutlineCloseCircle
          size={20}
          className="cursor-pointer"
          onClick={closeModal}
        />
      </div>
      )}
      {/* body */}
      <div className="p-4">{children}</div>
    </Modal>
  );
}

CustomModal.defaultProps = {
  closeModal: () => null,
  children: '',
  title: '',
  width: 300,
  showHeader: true,
};

CustomModal.propTypes = {
  closeModal: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.node,
  title: PropTypes.string,
  showHeader: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
export default CustomModal;
