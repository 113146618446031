/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import { NotificationManager } from 'react-notifications';

import axios from 'axios';
import { createAlert } from '../modules/common/Alerts';

export const API = process.env.REACT_APP_API_URL || 'https://copy-api.unbounce.com/api';

export const stripeToken = process.env.REACT_APP_STRIPE_TOKEN;

export const config = (token = null) => ({
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
    'Access-Control-Allow-Origin': '*',
  },
});

export const displayError = (name, error) => {
  const {
    response: { status, data },
  } = error;
  if (status === 422) {
    const errs = data?.errors;
    if (typeof errs === 'object') {
      const errorKeys = Object.keys(errs);
      if (
        errorKeys.length > 0
        && Array.isArray(errs[errorKeys[0]])
        && errs[errorKeys[0]].length > 0
      ) createAlert.error(name, errs[errorKeys[0]][0]);
    }
  }
};

export const handleErrors = (err) => {
  if (err.response) {
    const {
      response: {
        status,
        data: { message },
      },
    } = err;
    // if (status === 401 || status === 404) {
    //   NotificationManager.error('Error', message);

    // if (status === 401 && message !== 'Invalid username or password') {
    //   notification.error({
    //     message: 'Session Expired! You will be logged out very soon!',
    //   });

    //   setTimeout(() => {
    //     logout(true);
    //   }, 500);
    // }
    // }

    // if (status === 403) {
    //   if (err?.response?.data === 'No payment information on file.') return;
    //   NotificationManager.error(err.response.data || 'Forbidden request');
    // }
    if (status === 401 && message === 'Unauthenticated.') {
      sessionStorage.clear();
      localStorage.clear();
      window.location.href = '/';
    }
    if (status === 500) {
      NotificationManager.error('Error', 'Something wrong with the server!');
    }

    if (status === 429) {
      NotificationManager.error(message || "Can't send more request");
    }
  } else {
    if (axios.isCancel(err)) return;
    NotificationManager.error('SERVER ERROR!');
    // logout(true);
  }
};
