import {
  createContext, useContext, useEffect, useMemo,
} from 'react';

import { getStorage } from '../../helpers';
import { V3_TEMPLATES } from '../../templates/actions';
import req, { useApiData } from '../../../redux-setup';

import { TEMPLATE_VALUES } from './constants';

export const PageLayoutContext = createContext();
export const usePageLayoutContext = () => useContext(PageLayoutContext);

export const useStoredDynamicData = () => {
  const sections = useApiData(V3_TEMPLATES, []);

  const templateValues = useMemo(() => {
    const templates = [];
    sections.forEach((template) => {
      templates.push(...template.content);
    });
    return {
      ...templates?.reduce(
        (accu, val) => ({
          ...accu,
          [val?.code]: getStorage(val?.code),
        }),
        {},
      ),
    };
  }, [sections]);
  useEffect(() => {
    req.updateObject(TEMPLATE_VALUES, templateValues);
  }, [templateValues]);
};
