/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';

import PropTypes from 'prop-types';
import ContentEditable from 'react-contenteditable';
import { useHistory } from 'react-router-dom';

import { updateTemplate } from '../../../../config/token';
import { showConfirmation } from '../../../layout/actions';
import { CONFIRMATION_DATA } from '../../../layout/constants';
import { editTemplate } from '../../../savedcontent/actions';
import { SAVED_CONTENT } from '../../../savedcontent/constants';
import { deleteContent } from '../../actions';
import { saveCurrentDoc } from '../../../writer/helpers';
import req from '../../../../redux-setup';

import BrandFilter from '../BrandFilter/BrandFilterIcon';
import TemplateActions from './TemplateActions';
import TemplateFeedback from './TemplateFeedback';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/pro-light-svg-icons';

function TemplateFooter({
  data,
  onEditMode,
  handleOnChange,
  type,
  onDelete,
  isWriter,
  isDemo,
}) {
  const history = useHistory();
  return data && !onEditMode ? (
    <div className="flex justify-between items-center w-full text-silver-chalice pb-4">
      <div className="w-auto flex items-center spaec-x-3">
        <BrandFilter label={data.label} />
        <TemplateFeedback data={data} />
      </div>
      <TemplateActions data={data} isDemo={isDemo} />
    </div>
  ) : (
    <div className="flex justify-between items-center w-full text-silver-chalice px-2 font-source">
      <ContentEditable
        html={data.name || '--'}
        onChange={(e) => {
          handleOnChange(e.target.value, 'name');
        }}
        onBlur={({ target: { innerHTML } }) =>
          editTemplate(innerHTML, data, 'name')
        }
        style={{
          maxWidth: 400,
        }}
      />
      <div className="flex items-center">
        {isWriter && (
          <button
            type="button"
            className="mr-2 text-silver-chalice hover:text-ubblue font-semibold text-sm"
            onClick={() =>
              new Promise((res) => {
                saveCurrentDoc({
                  id: data?.id,
                  writerState: data?.content?.writerState,
                  contents: [data],
                });
                res('');
              }).then(() => history.push('/writer'))
            }
          >
            Continue Writing
          </button>
        )}
        {data.words && data.caracters_count && (
          <div className="text-sm text-gray-500 font-light font-source mr-1">
            {data?.words || ''} words / {data?.caracters_count || ''} characters
          </div>
        )}
        <TemplateActions data={data} onEditMode={onEditMode} isDemo={isDemo} />
        <FontAwesomeIcon
          icon={faTrashCan}
          onClick={() =>
            showConfirmation(
              'Are you sure you want to delete this content?',
              () => {
                req.arrayDelete(SAVED_CONTENT, data.id);
                deleteContent(data.id, () => {}, true).then(() => {
                  if (onDelete) {
                    onDelete();
                    req.omit(CONFIRMATION_DATA);
                    return;
                  }
                  updateTemplate(
                    type,
                    data?.id,
                    {
                      saveId: '',
                      saved: false,
                      shared: false,
                    },
                    () => {},
                    'saveId',
                  );
                  req.omit(CONFIRMATION_DATA);
                });
              },
            )
          }
          className="cursor-pointer text-silver-chalice hover:text-red-500 ml-3"
        />
      </div>
    </div>
  );
}

TemplateFooter.defaultProps = {
  type: '',
  onDelete: null,
  isWriter: false,
  isDemo: false,
};

TemplateFooter.propTypes = {
  onDelete: PropTypes.func,
  isWriter: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  onEditMode: PropTypes.bool.isRequired,
  type: PropTypes.string,
  handleOnChange: PropTypes.func.isRequired,
  isDemo: PropTypes.bool,
};
export default TemplateFooter;
