import React, { useState, createContext } from 'react';

import Axios from 'axios';
import PropTypes from 'prop-types';

import { API, config, handleErrors } from '../config/API';
import { getToken } from '../config/token';

const InformationContext = createContext({});
export const DEFAULT_VALUE = {
  name: '',
  company: '',
  brand_keywords: [],
  audience: [],
  payment_details: {},
  description: '',
  isLoaded: false,
  isDone: true,
  isEmailValidated: false,
  isPaid: false,
};
export function InformationProvider({ children }) {
  const [info, setInfo] = useState(DEFAULT_VALUE);
  const [viewLoading, setLoading] = useState(false);

  const setViewLoading = React.useCallback(
    (newState) => setLoading(newState),
    [],
  );

  const updateInfo = React.useCallback((updatedInfo) => {
    setInfo((current) => ({ ...current, ...updatedInfo }));
  }, []);

  const getCombinedKeywords = () => info.brand_keywords.join(', ');
  const getCombinedAudiences = () => info.audience.join(' and ');

  const getContents = async () => {
    try {
      const res = await Axios.get(`${API}/saved-content`, config(getToken()));
      return res.data;
    } catch (error) {
      handleErrors(error);
      throw new Error(error);
    }
  };

  const saveContent = async (payload) => {
    try {
      const res = await Axios.post(
        `${API}/saved-content/create`,
        { ...payload, sub_template: payload.subTemplate },
        config(getToken()),
      );
      return res.data;
    } catch (error) {
      handleErrors(error);
      throw new Error(error);
    }
  };

  const getContent = async (id) => {
    try {
      const res = await Axios.get(
        `${API}/saved-content/${id}`,
        config(getToken()),
      );
      return res.data;
    } catch (error) {
      handleErrors(error);
      throw new Error(error);
    }
  };

  const updateContent = async (payload, id) => {
    try {
      const res = await Axios.post(
        `${API}/saved-content/update/${id}`,
        payload,
        config(getToken()),
      );
      return res.data;
    } catch (error) {
      handleErrors(error);
      throw new Error(error);
    }
  };

  const deleteContent = async (id) => {
    try {
      const res = await Axios.post(
        `${API}/saved-content/delete/${id}`,
        {},
        config(getToken()),
      );
      return res.data;
    } catch (error) {
      throw new Error(error);
    }
  };

  // const personalizeUpdate = async (updatedInfo) => {
  //   const newInfo = { ...info, ...updatedInfo };
  //   const payload = {
  //     company_name: newInfo.company,
  //     audience: newInfo.audience,
  //     description: newInfo.description,
  //     brand_keywords: newInfo.brand_keywords,
  //   };

  //   try {
  //     await Axios.post(`${API}/personalize`, payload, config(getToken()));
  //   } catch (err) {
  //     handleErrors(err);
  //   }
  // };

  const sendFeedback = async (values) => {
    try {
      await Axios.post(
        `${API}/feedback/${values.feedback_id}`,
        values,
        config(getToken()),
      );
    } catch (err) {
      handleErrors(err);
    }
  };

  return (
    <InformationContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        info,
        updateInfo,
        getCombinedKeywords,
        getCombinedAudiences,
        viewLoading,
        setViewLoading,
        getContents,
        getContent,
        saveContent,
        updateContent,
        deleteContent,
        // personalizeUpdate,
        sendFeedback,
      }}
    >
      {children}
    </InformationContext.Provider>
  );
}

InformationProvider.defaultProps = {
  children: null,
};

InformationProvider.propTypes = {
  children: PropTypes.node,
};
export default InformationContext;
