import React from 'react';
import { Modal } from 'react-responsive-modal';
import { ICancelModalProps } from './props';
import smartCopyLarge from '../../../../../assets/img/UBSmartCopy-short.svg';

const closeIcon = (
  <svg
    width="28"
    height="28"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.15234 6.15234C6.46875 5.83594 6.99609 5.83594 7.3125 6.15234L8.96484 7.83984L10.6523 6.15234C10.9688 5.83594 11.4961 5.83594 11.8125 6.15234C12.1641 6.50391 12.1641 7.03125 11.8125 7.34766L10.1602 9L11.8125 10.6523C12.1641 11.0039 12.1641 11.5312 11.8125 11.8477C11.4961 12.1992 10.9688 12.1992 10.6523 11.8477L8.96484 10.1953L7.3125 11.8477C6.99609 12.1992 6.46875 12.1992 6.15234 11.8477C5.80078 11.5312 5.80078 11.0039 6.15234 10.6523L7.80469 9L6.15234 7.34766C5.80078 7.03125 5.80078 6.50391 6.15234 6.15234ZM18 9C18 13.9922 13.957 18 9 18C4.00781 18 0 13.9922 0 9C0 4.04297 4.00781 0 9 0C13.957 0 18 4.04297 18 9ZM9 1.6875C4.95703 1.6875 1.6875 4.99219 1.6875 9C1.6875 13.043 4.95703 16.3125 9 16.3125C13.0078 16.3125 16.3125 13.043 16.3125 9C16.3125 4.99219 13.0078 1.6875 9 1.6875Z"
      fill="black"
    />
  </svg>
);

function CancelModal({ open, setOpen, message, endDate }: ICancelModalProps) {
  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
        window.location.reload();
      }}
      center
      styles={{
        modal: { maxWidth: '680px', borderRadius: '10px', padding: '2rem' },
      }}
      closeIcon={closeIcon}
      classNames={{ overlay: 'text-center' }}
    >
      <img
        src={smartCopyLarge}
        alt="logo"
        className="h-10 mx-auto block my-6"
      />
      <h1 className="font-barlow font-semibold text-3xl">
        {message === 'cancelled' && 'Your subscription has been cancelled.'}
        {message === 'downgraded' && 'Your subscription has been downgraded.'}
        {message === 'reactivated' && 'Your subscription has been reactivated.'}
      </h1>
      {(message === 'cancelled' || message === 'downgraded') && (
        <h2 className="text-ubblue font-bold">
          {message === 'cancelled'
            ? `You will be downgraded to our Free plan as of ${endDate}.`
            : 'You have been downgraded to our Essential plan.'}
        </h2>
      )}
      <p className="text-ugray my-6">
        {message === 'cancelled' &&
          'You can continue using Smart Copy with a limited number of credits and features. Some of your saved content will no longer be accessible.'}
        {message === 'downgraded' &&
          'You can continue using Smart Copy with a limited number of credits. Some of your saved content will no longer be accessible.'}
        {message === 'reactivated' && ''}
      </p>
      {message === 'reactivated' && (
        <p className="text-ugray my-6">
          Our mission is to help YOU be more productive and profitable. And
          it&apos;s only possible with your support, so again, thank you.
        </p>
      )}
      {message === 'downgraded' && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          height="169px"
          className="inline-block"
          fill="#27CC8D"
        >
          <path d="M256 368C299.4 368 329.1 346.2 347.9 325.5C353.7 318.8 363.8 318.1 370.5 323.9C377.2 329.7 377.9 339.8 372.1 346.5C349.8 372.1 311.1 400 256 400C200.9 400 162.2 372.1 139.9 346.5C134.1 339.8 134.8 329.7 141.5 323.9C148.2 318.1 158.3 318.8 164.1 325.5C182 346.2 212.6 368 256 368H256zM200.4 208C200.4 221.3 189.6 232 176.4 232C163.1 232 152.4 221.3 152.4 208C152.4 194.7 163.1 184 176.4 184C189.6 184 200.4 194.7 200.4 208zM312.4 208C312.4 194.7 323.1 184 336.4 184C349.6 184 360.4 194.7 360.4 208C360.4 221.3 349.6 232 336.4 232C323.1 232 312.4 221.3 312.4 208zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 32C132.3 32 32 132.3 32 256C32 379.7 132.3 480 256 480C379.7 480 480 379.7 480 256C480 132.3 379.7 32 256 32z" />
        </svg>
      )}
      {message === 'cancelled' && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          fill="#0033ff"
          height="169px"
          className="inline-block my-6"
        >
          <path d="M139.3 399.3C130.8 396.7 126.1 387.7 128.7 379.3C145.2 326.1 196.3 288 256 288C315.8 288 366.8 326.1 383.3 379.3C385.9 387.7 381.2 396.7 372.7 399.3C364.3 401.9 355.3 397.2 352.7 388.7C340.5 349.4 302.1 320 256 320C209.9 320 171.5 349.4 159.3 388.7C156.7 397.2 147.7 401.9 139.3 399.3H139.3zM200.4 208C200.4 221.3 189.6 232 176.4 232C163.1 232 152.4 221.3 152.4 208C152.4 194.7 163.1 184 176.4 184C189.6 184 200.4 194.7 200.4 208zM312.4 208C312.4 194.7 323.1 184 336.4 184C349.6 184 360.4 194.7 360.4 208C360.4 221.3 349.6 232 336.4 232C323.1 232 312.4 221.3 312.4 208zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 32C132.3 32 32 132.3 32 256C32 379.7 132.3 480 256 480C379.7 480 480 379.7 480 256C480 132.3 379.7 32 256 32z" />
        </svg>
      )}
      {message === 'reactivated' && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          fill="#27CC8D"
          height="169px"
          className="inline-block my-6"
        >
          <path d="M256 368C299.4 368 329.1 346.2 347.9 325.5C353.7 318.8 363.8 318.1 370.5 323.9C377.2 329.7 377.9 339.8 372.1 346.5C349.8 372.1 311.1 400 256 400C200.9 400 162.2 372.1 139.9 346.5C134.1 339.8 134.8 329.7 141.5 323.9C148.2 318.1 158.3 318.8 164.1 325.5C182 346.2 212.6 368 256 368H256zM156.5 194.5C150.2 201.7 145.7 211.3 143.5 219.9C141.4 228.5 132.7 233.7 124.1 231.5C115.5 229.4 110.3 220.7 112.5 212.1C115.7 199.4 122.3 185 132.3 173.5C142.4 161.9 157.2 152 176 152C194.8 152 209.6 161.9 219.7 173.5C229.7 185 236.3 199.4 239.5 212.1C241.7 220.7 236.5 229.4 227.9 231.5C219.3 233.7 210.6 228.5 208.5 219.9C206.3 211.3 201.8 201.7 195.5 194.5C189.4 187.4 182.8 184 176 184C169.2 184 162.6 187.4 156.5 194.5V194.5zM284.1 231.5C275.5 229.4 270.3 220.7 272.5 212.1C275.7 199.4 282.3 185 292.3 173.5C302.4 161.9 317.2 152 336 152C354.8 152 369.6 161.9 379.7 173.5C389.7 185 396.3 199.4 399.5 212.1C401.7 220.7 396.5 229.4 387.9 231.5C379.3 233.7 370.6 228.5 368.5 219.9C366.3 211.3 361.8 201.7 355.5 194.5C349.4 187.4 342.8 184 336 184C329.2 184 322.6 187.4 316.5 194.5C310.2 201.7 305.7 211.3 303.5 219.9C301.4 228.5 292.7 233.7 284.1 231.5H284.1zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 32C132.3 32 32 132.3 32 256C32 379.7 132.3 480 256 480C379.7 480 480 379.7 480 256C480 132.3 379.7 32 256 32z" />
        </svg>
      )}
      {message === 'cancelled' && (
        <p className="text-ugray my-6">
          You are welcome to comeback anytime.
          <br />
          To recover your saved content and projects by reactivating your
          subscription.
        </p>
      )}
      {message === 'downgraded' && <p className="text-ugray my-6">Enjoy!</p>}
    </Modal>
  );
}

export default CancelModal;
