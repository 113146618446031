import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import LoginPage from './modules/auth/containers/LoginContainer';
import ForgotPassword from './modules/password/containers/ForgotPassword';
import ResetPassword from './modules/password/containers/ResetPassword';
import RegisterContainer from './modules/register/containers/RegisterContainer';
import SharedTemplate from './modules/shared/containers/SharedTemplate';
import ShortcutContainer from './modules/shortcuts/containers/ShortcutContainer';
import UnbouncePage from './modules/Unbounce';

function PublicRoutes() {
  return (
    <Switch>
      <Route exact path="/login" component={LoginPage} />
      <Route exact path="/" component={LoginPage} />
      <Route path="/register/:plan?/:tenure?" component={RegisterContainer} />
      <Route exact path="/forgot-password" component={ForgotPassword} />
      <Route exact path="/reset-password" component={ResetPassword} />
      <Route exact path="/share/:id" component={SharedTemplate} />
      <Route path="/shortcut" component={ShortcutContainer} />
      <Route path="/unbounce/page" component={UnbouncePage} />
      <Route>
        <Redirect to="/" />
      </Route>
    </Switch>
  );
}

export default PublicRoutes;
