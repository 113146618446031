import React from 'react';
import ContentEditable from 'react-contenteditable';
import useTemplateState from '../../../../../../hooks/useTemplateState';
import TemplateFooter from '../../../../components/footer/TemplateFooter';
import { IBenefitsResultProps } from './props';

function BenefitsResult({
  data,
  hideFooter = false,
  onEditMode = false,
}: IBenefitsResultProps) {
  const { templateValue, handleOnChange } = useTemplateState(data);
  let headline = '';
  let description = '';
  if (typeof data.content === 'string') {
    headline = data.content.slice(0, data.content.indexOf(' - '));
    description = data.content.slice(data.content.indexOf(' - ') + 3, data.content.length);
    if (description === data.content.slice(2)) {
      headline = '';
      description = data.content;
    }
  } else {
    headline = data.content.headline;
    description = data.content.description;
  }
  const benefitNoPhotos = (
    <div className="max-w-2xl mx-auto text-center py-8 px-4 sm:px-6 lg:px-8">
      <h2 className="text-xl xlg:text-2xl font-extrabold text-white ">
        <ContentEditable
          html={headline || ''}
          disabled
          spellCheck={false}
          onChange={() => null}
        />
      </h2>
      <div className="mt-4 text-base xlg:text-lg leading-6 text-indigo-200">
        <ContentEditable
          html={description || ''}
          disabled
          onChange={() => null}
          spellCheck={false}
        />
      </div>
      <button
        type="button"
        className="cursor-default mt-6 mx-auto shadow flex items-center justify-center px-4 py-2  border border-transparent text-xs sm:text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-5"
      >
        Learn More
      </button>
    </div>
  );
  return (
    <div className="relative mb-4 px-px">
      <div className="bg-ubblue py-4 px-6 mb-3 shadow-md font-source rounded-lg">
        <div className="py-6 px-8">{benefitNoPhotos}</div>
      </div>
      {!hideFooter && (
        <TemplateFooter
          data={templateValue}
          onEditMode={onEditMode}
          handleOnChange={handleOnChange}
          type="header-description"
        />
      )}
    </div>
  );
}

export default BenefitsResult;
