import React, {
  ChangeEvent,
  useEffect,
  useState,
} from 'react';
import { clsx } from 'clsx';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Country, State } from 'country-state-city';
import {
  requestCreditsCheckout,
  updateStripeAddress,
  voidStripeInvoice,
} from '../../../../common/sendInfo';
import { setLoading } from '../../../../redux-setup/actions/loading';
import CreditsCard from './CreditsCard';
import CreditsOption from './CreditsOption';
import {
  IAddress,
  ICreditsObj,
  ICreditsPackSectionProps,
  IResponseProps,
} from './types';
import { IDefaultSelect } from '../../../../common/props';
import { SingleValue } from 'react-select';
import { NotificationManager } from 'react-notifications';
import CreditsModal from './CreditsModal';

function CreditsPackSection({ wrapperClass }: ICreditsPackSectionProps) {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [creditsObject, setCreditsObject] = useState<ICreditsObj[]>([
    {
      numberCredits: 50,
      costCredits: 10,
      selected: true,
    },
    {
      numberCredits: 100,
      costCredits: 15,
      selected: false,
    },
  ]);
  const [clientSecret, setClientSecret] = useState('');
  const [countryOptions, setCountryOptions] = useState<IDefaultSelect[]>([]);
  const [stateOptions, setStateOptions] = useState<IDefaultSelect[]>([]);
  const [address, setAddress] = useState<IAddress | null>(null);
  const [step, setStep] = useState(1);
  const [invoice, setInvoice] = useState('');
  const [stateKey, setStateKey] = useState(Math.random() * 1000);
  const [countryDefault, setCountryDefault] = useState<
    IDefaultSelect | undefined
  >(undefined);
  const [stateDefault, setStateDefault] = useState<IDefaultSelect | undefined>(
    undefined,
  );
  const [formAddress, setFormAddress] = useState<IAddress>({
    city: '',
    country: '',
    line1: '',
    line2: '',
    postal_code: '',
    state: '',
  });
  const [openPayModal, setOpenPayModal] = useState<boolean>(false);

  const onChangeCredits = (event: ChangeEvent<HTMLInputElement>) => {
    const val = parseInt(event.target.value, 10) || 0;
    setCreditsObject((prev) =>
      prev.map((crObj) => {
        if (crObj.numberCredits === val) {
          return { ...crObj, selected: true };
        }
        return { ...crObj, selected: false };
      }),
    );
  };

  const onClosePayModal = () => {
    setOpenPayModal(false);
    if (invoice) {
      voidStripeInvoice({ invoice })
        .then((response) => response.json())
        .then(() => {
          setInvoice('');
        })
        .catch((e) => console.log(e));
    }
    setStep(1);
  };

  const onPurchaseCredits = (credits?: number) => {
    dispatch(setLoading(true));
    if (credits) {
      setCreditsObject((prev) =>
        prev.map((crObj) => {
          if (crObj.numberCredits === credits) {
            return { ...crObj, selected: true };
          }
          return { ...crObj, selected: false };
        }),
      );
    }
    requestCreditsCheckout({
      success_url: `${
        window.location.origin
      }/credits/success?success=true&credits=${
        creditsObject.filter((obj) => obj.selected)[0].numberCredits || 100
      }`,
      cancel_url: `${window.location.origin}${pathname}`,
      option: credits
        ? credits
        : creditsObject.filter((obj) => obj.selected)[0].numberCredits || 100,
    })
      .then((response) => response.json())
      .then(({ data }: IResponseProps) => {
        setAddress(data.address);
        setClientSecret(data.client);
        setInvoice(data.invoice);
        setOpenPayModal(true);
        dispatch(setLoading(false));
      })
      .catch(() => dispatch(setLoading(false)));
  };

  const handleChangeAddressInput = (
    evnt: React.ChangeEvent<HTMLInputElement>,
    name: string,
  ) => {
    const val = evnt.target.value || '';
    setFormAddress((preFA) => ({
      ...preFA,
      [name]: val,
    }));
  };

  const onChangeCountry = (newValue: SingleValue<IDefaultSelect>) => {
    if (newValue) {
      setFormAddress((preAD) => ({
        ...preAD,
        country: newValue?.value,
        state: '',
        city: '',
      }));
    } else {
      setFormAddress((preAD) => ({
        ...preAD,
        country: '',
        state: '',
        city: '',
      }));
    }
    setStateDefault(undefined);
    setStateKey(Math.random() * 1000);
    setStateOptions([]);
  };

  const onChangeState = (newValue: SingleValue<IDefaultSelect>) => {
    if (newValue) {
      setFormAddress((preAD) => ({
        ...preAD,
        state: newValue?.value,
      }));
    } else {
      setFormAddress((preAD) => ({ ...preAD, state: '' }));
    }
  };

  const onClickSendAddress = () => {
    if (
      formAddress.city &&
      formAddress.country &&
      formAddress.line1 &&
      formAddress.postal_code &&
      formAddress.state
    ) {
      updateStripeAddress(formAddress)
        .then((response) => response.json())
        .then((result) => {
          setAddress(result.data?.address);
          dispatch(setLoading(false));
          setStep(2);
        })
        .catch(() => dispatch(setLoading(false)));
    } else {
      NotificationManager.error('required Fields');
    }
  };

  useEffect(() => {
    setCountryOptions(
      Country.getAllCountries().map((country) => ({
        label: country.name,
        value: country.isoCode,
      })),
    );
  }, []);

  useEffect(() => {
    if (formAddress.country) {
      setStateOptions(
        State.getStatesOfCountry(formAddress.country)?.map((city) => ({
          value: city.name,
          label: city.name,
        })) || [],
      );
    } else {
      setStateOptions([]);
    }
  }, [formAddress.country]);

  useEffect(() => {
    if (address) {
      setFormAddress({ ...address });
      const country = Country.getCountryByCode(address.country);
      if (country) {
        setCountryDefault({ label: country.name, value: country.isoCode });
      }
      if (address.state) {
        setStateDefault({ label: address.state, value: address.state });
      }
    }
  }, [address]);

  return (
    <div
      className={clsx(
        'bg-pampas p-6 rounded-lg my-6 slg:flex slg:gap-x-6',
        wrapperClass,
      )}
    >
      <div className="slg:w-1/3">
        <h2 className="text-mine-shaft md:text-ubblue font-semibold mb-4">
          Keep up the good work writing, top up your credits!
        </h2>
        <p className="text-mine-shaft mb-4 hidden md:block text-sm">
          We&apos;ve made it easier than ever to continue using your favorite
          writing app when you run out of credits. You can now buy a credit pack
          to continue writing.
        </p>
      </div>
      <CreditsModal
        openPayModal={openPayModal}
        onClosePayModal={onClosePayModal}
        step={step}
        clientSecret={clientSecret}
        formAddress={formAddress}
        handleChangeAddressInput={handleChangeAddressInput}
        countryOptions={countryOptions}
        stateOptions={stateOptions}
        onChangeCountry={onChangeCountry}
        onChangeState={onChangeState}
        onClickSendAddress={onClickSendAddress}
        countryDefault={countryDefault}
        stateDefault={stateDefault}
        stateKey={stateKey}
        creditsObj={creditsObject}
      />
      <div className="hidden lg:flex gap-x-6 slg:w-2/3">
        {creditsObject.map((creditObj) => (
          <CreditsCard
            key={creditObj.numberCredits}
            creditsNumber={creditObj.numberCredits}
            creditsPrice={creditObj.costCredits}
            onClickCredits={onPurchaseCredits}
            wrapperClass="w-1/2"
          />
        ))}
      </div>
      <CreditsOption
        onChangeCredits={onChangeCredits}
        creditsObj={creditsObject}
        onPurchaseCredits={onPurchaseCredits}
        className="lg:hidden"
      />
    </div>
  );
}

export default CreditsPackSection;
