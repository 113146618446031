import has from 'lodash/has';
import set from 'lodash/set';

import { updateContent } from '../templates/actions';
import req from '../../redux-setup';

import { SAVED_CONTENT } from './constants';

// eslint-disable-next-line import/prefer-default-export
export const editTemplate = (value, data, path) => {
  const newData = { ...data };
  if (has(newData, path)) {
    set(newData, path, value);
    updateContent(newData).then(() => {
      req.arrayUpdate(SAVED_CONTENT, newData);
    });
  }
};
