import React, { useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import InputField from '../../../../../../common/InputField';
import Keywords from '../../../../../../common/Keywords';
import {
  updateProjectAudience,
  updateProjectDescription,
  updateProjectKeywords,
  updateProjectName,
} from '../../../../../../common/patchInfo';
import TextField from '../../../../../../common/TextField';
import {
  setIsLoadingFalse,
  setIsLoadingTrue,
  setProjectAudience,
  setProjectDescription,
  setProjectKeywords,
  setProjectName,
} from '../../../../../../redux-setup/actions/common';
import { IProjectInfoProps } from './props';

function ProjectInfoAutosave({
  project,
  setProject,
  disabled,
  setFocusEdit,
}: IProjectInfoProps) {
  const dispatch = useDispatch();
  const [audience, setAudience] = useState('');
  const [brandKeywords, setBrandKeywords] = useState('');
  const projectRedux = useSelector((state: RootStateOrAny) => state.PROJECT);
  const onChangeAudition = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const val = evt.target.value;
    if (val.length <= 50) {
      setAudience(val);
    }
  };
  const onChangeBrandKeywords = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const val = evt.target.value;
    if (val.length <= 50) {
      setBrandKeywords(val);
    }
  };

  const addBrandKeyword = () => {
    const audi = brandKeywords.trim();
    if (audi) {
      const prevKeywords = project?.brand_keywords || [];
      dispatch(setIsLoadingTrue());
      updateProjectKeywords({
        brand_keywords: [...prevKeywords, audi],
        persona_id: project?.id,
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.data) {
            dispatch(setProjectKeywords(result.data));
            setBrandKeywords('');
            setProject((preP) => {
              if (preP) {
                return { ...preP, brand_keywords: result.data };
              }
              return preP;
            });
          } else {
            NotificationManager.error(result.message);
          }
          dispatch(setIsLoadingFalse());
        });
    }
  };

  const addAudienceKeyword = () => {
    const audi = audience.trim();
    if (audi) {
      const prevAudience = project?.audience || [];
      dispatch(setIsLoadingTrue());
      updateProjectAudience({
        audience: [...prevAudience, audi],
        persona_id: project?.id,
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.data) {
            dispatch(setProjectAudience(result.data));
            setAudience('');
            setProject((preP) => {
              if (preP) {
                return { ...preP, audience: result.data };
              }
              return preP;
            });
          } else {
            NotificationManager.error(result.message);
          }
          dispatch(setIsLoadingFalse());
        });
    }
  };

  const handleRemoveAudience = (indx: number) => {
    dispatch(setIsLoadingTrue());
    const audience = project?.audience.filter((_aud, ind) => ind !== indx);
    updateProjectAudience({
      audience,
      persona_id: project?.id,
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.data) {
          dispatch(setProjectAudience(result.data));
          setProject((preP) => {
            if (preP) {
              return { ...preP, audience: result.data };
            }
            return preP;
          });
        } else {
          NotificationManager.error(result.message);
        }
        dispatch(setIsLoadingFalse());
      });
  };

  const handleRemoveBrandKeywords = (indx: number) => {
    dispatch(setIsLoadingTrue());
    const brand_keywords = project?.brand_keywords.filter(
      (_aud, ind) => ind !== indx,
    );
    updateProjectKeywords({
      brand_keywords,
      persona_id: project?.id,
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.data) {
          dispatch(setProjectKeywords(result.data));
          setProject((preP) => {
            if (preP) {
              return { ...preP, brand_keywords: result.data };
            }
            return preP;
          });
        } else {
          NotificationManager.error(result.message);
        }
        dispatch(setIsLoadingFalse());
      });
  };

  const handleChangeName = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const val = evt.target.value;
    setProject((preP) => {
      if (preP && val.length < 76) {
        return { ...preP, name: val };
      }
      return preP;
    });
  };

  const handlePasteName = (evt: React.ClipboardEvent<HTMLInputElement>) => {
    evt.preventDefault();
    let data = evt.clipboardData.getData('text');
    if (data.length > 75) {
      data = data.slice(0, 75);
    }
    setProject((preP) => {
      if (preP) {
        return { ...preP, name: data };
      }
      return preP;
    });
  };

  const handleChangeDescription = (
    evt: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const val = evt.target.value;
    setProject((preP) => {
      if (preP && val.length < 251) {
        return { ...preP, description: val };
      }
      return preP;
    });
  };

  const handlePasteDescription = (
    evt: React.ClipboardEvent<HTMLTextAreaElement>,
  ) => {
    evt.preventDefault();
    let data = evt.clipboardData.getData('text');
    if (data.length > 250) {
      data = data.slice(0, 250);
    }
    setProject((preP) => {
      if (preP) {
        return { ...preP, description: data };
      }
      return preP;
    });
  };

  const handleBlurName = (e: React.FocusEvent<HTMLInputElement>) => {
    const name = project?.name;
    const el = e.relatedTarget as HTMLButtonElement;
    // we check if name has at least one character
    if (name) {
      // we check if the name is different that the saved name and stored in the database;
      if (name !== projectRedux.name) {
        dispatch(setIsLoadingTrue());
        updateProjectName({ name, persona_id: project?.id })
          .then((response) => response.json())
          .then((result) => {
            if (result.data) {
              dispatch(
                setProjectName({
                  collID: project.collection_id,
                  prjID: project.id,
                  name: result.data,
                }),
              );
            } else {
              NotificationManager.error(result.message);
            }
            dispatch(setIsLoadingFalse());
            el.click();
          });
      }
    } else {
      setProject((preP) => {
        if (preP) {
          return { ...preP, name: projectRedux.name };
        }
        return preP;
      });
    }
  };

  const handleBlurDescription = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    const description = project?.description;
    const el = e.relatedTarget as HTMLButtonElement;
    if (description) {
      if (description !== projectRedux.description) {
        dispatch(setIsLoadingTrue());
        updateProjectDescription({ description, persona_id: project.id })
          .then((response) => response.json())
          .then((result) => {
            if (result.data) {
              dispatch(setProjectDescription(result.data));
            } else {
              NotificationManager.error(result.message);
            }
            dispatch(setIsLoadingFalse());
            el.click();
          });
      }
    } else {
      setProject((preP) => {
        if (preP) {
          return { ...preP, description: projectRedux.description };
        }
        return preP;
      });
    }
  };

  return (
    <div>
      <InputField
        label="Project / Product / Service Name"
        required
        name="product-service-name"
        placeholder=""
        value={project?.name || ''}
        onChange={handleChangeName}
        error={false}
        wrapperClass="my-2"
        disabled={disabled}
        disabledOnClick={() => {
          setFocusEdit(true);
        }}
        onPaste={handlePasteName}
        maxCounter={75}
        onBlur={handleBlurName}
        tooltip="The name of your first Project. We will also use it in some of the templates for copy generations."
        classNameTooltip="text-sm ml-1"
        classNameLabel="font-source text-ugray text-sm font-semibold leading-6 text-ugray"
      />
      <TextField
        label="Description"
        required
        name="description"
        placeholder=""
        value={project?.description || ''}
        onChange={handleChangeDescription}
        error={false}
        rows={4}
        wrapperClass="my-2"
        disabled={disabled}
        disabledOnClick={() => {
          setFocusEdit(true);
        }}
        onBlur={handleBlurDescription}
        onPaste={handlePasteDescription}
        maxCounter={250}
        tooltip='Describe what your Project is about in a few sentences. Take your time on this, as it will significantly impact the quality of the output.
        For example: "Rid yourself of pain conveniently with our affordable physiotherapy clinics. Insurance Reimbursable.'
        classNameTooltip="text-sm ml-1"
        classNameLabel="font-source text-ugray text-sm font-semibold leading-6 text-ugray"
      />
      <Keywords
        label="Audience"
        required
        keywords={project?.audience || []}
        wrapperClass="my-2"
        onChangeInput={onChangeAudition}
        inputValue={audience}
        name="audience"
        addKeyword={addAudienceKeyword}
        handleRemoveKeyword={handleRemoveAudience}
        disabled={disabled}
        disabledOnClick={() => {
          setFocusEdit(true);
        }}
        maxInput={25}
        tooltip="Add keywords that best describe the intended target group for your content. For example, based on demographics (millennials, gen z), types of users (consumers, business owners) or something entirely different (industrial supplies purchasers)."
        classNameTooltip="text-sm ml-1"
        classNameLabel="font-source text-ugray text-sm font-semibold leading-6 text-ugray"
        onPasteInput={(evt) => {
          evt.preventDefault();
          const data = evt.clipboardData
            .getData('text')
            .trim()
            .substring(0, 50);
          setAudience(data);
        }}
      />
      <Keywords
        wrapperClass="my-2"
        label="Keywords"
        required
        keywords={project?.brand_keywords || []}
        onChangeInput={onChangeBrandKeywords}
        inputValue={brandKeywords}
        name="keywords"
        addKeyword={addBrandKeyword}
        handleRemoveKeyword={handleRemoveBrandKeywords}
        disabled={disabled}
        disabledOnClick={() => {
          setFocusEdit(true);
        }}
        maxInput={25}
        tooltip="Add keywords to describe your product, service, or brand. They can be keywords and phrases that people may be looking for or the names of products/services you offer."
        classNameTooltip="text-sm ml-1"
        classNameLabel="font-source text-ugray text-sm font-semibold leading-6 text-ugray"
        onPasteInput={(evt) => {
          evt.preventDefault();
          const data = evt.clipboardData
            .getData('text')
            .trim()
            .substring(0, 50);
          setBrandKeywords(data);
        }}
      />
    </div>
  );
}

export default ProjectInfoAutosave;
