/* eslint-disable react/no-array-index-key */
import React from 'react';
// eslint-disable-next-line import/no-cycle
import PageLayout from '../../partials/pagelayout/containers/PageLayout';
import ContentExpanderTemplate from '../../templates/components/templates/ContentExpanderTemplate';

function ContentExpander() {
  return (
    <PageLayout
      template="content-expander"
      title="Content Expander"
      initCustomPayload={{ version: 2 }}
    >
      {({ contents }) => React.Children.toArray(
        contents.map((data) => (
          <ContentExpanderTemplate key={data.id || Math.random()} data={data} />
        )),
      )}
    </PageLayout>
  );
}
export default ContentExpander;
