import React from 'react';

import PropTypes from 'prop-types';

const INIT_VALUES = {
  formState: {
    'google-ads': {
      headline1: '',
      headline2: '',
      headline3: '',
      description: '',
      description2: '',
    },
    'landing-pages': {
      title: '',
      description: '',
      benefitHeadline: '',
      benefitDescription: '',
      firstFeature: '',
      secondFeature: '',
      thirdFeature: '',
      firstFeatureDescription: '',
      secondFeatureDescription: '',
      thirdFeatureDescription: '',
    },
    taglines: {
      tagline: '',
    },
    'product-descriptions': {
      productName: '',
      productTags: '',
    },
    'facebook-ads': {
      headline: '',
      description: '',
    },
    'content-expander': {
      topic: '',
      summary: '',
    },
    'header-description': {
      headline: '',
      description: '',
    },
    features: {
      headline: '',
      description: '',
    },
    benefits: {
      headline: '',
      description: '',
    },
    'blog-ideas': {
      topic: '',
    },
    remixer: {
      original: '',
    },
  },
};

const FormContext = React.createContext(INIT_VALUES);
export function FormProvider({ children }) {
  const [values, setValues] = React.useState(INIT_VALUES);

  const resetFormValues = () => setValues(INIT_VALUES);
  const saveFormValues = (value) => {
    setValues((val) => ({ formState: { ...val?.formState, ...value } }));
  };

  return (
    <FormContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        formState: values?.formState,
        resetFormValues,
        saveFormValues,
      }}
    >
      {children}
    </FormContext.Provider>
  );
}

FormProvider.defaultProps = {
  children: <div />,
};
FormProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.instanceOf(Element),
  ]),
};
export default FormContext;
