import React from 'react';

import { AiFillStar } from 'react-icons/ai';

import writerVid from '../../../assets/img/Writer.mp4';

export const MULTIADS = "multiAds"
export const TEMPLATE_VALUES = 'TEMPLATE_VALUES';
export const EQUALIZER_VALUE = 'EQ_VALUE';
export const UNASSOCIATE_ID = 'UnassociateID';
export const PERSONAS = 'PERSONAS';
export const COLLECTIONS = 'COLLECTIONS';
export const SELECTED_PERSONA = 'PERSONAS/SELECTED';
export const CUSTOM_FORM_VALUE = 'REFINE/FORM/VALUE';
export const BUTTON_STATUS = 'BUTTON_STATUS';
export const TAB_RESULTS = 'TAB_RESULTS';
export const TAB_PROMPT = 'TAB_PROMPT';

export const TOOLTIPS_TEXT = [
  'Enter the name of your company or the subject you would like the content to be written about.',
  'These terms should describe your company or brand. They can be descriptive or the names of products/services you offer.',
  'Add terms that describe who the audience for the content is. For example, based on demographics (millennials, gen z), types of users (consumers, business owner) or something entirely different (industrial supplies purchasers).',
  'Describe what your company does in a single sentence. For example, "The easiest way to buy widgest for your home." or "We create agile software products."',
];

export const EQUALIZERS = {
  tones: [
    'Neutral',
    'Friendly',
    'Confident',
    'Urgent',
    'Analytical',
    'Optimistic',
  ],
  lengths: ['Short', 'Medium', 'Long'],
};

export const FORM_DEFAULT_VALUE = {
  'content-expander': {
    topic: '',
    summary: '',
  },
  remixer: {
    original: '',
  },
};

export const writerFeatureData = {
  title: (
    <div>
      <div className="text-black font-normal text-4xl  text-center">
        Introducing <span className="font-bold">Writer</span>
      </div>
      <div className="px-6 text-main font-semibold text-lg my-4 text-center">
        Starting at $40 per month
      </div>
    </div>
  ),
  // hideClose: true,
  width: 600,
  feature: (
    <video width="100%" height="600" autoPlay loop muted playsInline>
      <source src={writerVid} type="video/mp4" />
      Your browser does not support HTML video.
    </video>
  ),
  description: (
    <div className="mb-4">
      <div>
        <span className="font-bold">Writer is a page that writes itself.</span>
      </div>
      <div>
        Seriously. With minimal inputs you can generate sentences, paragraphs,
        and whole stories in seconds. Write any kind of long-form content on
        different topics, with expert level understanding.
      </div>
    </div>
  ),
  closeRedirectPath: '/templates',
  hideFeatures: true,
};

export const writerNoTrialFeatureData = {
  title: (
    <div>
      <div className="text-black font-normal text-4xl  text-center">
        Introducing <span className="font-bold">Writer</span>
      </div>
      <div className="px-6 text-main font-semibold text-lg my-4 text-center">
        Starting at $40 per month
      </div>
    </div>
  ),
  width: 600,
  feature: (
    <video width="100%" height="600" autoPlay loop muted playsInline>
      <source src={writerVid} type="video/mp4" />
      Your browser does not support HTML video.
    </video>
  ),
  description: (
    <div className="mb-4">
      <div>
        <span className="font-bold">Writer is a page that writes itself.</span>
      </div>
      <div>
        Seriously. With minimal inputs you can generate sentences, paragraphs,
        and whole stories in seconds. Write any kind of long-form content on
        different topics, with expert level understanding.
      </div>
    </div>
  ),
  closeRedirectPath: '/templates',
  hideFeatures: true,
};

export const personaFeatureData = {
  title: 'Create Unlimited Projects',
  feature: (
    <div className="flex items-center my-10">
      <AiFillStar size={20} className="text-yellow-300 mr-2" />
      <p className="text-ubblue text-lg font-semibold">Add new project</p>
    </div>
  ),
  description:
    'Quickly save projects for different brands, services and clients.',
};

export const templatesCustomizeFields = {
  taglines: {
    defaultValue: {
      version: 3,
    },
    fields: [
      {
        field: 'tone',
        label: 'Tone',
        type: 'string',
        placeholder: 'Informative, catchy',
      },
    ],
  },
  googleads: {
    defaultValue: {
      version: 3,
    },
    fields: [
      {
        field: 'keyword',
        label: 'Topics',
        type: 'string',
        placeholder: 'Widget sale',
      },
    ],
  },
  landingpage: {
    defaultValue: {
      version: 3,
    },
    fields: [
      {
        field: 'topic',
        label: 'Topic',
        type: 'string',
        placeholder: 'New widget released',
      },
      {
        field: 'feature',
        label: 'Feature',
        type: 'string',
        placeholder: 'New Widget',
      },
      {
        field: 'benefit',
        label: 'Benefit',
        type: 'string',
        placeholder: 'Saves you money',
      },
    ],
  },
  header_description: {
    defaultValue: {
      version: 3,
    },
    fields: [
      {
        field: 'topic',
        label: 'Topic',
        type: 'string',
        placeholder: 'Widget discount',
      },
    ],
  },
  websitefeature: {
    defaultValue: {
      version: 3,
    },
    fields: [
      {
        field: 'feature',
        label: 'Feature',
        type: 'string',
        placeholder: 'New shiny widgets',
      },
    ],
  },
  benefits: {
    defaultValue: {
      version: 3,
    },
    fields: [
      {
        field: 'benefit',
        label: 'Benefit',
        type: 'string',
        placeholder: 'Fits in your pocket',
      },
    ],
  },
  facebookads: {
    defaultValue: {
      version: 3,
    },
    fields: [
      {
        field: 'subject',
        label: 'Subject',
        type: 'string',
        placeholder: 'Black Friday Sale',
      },
    ],
  },
  productdescription: {
    fields: [
      {
        field: 'product_name',
        label: 'Product Name',
        type: 'string',
        placeholder: 'Widget X',
      },
      {
        field: 'product_tags',
        label: 'Product Tags',
        type: 'array',
        placeholder: 'Widget X',
      },
    ],
  },
  blogideas: {
    defaultValue: {
      version: 3,
    },
    fields: [
      {
        field: 'topic',
        label: 'Topic',
        type: 'string',
        placeholder: 'Widget discount',
      },
    ],
  },
  tiktok_ideas: {
    defaultValue: {
      version: 3,
    },
    fields: [
      {
        field: 'topic',
        label: 'Topic',
        type: 'string',
        placeholder: 'Trending',
      },
    ],
  },
  coldemail: {
    defaultValue: {
      version: 3,
    },
    fields: [
      {
        field: 'tone',
        label: 'Tone',
        type: 'string',
        placeholder: 'Informative, inspiring',
      },
    ],
  },
  vc_email: {
    defaultValue: {
      version: 3,
    },
    fields: [
      {
        field: 'tone',
        label: 'Tone',
        type: 'string',
        placeholder: 'Informative, inspiring',
      },
    ],
  },
  angel_email: {
    defaultValue: {
      version: 3,
    },
    fields: [
      {
        field: 'tone',
        label: 'Tone',
        type: 'string',
        placeholder: 'Informative, inspiring',
      },
    ],
  },
};
