export const API =
  process.env.REACT_APP_API_URL || 'https://copy-api.unbounce.com/api';

export const validateEmailURL = () => `${API}/v2/validate_email`;
export const obtainSubscriptionsURL = () => `${API}/v3/obtain_subscriptions`;
export const zapierWebhook =
  'https://hooks.zapier.com/hooks/catch/5491237/bsxk655/';
export const logoutURL = () => `${API}/logout`;
export const copiedResultURL = () => `${API}/v2/copied_result`;
export const writerTrialURL = () => `${API}/writer_trial_start`;
export const checkoutSessionURL = () => `${API}/v2/checkout_session`;
export const createRequestURL = () => `${API}/v3/generator_request`;
export const obtainRequestURL = (id) => `${API}/v2/generator_request/${id}`;
export const billingPortalURL = () => `${API}/v2/billing_portal`;
export const couponInfoURL = (id) => `${API}/coupons/${id}`;
export const obtainSubscriptionIDURL = () => `${API}/subscription/stripe_id`;
export const cancelSubscriptionURL = () => `${API}/subscription/cancel`;
export const cancelCancelationURL = () =>
  `${API}/subscription/cancel/cancelation`;
export const downgradeToEssentialURL = () =>
  `${API}/subscription/downgrade/essential`;
export const updateSubscriptionURL = () => `${API}/subscription/update`;
export const creditsCheckoutURL = () => `${API}/v2/credits_checkout_session`;
export const updateAddressURL = () => `${API}/v2/stripe/address`;
export const voidInvoiceURL = () => `${API}/v2/stripe/invoice/cancel`;
export const collectionsURL = () => `${API}/collection`;
export const singleCollectionURL = (id) => `${API}/collection/${id}`;
export const singleProjectURL = (id) => `${API}/project/${id}`;
export const projectURL = () => `${API}/project`;
export const projectDuplicateURL = () => `${API}/project/duplicate`;
export const projectMoveURL = () => `${API}/project/move`;
export const insertValueURL = () => `${API}/v2/insert_result`;
export const welcomeStep1URL = () => `${API}/user/welcome-1`;
export const welcomeStep2URL = () => `${API}/user/welcome-2`;
export const welcomeStep3URL = () => `${API}/user/welcome-3`;
export const getProjectsFromCollectionURL = (id) =>
  `${API}/collection/projects/${id}`;
export const updateProjectNameURL = () => `${API}/persona/update/name`;
export const updateProjectDescriptionURL = () =>
  `${API}/persona/update/description`;
export const updateProjectAudienceURL = () => `${API}/persona/update/audience`;
export const updateProjectKeywordsURL = () => `${API}/persona/update/keywords`;
//Unbounce Flow
export const verifiedUBCompURL = () => `${API}/sc/ub/comp`;
