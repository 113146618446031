/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import { Loader as Loading } from '@unbounce/ui-components';
import { FaTimes } from 'react-icons/fa';
import { NotificationManager } from 'react-notifications';
import req, { useApiData } from '../../../redux-setup';
import { WRITER_SIDEBAR } from '../contants';
// eslint-disable-next-line import/no-cycle
import { axiosController } from '../../../index';
import { sendInserted } from '../../../common/sendInfo';

export const templateNumber = {
  expand: 161,
  summarize: 164,
  remix: 159,
  next: 160,
  writemore: 165,
};

function WriterSidebar({ editor, generateContent }) {
  const siderbarOpt = useApiData(WRITER_SIDEBAR, {});
  const [isLoading, setLoading] = React.useState(false);
  const [copies, setCopies] = React.useState([]);
  React.useEffect(() => {
    const generateCopy = async () => {
      const templateNum = templateNumber[siderbarOpt?.type] || '';
      if (!templateNum) return;
      setLoading(true);
      const highlighted =
        siderbarOpt.selected || editor?.selection?.getContent();
      const data = await generateContent(
        highlighted,
        'V2 Template',
        templateNum,
        true,
      );
      setLoading(false);
      setCopies(data);
    };

    if (editor) generateCopy();

    return () => {
      axiosController.abort();
    };
  }, [siderbarOpt]);

  const filteredCopies = React.useMemo(
    () =>
      copies?.filter(
        (copy) =>
          copy?.content !== 'undefined' &&
          copy.content !== 'NULL' &&
          copy.content !== 'null' &&
          copy.content !== '{}' &&
          copy?.content,
      ),
    [copies],
  );

  const replaceText = (newText) => {
    const hlText = editor.selection.getContent();
    if (!hlText) {
      // MESSAGE FOR remix and expander
      NotificationManager.warning(
        'Please highlight the text you want to replace, before choosing your copy from the sidebar suggestions.',
      );
      return false;
    }
    editor.selection.setContent(
      `<span class="colored-text" style="color: #7e8c8d;">${newText}</span>`,
    );
    return true;
  };

  const addNextText = (newText) => {
    const highlighted = editor.selection.getContent();
    if (!highlighted) {
      editor.selection.setContent(
        ` <span class="colored-text" style="color: #7e8c8d;">${newText}</span>`,
      );
    } else {
      editor.selection.setContent(
        `${highlighted} <span class="colored-text" style="color: #7e8c8d;">${newText}</span>`,
      );
    }
    return true;
  };

  const onClickText = (newText) => {
    if (siderbarOpt?.type === 'next') {
      addNextText(newText);
      return;
    }
    replaceText(newText);
  };

  if (!editor || !siderbarOpt) return null;
  return (
    <div
      className="flex flex-grow flex-col pr-4"
      style={{
        width: '40%',
        minWidth: 300,
        maxWidth: 400,
      }}
    >
      <div className="w-full flex justify-between items-center pt-2">
        <p className="capitalize text-xl font-barlow font-semibold">
          {siderbarOpt?.type}
        </p>
        <button
          type="button"
          onClick={() => req.set(WRITER_SIDEBAR, {})}
          className="bg-white text-gray-500 border border-gray-200 text-sm hover:text-ubblue py-1.5 px-1.5 font-semibold rounded-xl"
        >
          <FaTimes />
        </button>
      </div>
      {siderbarOpt && (
        <div className="text-xs italic mt-4 text-silver-chalice mr-4">
          {siderbarOpt?.type === 'next'
            ? 'Place the cursor where you would like this text to be inserted, and then choose your copy from below.'
            : 'Highlight the copy you would like to replace, and then choose your copy from below.'}
        </div>
      )}
      {isLoading && (
        <div className="justify-self-end mt-20">
          <Loading />
        </div>
      )}
      <div className="relative flex flex-col flex-grow mt-4">
        {!isLoading && filteredCopies?.length > 0 && (
          <div className="absolute bottom-0 right-0 left-0 top-0 overflow-y-auto space-y-4 p-4">
            {React.Children.toArray(
              filteredCopies.map((copy, i) => (
                <div
                  id={`sidebar-copy-result-${i}`}
                  onClick={() => {
                    sendInserted({
                      id: copy.id,
                      value: copy.content,
                      persona: copy.persona_id,
                      templateName: copy.templateName,
                    });
                    onClickText(copy?.content);
                  }}
                  className="bg-white break-white break-words font-medium border-transparent text-md p-5 shadow-xl rounded-xl m-1 mt-2 border-2 border-white hover:border-ubblue cursor-pointer text-md font-source"
                >
                  {copy?.content}
                </div>
              )),
            )}
          </div>
        )}
        {!isLoading && filteredCopies?.length < 1 && (
          <div className="absolute top-0 bottom-0 right-0 left-0 flex items-center justify-center z-100">
            <span />
          </div>
        )}
      </div>
    </div>
  );
}
WriterSidebar.propTypes = {
  generateContent: PropTypes.func.isRequired,
  editor: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
export default WriterSidebar;
