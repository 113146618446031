import React, { useEffect, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import {
  createColletion,
  duplicateProjectTo,
  moveProjectTo,
} from '../../../common/sendInfo';
import { setLoading } from '../../../redux-setup/actions/loading';
import Loader from '../../common/Loader/Loader';
import AllProjectsSection from './AllProjectsSection';
import ProjectSection from './ProjectSection';
import {
  IBasicProjectDataProps,
  ICollectionDataProps,
  IProjectDataProps,
} from './props';
import ModalNewCollection from './ModalNewCollection';
import ModalNewProject from './ModalNewProject';
import ModalDuplicateProject from './ModalDuplicateProject';
import ModalMoveProject from './ModalMoveProject';
import ModalDeleteProject from './ModalDeleteProject/indext';
import {
  IDefaultErrorValue,
  IDefaultInfoObj,
  IDefaultSelect,
  IOpenIDNameObj,
  IOpenIDObj,
} from '../../../common/props';
import { deleteProject } from '../../../common/deleteInfo';
import Button from '../../../common/Button';
import {
  addCollection,
  addProjectInCollection,
  removeProjectInCollection,
  setDefaultProject,
  setProject,
} from '../../../redux-setup/actions/common';
import { getAllProjectsFromCollectionID } from '../../../redux-setup/actions/project';

function ProjectsTab() {
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoading = useSelector((state: RootStateOrAny) => state.isLoading);
  const collections = useSelector(
    (state: RootStateOrAny) => state.COLLECTIONS,
  ) as ICollectionDataProps[];
  const project = useSelector((state: RootStateOrAny) => state.PROJECT);
  const defaultProjectID: number | null = useSelector(
    (state: RootStateOrAny) => state['PERSONALIZE/DATA'].project_default,
  );
  const isUnlimited: boolean = useSelector(
    (state: RootStateOrAny) => state['PERSONALIZE/DATA'].is_unlimited,
  );
  const isPaid: boolean = useSelector(
    (state: RootStateOrAny) => state['PERSONALIZE/DATA'].isPaid,
  );
  const [numOfProjects, setNumOfProjects] = useState(0);
  const [numOfProjectsInSelectedColl, setNumOfProjectsInSelectedColl] =
    useState(0);
  const [projectData, setProjectData] = useState<IProjectDataProps | null>(
    null,
  );
  const [openNewCollection, setOpenNewCollection] = useState(false);
  const [openNewProject, setOpenNewProject] = useState(false);
  const [openDuplicateModal, setOpenDuplicateModal] = useState<IOpenIDObj>({
    open: false,
    id: 0,
  });
  const [openMoveModal, setOpenMoveModal] = useState<IOpenIDObj>({
    open: false,
    id: 0,
  });
  const [openDeleteProjectModal, setOpenDeleteProjectModal] =
    useState<IOpenIDNameObj>({ open: false, name: '', id: 0 });
  const [newCollectionObj, setNewCollectionObj] = useState<IDefaultErrorValue>({
    error: '',
    value: '',
  });
  const [newProjectObj, setNewProjectObj] = useState<IDefaultErrorValue>({
    error: '',
    value: '',
  });
  const [projectsSelected, setProjectsSelected] = useState<
    IBasicProjectDataProps[]
  >([]);
  const [selectedCollObj, setSelectedCollObj] = useState<IDefaultInfoObj>({
    id: 0,
    name: '',
  });
  const [disabledProjectEdit, setDisabledProjectEdit] = useState(true);

  const handleNewCollection = () => {
    if (newCollectionObj.value === '') {
      setNewCollectionObj({
        value: '',
        error: 'The field "Collection Name" is required',
      });
      return;
    }
    if (newCollectionObj.value.length < 3) {
      setNewCollectionObj((preVal) => ({
        ...preVal,
        error: 'The name must have at least 3 characters',
      }));
      return;
    }
    dispatch(setLoading(true));
    createColletion({ name: newCollectionObj.value })
      .then((response) => response.json())
      .then((result) => {
        if (result.data) {
          dispatch(addCollection(result.data.collection));
          dispatch(setDefaultProject(result.data.project.id));
          setSelectedCollObj({
            id: result.data.collection.id,
            name: result.data.collection.name,
          });
          setOpenNewCollection(false);
          dispatch(setProject(result.data.project));
          setProjectData(result.data.project);
          NotificationManager.success(
            `Collection ${result.data.collection.name} Created`,
          );
        } else {
          NotificationManager.error(result.message);
        }
        dispatch(setLoading(false));
      })
      .catch((e) => {
        dispatch(setLoading(false));
        NotificationManager.error(e.toString());
      });
  };

  const handleDuplicateProject = (collectionObj: IDefaultSelect) => {
    if (openDuplicateModal.id) {
      dispatch(setLoading(true));
      duplicateProjectTo({
        project_id: openDuplicateModal.id,
        collection_id: collectionObj.value,
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.data) {
            dispatch(
              addProjectInCollection({
                collectionID: result.data.destination_collection_id,
                project: {
                  id: result.data.project_id,
                  name: result.data.name,
                  collection_id: result.data.destination_collection_id,
                  created_at: result.data.created_at,
                },
              }),
            );
            setSelectedCollObj({
              id: result.data.destination_collection_id,
              name: result.data.destination_collection_name,
            });
            dispatch(setDefaultProject(result.data.project_id));
            setOpenDuplicateModal({ open: false, id: 0 });
            NotificationManager.success(
              `Project ${result.data.name} duplicated to ${collectionObj.label}`,
            );
          } else {
            NotificationManager.error(result.message);
          }
          dispatch(setLoading(false));
        })
        .catch((err) => {
          dispatch(setLoading(false));
          NotificationManager.error(err.toString());
        });
    } else {
      NotificationManager.error('Please select a project to duplicate to');
    }
  };

  const handleMoveProject = (collectionObj: IDefaultSelect) => {
    if (openMoveModal.id) {
      if (collectionObj.value === selectedCollObj.id.toString()) {
        NotificationManager.error(
          `The project is already in ${selectedCollObj.name}`,
        );
        return;
      }
      dispatch(setLoading(true));
      moveProjectTo({
        project_id: openMoveModal.id,
        collection_id: collectionObj.value,
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.data) {
            dispatch(
              removeProjectInCollection({
                collectionID: result.data.origin_collection_id,
                projectID: result.data.project_id,
              }),
            );
            dispatch(
              addProjectInCollection({
                collectionID: result.data.destination_collection_id,
                project: {
                  id: result.data.project_id,
                  name: result.data.name,
                  collection_id: result.data.destination_collection_id,
                  created_at: result.data.created_at,
                },
              }),
            );
            setSelectedCollObj({
              id: result.data.destination_collection_id,
              name: result.data.destination_collection_name,
            });
            dispatch(setDefaultProject(result.data.project_id));
            setOpenMoveModal({ open: false, id: 0 });
            NotificationManager.success(
              `Project ${result.data.name} moved to ${collectionObj.label}`,
            );
          } else {
            NotificationManager.error(result.message);
          }
          dispatch(setLoading(false));
        })
        .catch((err) => {
          dispatch(setLoading(false));
          NotificationManager.error(err.toString());
        });
    } else {
      NotificationManager.error('Please select a project to move to');
    }
  };

  const handleDeleteProject = () => {
    if (openDeleteProjectModal.id) {
      dispatch(setLoading(true));
      deleteProject(openDeleteProjectModal.id)
        .then((response) => response.json())
        .then((result) => {
          if (result.data) {
            if (
              (!isPaid && numOfProjectsInSelectedColl > 1) ||
              (!isUnlimited && isPaid && numOfProjectsInSelectedColl > 3)
            ) {
              dispatch(
                getAllProjectsFromCollectionID(result.data.collection_id),
              );
            } else {
              dispatch(
                removeProjectInCollection({
                  projectID: result.data.project_id,
                  collectionID: result.data.collection_id,
                }),
              );
            }
            dispatch(setDefaultProject(result.data.project_default));
            setOpenDeleteProjectModal({ open: false, name: '', id: 0 });
            NotificationManager.success(
              `Project "${openDeleteProjectModal.name}" deleted`,
            );
          } else {
            NotificationManager.error(result.message);
          }
          dispatch(setLoading(false));
        })
        .catch((err) => {
          dispatch(setLoading(false));
          NotificationManager.error(err.toString());
        });
    } else {
      NotificationManager.error('Please select a project to delete');
    }
  };

  // when collections change, we update the projects selected
  useEffect(() => {
    if (collections.length) {
      let prjNumber = 0;
      collections.forEach((proj) => (prjNumber += proj.projects.length));
      setNumOfProjects(prjNumber);
      let selected = undefined as ICollectionDataProps | undefined;
      if (isUnlimited) {
        if (selectedCollObj.id > 0) {
          selected = collections.find((coll) => coll.id === selectedCollObj.id);
        } else if (
          collections.length &&
          selectedCollObj.id === 0 &&
          defaultProjectID
        ) {
          selected = collections.find((coll) =>
            coll.projects.find((proj) => proj.id === defaultProjectID),
          );
        }
      } else {
        selected = collections[0];
      }
      if (selected) {
        setProjectsSelected(selected ? selected.projects : []);
        setNumOfProjectsInSelectedColl(selected ? selected.projects.length : 0);
        setSelectedCollObj({ id: selected.id, name: selected.name });
      }
    }
  }, [collections]);

  useEffect(() => {
    if (isUnlimited) {
      if (selectedCollObj.id > 0) {
        const selected = collections.find(
          (coll) => coll.id === selectedCollObj.id,
        );
        setProjectsSelected(selected ? selected.projects : []);
        setNumOfProjectsInSelectedColl(selected ? selected.projects.length : 0);
      }
    }
  }, [selectedCollObj]);

  useEffect(() => {
    if (project.id > 0) {
      setProjectData(project);
    } else {
      setProjectData(null);
    }
  }, [project]);

  return (
    <div>
      <ModalNewCollection
        openNewCollection={openNewCollection}
        setOpenNewCollection={setOpenNewCollection}
        newCollectionObj={newCollectionObj}
        setNewCollectionObj={setNewCollectionObj}
        handleNewCollection={handleNewCollection}
      />
      <ModalNewProject
        openNewProject={openNewProject}
        setOpenNewProject={setOpenNewProject}
        newProjectObj={newProjectObj}
        setNewProjectObj={setNewProjectObj}
        selectedCollObj={selectedCollObj}
      />
      <ModalDuplicateProject
        openDuplicateProject={openDuplicateModal}
        setOpenDuplicateProject={setOpenDuplicateModal}
        handleDuplicateProject={handleDuplicateProject}
        defaultInputValue={{
          label: selectedCollObj.name,
          value: selectedCollObj.id.toString(),
        }}
      />
      <ModalDeleteProject
        openDeleteModal={openDeleteProjectModal}
        setOpenDeleteModal={setOpenDeleteProjectModal}
        onClickDeleteProject={handleDeleteProject}
      />
      <ModalMoveProject
        openMoveProject={openMoveModal}
        setOpenMoveProject={setOpenMoveModal}
        handleMoveProject={handleMoveProject}
      />
      <div className="flex gap-4 items-center my-4 w-full flex-wrap lg:flex-nowrap lg:justify-between">
        <h2 className="text-sm text-silver-chalice">
          Describe the product or service you want us to write about and
          we&apos;ll take care of the rest. This step is key in generating the
          right copy for you.
        </h2>
        <div className="flex items-center justify-end justify-self-end flex-wrap lg:flex-nowrap gap-4">
          {!isUnlimited &&
            ((numOfProjects > 3 && isPaid) ||
              (numOfProjects > 1 && !isPaid)) && (
              <Button
                type="alternative"
                onClick={() => history.push('/plan')}
                className="w-48"
              >
                Reactivate Projects
              </Button>
            )}
          <Button
            type="primary"
            onClick={() => setOpenNewProject(true)}
            disabled={
              selectedCollObj.id < 1 ||
              (!isPaid && !isUnlimited && numOfProjectsInSelectedColl > 0) ||
              (isPaid && !isUnlimited && numOfProjectsInSelectedColl > 2)
            }
            className="w-52"
          >
            <FontAwesomeIcon icon={faPlus} /> Create New Project
          </Button>
        </div>
      </div>
      <div className="grid lg:grid-cols-3 gap-4">
        <AllProjectsSection
          onNewCollection={() => {
            setNewCollectionObj({ value: '', error: '' });
            setOpenNewCollection(true);
          }}
          wrapperClass="mx-4 h-full w-full"
          setSelectedCollObj={setSelectedCollObj}
          selectedCollObj={selectedCollObj}
        />
        <ProjectSection
          projectsList={projectsSelected}
          setOpenDuplicateModal={setOpenDuplicateModal}
          setOpenMoveModal={setOpenMoveModal}
          setOpenDeleteProjectModal={setOpenDeleteProjectModal}
          projectData={projectData}
          setProjectData={setProjectData}
          className="lg:col-span-2 h-full pl-4"
          disabledProjectEdit={disabledProjectEdit}
          setDisabledProjectEdit={setDisabledProjectEdit}
        />
      </div>
      {isLoading && <Loader />}
    </div>
  );
}

export default ProjectsTab;
