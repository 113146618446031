import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import TemplatesContainer from './containers/TemplatesContainer';
import DynamicTemplate from './containers/DynamicTemplate';
import { useStoredDynamicData } from '../partials/pagelayout/hooks';

function TemplateRoutes() {
  const match = useRouteMatch();
  const v3Sections = useSelector((store) => store.V3_TEMPLATES);
  useStoredDynamicData();

  return (
    <Switch>
      {v3Sections?.map((section) =>
        section.content.map((template) => (
          <Route
            key={template.id}
            path={`${match.url}${template.slug}`}
            component={DynamicTemplate}
            exact
          />
        )),
      )}
      <Route path={match.url} component={TemplatesContainer} />
    </Switch>
  );
}

export default TemplateRoutes;
