import { getTemplateResults } from './getInfo';

export const delay = async (ms) => {
  // eslint-disable-next-line no-promise-executor-return
  await new Promise((res) => setTimeout(res, ms));
};

export const getTemplateData = async (id) => {
  try {
    const response = await getTemplateResults(id);
    const result = await response.json();
    if (!result.complete) {
      await delay(2500);
      return getTemplateData(id);
    }
    return result.contents;
  } catch (err) {
    return { message: 'an error ocurred', errors: err };
  }
};

export const isCouponPlan = (planName) => {
  return (
    planName === 'cpfree' ||
    planName === 'cpessential' ||
    planName === 'cpunlimited' ||
    planName === 'growth' ||
    planName === 'starter' ||
    planName === 'ubgrowth'
  );
};

export const isUnlimitedPlan = (planName) => {
  return (
    planName === 'growth' ||
    planName === 'ubgrowth' ||
    planName === 'cpunlimited' ||
    planName === 'unlimited'
  );
};

export const isPaidPlan = (planName) => {
  if (
    planName === 'growth' ||
    planName === 'ubgrowth' ||
    planName === 'cpessential' ||
    planName === 'essential' ||
    planName === 'cpunlimited' ||
    planName === 'unlimited'
  ) {
    return true;
  }
  return false;
};

export const checkPrice = (plan, tenure) => {
  switch (plan) {
    case 'growth':
    case 'unlimited':
    case 'cpunlimited': {
      switch (tenure) {
        case 'annual':
          return '$480';
        case 'monthly':
          return '$49';
        default:
          return '$49';
      }
    }
    case 'essential':
    case 'cpessential': {
      switch (tenure) {
        case 'annual':
          return '$96';
        case 'monthly':
          return '$9';
        default:
          return '$9';
      }
    }
    default:
      return '$480';
  }
};

export const checkPlan = (plan, tenure) => {
  switch (plan) {
    case 'growth':
    case 'ubgrowth': {
      switch (tenure) {
        case 'annual':
          return 'Growth - $480 Billed Annually';
        case 'monthly':
          return 'Growth - $49 Billed Monthly';
        default:
          return 'Growth - $49 Billed Monthly';
      }
    }
    case 'unlimited':
    case 'cpunlimited': {
      switch (tenure) {
        case 'annual':
          return 'Unlimited - $480 Billed Annually';
        case 'monthly':
          return 'Unlimited - $49 Billed Monthly';
        default:
          return 'Unlimited - $49 Billed Monthly';
      }
    }
    case 'essential':
    case 'cpessential': {
      switch (tenure) {
        case 'annual':
          return 'Essential - $96 Billed Annually';
        case 'monthly':
          return 'Essential - $9 Billed Monthly';
        default:
          return 'Essential - $9 Billed Monthly';
      }
    }
    default:
      return 'Unlimited - $49 Billed Monthly';
  }
};

export const totalDue = (plan) => {
  switch (plan) {
    case 'growth':
    case 'unlimited':
    case 'cpunlimited':
      return 'Total due';
    case 'ubgrowth':
      return 'Total due in 90 days';
    default:
      return 'Total due';
  }
};

export const optionPlan = (plan, tenure) => {
  switch (plan) {
    case 'ubgrowth':
      switch (tenure) {
        case 'annual':
          return 'concierge-annual';
        case 'monthly':
          return 'concierge-monthly';
        default:
          return '';
      }
    case 'growth':
      return tenure;
    default:
      return tenure;
  }
};

export const lenghtOptions = [
  { label: 'Short', value: 'short' },
  { label: 'Medium', value: 'medium' },
  { label: 'Long', value: 'long' },
];

// return a number between 0 and max-1 perfect for array's indexes
export function getRandomNumber(max) {
  return Math.floor(Math.random() * max);
}

export const websiteRegExp = /^(https?:\/\/)?(www\.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)|(https?:\/\/)?(www\.)?(?!ww)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,10}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/;

export const resultWebsite = (value) => {
  const matchWebsite = value.match(websiteRegExp);
  const resultUrl = matchWebsite?.length ? matchWebsite[0] : '';
  if (resultUrl.startsWith('http')) {
    return resultUrl;
  }
  return `https://${resultUrl}`;
};
