/* eslint-disable react/button-has-type */
import React from 'react';
import { clsx } from 'clsx';
import IButtonProps from './props';
import Tippy from '@tippyjs/react';

function IconButton({
  children,
  className: classCSS,
  onClick,
  disabled,
  loading,
  onMouseEnter,
  onMouseLeave,
  helperText,
  type,
}: IButtonProps) {
  if (disabled) {
    return (
      <button
        className={clsx(
          'text-center font-source font-semibold text-sm px-2 py-1 rounded select-none pointer-events-none',
          type === 'primary' && 'px-2 py-1',
          classCSS,
        )}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {loading ? (
          <>
            <i className="fa fa-spinner fa-spin mr-1" />
            {children}
          </>
        ) : (
          children
        )}
      </button>
    );
  } else if (helperText) {
    return (
      <Tippy content={helperText} theme="light-border" placement="bottom">
        <button
          className={clsx(
            'group relative text-center font-source font-semibold text-sm px-2 py-1 rounded select-none',
            type === 'primary' &&
              'betterhover:hover:text-ubblue betterhover:hover:bg-link-water',
            classCSS,
          )}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {loading ? (
            <>
              <i className="fa fa-spinner fa-spin mr-1" />
              {children}
            </>
          ) : (
            children
          )}
        </button>
      </Tippy>
    );
  } else {
    return (
      <button
        className={clsx(
          'group relative text-center font-source font-semibold text-sm px-2 py-1 rounded select-none',
          type === 'primary' &&
            'betterhover:hover:text-ubblue betterhover:hover:bg-link-water',
          classCSS,
        )}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {loading ? (
          <>
            <i className="fa fa-spinner fa-spin mr-1" />
            {children}
          </>
        ) : (
          children
        )}
      </button>
    );
  }
}

export default IconButton;
