import React from 'react';
import Skeleton from 'react-loading-skeleton';
import * as nativeWindow from '@todesktop/client-core/nativeWindow';
import { MdContentCopy } from 'react-icons/md';
import { useRouteMatch } from 'react-router-dom';
import LogoB from '../../../assets/img/SmartCopyWordmark.svg';

import { useFetchContent } from '../actions';
import { copyWords } from '../../helpers';
import { useShortcutContext } from '../context';

const templateNumber = {
  expand: 180,
  remix: 182,
  next: 181,
};

function Loader() {
  return (
    <div className="flex flex-col w-10/12 space-y-2">
      <Skeleton count={3} height={18} />
    </div>
  );
}

const validTypes = ['remix', 'expand', 'next'];

function CopyCard() {
  const getContent = useFetchContent();
  const match = useRouteMatch();
  // eslint-disable-next-line no-unsafe-optional-chaining
  const { type } = match?.params;
  const { currentWord } = useShortcutContext();
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [currentIndex, setCurrentIndex] = React.useState(0);

  const isInvalid = React.useMemo(() => !validTypes.includes(type), [type]);

  const content = React.useMemo(() => {
    if (isInvalid) return ['Invalid Template Type'];
    if (currentIndex > data.length - 1) return [''];
    return [
      React.Children.toArray(
        data[currentIndex]
          ?.split(' ')
          ?.filter((txt) => txt)
          .map((txt) => (
            <>
              {currentWord.includes(txt) ? (
                txt
              ) : (
                <span className="text-ubblue">{txt}</span>
              )}
              {' '}
            </>
          )),
      ),
      data[currentIndex],
    ];
  }, [currentIndex, data, isInvalid, currentWord]);
  const fetchContent = React.useCallback(async () => {
    try {
      if (isInvalid || !type) return;
      setLoading(true);
      const data = await getContent(
        currentWord,
        'V2 Template',
        templateNumber[type] || 159,
      );
      setData((prev) => prev.concat(data?.map((dt) => dt?.content)));
    } finally {
      setLoading(false);
    }
  }, [getContent, currentWord, isInvalid, type]);

  const handleNext = () => {
    if (currentIndex + 1 > data.length - 1) {
      fetchContent();
    }
    setCurrentIndex((prev) => prev + 1);
  };

  const handlePrev = () => {
    if (currentIndex < 1) return;
    setCurrentIndex((prev) => prev - 1);
  };

  React.useEffect(() => {
    if (currentWord) fetchContent();
  }, [currentWord, fetchContent]);

  return (
    <div className="flex flex-col text-xl xlg:text-2xl font-barlow font-medium w-full text-ubblack">
      <div className="p-4 w-full flex items-center justify-between">
        {currentIndex > 0 && (
          <button
            type="button"
            onClick={handlePrev}
            disabled={loading}
            className="font-semibold text-sm text-gray-600 hover:text-ubblue z-50 cursor-pointer"
          >
            PREV
          </button>
        )}
        <button
          type="button"
          onClick={handleNext}
          disabled={loading}
          className="font-semibold text-sm text-gray-600 ml-auto hover:text-ubblue z-50 cursor-pointer"
        >
          NEXT
        </button>
      </div>
      <div className="flex-grow flex items-center p-4 px-12 justify-center text-center font-light newcontent">
        {loading ? <Loader /> : <div>{content[0]}</div>}
      </div>
      <div className="flex items-center justify-center">
        <button
          type="button"
          onClick={() => {
            copyWords(content[1] || '', true);
            nativeWindow.hide();
          }}
          className="text-xs text-gray-500 font-medium hover:text-ubblue py-4 flex space-x-2 items-center"
        >
          <MdContentCopy size={17} className="mr-1 cursor-pointer" />
          Copy to Clipboard
        </button>
      </div>
      <div className="p-4 w-full flex items-center justify-between border-t">
        <img src={LogoB} alt="smart-copy" className="w-24 cursor-pointer" />
        <a
          href="https://smartcopy.productlift.dev/new"
          target="_blank"
          rel="noopener noreferrer"
          className="text-xs font-light text-gray-400 hover:text-ubblue cursor-pointer"
        >
          feedback
        </a>
      </div>
    </div>
  );
}

export default CopyCard;
