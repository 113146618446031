/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useContext, useMemo } from 'react';

import { useRouteMatch } from 'react-router-dom';

import { V3_TEMPLATES } from './actions';
import { useApiData } from '../../redux-setup';

export const TemplateContext = createContext({
  data: [],
  setTemplateData: () => {},
});
export const useTemplateContext = () => useContext(TemplateContext);

export const useCurrentTemplate = (slug = '') => {
  const match = useRouteMatch();

  const sections = useApiData(V3_TEMPLATES, []);
  const templates = [];
  sections.forEach((template) => {
    templates.push(...template.content);
  });

  const templateData = useMemo(
    () =>
      templates?.filter(
        (t) => t.slug === (slug || `/${match?.path?.split('/').pop()}`),
      )[0] || {},
    [templates, match],
  );

  return templateData;
};
