import React from 'react';
import FooterOptionSkeleton from './FooterOptionSkeleton';

function FooterSkeleton() {
  return (
    <div className="flex">
      <FooterOptionSkeleton />
      <FooterOptionSkeleton />
      <FooterOptionSkeleton />
      <FooterOptionSkeleton />
      <FooterOptionSkeleton />
    </div>
  );
}

export default FooterSkeleton;
