import React from 'react';
import { clsx } from 'clsx';
import { Link, useLocation } from 'react-router-dom';
import LogoB from '../../../../assets/img/UBSmartCopy-short.svg';
import { menuItems, userItems } from '../../sidenav/constants';
import Menu from '../../sidenav/components/Menu';

function MobileNav() {
  const [open, setOpen] = React.useState(false);
  const location = useLocation();

  const renderLinks = React.useCallback(
    (lists = []) => React.Children.toArray(
      lists?.map((val) => (
        <Menu
          key={val?.label}
          favicon={val?.icon}
          tooltip={val?.tooltip}
          label={val?.label}
          data={
              typeof val?.data === 'function'
                ? val?.data(location.pathname)
                : val?.data
            }
          disableActive={val?.disableActive}
          onClick={() => {
            setOpen(false);
            if (typeof val.onClick === 'function') {
              val.onClick();
            }
          }}
        />
      )),
    ),
    [location],
  );

  return (
    <div
      className="w-full bg-white flex transition ease-in-out flex-col sm:hidden"
      style={{
        zIndex: 99999,
        transitionProperty: 'height',
      }}
    >
      <div className="flex justify-between items-center py-6 px-8">
        <Link
          to="/browse"
          onClick={() => {
            setOpen(false);
          }}
        >
          <img src={LogoB} alt="logo" className="w-40" />
        </Link>
        <div
          role="button"
          tabIndex={0}
          onKeyDown={() => setOpen((prev) => !prev)}
          className={clsx('hamburger-menu', open ? 'open' : '')}
          onClick={() => setOpen((prev) => !prev)}
        >
          <span />
          <span />
          <span />
          <span />
        </div>
      </div>
      {open && (
        <div className="px-5 flex flex-col mb-4 text-main">
          {renderLinks(menuItems)}
          {renderLinks(userItems)}
        </div>
      )}
    </div>
  );
}

export default MobileNav;
