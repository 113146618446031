import React, { useEffect, useState } from 'react';
import { clsx } from 'clsx';
import { IToolsBarProps } from './props';
import SortByFilter from '../../../../../common/SortByFilter';
import { IDefaultInfoObj } from '../../../../../common/props';
import SearchBar from '../../../../../common/SearchBar';

function ToolsBar({
  wrapperClass,
  setProjects,
  projectsSelected,
}: IToolsBarProps) {
  const [searchText, setSearchText] = useState('');
  const [selectedFilter, setSelectedFilter] = useState<IDefaultInfoObj>({
    id: 0,
    name: 'Sort By',
  });

  const handleClickOption = (id: number, label: string) => {
    setSelectedFilter({ id, name: label });
    setProjects((preProj) => {
      const arrPro = [...preProj];
      switch (id) {
        case 1:
          arrPro.sort((pro1, pro2) => {
            if (pro1.created_at > pro2.created_at) {
              return -1;
            }
            if (pro1.created_at < pro2.created_at) {
              return 1;
            }
            return 0;
          });
          break;
        case 2:
          arrPro.sort((pro1, pro2) => {
            if (pro1.created_at < pro2.created_at) {
              return -1;
            }
            if (pro1.created_at > pro2.created_at) {
              return 1;
            }
            return 0;
          });
          break;
        case 3:
          arrPro.sort((pro1, pro2) => {
            if (pro1.name.toUpperCase() < pro2.name.toUpperCase()) {
              return -1;
            }
            if (pro1.name.toUpperCase() > pro2.name.toUpperCase()) {
              return 1;
            }
            return 0;
          });
          break;
        case 4:
          arrPro.sort((pro1, pro2) => {
            if (pro1.name.toUpperCase() > pro2.name.toUpperCase()) {
              return -1;
            }
            if (pro1.name.toUpperCase() < pro2.name.toUpperCase()) {
              return 1;
            }
            return 0;
          });
          break;
        default:
          return arrPro;
      }
      return arrPro;
    });
  };

  const handleFilterSelect = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const val = evt.target.value;
    setSearchText(val);
    if (val !== '') {
      const regx = new RegExp(val);
      setProjects(projectsSelected.filter((proj) => regx.test(proj.name)));
    } else {
      return setProjects(projectsSelected);
    }
  };

  useEffect(() => {
    setSelectedFilter({ id: 0, name: 'Sort By' });
  }, [projectsSelected]);

  return (
    <div className={clsx(wrapperClass, 'flex justify-between items-center')}>
      <SearchBar
        onChangeSearch={handleFilterSelect}
        searchText={searchText}
        wrapperClass="flex items-center md:w-96 lg:mr-8"
      />
      <SortByFilter
        options={[
          { id: 1, name: 'Lastest' },
          { id: 2, name: 'Earliest' },
          { id: 3, name: 'A – Z' },
          { id: 4, name: 'Z – A' },
        ]}
        selected={selectedFilter}
        handleClickOption={handleClickOption}
        className="text-ubblue ml-4 min-w-[100px]"
      />
    </div>
  );
}

export default ToolsBar;
