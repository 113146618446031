const UPDATESTEP1WELCOME = 'UPDATESTEP1WELCOME';
const UPDATESTEP2WELCOME = 'UPDATESTEP2WELCOME';
const UPDATESTEP3WELCOME = 'UPDATESTEP3WELCOME';

const updateWelcome3 = (payload) => ({
  type: UPDATESTEP3WELCOME,
  payload: payload,
});

const updateWelcome1 = (payload) => ({
  type: UPDATESTEP1WELCOME,
  payload: payload,
});

const updateWelcome2 = (payload) => ({
  type: UPDATESTEP2WELCOME,
  payload: payload,
});

export {
  UPDATESTEP1WELCOME,
  UPDATESTEP2WELCOME,
  UPDATESTEP3WELCOME,
  updateWelcome1,
  updateWelcome2,
  updateWelcome3,
};
