import { useEffect } from 'react';

import { checkAuth } from '../../config/token';
import req, { useApiData } from '../../redux-setup';

import { AUTHENTICATION } from './constants';

// eslint-disable-next-line import/prefer-default-export
export const useCheckAuth = () => {
  const isAuth = useApiData(AUTHENTICATION);
  useEffect(() => {
    const auth = checkAuth();
    req.set(AUTHENTICATION, auth);
  }, []);

  return isAuth;
};
