import React from 'react';

import PropTypes from 'prop-types';

import { FormProvider } from '../../../context/FormValue';
import { InformationProvider } from '../../../context/Personalize';
import { UtilsProvider } from '../../../context/Utils';

function ContextProviders({ children }) {
  return (
    <InformationProvider>
      <UtilsProvider>
        <FormProvider>{children}</FormProvider>
      </UtilsProvider>
    </InformationProvider>
  );
}

ContextProviders.propTypes = {
  children: PropTypes.node.isRequired,
};
export default ContextProviders;
