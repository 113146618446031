import React from 'react';
import './Loader.css';
import { Loader as LoaderUnbounce } from '@unbounce/ui-components';

function Loader() {
  return (
    <div className="loader">
      <LoaderUnbounce />
    </div>
  );
}

export default Loader;
