import React from 'react';

import { Formik, Form, Field } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import { Input } from '../../common/Formik/FormInputs';

// validation
const rqdmsg = 'This field is required.';

const inputValidation = Yup.string()
  .when('activeKey', (activeKey, schema) => (!activeKey ? schema.required(rqdmsg) : schema.nullable()))
  .max(30)
  .label('Headline');

const validateForm = Yup.object().shape({
  activeKey: Yup.bool(),
  headline1: inputValidation,
  headline2: inputValidation,
  headline3: inputValidation,
  description: Yup.string().required(rqdmsg).max(90).label('Description'),
  description2: Yup.string().required(rqdmsg).max(90).label('Description'),
});
// comment for commit
// eslint-disable-next-line react/display-name
const GoogleAdForm = React.forwardRef(({ initialValues, onSubmit }, ref) => (
  <Formik
    innerRef={ref}
    initialValues={initialValues}
    validationSchema={validateForm}
    onSubmit={(values, { setSubmitting }) => onSubmit(values, setSubmitting)}
    enableReinitialize
  >
    {({ values }) => (
      <Form className="">
        <h4 className="text-gray-700 text-lg tracking-wide font-semibold">
          Google Ad Headlines
        </h4>
        <Field
          component={Input}
          type="text"
          name="headline1"
          value={values.headline1}
          placeholder="Google Ad Headline 1"
          wrapperClass="mt-6 mb-6"
          counter={30}
        />
        <Field
          component={Input}
          type="text"
          name="headline2"
          value={values.headline2}
          placeholder="Google Ad Headline 2"
          wrapperClass="mb-6"
          counter={30}
        />
        <Field
          component={Input}
          type="text"
          name="headline3"
          value={values.headline3}
          placeholder="Google Ad Headline 3"
          wrapperClass="mb-6"
          counter={30}
        />
        <h4 className="text-gray-700 text-lg tracking-wide font-semibold mt-8">
          Google Ad Descriptions
        </h4>
        <Field
          component={Input}
          name="description"
          value={values.description}
          placeholder="Google Ad Description 1"
          wrapperClass="mt-6"
          counter={90}
        />
        <Field
          component={Input}
          name="description2"
          value={values.description2}
          placeholder="Google Ad Description 2"
          wrapperClass="mt-6"
          counter={90}
        />
      </Form>
    )}
  </Formik>
));

GoogleAdForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  initialValues: PropTypes.objectOf(PropTypes.any).isRequired,
  onSubmit: PropTypes.func.isRequired,
};
export default GoogleAdForm;
