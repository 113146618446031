/* eslint-disable react/no-array-index-key */
import React from 'react';
import { v4 } from 'uuid';
// eslint-disable-next-line import/no-cycle
import PageLayout from '../../partials/pagelayout/containers/PageLayout';
import TaglinesTemplate from '../components/templates/TaglinesTemplate';

export default function Taglines() {
  return (
    <PageLayout template="taglines" title="Tagline">
      {({ contents }) => React.Children.toArray(
        contents.map((tag) => <TaglinesTemplate key={v4()} data={tag} />),
      )}
    </PageLayout>
  );
}
