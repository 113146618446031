import React from 'react';
import Tippy from '@tippyjs/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IBrandFilterProps } from './props';
import {
  faCircleExclamationCheck,
  faOctagonXmark,
  faTriangleExclamation,
  faLoader,
} from '@fortawesome/pro-regular-svg-icons';

function BrandFilter({ label }: IBrandFilterProps) {
  const textContent = () => {
    if (label === 0) {
      return (
        <span className="">
          The text is <span className="font-medium text-shamrock">safe</span>.
        </span>
      );
    } else if (label === 1) {
      return (
        <span>
          This text is{' '}
          <span className="font-medium text-uorange">sensitive</span>. This
          means that the text could be talking about a sensitive topic,
          something political, religious, or talking about a protected class
          such as race or nationality.
        </span>
      );
    } else if (label === 2) {
      return (
        <span>
          This text is <span className="font-medium text-amaranth">unsafe</span>
          . This means that the text contains profane language, prejudiced or
          hateful language, something that could be NSFW, or text that portrays
          certain groups/people in a harmful manner.
        </span>
      );
    } else {
      return 'Our brand safety filter is loading.';
    }
  };

  const iconContent = () => {
    if (label === 0) {
      return (
        <FontAwesomeIcon
          icon={faCircleExclamationCheck}
          className="mr-2 text-shamrock"
        />
      );
    } else if (label === 1) {
      return (
        <FontAwesomeIcon
          icon={faTriangleExclamation}
          className="mr-2 text-uorange"
        />
      );
    } else if (label === 2) {
      return (
        <FontAwesomeIcon
          icon={faOctagonXmark}
          className="mr-2 text-radical-red"
        />
      );
    } else {
      return <FontAwesomeIcon icon={faLoader} className="mr-2 text-ubblue" />;
    }
  };

  return (
    <Tippy content={textContent()} theme="light-border">
      {iconContent()}
    </Tippy>
  );
}

export default BrandFilter;
