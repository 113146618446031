import React, { useEffect, useState } from 'react';

import {
  Route,
  Switch,
  useRouteMatch,
  Redirect,
  useLocation,
} from 'react-router-dom';

import { useApiData } from '../../redux-setup';

import AuthorRoutesContainer from './AuthorRoutesContainer';
import AuthoringPage from './AuthoringPage';
import { getTemplates } from './actions';

function FindTemplateID() {
  const match = useRouteMatch();
  const location = useLocation();
  const [template, setTemplate] = useState(null);
  const id = +location.pathname.slice(1).split('/')[1];
  const templates = useApiData('templates');

  useEffect(() => {
    getTemplates();
  }, []);

  React.useEffect(() => {
    if (templates) {
      const temp = templates.filter((t) => t.id === id)[0];
      setTemplate(temp || 'FAILED');
    }
  }, [id, templates]);

  if (!template) return <p />;
  if (template && template === 'FAILED') return <Redirect to="/author" />;
  return (
    <Route
      key={template?.id}
      path={`${match.url}/${template?.id}`}
      render={() => <AuthoringPage template={template} />}
      exact
    />
  );
}

function AuthorRoutes() {
  const match = useRouteMatch();
  const templates = useApiData('templates') || [];
  return (
    <Switch>
      {templates?.length
        && templates.map((template) => (
          <Route
            key={template?.id}
            path={`${match.url}/${template?.id}`}
            render={() => <AuthoringPage template={template} />}
            exact
          />
        ))}
      <Route exact path={match.url} component={AuthorRoutesContainer} />
      <Route path={match.url} component={FindTemplateID} />
    </Switch>
  );
}

export default AuthorRoutes;
