/* eslint-disable import/no-cycle */
import { CgShoppingBag, CgFacebook } from 'react-icons/cg';
import { FaExpandAlt } from 'react-icons/fa';
import {
  FiGlobe,
  FiAnchor,
  FiSun,
  FiMail,
  FiRepeat,
  FiChrome,
} from 'react-icons/fi';
import { SiGoogleads } from 'react-icons/si';
import { v4 as uuidv4 } from 'uuid';

import GoogleAds from './containers/GoogleAds';
import LandingPages from './containers/LandingPages';
import ProductionDescriptions from './containers/ProductionDescriptions';
import Taglines from './containers/Taglines';
import ContentExpander from '../tools/containers/ContentExpander';
import Remixer from '../tools/containers/Remixer';
import ToneChanger from '../tools/containers/ToneChanger';

import AngelEmail from './containers/AngelEmail';
import BlogIdeas from './containers/BlogIdeas';
import ColdEmail from './containers/ColdEmail';
import FacebookAds from './containers/FacebookAds';
import TiktokIdeas from './containers/TiktokIdeas';
import VcEmail from './containers/VcEmail';
import Benefits from './containers/itemize/Benefits';
import HeaderDescription from './containers/itemize/HeaderDescription';
import WebsiteFeature from './containers/itemize/WebsiteFeature';

export const templatesContainerVariants = {
  hide: {
    x: -20,
  },
  show: {
    x: 0,
    transition: {
      staggerChildren: 0.05,
    },
  },
};

export const templateVariants = {
  hide: {
    x: -20,
    opacity: 0,
  },
  show: {
    x: 0,
    opacity: 1,
  },
};

export const FAVORITE_TEMPLATES = 'TEMPLATES/favorited';

export const toolsList = {
  config: {
    color: 'green',
  },
  templates: [
    {
      id: uuidv4(),
      title: 'Content Expander',
      slug: '/content-expander',
      logo: FaExpandAlt,
      description:
        'Expand a single sentence or bullet points into a complete thought.',
      notAvailable: false,
      type: 'tools',
      code: 'content-expander',
      component: ContentExpander,
      collapse: true,
    },
    {
      id: uuidv4(),
      title: 'Remix',
      slug: '/remixer',
      logo: FiRepeat,
      description:
        'Create limitless variations of a piece of content and unleash your creativity with the Remix tool.',
      notAvailable: false,
      type: 'tools',
      isNew: true,
      code: 'remixer',
      collapse: true,
      component: Remixer,
    },
    {
      id: uuidv4(),
      title: 'Tone Changer',
      slug: '/tone-changer',
      logo: FiRepeat,
      description:
        'Create limitless variations of a piece of content and unleash your creativity with the Remix tool.',
      notAvailable: false,
      type: 'tools',
      isNew: true,
      code: 'tone-changer',
      collapse: true,
      component: ToneChanger,
    },
    {
      id: uuidv4(),
      title: 'Chrome Extension',
      logo: FiChrome,
      collapse: true,
      link:
        'https://chrome.google.com/webstore/detail/smart-copy-write-better-m/elheiaagkmeiekjhkidoabinjajnohgo',
    },
  ],
};

export const recommendedList = {
  templates: [
    {
      id: uuidv4(),
      title: 'Blog Ideas',
      slug: '/blog-ideas',
      logo: FiSun,
      color: 'indigo',
      description:
        'You know you should write something. This template helps you take the first step towards an article.',
      notAvailable: false,
      type: 'template',
      component: BlogIdeas,
      // isNew: true,
    },
    {
      id: uuidv4(),
      title: 'Landing Pages',
      slug: '/landing-pages',
      logo: FiGlobe,
      color: 'blue',
      description:
        'Reimagine your landing page with limitless variations of new or existing copy.',
      notAvailable: false,
      type: 'template',
      component: LandingPages,
    },
    {
      id: uuidv4(),
      title: 'Google Ads',
      slug: '/google-ads',
      logo: SiGoogleads,
      color: 'green',
      description:
        'Create Google Ads from scratch with exact requirements and layout required for use.',
      notAvailable: false,
      type: 'template',
      component: GoogleAds,
    },
  ],
};

export const templates = {
  Emails: {
    config: {
      color: 'green',
    },
    templates: [
      {
        id: uuidv4(),
        title: 'Sales Outreach',
        slug: '/cold-email',
        logo: FiMail,
        description:
          'Build new connections and land clients with a compelling bespoke email.',
        notAvailable: false,
        type: 'template',
        component: ColdEmail,
        isNew: false,
      },
      {
        id: uuidv4(),
        title: 'Pitch a VC',
        slug: '/vc-email',
        logo: FiMail,
        description:
          'Ready to grab that Series A round? Try out the Pitch A VC template for some inspiration.',
        type: 'template',
        component: VcEmail,
        isNew: true,
      },
      {
        id: uuidv4(),
        title: 'Pitch an Angel',
        slug: '/angel-email',
        logo: FiMail,
        description:
          'Just starting out and could use a little kickstart to your seed round? Try pitching an Angel Investor with this template.',
        type: 'template',
        component: AngelEmail,
        isNew: true,
      },
    ],
  },
  Writing: {
    config: {
      color: 'indigo',
    },
    templates: [
      {
        id: uuidv4(),
        title: 'Product Description',
        slug: '/product-descriptions',
        logo: CgShoppingBag,
        description:
          "Launching a new product? Let's couple it with a catchy product description.",
        notAvailable: false,
        type: 'template',
        component: ProductionDescriptions,
      },
      {
        id: uuidv4(),
        title: 'TikTok Video Ideas',
        slug: '/tiktok-ideas',
        logo: FiSun,
        description:
          "Looking to brainstorm video ideas to boost your followers? We've got you covered.",
        notAvailable: false,
        type: 'template',
        component: TiktokIdeas,
        // isNew: true,
      },
    ],
  },
  Website: {
    config: {
      color: 'blue',
    },
    templates: [
      {
        id: uuidv4(),
        title: 'Header + Description',
        slug: '/header-description',
        logo: FiGlobe,
        description:
          'Quickly create dozens of new headlines and descriptions for your website.',
        notAvailable: false,
        type: 'template',
        component: HeaderDescription,
        // isNew: true,
      },
      {
        id: uuidv4(),
        title: 'Features',
        slug: '/features',
        logo: FiGlobe,
        description:
          'What features should you highlight? Always a tricky decision. This template helps your pick from the best of the bunch.',
        notAvailable: false,
        type: 'template',
        component: WebsiteFeature,
        // isNew: true,
      },
      {
        id: uuidv4(),
        title: 'Benefits',
        slug: '/benefits',
        logo: FiGlobe,
        description:
          'Why is your company important to your customers? Tell them with the Benefits template.',
        notAvailable: false,
        type: 'template',
        component: Benefits,
        // isNew: true,
      },
    ],
  },
  Ads: {
    config: {
      color: 'blue',
    },
    templates: [
      {
        id: uuidv4(),
        title: 'Google Ads',
        slug: '/google-ads',
        logo: SiGoogleads,
        description:
          'Create Google Ads from scratch with exact requirements and layout required for use.',
        notAvailable: false,
        type: 'template',
        component: GoogleAds,
      },
      {
        id: uuidv4(),
        title: 'Facebook Ads',
        slug: '/facebook-ads',
        logo: CgFacebook,
        description: 'A limitless supply of original Facebook Ad copy.',
        notAvailable: false,
        type: 'template',
        component: FacebookAds,
      },
    ],
  },
  Brand: {
    config: {
      color: 'purple',
    },
    templates: [
      {
        id: uuidv4(),
        title: 'Taglines',
        slug: '/tagline',
        logo: FiAnchor,
        description:
          "What's better than a catchy brand slogan? Not much. Create a new one in seconds.",
        notAvailable: false,
        type: 'template',
        component: Taglines,
      },
    ],
  },
};
