import React from 'react';

import { IoMdOpen } from 'react-icons/io';
import { MdContentCopy } from 'react-icons/md';

import { AlertMarker, createAlert } from '../../common/Alerts';
import FormSwitch from '../../common/FormSwitch';
import Modal from '../../common/Modal';
import Tooltip from '../../common/Tooltip';
import { copyWords } from '../../helpers';
import req, { useApiData } from '../../../redux-setup';

import { updateShareTemplateStatus } from '../actions';
import ShareActions from '../components/share/ShareActions';
import { SHARE_DATA } from '../components/share/constants';

function ShareModal() {
  const {
    shared,
    sharing_id: sharingId,
    templateCode,
    updateShareStatus,
    onEditMode,
    ...data
  } = useApiData(SHARE_DATA, {});
  const [isShared, setIsShared] = React.useState(shared || false);
  const shareLink = `${window.location.origin}/share/${sharingId}`;
  return (
    <Modal
      isOpen
      closeModal={() => req.omit(SHARE_DATA)}
      width={500}
      showHeader={false}
    >
      <div className="w-full flex flex-col p-4 space-y-6">
        <FormSwitch
          label={`Sharing ${isShared ? 'Enabled' : 'Disabled'}`}
          labelWrapperClass="font-semibold text-lg"
          checkedIcon={false}
          uncheckedIcon={false}
          checked={isShared}
          onChange={(val) => {
            if (!val) updateShareStatus(false);
            else updateShareStatus(true);

            updateShareTemplateStatus(
              onEditMode ? data?.id : data?.saveId,
              data?.id,
              templateCode,
              val,
              onEditMode ? 'saveId' : 'id',
            );

            setIsShared(val);
          }}
        />
        <AlertMarker name="share-modal" />
        <div className="space-y-4 text-gray-400 flex flex-col w-full">
          <p className="text-base">Copy and paste this link to share.</p>
          <div className="flex items-center w-full space-x-2">
            <input
              type="text"
              className="rounded-xl flex-1 bg-gray-100 p-2 outline-none w-full"
              value={shareLink}
              readOnly
            />
            <div className="flex items-start">
              <Tooltip text="Copy" width={75}>
                <button
                  type="button"
                  disabled={!isShared}
                  onClick={() => copyWords(shareLink).then(() => {
                    createAlert.success(
                      'share-modal',
                      'Copied to clipboard',
                    );
                  })}
                >
                  <MdContentCopy size={25} />
                </button>
              </Tooltip>
              <Tooltip
                disabled={!isShared}
                position="left"
                text="Open in new tab"
                width={170}
              >
                {isShared ? (
                  <a
                    className="flex"
                    href={shareLink}
                    disabled={!isShared}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <IoMdOpen size={25} />
                  </a>
                ) : (
                  <IoMdOpen size={25} />
                )}
              </Tooltip>
            </div>
          </div>
        </div>
        <div className="w-full flex items-center font-semibold space-x-4">
          <h6 className="text-sm text-gray-500">OR</h6>
          <div className="border-t border-gray-500 w-full" />
        </div>
        <ShareActions
          isShared={isShared}
          sharingId={sharingId}
          shareLink={shareLink}
        />
      </div>
    </Modal>
  );
}

ShareModal.propTypes = {};

export default ShareModal;
