import React from 'react';

function TitleDescriptionSkeleton() {
  return (
    <div className="flex justify-center flex-wrap mt-4 w-full">
      <div className="flex justify-between mb-4 w-full">
        <div className="h-2.5 bg-gray-200 rounded-full w-32 mb-2" />
        <div className="h-2.5 bg-gray-200 rounded-full w-32 mb-2" />
      </div>
      <div className="h-5 bg-gray-200 rounded-full w-2/3 mb-4" />
      <div className="h-4 bg-gray-200 rounded-full w-2/3 mb-4" />
      <div className="flex w-full justify-center gap-6">
        <div className="h-4 bg-gray-200 rounded w-24 mb-4" />
        <div className="h-4 bg-gray-200 rounded w-24 mb-4" />
      </div>
    </div>
  );
}

export default TitleDescriptionSkeleton;
