import React from 'react';

import PropTypes from 'prop-types';
import ContentEditable from 'react-contenteditable';

import useTemplateState from '../../../../hooks/useTemplateState';
import { editTemplate } from '../../../savedcontent/actions';

import TemplateFooter from '../footer/TemplateFooter';

function ProdDescriptionTemplate({ data, onEditMode, hideFooter }) {
  const { templateValue, handleOnChange } = useTemplateState(data);
  const {
    companyName,
    productDescription,
    productName,
    // eslint-disable-next-line no-unsafe-optional-chaining
  } = templateValue?.content;
  return (
    <div
      style={{
        position: 'relative',
        marginBottom: 25,
      }}
    >
      <div
        className="bg-white py-8 p-8 mb-2 shadow-md font-source overflow-auto h-auto"
        style={{
          boxShadow: '0px 4px 18px rgba(0, 0, 0, 0.06)',
          borderRadius: '20px',
        }}
      >
        <div
          className="rounded-lg sm:float-left mr-6 mb-2 w-full sm:w-2/5 h-auto flex justify-center items-center text-center uppercase font-black text-3xl bg-indigo-500 text-white "
          style={{ minHeight: 270 }}
        >
          {productName}
        </div>
        <h3 className="text-sm sm:text-base font-semibold text-gray-400">
          <ContentEditable
            disabled={!onEditMode}
            html={companyName}
            onBlur={({ target: { innerHTML } }) =>
              editTemplate(innerHTML, data, 'content.companyName')
            }
            onChange={(e) =>
              handleOnChange(e.target.value, 'content.companyName')
            }
            spellCheck={false}
          />
        </h3>
        <h3 className="text-2xl xlg:text-3xl font-semibold mt-3">
          <ContentEditable
            disabled={!onEditMode}
            html={productName}
            onBlur={({ target: { innerHTML } }) =>
              editTemplate(innerHTML, data, 'content.productName')
            }
            onChange={(e) =>
              handleOnChange(e.target.value, 'content.productName')
            }
            spellCheck={false}
          />
        </h3>
        <div className="mt-3 text-sm sm:text-base">
          <ContentEditable
            html={productDescription}
            disabled={!onEditMode}
            onBlur={({ target: { innerHTML } }) =>
              editTemplate(innerHTML, data, 'content.productDescription')
            }
            onChange={(e) =>
              handleOnChange(e.target.value, 'content.productDescription')
            }
            spellCheck={false}
          />
        </div>
      </div>
      {!hideFooter && (
        <TemplateFooter
          data={templateValue}
          onEditMode={onEditMode}
          handleOnChange={handleOnChange}
          type="product-descriptions"
        />
      )}
    </div>
  );
}

ProdDescriptionTemplate.defaultProps = {
  data: {},
  onEditMode: false,
  hideFooter: false,
};
ProdDescriptionTemplate.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.objectOf(PropTypes.any),
  onEditMode: PropTypes.bool,
  hideFooter: PropTypes.bool,
};

export default ProdDescriptionTemplate;
