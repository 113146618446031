/* eslint-disable no-nested-ternary */
import React from 'react';
import Tippy from '@tippyjs/react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as faStarSolid } from '@fortawesome/pro-solid-svg-icons';
import { faStar } from '@fortawesome/pro-regular-svg-icons';
import {
  getAvailableTemplates,
  markAsFavorite,
  unmarkAsFavorite,
} from '../templates/actions';
import { useCurrentTemplate } from '../templates/hooks';

function Header({ title }) {
  const [isLoading, setLoading] = React.useState(false);
  const templateData = useCurrentTemplate();
  return (
    <div className="flex items-center">
      <div className="flex text-3xl xlg:text-3xl font-barlow font-medium">
        {title}
      </div>
      <Tippy
        content={
          templateData.is_favorited
            ? 'Remove from favorites'
            : 'Add to favorites'
        }
      >
        <button
          type="button"
          className="ml-2"
          onClick={async (e) => {
            e.stopPropagation();
            e.preventDefault();
            // setFavorite(!isFavorite);
            setLoading(true);
            try {
              if (templateData?.is_favorited) {
                await unmarkAsFavorite(templateData?.id);
              } else await markAsFavorite(templateData?.id);
              await getAvailableTemplates();
              // eslint-disable-next-line no-empty
            } catch (_e) {
            } finally {
              setLoading(false);
            }
          }}
          disabled={isLoading}
        >
          {isLoading ? (
            <p className="text-xs font-source text-ugray italic">Saving...</p>
          ) : templateData?.is_favorited ? (
            <FontAwesomeIcon
              icon={faStarSolid}
              className="text-uorange text-lg"
            />
          ) : (
            <FontAwesomeIcon icon={faStar} className="text-uorange text-lg" />
          )}
        </button>
      </Tippy>
    </div>
  );
}

Header.propTypes = {
  title: PropTypes.string.isRequired,
};
export default Header;
