/* eslint-disable camelcase */
import { useCallback } from 'react';
import { getGeneratorId } from '../partials/pagelayout/actions';
import { useSelector } from 'react-redux';

// eslint-disable-next-line import/prefer-default-export
export const useFetchContent = () => {
  const id = useSelector((state) => state['PERSONALIZE/DATA'].project_default);
  const writeContent = useCallback(
    // eslint-disable-next-line no-async-promise-executor
    async (original = '', template = 'V2 Template', template_id = 159) =>
      // eslint-disable-next-line no-async-promise-executor
      new Promise(async (res) => {
        const payload = {
          original,
        };
        try {
          const data = await new Promise((resolve) => {
            getGeneratorId(
              false,
              null,
              {
                template,
                template_id,
              },
              id,
              payload,
              null,
              (dt) => resolve(dt),
            );
          });
          res(data?.contents);
        } catch (_e) {
          res([]);
        }
      }),
    [id],
  );

  return writeContent;
};
