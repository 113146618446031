import React from 'react';
import BenefitsResult from './BenefitsResult';
import ArrayTemplateResult from './ArrayTemplateResult';
import FeaturesResult from './FeaturesResult';
import HeaderDescriptionResult from './HeaderDescriptionResult';
import LandingPageResult from './LandingPageResult';
import PitchAVCResult from './PitchAVCResult';
import ProductDescriptionResult from './ProductDescriptionResult';
import { ITemplateResultProps } from './props';
import StringEmailResult from './StringEmailResult';
import StringTemplateResult from './StringTemplateResult';
import GoogleAdsResult from './GoogleAdsResult';
import FacebookAdsResult from './FacebookAdsResult';
import EmailSubjectResult from './EmailSubjectResult';

function TemplateResult({ data }: ITemplateResultProps) {
  switch (data.templateName.toLowerCase()) {
    case 'landing page':
      return <LandingPageResult data={data} />;
    case 'header + description':
      return <HeaderDescriptionResult data={data} />;
    case 'benefits':
      return <BenefitsResult data={data} />;
    case 'features':
      return <FeaturesResult data={data} />;
    case 'pitch a vc':
    case 'cold-email':
    case 'sales outreach':
    case 'pitch an angel':
      return <PitchAVCResult data={data} />;
    case 'lead nurture email':
      return <StringEmailResult data={data} />;
    case 'product description':
      if (typeof data.content === 'string') {
        return <StringTemplateResult data={data} />;
      } else {
        return <ProductDescriptionResult data={data} />;
      }
    case 'google ads':
      return <GoogleAdsResult data={data} />;
    case 'facebook ads':
      return <FacebookAdsResult data={data} />;
    case 'email subject lines':
      return <EmailSubjectResult data={data} />;
    case 'content expander':
    case 'remix':
      if (typeof data.content === 'object') {
        return <ArrayTemplateResult data={data} />;
      } else {
        return <StringTemplateResult data={data} />;
      }
      break;
    case 'blog-ideas':
    default:
      return <StringTemplateResult data={data} />;
  }
}
export default TemplateResult;
