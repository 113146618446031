import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { sendUBCompEmail } from '../../common/sendInfo';
import Loader from '../common/Loader/Loader';
import { isFramed } from '../helpers';
import LoginUnbounce from './LoginUnbounce';
import FirstStepUB from './LoginUnbounce/FirstStepUB';
import SignupPassword from './LoginUnbounce/SignupPassword';

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

function UnbouncePage() {
  const history = useHistory();
  const [showLoader, setShowLoader] = useState(true);
  const [step, setStep] = useState(0);
  const query = useQuery();
  const email = query.get('email');
  const ubid = query.get('ubid');
  const test = query.get('trial') === 'true';

  // we need to look with the email to te page and know the result there
  useEffect(() => {
    if (!isFramed()) {
      history.push('/');
    } else {
      sessionStorage.setItem('ube', email || '');
      sessionStorage.setItem('ubi', ubid || '');
      sessionStorage.setItem('ubt', test.toString() || '');
    }
  }, []);

  useEffect(() => {
    if (email && isFramed()) {
      sendUBCompEmail({ email, ub: ubid, trial: test })
        .then((response) => response.json())
        .then((result) => {
          setShowLoader(false);
          if (result.data.sc) {
            setStep(3);
          } else {
            setStep(1);
          }
        });
    }
  }, [email]);

  switch (step) {
    case 1:
      return <FirstStepUB setStep={setStep} email={email || ''} />;
    case 2:
      return <SignupPassword email={email || ''} />;
    case 3:
      return <LoginUnbounce email={email || ''} />;
    case 0:
    default:
      return <div>{showLoader && <Loader />}</div>;
  }
}

export default UnbouncePage;
