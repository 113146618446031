import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FaChevronLeft, FaTimes } from 'react-icons/fa';
// eslint-disable-next-line import/no-cycle
import { axiosController } from '../../../index';
import req from '../../../redux-setup';
import { WRITER_SIDEBAR } from '../contants';
import WriterTemplatesList from './WriterTemplatesList';
import { TemplateSidebarContext } from '../context';

export const templateNumber = {
  expand: 161,
  summarize: 164,
  remix: 159,
  next: 160,
  writemore: 165,
};

function WriterTemplateSidebar({ editor }) {
  const [viewProfile, setViewProfile] = React.useState(false);
  React.useEffect(
    // generate templates list here
    () => () => {
      axiosController.abort();
    },
    [],
  );
  useEffect(() => {
    if (editor) {
      editor.execCommand(
        'mceInsertContent',
        false,
        '<span class="markerT">\ufeff</span>',
      );
      editor
        .getBody()
        .querySelectorAll('span.markerT')
        .forEach((elem) => {
          elem.remove();
        });
    }
  }, [editor]);
  if (!editor) return null;
  return (
    <TemplateSidebarContext.Provider
      value={{
        viewProfile,
        setViewProfile,
        editor,
      }}
    >
      <div
        className="flex flex-grow flex-col pr-4"
        style={{
          width: '35%',
          minWidth: 250,
          maxWidth: 400,
        }}
      >
        <div className="w-full flex justify-between items-center pt-2">
          <div className="flex items-center space-x-2">
            {viewProfile && (
              <button
                className="text-ubblue"
                onClick={() => setViewProfile(false)}
              >
                <FaChevronLeft />
              </button>
            )}
            <div>
              <p
                className="capitalize font-barlow font-semibold text-ubblack"
                style={{
                  fontSize: 20,
                }}
              >
                Templates
              </p>
              {!viewProfile && (
                <p className="text-sm italic mt-2 text-ugray">
                  Choose a template to continue writing.
                </p>
              )}
            </div>
          </div>
          <button
            type="button"
            onClick={() => req.set(WRITER_SIDEBAR, {})}
            className="bg-white text-gray-500 border border-gray-200 text-sm hover:text-ubblue py-1.5 px-1.5 font-semibold rounded-xl"
          >
            <FaTimes />
          </button>
        </div>
        <div className="relative flex flex-col flex-grow mt-4">
          <WriterTemplatesList />
        </div>
      </div>
    </TemplateSidebarContext.Provider>
  );
}
WriterTemplateSidebar.propTypes = {
  editor: PropTypes.oneOfType([PropTypes.object]),
};
export default WriterTemplateSidebar;
