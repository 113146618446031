import { useCallback } from 'react';
import { clsx } from 'clsx';
import { RootStateOrAny, useSelector } from 'react-redux';
import Button from '../../../common/Button';
import IExampleCardTextProps from './props';

function ExampleCardText({
  planName,
  annual,
  priceYear,
  priceMonthly,
  className,
  selected,
  ribbonText,
  onCheckout,
  creditsPerMonth,
  projects,
  seo,
  support,
  currentPlan,
  fromOriginalPrice,
  creditsTooltip,
  onUpgradeDowngrade,
  disabled,
  selectedPlan,
  onCanceling,
  onReactivateClick,
}: IExampleCardTextProps) {
  const { isPaid } = useSelector(
    (state: RootStateOrAny) => state['PERSONALIZE/DATA'],
  );
  const word = useCallback(() => {
    switch (planName) {
      case 'essential':
        if (currentPlan === 'growth' || currentPlan === 'unlimited') {
          return 'Downgrade';
        }
        return 'Upgrade';
      case 'growth':
      case 'unlimited':
      default:
        return 'Upgrade';
    }
  }, [planName]);
  return (
    <div
      className={clsx(
        'bg-uwhite text-center py-6',
        !annual && 'rounded-r-xl',
        selected &&
          'border-4 border-shamrock rounded-xl scale-105 drop-shadow-lg',
        className,
        ribbonText && 'relative overflow-hidden',
      )}
    >
      {ribbonText && (
        <span
          className="text-sm bg-shamrock absolute text-white font-semibold py-.5"
          style={{
            transform: 'rotate(45deg)',
            width: '150px',
            height: '18px',
          }}
        >
          <span className="w-full">{ribbonText}</span>
        </span>
      )}
      <h3
        className={clsx('font-barlow font-medium', currentPlan && 'text-ugray')}
      >
        {planName.toUpperCase()}
      </h3>
      <div className="text-center">
        {fromOriginalPrice && (
          <h2
            className={clsx(
              'font-barlow font-semibold text-3xl text-shamrock line-through inline-block mr-1',
              (selectedPlan || disabled) && 'text-jungle-green',
            )}
          >{`$${fromOriginalPrice}`}</h2>
        )}
        <h2
          className={clsx(
            'font-barlow font-semibold text-3xl inline-block',
            (selectedPlan || disabled) && 'text-ugray',
          )}
        >
          {`$${priceMonthly}`}
          <span className="text-base font-normal">/month</span>
        </h2>
      </div>
      {annual ? (
        <span className="text-silver-chalice text-xs">
          {`$${priceYear}`} Billed Annually
        </span>
      ) : (
        <span className="text-xs invisible">Billed</span>
      )}
      <ul className="text-center mt-2">
        <li
          className={clsx(
            'mb-4 text-sm font-semibold',
            currentPlan ? 'text-ugray' : 'text-mine-shaft',
          )}
        >
          45 + Templates
        </li>
        <li
          className={clsx(
            'mb-4 text-sm font-semibold',
            currentPlan ? 'text-ugray' : 'text-mine-shaft',
          )}
        >
          30+ Languages
        </li>
        <li
          className={clsx(
            'mb-4 text-sm font-semibold',
            currentPlan ? 'text-ugray' : 'text-mine-shaft',
          )}
        >
          Chrome Extension
        </li>
        <li
          className={clsx(
            'mb-4 text-sm font-semibold',
            currentPlan ? 'text-ugray' : 'text-mine-shaft',
          )}
        >
          Desktop App
        </li>
        <li
          className={clsx(
            'mb-4 font-semibold text-sm',
            currentPlan && 'text-ugray',
          )}
        >
          {creditsPerMonth}
        </li>
        <li
          className={clsx(
            'mb-4 font-semibold text-sm',
            currentPlan && 'text-ugray',
          )}
        >
          {projects}
        </li>
        <li
          className={clsx(
            'mb-4 text-sm font-semibold',
            currentPlan ? 'text-ugray' : 'text-mine-shaft',
          )}
        >
          Writer
        </li>
        <li
          className={clsx('mb-4 text-sm italic', currentPlan && 'text-ugray')}
        >
          {seo ? 'SEO Coming soon' : '-'}
        </li>
        <li
          className={clsx(
            'mb-4 font-semibold text-sm',
            currentPlan && 'text-ugray',
          )}
        >
          {support ? 'Priority' : 'Support'}
        </li>
        <li
          className={clsx(
            'mb-4 font-semibold text-sm',
            currentPlan && 'text-ugray',
          )}
        >
          Community
        </li>
      </ul>
      {/* {(planName !== 'free' || currentPlan) &&
        (currentPlan ? (
          <Button secondary disabled>
            Your Current Plan
          </Button>
        ) : (
          <Button primary onClick={onCheckout}>
            {`Upgrade to ${planName[0].toUpperCase()}${planName.slice(1)}`}
          </Button>
        ))} */}
      <div>
        {selectedPlan && !onCanceling && (
          <Button type="secondary" disabled>
            Your Current Plan
          </Button>
        )}
        {selectedPlan && onCanceling && (
          <Button
            type="alternative"
            onClick={onReactivateClick}
            className="relative"
          >
            <span
              className="animate-ping absolute -top-2 -right-2 h-6 w-6 rounded-full opacity-75"
              style={{ backgroundColor: '#FFCE00' }}
            />
            Reactivate Subscription
          </Button>
        )}
        {planName !== 'free' &&
          !disabled &&
          !selectedPlan &&
          (isPaid ? (
            <Button type="primary" onClick={onUpgradeDowngrade}>
              {`${word()} to ${planName[0].toUpperCase()}${planName.slice(1)}`}
            </Button>
          ) : (
            <Button type="primary" onClick={onCheckout}>
              {`Upgrade to ${planName[0].toUpperCase()}${planName.slice(1)}`}
            </Button>
          ))}
      </div>
    </div>
  );
}

export default ExampleCardText;
