import React, { useEffect } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { getSubscriptionsByUser } from '../../common/getInfo';
import { sendZapierError } from '../../common/sendInfo';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import SettingsTab from '../Settings/SettingsTab';
import PersonalTab from '../Settings/PersonalTab';
import APISection from '../Settings/APISection';
import BillingTab from '../Settings/BillingTab';
import ProjectsTab from './ProjectsTab';

function AdminPage() {
  const { isPaid, stripe_id } = useSelector(
    (state: RootStateOrAny) => state['PERSONALIZE/DATA'],
  );
  const { path } = useRouteMatch();

  useEffect(() => {
    if (stripe_id) {
      getSubscriptionsByUser(stripe_id)
        .then((response) => response.json())
        .then((result) => {
          if (result.code === 200 && result.subscriptions.length > 1) {
            sendZapierError({
              error: 'two or more subscriptions active',
              customer_id: stripe_id,
            });
          } else if (
            result.code === 200 &&
            !isPaid &&
            result.subscriptions.length >= 1
          ) {
            sendZapierError({
              error: 'at least one subscription and is paid false',
              customer_id: stripe_id,
            });
          }
        })
        .catch((error) => console.log(error));
    }
  }, [stripe_id, isPaid]);
  return (
    <div className="mx-auto container px-8 max-w-screen-xl">
      <h1 className="text-3xl xlg:text-3xl font-barlow font-medium mb-5 mt-5">
        Settings
      </h1>
      <SettingsTab />
      <Switch>
        <Route path={`${path}/personal`} component={PersonalTab} />
        <Route path={`${path}/projects`} component={ProjectsTab} />
        <Route path={`${path}/billing`} component={BillingTab} />
        {/* <Route path={`${path}/api`} component={APISection} /> */}
        <Route path={path} exact>
          <Redirect to="settings/projects" />
        </Route>
      </Switch>
    </div>
  );
}

export default AdminPage;
