/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, useLocation } from 'react-router-dom';
import Tippy from '@tippyjs/react';

function LinkWrapper({ children, disableActive, data, ...rest }) {
  const location = useLocation();
  const ref = React.useRef();

  const isActive = `/${location?.pathname?.split('/')[1] || ''}` === data?.slug;
  React.useEffect(() => {
    const addClassToPrevElement = () =>
      ref.current?.previousElementSibling?.classList?.add('prev');
    const removeClassToPrevElement = () =>
      ref.current?.previousElementSibling?.classList?.remove('prev');
    if (isActive) {
      addClassToPrevElement();
    } else {
      removeClassToPrevElement();
    }
  }, [isActive]);
  if (data?.slug) {
    const activeClassName = !disableActive ? 'text-ubblue active ' : '';

    return (
      <NavLink ref={ref} activeClassName={activeClassName} {...rest}>
        {children}
      </NavLink>
    );
  }

  if (data?.link) {
    return (
      <a {...rest} target="_blank">
        {children}
      </a>
    );
  }

  return <div />;
}
LinkWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  disableActive: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

function Menu({ data, favicon, onClick, disableActive, label}) {
  const { img } = data;
  return (
    <Tippy content={label} placement="right" className="hidden sm:block"><div> <LinkWrapper
      to={data?.slug}
      href={data?.link}
      data={data}
      onClick={onClick}
      id={label}
      disableActive={disableActive}
      className="flex items-center p-4 leading-4 sm:justify-center relative active:text-ubblue betterhover:hover:text-ubblue"
    >

        {favicon}


      {label && (
        <h1 className="block sm:hidden sm:pl-3 font-regular leading-snug font-barlow text-xl">
          {label}
        </h1>
      )}
    </LinkWrapper></div></Tippy>
  );
}

Menu.defaultProps = {
  favicon: null,
  onClick: () => null,
  disableActive: false,
  label: '',
  id: '',
};

Menu.propTypes = {
  label: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  favicon: PropTypes.node,
  onClick: PropTypes.func,
  disableActive: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
export default React.memo(Menu);
