/* eslint-disable camelcase */
import React from 'react';

import { MdDragHandle } from 'react-icons/md';
import { sortableElement, sortableHandle } from 'react-sortable-hoc';
import DynamicIcon from '../../common/DynamicIcon';

const DragHandle = sortableHandle(() => <MdDragHandle size={23} />);

const SortableItem = sortableElement(({ content }) => {
  const {
    title, card_icon, color, description,
  } = content;

  return (
    <div className="flex justify-between p-6 w-full border rounded-md h-full bg-white text-left mb-1">
      <div className="flex flex-wrap md:flex-nowrap gap-2 items-center w-full">
        <div className={`p-2 rounded bg-${color}-100`}>
          <DynamicIcon
            name={card_icon}
            size={18}
            className={`p-0 m-0 text-${color}-700`}
          />
        </div>
        <p className="text-base text-black mr-2 md:w-3/12">{title}</p>
        <p className="text-gray-500 font-thin text-sm w-full md:w-8/12">
          {description}
        </p>
      </div>
      <div className="flex items-center text-gray-500 min-w-20 pl-2">
        <DragHandle />
      </div>
    </div>
  );
});
export default SortableItem;
