/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import CreateTableSelect from 'react-select/creatable';
import { clsx } from 'clsx';
import { useFormikContext } from 'formik';
import get from 'lodash/get';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { MdInfo, MdClose } from 'react-icons/md';
import RSelect from 'react-select';
import TooltipNew from '../../../common/Tooltip';
import Tooltip from '../Tooltip';
import KeywordInput from '../../../common/Keywords/KeywordInput';
import Keyword from '../../../common/Keywords/Keyword';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons';

const sizeAttributes = {
  lg: {
    label: 'text-lg',
    input: 'text-sm sm:text-base',
  },
  sm: {
    label: 'text-sm',
    input: 'text-sm',
  },
};

const toneOptions = [
  { label: 'Adventurous', value: 'adventurous' },
  { label: 'Analytical', value: 'analytical' },
  { label: 'Appreciative', value: 'appreciative' },
  { label: 'Assertive', value: 'assertive' },
  { label: 'Awestruck', value: 'awestruck' },
  { label: 'Bold', value: 'bold' },
  { label: 'Candid', value: 'candid' },
  { label: 'Casual', value: 'casual' },
  { label: 'Cautionary', value: 'cautionary' },
  { label: 'Compassionate', value: 'compassionate' },
  { label: 'Confident', value: 'confident' },
  { label: 'Conversational', value: 'conversational' },
  { label: 'Convincing', value: 'convincing' },
  { label: 'Cooperative', value: 'cooperative' },
  { label: 'Critical', value: 'critical' },
  { label: 'Curious', value: 'curious' },
  { label: 'Dramatic', value: 'dramatic' },
  { label: 'Empatic', value: 'empatic' },
  { label: 'Encouraging', value: 'encouraging' },
  { label: 'Enthusiastic', value: 'enthusiastic' },
  { label: 'Excited', value: 'excited' },
  { label: 'Earnest', value: 'earnest' },
  { label: 'Formal', value: 'formal' },
  { label: 'Friendly', value: 'friendly' },
  { label: 'Funny', value: 'funny' },
  { label: 'Grumpy', value: 'grumpy' },
  { label: 'Humble', value: 'humble' },
  { label: 'Humorous', value: 'humorous' },
  { label: 'Informal', value: 'informal' },
  { label: 'Informative', value: 'informative' },
  { label: 'Inspirational', value: 'inspirational' },
  { label: 'Joyful', value: 'joyful' },
  { label: 'Luxurious', value: 'luxurious' },
  { label: 'Motivating', value: 'motivating' },
  { label: 'Neutral', value: 'neutral' },
  { label: 'Optimistic', value: 'optimistic' },
  { label: 'Passionate', value: 'passionate' },
  { label: 'Persuasive', value: 'persuasive' },
  { label: 'Professional', value: 'professional' },
  { label: 'Relaxed', value: 'relaxed' },
  { label: 'Sarcastic', value: 'sarcastic' },
  { label: 'Secretive', value: 'secretive' },
  { label: 'Surprised', value: 'surprised' },
  { label: 'Thoughtful', value: 'thoughtful' },
  { label: 'Urgent', value: 'urgent' },
  { label: 'Witty', value: 'witty' },
  { label: 'Worried', value: 'worried' },
];

const styleTextOptions = [
  { label: 'General', value: 'general' },
  { label: 'Casual', value: 'casual' },
  { label: 'Formal', value: 'formal' },
  { label: 'Long', value: 'long' },
  { label: 'Short', value: 'short' },
];

export const lenghtOptions = [
  { label: 'Short', value: 'short' },
  { label: 'Medium', value: 'medium' },
  { label: 'Long', value: 'long' },
];

export function BorderedInput({
  field, // { name, value, onChange, onBlur }
  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  wrapperClass,
  counter,
  removeInput,
  label,
  disabled,
  subLabel,
  hideError,
  inputWrapperClass,
  tooltip,
  size = 'lg',
  ...props
}) {
  const hasErrors = get(form.errors, field.name);
  const hasTouched = get(form.touched, field.name);
  return (
    <div className={clsx(wrapperClass, 'relative w-full')}>
      {disabled && (
        <div className="absolute bg-gray-200 top-0 bottom-0 left-0 right-0 opacity-20" />
      )}
      {label && (
        <div className="flex items-center  mb-3 space-x-1">
          <label
            className={clsx('text-black block', sizeAttributes[size].label)}
            htmlFor="input"
          >
            {label}
          </label>
          {tooltip && (
            <Tooltip position="right" text={tooltip} fontSize={12} width={200}>
              <MdInfo className="text-gray-300" />
            </Tooltip>
          )}
        </div>
      )}
      {subLabel && (
        <label className="text-main text-md block mb-1" htmlFor="input">
          {subLabel}
        </label>
      )}
      <div
        style={{ position: 'relative' }}
        className={clsx(
          'flex items-center border rounded-lg px-4 py-3',
          inputWrapperClass,
        )}
      >
        <input
          {...field}
          {...props}
          disabled={disabled}
          style={{ width: '100%' }}
          autoComplete="off"
          className={clsx(
            ' text-base font-source border-gray-500 pb-1 outline-none bg-transparent',
            sizeAttributes[size].input,
            props?.type !== 'password' && 'LoNotSensitive',
          )}
        />
        {counter && (
          <span
            style={{
              position: 'absolute',
              right: removeInput ? 37 : 6,
              top: 12,
              fontSize: 12,
            }}
            className={clsx(
              'text-gray-500',
              field.value.length > counter && 'text-red-600',
            )}
          >
            {`${field.value.length}/${counter}`}
          </span>
        )}
        {removeInput && (
          <AiOutlineCloseCircle
            className="text-gray-500 cursor-pointer ml-2"
            onClick={removeInput}
            size={25}
          />
        )}
      </div>
      {hasTouched && hasErrors && !hideError && (
        <div className="text-red-500 text-sm font-normal">{hasErrors}</div>
      )}
    </div>
  );
}

export function Input({
  field, // { name, value, onChange, onBlur }
  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  wrapperClass,
  counter,
  removeInput,
  label,
  disabled,
  subLabel,
  hideError,
  inputWrapperClass,
  tooltip,
  isPassword,
  show,
  changeShow,
  size = 'lg',
  ...props
}) {
  const hasErrors = get(form.errors, field.name);
  const hasTouched = get(form.touched, field.name);
  return (
    <div className={clsx(wrapperClass, 'relative')}>
      {disabled && (
        <div className="absolute bg-gray-200 rounded-lg top-0 bottom-0 left-0 right-0 opacity-20" />
      )}

      {label && (
        <div className="flex items-center mb-3 space-x-1">
          <label
            className={clsx('text-black block', sizeAttributes[size].label)}
            htmlFor="input"
          >
            {label}
          </label>
          {tooltip && (
            <Tooltip position="right" text={tooltip} fontSize={12} width={200}>
              <MdInfo className="text-gray-300" />
            </Tooltip>
          )}
        </div>
      )}
      {subLabel && (
        <label className="text-main text-md block mb-1" htmlFor="input">
          {subLabel}
        </label>
      )}
      <div
        style={{ position: 'relative ' }}
        className={clsx('flex items-center', inputWrapperClass)}
      >
        <div className="relative w-full">
          {isPassword && (
            <span className="absolute right-2 top-[2px]">
              <FontAwesomeIcon
                className="text-silver cursor-pointer"
                icon={show ? faEyeSlash : faEye}
                onClick={() => changeShow(!show)}
              />
            </span>
          )}
          <input
            {...field}
            {...props}
            disabled={disabled}
            style={{ width: '100%' }}
            autoComplete="off"
            className={clsx(
              'border-b text-base font-normal font-source border-gray-500 pb-1 outline-none bg-transparent',
              sizeAttributes[size].input,
              props?.type !== 'password' && 'LoNotSensitive',
            )}
          />
        </div>
        {counter && (
          <span
            style={{
              position: 'absolute',
              right: removeInput ? 37 : 6,
              top: 12,
              fontSize: 12,
            }}
            className={clsx(
              'text-gray-500',
              field.value.length > counter && 'text-red-600',
            )}
          >
            {`${field.value.length}/${counter}`}
          </span>
        )}
        {removeInput && (
          <AiOutlineCloseCircle
            className="text-gray-500 cursor-pointer ml-2"
            onClick={removeInput}
            size={25}
          />
        )}
      </div>
      {hasTouched && hasErrors && !hideError && (
        <div className="text-red-500 text-sm font-normal">{hasErrors}</div>
      )}
    </div>
  );
}

export function InputSm({
  field, // { name, value, onChange, onBlur }
  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  wrapperClass,
  counter,
  removeInput,
  label,
  disabled,
  subLabel,
  hideError,
  inputWrapperClass,
  tooltip,
  isPassword,
  show,
  changeShow,
  size = 'lg',
  ...props
}) {
  const hasErrors = get(form.errors, field.name);
  const hasTouched = get(form.touched, field.name);
  return (
    <div className={clsx(wrapperClass, 'relative')}>
      {disabled && (
        <div className="absolute bg-gray-200 rounded-lg top-0 bottom-0 left-0 right-0 opacity-20" />
      )}

      {label && (
        <div className="flex items-center mb-2 space-x-1">
          <label className="text-black block text-sm" htmlFor="input">
            {label}
          </label>
          {tooltip && (
            <Tooltip position="right" text={tooltip} fontSize={12} width={200}>
              <MdInfo className="text-gray-300" />
            </Tooltip>
          )}
        </div>
      )}
      {subLabel && (
        <label className="text-main text-md block mb-1" htmlFor="input">
          {subLabel}
        </label>
      )}
      <div className={clsx('mt-4', inputWrapperClass)}>
        <div className=" relative">
          <span
            className={` ${
              isPassword ? 'block absolute right-2 top-[2px]' : 'hidden'
            }`}
          >
            <FontAwesomeIcon
              className="text-silver cursor-pointer"
              icon={show ? faEyeSlash : faEye}
              onClick={() => changeShow(!show)}
            />
          </span>
          <input
            {...field}
            {...props}
            disabled={disabled}
            style={{ width: '100%' }}
            autoComplete="off"
            className={clsx(
              'border-b text-base font-normal font-source border-gray-500 pb-1 outline-none bg-transparent',
              sizeAttributes[size].input,
              props?.type !== 'password' && 'LoNotSensitive',
            )}
          />
        </div>
        {counter && (
          <span
            style={{
              position: 'absolute',
              right: removeInput ? 37 : 6,
              top: 12,
              fontSize: 12,
            }}
            className={clsx(
              'text-gray-500',
              field.value.length > counter && 'text-red-600',
            )}
          >
            {`${field.value.length}/${counter}`}
          </span>
        )}
        {removeInput && (
          <AiOutlineCloseCircle
            className="text-gray-500 cursor-pointer ml-2"
            onClick={removeInput}
            size={25}
          />
        )}
      </div>
      {hasTouched && hasErrors && !hideError && (
        <div className="text-red-500 text-sm font-normal">{hasErrors}</div>
      )}
    </div>
  );
}

export function OutlinedInput({
  field, // { name, value, onChange, onBlur }
  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  wrapperClass,
  counter,
  removeInput,
  label,
  disabled,
  hideError,
  inputWrapperClass,
  tooltip,
  size = 'lg',
  ...props
}) {
  const hasErrors = get(form.errors, field.name);
  const hasTouched = get(form.touched, field.name);
  return (
    <div className={clsx(wrapperClass, 'relative')}>
      {disabled && (
        <div className="absolute bg-gray-200 rounded-lg top-0 bottom-0 left-0 right-0 opacity-20" />
      )}
      <div
        style={{ position: 'relative' }}
        className={clsx('flex items-center', inputWrapperClass)}
      >
        {label && (
          <div
            className="flex items-center -top-2.5 ml-2"
            style={{ position: 'absolute' }}
          >
            {disabled && (
              <label
                className={clsx(
                  'text-gray-400 block bg-transparent px-1 z-10',
                  sizeAttributes[size].label,
                  { 'text-gray-400': disabled },
                )}
                htmlFor="input"
              >
                {label}
              </label>
            )}
            {!disabled && (
              <label
                className={clsx(
                  'text-gray-700 block bg-transparent px-1 z-10',
                  sizeAttributes[size].label,
                  { 'text-gray-400': disabled },
                )}
                htmlFor="input"
              >
                {label}
              </label>
            )}
            <span
              className="bg-white w-full h-1 top-2"
              style={{ position: 'absolute' }}
            />
            {tooltip && (
              <Tooltip
                className="bg-transparent pr-1"
                position="right"
                text={tooltip}
                fontSize={12}
                lineHeight="14px"
                width={200}
              >
                <MdInfo className="text-gray-300" />
              </Tooltip>
            )}
          </div>
        )}
        <input
          {...field}
          {...props}
          disabled={disabled}
          style={{ width: '100%' }}
          autoComplete="off"
          className={clsx(
            'text-main border border-gray-300 p-3.5 rounded-lg',
            sizeAttributes[size].input,
            props?.type !== 'password' && 'LoNotSensitive',
          )}
        />
        {counter && (
          <span
            style={{
              position: 'absolute',
              right: removeInput ? 37 : 6,
              top: 12,
              fontSize: 12,
            }}
            className={clsx(
              'text-gray-500',
              field.value.length > counter && 'text-red-600',
            )}
          >
            {`${field.value.length}/${counter}`}
          </span>
        )}
        {removeInput && (
          <AiOutlineCloseCircle
            className="text-gray-500 cursor-pointer ml-2"
            onClick={removeInput}
            size={25}
          />
        )}
      </div>
      {hasTouched && hasErrors && !hideError && (
        <div className="text-red-500 text-sm font-normal">{hasErrors}</div>
      )}
    </div>
  );
}

export function OutlinedInputSM({
  field, // { name, value, onChange, onBlur }
  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  wrapperClass,
  counter,
  removeInput,
  label,
  disabled,
  hideError,
  inputWrapperClass,
  tooltip,
  size = 'lg',
  ...props
}) {
  const hasErrors = get(form.errors, field.name);
  const hasTouched = get(form.touched, field.name);
  return (
    <div className={clsx(wrapperClass, 'relative')}>
      {disabled && (
        <div className="absolute bg-gray-200 rounded-lg top-0 bottom-0 left-0 right-0 opacity-20" />
      )}
      <div
        style={{ position: 'relative' }}
        className={clsx('flex items-center', inputWrapperClass)}
      >
        {label && (
          <div
            className="flex items-center -top-2.5 ml-2"
            style={{ position: 'absolute' }}
          >
            {disabled && (
              <label
                className={clsx(
                  'text-gray-400 block bg-transparent px-1 z-10',
                  sizeAttributes[size].label,
                  { 'text-gray-400': disabled },
                )}
                htmlFor="input"
              >
                {label}
              </label>
            )}
            {!disabled && (
              <label
                className={clsx(
                  'text-gray-700 block bg-transparent px-1 z-10',
                  sizeAttributes[size].label,
                  { 'text-gray-400': disabled },
                )}
                htmlFor="input"
              >
                {label}
              </label>
            )}
            <span
              className="bg-white w-full h-1 top-2"
              style={{ position: 'absolute' }}
            />
            {tooltip && (
              <Tooltip
                className="bg-transparent pr-1"
                position="right"
                text={tooltip}
                fontSize={12}
                lineHeight="14px"
                width={200}
              >
                <MdInfo className="text-gray-300" />
              </Tooltip>
            )}
          </div>
        )}
        <input
          {...field}
          {...props}
          disabled={disabled}
          style={{ width: '100%' }}
          autoComplete="off"
          className={clsx(
            'text-main border border-gray-300 py-[.4rem] px-2 rounded',
            sizeAttributes[size].input,
            props?.type !== 'password' && 'LoNotSensitive',
          )}
        />
        {counter && (
          <span
            style={{
              position: 'absolute',
              right: removeInput ? 37 : 6,
              top: 12,
              fontSize: 12,
            }}
            className={clsx(
              'text-gray-500',
              field.value.length > counter && 'text-red-600',
            )}
          >
            {`${field.value.length}/${counter}`}
          </span>
        )}
        {removeInput && (
          <AiOutlineCloseCircle
            className="text-gray-500 cursor-pointer ml-2"
            onClick={removeInput}
            size={25}
          />
        )}
      </div>
      {hasTouched && hasErrors && !hideError && (
        <div className="text-red-500 text-sm font-normal">{hasErrors}</div>
      )}
    </div>
  );
}

export function TagsInput({
  field, // { name, value, onChange, onBlur }
  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  wrapperClass,
  counter,
  removeInput,
  removeTag,
  tagsArray,
  label,
  disabled,
  hideError,
  inputWrapperClass,
  tooltip,
  size = 'lg',
  placeholder,
  ...props
}) {
  const hasErrors = get(form.errors, tagsArray);
  const hasTouched = get(form.touched, tagsArray);
  return (
    <div>
      <div
        className={clsx(
          'border border-gray-300 rounded-lg py-1 px-2',
          wrapperClass,
          'relative',
        )}
      >
        {disabled && (
          <div className="absolute bg-gray-200 rounded-lg top-0 bottom-0 left-0 right-0 opacity-20" />
        )}
        <div
          style={{ position: 'relative' }}
          className={clsx('flex items-center', inputWrapperClass)}
        >
          {label && (
            <div
              className="flex items-center -top-3.5"
              style={{ position: 'absolute' }}
            >
              <label
                className={clsx(
                  'text-gray-700 block bg-white px-1 z-10',
                  sizeAttributes[size].label,
                  { 'text-gray-400': disabled },
                )}
                htmlFor="input"
              >
                {label}
              </label>
              <span
                className="bg-white w-full h-1 top-2"
                style={{ position: 'absolute' }}
              />
              {tooltip && (
                <Tooltip
                  className="bg-white pr-1"
                  position="right"
                  text={tooltip}
                  fontSize={12}
                  lineHeight="14px"
                  width={200}
                >
                  <MdInfo className="text-gray-300" />
                </Tooltip>
              )}
            </div>
          )}
          <div className="flex flex-wrap w-full">
            {form.values[tagsArray] &&
              form.values[tagsArray].length > 0 &&
              React.Children.toArray(
                form.values[tagsArray].map((brand, key) => (
                  <div
                    key={brand}
                    className="bg-gray-100 text-gray-800 m-1 mt-2.5 mb-1.5 px-3 py-1 flex items-center rounded text-sm font-medium"
                  >
                    <span className="whitespace-wrap">{brand}</span>
                    <div>
                      <MdClose
                        onClick={() => removeTag(key)}
                        size={15}
                        className="ml-1 cursor-pointer"
                      />
                    </div>
                  </div>
                )),
              )}
            <input
              {...field}
              {...props}
              disabled={disabled}
              autoComplete="off"
              className={clsx(
                'text-sm font-source p-2 pl-2 mt-1 rounded-lg flex-grow',
                sizeAttributes[size].input,
                props?.type !== 'password' && 'LoNotSensitive',
              )}
              placeholder={
                form.values[tagsArray] && form.values[tagsArray].length > 0
                  ? 'Add more...'
                  : placeholder
              }
            />
          </div>
          {counter && (
            <span
              style={{
                position: 'absolute',
                right: removeInput ? 37 : 6,
                top: 12,
                fontSize: 12,
              }}
              className={clsx(
                'text-gray-500',
                field.value.length > counter && 'text-red-600',
              )}
            >
              {`${field.value.length}/${counter}`}
            </span>
          )}
          {removeInput && (
            <AiOutlineCloseCircle
              className="text-gray-500 cursor-pointer ml-2"
              onClick={removeInput}
              size={25}
            />
          )}
        </div>
      </div>
      {(hasTouched || hasErrors) && !hideError && (
        <div className="text-red-500 text-sm font-normal">{hasErrors}</div>
      )}
    </div>
  );
}

export function TextArea({
  field, // { name, value, onChange, onBlur }
  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  wrapperClass,
  counter,
  label,
  size = 'lg',
  tooltip,
  ...props
}) {
  const hasErrors = get(form.errors, field.name);
  const hasTouched = get(form.touched, field.name);
  return (
    <div className={wrapperClass}>
      {label && (
        <div className="flex items-center  mb-3 space-x-1">
          <label
            className={clsx('text-black block', sizeAttributes[size].label)}
            htmlFor="input"
          >
            {label}
          </label>
          {tooltip && (
            <Tooltip position="right" text={tooltip} fontSize={12} width={200}>
              <MdInfo className="text-gray-300" />
            </Tooltip>
          )}
        </div>
      )}
      <div style={{ position: 'relative' }}>
        <textarea
          {...field}
          {...props}
          style={{ width: '100%', ...props?.style }}
          autoComplete="off"
          className={clsx(
            'border border-gray-300 px-2 py-2 resize-none rounded outline-none LoNotSensitive',
            sizeAttributes[size].input,
          )}
        />
        {counter && (
          <span
            style={{
              position: 'absolute',
              right: 6,
              bottom: 10,
              fontSize: 12,
            }}
            className={clsx(
              'text-gray-500',
              field.value.length > counter && 'text-red-600',
            )}
          >
            {`${field.value.length}/${counter}`}
          </span>
        )}
      </div>
      {hasTouched && hasErrors && (
        <div className="text-red-500 text-sm font-semibold">{hasErrors}</div>
      )}
    </div>
  );
}

export function Debug() {
  const { values, errors, initialValues } = useFormikContext();
  return (
    <div style={{ margin: '1rem 0' }}>
      <pre
        style={{
          background: '#f6f8fa',
          fontSize: '.65rem',
          padding: '.5rem',
        }}
      >
        <strong>props</strong> ={' '}
        {JSON.stringify(
          {
            initialValues,
            values,
            errors,
          },
          null,
          2,
        )}
      </pre>
    </div>
  );
}

export function Select({
  field, // { name, value, onChange, onBlur }
  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  wrapperClass,
  label,
  disabled,
  subLabel,
  hideError,
  inputWrapperClass,
  tooltip,
  size = 'lg',
  options,
  name,
  value,
  startValue,
}) {
  const hasErrors = get(form.errors, field.name);
  const hasTouched = get(form.touched, field.name);
  const defaultValue = (opts, val) =>
    opts ? opts.find((option) => option.val === val) : '';
  return (
    <div className={clsx(wrapperClass, 'relative')}>
      {disabled && (
        <div className="absolute bg-gray-200 rounded-lg top-0 bottom-0 left-0 right-0 opacity-20" />
      )}

      {label && (
        <div className="flex items-center  mb-3 space-x-1">
          <label
            className={clsx('text-black block', sizeAttributes[size].label)}
            htmlFor="input"
          >
            {label}
          </label>
          {tooltip && (
            <Tooltip position="right" text={tooltip} fontSize={12} width={200}>
              <MdInfo className="text-gray-300" />
            </Tooltip>
          )}
        </div>
      )}
      {subLabel && (
        <label className="text-main text-md block mb-1" htmlFor="input">
          {subLabel}
        </label>
      )}
      <div
        style={{ position: 'relative' }}
        className={clsx('flex items-center', inputWrapperClass)}
      >
        <RSelect
          className="w-full"
          name={name}
          options={options}
          defaultValue={startValue}
          value={defaultValue(options, value)}
          onChange={(val) => {
            form.setFieldValue(field.name, val.value);
          }}
        />
      </div>
      {hasTouched && hasErrors && !hideError && (
        <div className="text-red-500 text-sm font-normal">{hasErrors}</div>
      )}
    </div>
  );
}

export function SelectSm({
  field, // { name, value, onChange, onBlur }
  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  wrapperClass,
  label,
  disabled,
  subLabel,
  hideError,
  inputWrapperClass,
  tooltip,
  // eslint-disable-next-line no-unused-vars
  size = 'lg',
  options,
  name,
  value,
  startValue,
}) {
  const hasErrors = get(form.errors, field.name);
  const hasTouched = get(form.touched, field.name);
  const defaultValue = (opts, val) =>
    opts ? opts.find((option) => option.val === val) : '';
  return (
    <div className={clsx(wrapperClass, 'relative')}>
      {disabled && (
        <div className="absolute bg-gray-200 rounded-lg top-0 bottom-0 left-0 right-0 opacity-20" />
      )}

      {label && (
        <div className="flex items-center mb-3 space-x-1">
          <label className="text-black block text-sm" htmlFor="input">
            {label}
          </label>
          {tooltip && (
            <Tooltip position="right" text={tooltip} fontSize={12} width={200}>
              <MdInfo className="text-gray-300" />
            </Tooltip>
          )}
        </div>
      )}
      {subLabel && (
        <label className="text-main text-md block mb-1" htmlFor="input">
          {subLabel}
        </label>
      )}
      <div
        style={{ position: 'relative' }}
        className={clsx('flex items-center', inputWrapperClass)}
      >
        <RSelect
          className="w-full font-normal text-sm"
          name={name}
          options={options}
          defaultValue={startValue}
          value={defaultValue(options, value)}
          onChange={(val) => {
            form.setFieldValue(field.name, val.value);
          }}
        />
      </div>
      {hasTouched && hasErrors && !hideError && (
        <div className="text-red-500 text-sm font-normal">{hasErrors}</div>
      )}
    </div>
  );
}

export function InputStandard({
  field, // { name, value, onChange, onBlur }
  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  wrapperClass,
  label,
  disabled,
  hideError,
  maxCounter,
  ...props
}) {
  const hasErrors = get(form.errors, field.name);
  const hasTouched = get(form.touched, field.name);
  return (
    <div className={clsx(wrapperClass, 'relative')}>
      <div>
        <div>
          <label
            className={clsx(
              'font-source text-ugray text-sm font-semibold leading-6',
              disabled ? 'text-silver-alto' : 'text-ugray',
            )}
            htmlFor="input"
          >
            {label}
          </label>
        </div>
        <input
          {...field}
          {...props}
          disabled={disabled}
          autoComplete="off"
          className={clsx(
            'text-sm font-source border border-gray-300 pl-2 py-2 rounded w-full placeholder:text-usilver',
            props?.type !== 'password' && 'LoNotSensitive',
          )}
          value={field.value}
          onChange={(evt) => {
            const val = evt.target.value || '';
            if (val.length <= maxCounter) {
              form.setFieldValue(field.name, val);
            }
          }}
          onPaste={(evt) => {
            evt.preventDefault();
            const data = evt.clipboardData
              .getData('text')
              .trim()
              .substring(0, maxCounter);
            form.setFieldValue(field.name, data);
          }}
        />
      </div>
      {maxCounter && (
        <span
          className={clsx(
            'text-ugray text-xs absolute bottom-1 right-1 font-source',
            field.value.length >= maxCounter && 'text-red-600',
          )}
        >
          {`${field.value.length}/${maxCounter}`}
        </span>
      )}
      {hasTouched && hasErrors && !hideError && (
        <div className="text-radical-red text-sm font-normal font-source">
          {hasErrors}
        </div>
      )}
    </div>
  );
}

export function TextAreaStandard({
  field, // { name, value, onChange, onBlur }
  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  wrapperClass,
  label,
  hideError,
  disabled,
  maxCounter,
  rows = 4,
  ...props
}) {
  const hasErrors = get(form.errors, field.name);
  const hasTouched = get(form.touched, field.name);
  return (
    <div className={clsx(wrapperClass)}>
      <div className="relative">
        <div>
          <label
            className={clsx(
              'font-source text-ugray text-sm font-semibold leading-6 placeholder:text-usilver',
              disabled ? 'text-silver-alto' : 'text-ugray',
            )}
            htmlFor="input"
          >
            {label}
          </label>
        </div>
        <textarea
          {...field}
          {...props}
          autoComplete="off"
          className={clsx(
            'text-sm font-source border border-gray-300 pl-2 py-2 rounded w-full',
          )}
          rows={rows}
          value={field.value}
          onChange={(evt) => {
            const val = evt.target.value || '';
            if (val.length <= maxCounter) {
              form.setFieldValue(field.name, val);
            }
          }}
          onPaste={(evt) => {
            evt.preventDefault();
            const data = evt.clipboardData
              .getData('text')
              .trim()
              .substring(0, maxCounter);
            form.setFieldValue(field.name, data);
          }}
        />
        {maxCounter && (
          <span
            className={clsx(
              'text-ugray text-xs absolute bottom-2 right-3 font-source',
              field.value.length >= maxCounter && 'text-red-600',
            )}
          >
            {`${field.value.length}/${maxCounter}`}
          </span>
        )}
      </div>
      {hasTouched && hasErrors && !hideError && (
        <div className="text-radical-red text-sm font-normal font-source">
          {hasErrors}
        </div>
      )}
    </div>
  );
}

export function ToneSelectStandard({
  field, // { name, value, onChange, onBlur }
  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  wrapperClass,
  label,
  disabled,
  hideError,
  value,
}) {
  const hasErrors = get(form.errors, field.name);
  const hasTouched = get(form.touched, field.name);
  return (
    <div className={clsx(wrapperClass, 'relative')}>
      <div>
        <div>
          <label
            className={clsx(
              'font-source text-ugray text-sm font-semibold leading-6',
              disabled ? 'text-silver-alto' : 'text-ugray',
            )}
            htmlFor="input"
          >
            {label}
            <TooltipNew
              text="Select or type the tone of your copy"
              className="text-xs ml-1"
            />
          </label>
        </div>
        <CreateTableSelect
          isDisabled={disabled}
          name="tone"
          value={{
            value: value,
            label: value
              ? `${value[0].toUpperCase()}${value.slice(1)}`
              : `Select ${field.name}...`,
          }}
          isClrearable
          onChange={(newValue) => {
            form.setFieldValue(field.name, newValue.value);
          }}
          onBlur={field.onBlur}
          options={toneOptions}
          styles={{
            menu: (provided) => ({
              ...provided,
              zIndex: 25,
              fontFamily: `${'Source Sans Pro'}, sans-serif`,
              fontSize: '14px',
            }),
            singleValue: (provided) => ({
              ...provided,
              fontFamily: `${'Source Sans Pro'}, sans-serif`,
              color: value ? '#303030' : '#c9c9c9',
            }),
            option: (provided) => ({
              ...provided,
              fontFamily: `${'Source Sans Pro'}, sans-serif`,
            }),
            control: (provided) => ({
              ...provided,
              fontFamily: 'Source Sans Pro',
              fontSize: '14px',
            }),
          }}
        />
      </div>
      {hasTouched && hasErrors && !hideError && (
        <div className="text-radical-red text-sm font-normal font-source">
          {hasErrors}
        </div>
      )}
    </div>
  );
}

export function LengthSelectStandard({
  field, // { name, value, onChange, onBlur }
  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  wrapperClass,
  label,
  disabled,
  hideError,
}) {
  const hasErrors = get(form.errors, field.name);
  const hasTouched = get(form.touched, field.name);
  return (
    <div className={clsx(wrapperClass, 'relative')}>
      <div>
        <div>
          <label
            className={clsx(
              'font-source text-ugray text-sm font-semibold leading-6',
              disabled ? 'text-silver-alto' : 'text-ugray',
            )}
            htmlFor="input"
          >
            {label}
          </label>
        </div>
        <RSelect
          isDisabled={disabled}
          name="length"
          value={{
            value: field.value,
            label: field.value
              ? `${field.value[0].toUpperCase()}${field.value.slice(1)}`
              : `Select ${field.name}...`,
          }}
          isClrearable
          onChange={(newValue) => {
            form.setFieldValue(field.name, newValue.value);
          }}
          options={lenghtOptions}
          styles={{
            menu: (provided) => ({
              ...provided,
              zIndex: 25,
              fontFamily: `${'Source Sans Pro'}, sans-serif`,
              fontSize: '14px',
            }),
            singleValue: (provided) => ({
              ...provided,
              fontFamily: `${'Source Sans Pro'}, sans-serif`,
              color: field.value ? '#303030' : '#c9c9c9',
            }),
            option: (provided) => ({
              ...provided,
              fontFamily: `${'Source Sans Pro'}, sans-serif`,
            }),
            control: (provided) => ({
              ...provided,
              fontFamily: 'Source Sans Pro',
              fontSize: '14px',
            }),
          }}
        />
      </div>
      {hasTouched && hasErrors && !hideError && (
        <div className="text-radical-red text-sm font-normal font-source">
          {hasErrors}
        </div>
      )}
    </div>
  );
}

export function KeywordsStandard({
  field, // { name, value, onChange, onBlur }
  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  wrapperClass,
  label,
  disabled,
  hideError,
  maxCounter,
}) {
  const hasErrors = get(form.errors, field.name);
  const hasTouched = get(form.touched, field.name);
  const [inputValue, setInputValue] = useState('');
  return (
    <div className={clsx(wrapperClass, 'relative')}>
      <div>
        <div>
          <label
            className={clsx(
              'font-source text-ugray text-sm font-semibold leading-6',
              disabled ? 'text-silver-alto' : 'text-ugray',
            )}
            htmlFor="input"
          >
            {label}
          </label>
        </div>
        <div
          className={clsx(
            'w-full rounded border border-alto py-1 px-2 flex flex-wrap items-center font-source text-sm',
          )}
        >
          {field.value.map((el, i) => (
            <Keyword
              key={`el-${String(i)}`}
              name={el}
              index={i}
              handleRemoveKeyword={(indx) => {
                const resultsFilter = field.value.filter(
                  (_keyword, idx) => idx !== indx,
                );
                form.setFieldValue(field.name, resultsFilter);
              }}
              disabled={disabled}
            />
          ))}
          <KeywordInput
            value={inputValue}
            onChange={(evt) => {
              const val = evt.target.value || '';
              if (val.length <= 50) {
                setInputValue(val);
              }
            }}
            name={label}
            insertInput={() => {
              if (inputValue && field.value.length <= 25) {
                form.setFieldValue(field.name, [...field.value, inputValue]);
                setInputValue('');
              }
            }}
            disabled={
              disabled ||
              (maxCounter ? field.value.length >= maxCounter : false)
            }
            onPaste={(evt) => {
              evt.preventDefault();
              const data = evt.clipboardData
                .getData('text')
                .trim()
                .substring(0, 50);
              setInputValue(data);
            }}
          />
          {maxCounter && (
            <span
              className={clsx(
                'text-ugray text-xs absolute bottom-1 right-2 font-source',
                field.value.length >= maxCounter && 'text-red-600',
              )}
            >
              {`${field.value.length}/${maxCounter}`}
            </span>
          )}
        </div>
      </div>
      {hasTouched && hasErrors && !hideError && (
        <div className="text-radical-red text-sm font-normal font-source">
          {hasErrors}
        </div>
      )}
    </div>
  );
}

export function StyleTextSelectStandard({
  field, // { name, value, onChange, onBlur }
  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  wrapperClass,
  label,
  disabled,
  hideError,
  value,
}) {
  const hasErrors = get(form.errors, field.name);
  const hasTouched = get(form.touched, field.name);
  return (
    <div className={clsx(wrapperClass, 'relative')}>
      <div>
        <div>
          <label
            className={clsx(
              'font-source text-ugray text-sm font-semibold leading-6',
              disabled ? 'text-silver-alto' : 'text-ugray',
            )}
            htmlFor="input"
          >
            {label}
            <TooltipNew
              text="Select the tone or length of your copy"
              className="text-xs ml-1"
            />
          </label>
        </div>
        <CreateTableSelect
          isDisabled={disabled}
          name="tone"
          value={{
            value: value,
            label: value
              ? `${value[0].toUpperCase()}${value.slice(1)}`
              : `Select ${field.name}...`,
          }}
          isClrearable
          onChange={(newValue) => {
            form.setFieldValue(field.name, newValue.value);
          }}
          onBlur={field.onBlur}
          options={styleTextOptions}
          styles={{
            menu: (provided) => ({
              ...provided,
              zIndex: 25,
              fontFamily: `${'Source Sans Pro'}, sans-serif`,
              fontSize: '14px',
            }),
            singleValue: (provided) => ({
              ...provided,
              fontFamily: `${'Source Sans Pro'}, sans-serif`,
              color: value ? '#303030' : '#c9c9c9',
            }),
            option: (provided) => ({
              ...provided,
              fontFamily: `${'Source Sans Pro'}, sans-serif`,
            }),
            control: (provided) => ({
              ...provided,
              fontFamily: 'Source Sans Pro',
              fontSize: '14px',
            }),
          }}
        />
      </div>
      {hasTouched && hasErrors && !hideError && (
        <div className="text-radical-red text-sm font-normal font-source">
          {hasErrors}
        </div>
      )}
    </div>
  );
}
