import React from 'react';
import ContentEditable from 'react-contenteditable';
import useTemplateState from '../../../../../../hooks/useTemplateState';
import { editTemplate } from '../../../../../savedcontent/actions';
import TemplateFooter from '../../../../components/footer/TemplateFooter';
import { IProductDescriptionProps } from './props';

function ProductDescriptionResult({
  data,
  onEditMode = false,
  hideFooter = false,
}: IProductDescriptionProps) {
  const { templateValue, handleOnChange } = useTemplateState(data);
  const companyName = templateValue?.content.companyName || '';
  const productDescription = templateValue?.content.productDescription || '';
  const productName = templateValue?.content.productName || '';
  return (
    <div className="relative mb-4 px-px">
      <div className="border lg:border-0 border-gallery bg-white px-10 pt-8 pb-10 mb-3 shadow-lg font-source rounded-lg">
        <div
          className="rounded-lg sm:float-left mr-6 mb-2 w-full sm:w-2/5 h-auto flex justify-center items-center text-center uppercase font-black text-3xl bg-link-water text-white "
          style={{ minHeight: 270 }}
        >
          {productName}
        </div>
        <h3 className="text-sm sm:text-base font-semibold text-gray-400">
          <ContentEditable
            disabled={!onEditMode}
            html={companyName}
            onBlur={({ target: { innerHTML } }) =>
              editTemplate(innerHTML, data, 'content.companyName')
            }
            onChange={(e) =>
              handleOnChange(e.target.value, 'content.companyName')
            }
            spellCheck={false}
          />
        </h3>
        <h3 className="text-2xl xlg:text-3xl font-semibold mt-3">
          <ContentEditable
            disabled={!onEditMode}
            html={productName}
            onBlur={({ target: { innerHTML } }) =>
              editTemplate(innerHTML, data, 'content.productName')
            }
            onChange={(e) =>
              handleOnChange(e.target.value, 'content.productName')
            }
            spellCheck={false}
          />
        </h3>
        <div className="mt-3 text-sm sm:text-base">
          <ContentEditable
            html={productDescription}
            disabled={!onEditMode}
            onBlur={({ target: { innerHTML } }) =>
              editTemplate(innerHTML, data, 'content.productDescription')
            }
            onChange={(e) =>
              handleOnChange(e.target.value, 'content.productDescription')
            }
            spellCheck={false}
          />
        </div>
      </div>
      {!hideFooter && (
        <TemplateFooter
          data={templateValue}
          onEditMode={onEditMode}
          handleOnChange={handleOnChange}
          type="product-descriptions"
        />
      )}
    </div>
  );
}

export default ProductDescriptionResult;
