import React from 'react';
import { FiFacebook, FiTwitter } from 'react-icons/fi';
import { MdContentCopy } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import { ReactComponent as Logo } from '../../../assets/img/UBSmartCopy-short.svg';
import Button from '../../../common/Button';
import { checkAuth } from '../../../config/token';
import { AlertMarker, createAlert } from '../../common/Alerts';
import usePersonalizeDetails from '../../personalize/hooks/usePersonalizeDetails';
import { copyContent } from '../../templates/actions';
import Template from '../components/Template';
import { useSharedTemplate } from '../hooks';

const shareLink = window.location.href;

function SharedTemplate() {
  usePersonalizeDetails(checkAuth());
  const history = useHistory();
  const facebookRef = React.useRef();
  const twitterRef = React.useRef();
  const { isLoading, error, data } = useSharedTemplate();

  const content = React.useMemo(() => {
    if (!isLoading && error) {
      return (
        <div className="flex flex-col w-full p-4 justify-center items-center">
          <h1 className="text-2xl font-semibold">{error}</h1>
        </div>
      );
    }
    if (!isLoading && data) {
      return (
        <div className="w-full flex flex-col">
          <Template data={data} />
          <div className="flex flex-col w-full">
            <h1 className="text-xl font-semibold text-gray-600">
              {data?.name}
            </h1>
            <AlertMarker name="shared-page" />
            <h3 className="text-gray-400">
              Shared by:
              {data?.shared_by}
            </h3>
            <div className="flex mt-2 items-center text-gray-400">
              <button type="button" onClick={() => twitterRef.current.click()}>
                <FiTwitter className="mr-2" size={21} />
              </button>
              <button type="button" onClick={() => facebookRef.current.click()}>
                <FiFacebook className="mr-2" size={21} />
              </button>
              <button
                type="button"
                onClick={() =>
                  copyContent(data, () =>
                    createAlert.success('shared-page', 'Copied to clipboard'),
                  )
                }
              >
                <MdContentCopy size={21} />
              </button>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="flex justify-center items-center p-4 w-full">
        <i className="fa fa-spinner fa-spin text-3xl text-ubblue" />
      </div>
    );
  }, [isLoading, data, error, facebookRef, twitterRef]);

  return (
    <div className="flex h-screen w-screen justify-center py-12 px-4">
      <FacebookShareButton
        url={shareLink}
        hashtag="#smartcopy#futureofcontent"
        ref={facebookRef}
      />
      <TwitterShareButton
        url={shareLink}
        hashtag="#smartcopy#futureofcontent"
        ref={twitterRef}
      />
      <div
        className="w-full flex flex-col justify-center space-y-12 m-5"
        style={{
          maxWidth: 900,
        }}
      >
        <div className="bg-white rounded-xl flex flex-col justify-between items-center py-4 px-6 w-5/6 max-w-sm sm:w-1/2 mx-auto">
          <Logo />
        </div>
        {content}
        <div className="max-w-sm flex flex-col justify-center space-y-12 m-auto">
          <Button type="primary" onClick={() => history.push('/browse')}>
            Create your own content
          </Button>
        </div>
      </div>
    </div>
  );
}

export default SharedTemplate;
