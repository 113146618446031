import req from '../../redux-setup';

export const changeOrder = (listings = []) => listings.map((l, i) => ({
  template_id: l?.templateId,
  order: i + 1,
}));

export const updateOrderList = async (payload = {}) => req.put({
  url: '/v3/update_template_list',
  payload,
});
