/* eslint-disable react/prop-types */
import React from 'react';
import { MdDone } from 'react-icons/md';

function GrowPlanFeatures({ textSize = 'text-base' }) {
  return (
    <div className="text-left mb-4 mt-4">
      <div className={`flex items-center mt-2 ${textSize}`}>
        <span className="text-ubblue text-xl font-bold inline-block mr-4">
          <MdDone />
        </span>
        Access to&nbsp;
        <span className="font-medium">Writer</span>
      </div>
      <div className={`flex items-center mt-2 ${textSize}`}>
        <span className="text-ubblue text-xl font-bold inline-block mr-4">
          <MdDone />
        </span>
        <span className="font-medium">Unlimited</span>
        &nbsp;Generations
      </div>
      <div className={`flex items-center mt-2 ${textSize}`}>
        <span className="text-ubblue text-xl font-bold inline-block mr-4">
          <MdDone />
        </span>
        <span className="font-medium">Unlimited</span>
        &nbsp;Projects
      </div>
      <div className={`flex items-center mt-2 ${textSize}`}>
        <span className="text-ubblue text-xl font-bold inline-block mr-4">
          <MdDone />
        </span>
        45+ Content&nbsp;
        <span className="font-medium">Templates</span>
      </div>
      <div className={`flex items-center mt-2 ${textSize}`}>
        <span className="text-ubblue text-xl font-bold inline-block mr-4">
          <MdDone />
        </span>
        30&nbsp;
        <span className="font-medium">Languages</span>
      </div>
    </div>
  );
}

export default GrowPlanFeatures;
