import get from 'lodash/get';
import { shallowEqual, useSelector } from 'react-redux';

export const useLoading = () => {
  const loading = useSelector((state) => state.loading, shallowEqual);
  return loading;
};

export const useApiData = (key, defaultValue = undefined) => {
  const data = useSelector(
    (state) => get(state, key, defaultValue),
    shallowEqual,
  );
  return data;
};
