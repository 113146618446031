/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import React from 'react';

import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import 'react-notifications/lib/notifications.css';
// import './assets/main.css';
import './index.css';

import axios from 'axios';
import store from './redux-setup/store';

import App from './App';

// eslint-disable-next-line import/no-mutable-exports
export let axiosController;
axios.interceptors.request.use((config) => {
  // Do something before request is sent
  axiosController = new AbortController();
  return {
    ...config,
    signal: axiosController.signal,
  };
}, Promise.reject);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
