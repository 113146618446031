/* eslint-disable react/jsx-key */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
import React from 'react';
import PropTypes from 'prop-types';
import { useApiData } from '../../../redux-setup';
import { V3_TEMPLATES } from '../../templates/actions';
import { useTemplateSidebar } from '../context';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { BiSearchAlt2 } from 'react-icons/bi';
import { FiFilter } from 'react-icons/fi';
import { FaTimes } from 'react-icons/fa';
import { clsx } from 'clsx';

function TemplateFilter({ recommended }) {
  const { setViewProfile, viewProfile } = useTemplateSidebar();
  const [isOpen, setIsOpen] = React.useState(false);
  const [openFilter, setFilter] = React.useState(false);
  const [search, setSearch] = React.useState('');
  const [isSearchFocused, setSearchFocus] = React.useState(false);
  const [selectedFilters, setSelectedFilter] = React.useState([]);
  const templateData = useApiData(V3_TEMPLATES, []);
  const templates = React.useMemo(() => {
    const dt = templateData
      ?.filter(
        (td) => td.name !== 'Everyday Tools' && td.name !== 'Recommended',
      )
      ?.filter((tmp) =>
        selectedFilters?.length > 0
          ? selectedFilters.find((flt) => flt.id === tmp.id)
          : true,
      )
      ?.flatMap((tmp) => tmp?.content?.map((dt) => dt))
      .map((dt) => ({
        value: dt?.name,
        label: dt?.name,
        id: dt?.id,
        categoryId: dt.categoryId,
        category: dt.category,
      }))
      .filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i);

    return dt;
  }, [templateData, selectedFilters, recommended]);

  const filteredTemplates = React.useMemo(
    () =>
      templates?.filter(
        (tmp) =>
          tmp?.label
            ?.toString()
            ?.toLowerCase()
            ?.indexOf(search?.toString()?.toLowerCase()) !== -1,
      ),
    [search, templates],
  );

  React.useEffect(() => {
    const el = document.querySelector('#writer-template-container');
    if (el) {
      el?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, []);

  return (
    <div className="mb-4 w-full space-y-3" id="writer-template-container">
      <div className="w-full relative">
        <div
          className="p-2.5 rounded-md border border-gray-300 flex justify-between items-center"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span>{viewProfile?.name}</span>
          {isOpen ? <BsChevronUp size={15} /> : <BsChevronDown size={15} />}
        </div>
        {isOpen && (
          <div
            className="flex flex-col absolute py-2.5 px-3 left-0 right-0 top-12 bg-white border border-gray-300 rounded overflow-y-auto"
            style={{
              zIndex: 99999,
              minHeight: 200,
              maxHeight: 400,
            }}
          >
            <div className="flex items-center space-x-1">
              <input
                type="text"
                className="border-b p-2 w-full"
                placeholder="I'm looking for"
                value={search}
                onFocus={() => setSearchFocus(true)}
                onBlur={() => setSearchFocus(false)}
                onChange={(e) => setSearch(e?.target?.value)}
                autoFocus
              />
              <BiSearchAlt2
                className={isSearchFocused && 'text-ubblue'}
                size={25}
              />
              <FiFilter
                className={clsx('cursor-pointer', openFilter && 'text-ubblue')}
                onClick={() => setFilter(!openFilter)}
                size={25}
              />
            </div>
            {/* FILTER OPTIONS SELECTED */}
            <div className="flex flex-wrap mb-2 mt-1">
              {React.Children.toArray(
                selectedFilters?.map((tmp) => (
                  <div
                    onClick={() =>
                      setSelectedFilter((prev) =>
                        prev.filter((p) => p.id !== tmp.id),
                      )
                    }
                    className="flex items-center hover:cursor-pointer bg-shamrock mt-1 mr-2 text-sm text-white rounded-full px-2 py-1.5"
                  >
                    {tmp.name}
                    <button type="button" className="text-white ml-1">
                      <FaTimes />
                    </button>
                  </div>
                )),
              )}
            </div>
            {/* FILTER OPTIONS */}
            {openFilter && (
              <div className="flex flex-wrap">
                {recommended.length > 0 && (
                  <div
                    onClick={() => {
                      setSelectedFilter((prev) => [
                        {
                          name: 'Recommended',
                          id: 2,
                        },
                        ...prev,
                      ]);
                      setFilter(false);
                    }}
                    className="hover:cursor-pointer  mt-1 mr-2 text-sm bg-frosted-mint text-jungle-green betterhover:hover:bg-jungle-green  betterhover:hover:text-white text-white rounded-full px-2 py-1.5"
                  >
                    Recommended
                  </div>
                )}
                {React.Children.toArray(
                  templateData
                    ?.filter(
                      (td) =>
                        td.name !== 'Everyday Tools' &&
                        td.name !== 'Recommended',
                    )
                    ?.filter(
                      (tmp) =>
                        !selectedFilters.find((flt) => flt.id === tmp.id),
                    )
                    ?.map((tmp) => (
                      <div
                        onClick={() => {
                          setSelectedFilter((prev) => [tmp, ...prev]);
                          setFilter(false);
                        }}
                        className="hover:cursor-pointer  mt-1 mr-2 text-sm bg-frosted-mint text-jungle-green betterhover:hover:bg-jungle-green  betterhover:hover:text-white rounded-full px-2 py-1.5"
                      >
                        {tmp.name}
                      </div>
                    )),
                )}
              </div>
            )}
            {/* TEMPLATES LIST */}
            <div className="flex flex-grow overflow-y-auto flex-col mt-4 mb-2">
              {React.Children.toArray(
                filteredTemplates.map((tmp) =>
                  tmp.label !== 'Writer' && tmp.label !== 'writer' ? (
                    <div
                      className="cursor-pointer mb-2.5"
                      onClick={() => {
                        setViewProfile(
                          templateData
                            ?.flatMap((tmp) => tmp?.content?.map((dt) => dt))
                            .find((dt) => dt?.id === tmp?.id),
                        );
                        setSelectedFilter([]);
                        setIsOpen(false);
                      }}
                    >
                      {tmp.label}
                    </div>
                  ) : null,
                ),
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
TemplateFilter.propTypes = {
  recommended: PropTypes.arrayOf(PropTypes.object).isRequired,
};
export default TemplateFilter;
