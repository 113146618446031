import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';

const defaultValue = {
  equalizer: {
    tone: '',
    length: '',
  },
};
const UtilsContext = createContext({});
export function UtilsProvider({ children }) {
  const [values, setvalues] = useState(defaultValue);
  const updateValues = (updatedValues) => {
    setvalues((current) => ({ ...current, ...updatedValues }));
  };

  return (
    <UtilsContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        values,
        updateValues,
      }}
    >
      {children}
    </UtilsContext.Provider>
  );
}

UtilsProvider.defaultProps = {
  children: null,
};

UtilsProvider.propTypes = {
  children: PropTypes.node,
};
export default UtilsContext;
