/* eslint-disable react/no-array-index-key */
import React from 'react';
// eslint-disable-next-line import/no-cycle
import PageLayout from '../../partials/pagelayout/containers/PageLayout';
import RemixerTemplate from '../../templates/components/templates/RemixerTemplates';

export default function Remixer() {
  return (
    <PageLayout
      template="remixer"
      title="Remix"
      runInitValues={false}
      useEqualizer
    >
      {({ contents }) => (
        <>
          {React.Children.toArray(
            contents.map((data) => <RemixerTemplate key={data.id || Math.random()} data={data} />),
          )}
        </>
      )}
    </PageLayout>
  );
}
// Expander
