import React, { useState } from 'react';
import CreateTableSelect from 'react-select/creatable';
import * as yup from 'yup';
import { Field, Form, Formik } from 'formik';
import { useSelector } from 'react-redux';
import req from '../../../redux-setup';
import { WRITER_DETAILS } from '../contants';
import { OutlinedInputSM, TextArea } from '../../common/Formik/FormInputs';
import {
  getWriterAudience,
  getWriterTone,
  getWriterTopic,
  getWriterTypeOfContent,
  setWriterAudience,
  setWriterTone,
  setWriterTopic,
  setWriterTypeOfContent,
} from '../../../config/localStorage';
import CreditsBar from '../../templates/components/CreditsBar';
import Button from '../../../common/Button';
import Language from '../../../common/Language';
import SelectProject from '../../../common/Profile/SelectProject';
import Tooltip from '../../../common/Tooltip';
import { NotificationManager } from 'react-notifications';
import Loader from '../../common/Loader/Loader';
import ManageProjects from '../../partials/pagelayout/containers/ManageProjects';

const validation = yup.object().shape({
  contentType: yup.string().required().label('Content Type').max(50),
  topic: yup.string().required().label('Topic').max(250),
  audience: yup.string().required().label('Audience').max(100),
  tone: yup.object({
    value: yup.string().required().label('Tone').max(50),
    label: yup.string(),
  }),
});

function WriterTemplates() {
  const formRef = React.useRef();
  const loading = useSelector((state) => state.loading);
  const { is_unlimited: isUnlimited } = useSelector(
    (state) => state['PERSONALIZE/DATA'],
  );
  const { id: profileID } = useSelector((state) => state.PROJECT);
  const selectedProfile = useSelector((state) => state['PROJECT']);

  const [toneOptions, setToneOptions] = useState([
    { label: 'Adventurous', value: 'adventurous' },
    { label: 'Analytical', value: 'analytical' },
    { label: 'Appreciative', value: 'appreciative' },
    { label: 'Assertive', value: 'assertive' },
    { label: 'Awestruck', value: 'awestruck' },
    { label: 'Bold', value: 'bold' },
    { label: 'Candid', value: 'candid' },
    { label: 'Casual', value: 'casual' },
    { label: 'Cautionary', value: 'cautionary' },
    { label: 'Compassionate', value: 'compassionate' },
    { label: 'Confident', value: 'confident' },
    { label: 'Conversational', value: 'conversational' },
    { label: 'Convincing', value: 'convincing' },
    { label: 'Cooperative', value: 'cooperative' },
    { label: 'Critical', value: 'critical' },
    { label: 'Curious', value: 'curious' },
    { label: 'Dramatic', value: 'dramatic' },
    { label: 'Empatic', value: 'empatic' },
    { label: 'Encouraging', value: 'encouraging' },
    { label: 'Enthusiastic', value: 'enthusiastic' },
    { label: 'Excited', value: 'excited' },
    { label: 'Earnest', value: 'earnest' },
    { label: 'Formal', value: 'formal' },
    { label: 'Friendly', value: 'friendly' },
    { label: 'Funny', value: 'funny' },
    { label: 'Grumpy', value: 'grumpy' },
    { label: 'Humble', value: 'humble' },
    { label: 'Humorous', value: 'humorous' },
    { label: 'Informal', value: 'informal' },
    { label: 'Informative', value: 'informative' },
    { label: 'Inspirational', value: 'inspirational' },
    { label: 'Joyful', value: 'joyful' },
    { label: 'Luxurious', value: 'luxurious' },
    { label: 'Motivating', value: 'motivating' },
    { label: 'Neutral', value: 'neutral' },
    { label: 'Optimistic', value: 'optimistic' },
    { label: 'Passionate', value: 'passionate' },
    { label: 'Persuasive', value: 'persuasive' },
    { label: 'Professional', value: 'professional' },
    { label: 'Relaxed', value: 'relaxed' },
    { label: 'Sarcastic', value: 'sarcastic' },
    { label: 'Secretive', value: 'secretive' },
    { label: 'Surprised', value: 'surprised' },
    { label: 'Thoughtful', value: 'thoughtful' },
    { label: 'Urgent', value: 'urgent' },
    { label: 'Witty', value: 'witty' },
    { label: 'Worried', value: 'worried' },
  ]);
  const toneVal = (tone) => {
    const toneOptVal = toneOptions.find((toneOpt) => toneOpt.value === tone);
    if (toneOptVal) {
      return toneOptVal;
    } else {
      const toneValue = { value: tone, label: tone };
      setToneOptions((preVal) => [...preVal, toneValue]);
      return toneValue;
    }
  };

  return (
    <div className="flex flex-col">
      <div className="flex justify-between items-center flex-wrap">
        {!isUnlimited && (
          <div className="w-full mlg:hidden my-5">
            <div className="mx-auto w-64 lg:w-96 h-6">
              <CreditsBar />
            </div>
          </div>
        )}
        <h1 className="flex text-3xl xlg:text-3xl font-barlow font-medium my-5">
          Writer
        </h1>
        <div className="flex items-center">
          {!isUnlimited && (
            <div className="hidden mlg:inline-block mx-auto w-64 lg:w-96 h-6 mr-4">
              <CreditsBar />
            </div>
          )}
          <Button
            type="secondary"
            className="mr-4"
            onClick={() => {
              req.updateObject(WRITER_DETAILS, {
                step: 2,
              });
            }}
          >
            Skip intro
          </Button>
          <Button
            type="primary"
            onClick={() => formRef?.current?.handleSubmit()}
          >
            Write an intro
          </Button>
        </div>
      </div>
      <div className="lg:grid lg:grid-cols-2 gap-4">
        <Language />
        <SelectProject />
      </div>
      <div className="my-4">
        <Formik
          innerRef={formRef}
          initialValues={{
            contentType: getWriterTypeOfContent(),
            topic: getWriterTopic(),
            audience: getWriterAudience(),
            tone: toneVal(getWriterTone()),
          }}
          onSubmit={(
            { topic, contentType, tone, audience },
            { setSubmitting },
          ) => {
            if (!profileID) {
              NotificationManager.error(
                'Please select the project you will generate content for.',
              );
              return;
            }
            if (
              selectedProfile &&
              (!selectedProfile.audience?.length ||
                !selectedProfile.brand_keywords?.length ||
                !selectedProfile.description)
            ) {
              let string = '';
              string += !selectedProfile.description ? '"Description"' : '';
              string += !selectedProfile.audience.length ? ', "Audience"' : '';
              string += !selectedProfile.brand_keywords.length
                ? ', "Keywords"'
                : '';
              NotificationManager.error(
                `Please update project with ${string} or select another project`,
              );
              return;
            }
            setWriterTopic(topic);
            setWriterTypeOfContent(contentType);
            setWriterAudience(audience);
            setWriterTone(tone.value);
            setSubmitting(false);
            req
              .updateObject(WRITER_DETAILS, {
                step: 1,
                topic,
                template: contentType,
                audience,
                tone: tone.value,
              })
              .finally(() => {
                setSubmitting(false);
              });
          }}
          validationSchema={validation}
        >
          {({ values, handleSubmit, setFieldValue, errors }) => (
            <Form
              onSubmit={handleSubmit}
              className="grid grid-cols grid-cols-1 sm:grid-cols-2 gap-4"
            >
              <div className="w-full">
                <p className="text-ugray text-sm font-semibold font-source">
                  Type of Content
                </p>
                <Field
                  component={TextArea}
                  name="contentType"
                  value={values?.contentType}
                  placeholder="e.g Blog Article, Product Description, Ideas"
                  wrapperClass="mt-1"
                  rows={4}
                />
              </div>
              <div className="w-full">
                <p className="text-ugray text-sm font-semibold font-source">
                  Topic
                </p>
                <Field
                  component={TextArea}
                  name="topic"
                  value={values?.topic}
                  placeholder="e.g How to Invest in Stock Market"
                  wrapperClass="mt-1"
                  rows={4}
                />
              </div>
              <div className="w-full">
                <p className="text-ugray text-sm font-semibold font-source">
                  Target audience
                </p>
                <Field
                  component={OutlinedInputSM}
                  name="audience"
                  value={values?.audience}
                  placeholder="e.g New Investors"
                  wrapperClass="mt-1"
                />
              </div>
              <div className="w-full">
                <div className="text-ugray text-sm font-semibold font-source mb-1 flex items-center">
                  <span className="mr-1 font-source">Tone</span>
                  <Tooltip
                    text="Select or type the tone of your copy"
                    className="text-xs"
                  />
                </div>
                <CreateTableSelect
                  name="tone"
                  value={values.tone}
                  isClrearable
                  onChange={(newValue) => {
                    // Here we set the new value
                    setFieldValue('tone', newValue);
                  }}
                  options={toneOptions}
                  styles={{
                    // fixes the overlapping problem of the component
                    menu: (provided) => ({ ...provided, zIndex: 25 }),
                  }}
                />
                {errors?.tone?.value && (
                  <span className="text-red-500 text-sm font-normal">
                    {errors?.tone?.value}
                  </span>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {selectedProfile && <ManageProjects />}
      {loading && <Loader />}
    </div>
  );
}

WriterTemplates.propTypes = {};

export default WriterTemplates;
