import React from 'react';
import { clsx } from 'clsx';
import Select from 'react-select';
import { ISelectFieldProps } from './props';
import Tooltip from '../Tooltip';

function SelectField({
  className: wrapperClass,
  label,
  required,
  options,
  onChangeSelect,
  value,
  tooltip,
  classNameLabel,
  classNameTooltip,
}: ISelectFieldProps) {
  return (
    <div className={clsx('mt-4', wrapperClass)}>
      {label && (
        <label
          className={clsx(
            classNameLabel ? classNameLabel : 'text-sm font-medium text-ugray',
          )}
        >
          {`${label}${required ? '*' : ''}`}
          {tooltip && <Tooltip text={tooltip} className={classNameTooltip} />}
        </label>
      )}
      <Select
        options={options}
        onChange={(nv) => onChangeSelect(nv)}
        styles={{
          menu: (provided) => ({
            ...provided,
            zIndex: 25,
            fontFamily: `${'Source Sans Pro'}, sans-serif`,
            fontSize: '14px',
          }),
          singleValue: (provided) => ({
            ...provided,
            fontFamily: `${'Source Sans Pro'}, sans-serif`,
            color: value ? '#303030' : '#c9c9c9',
          }),
          control: (provided) => ({
            ...provided,
            borderColor: '#dedede',
            borderRadius: '.25rem',
            minHeight: '32px',
            fontFamily: 'Source Sans Pro',
            fontSize: '14px',
          }),
          input: (provided) => ({ ...provided, margin: 0, padding: 0 }),
          dropdownIndicator: (provided) => ({ ...provided, padding: '6px' }),
        }}
        value={value}
      />
    </div>
  );
}

export default SelectField;
