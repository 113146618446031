import React from 'react';

import { clsx } from 'clsx';
import PropTypes from 'prop-types';
import ContentEditable from 'react-contenteditable';

import useTemplateState from '../../../../hooks/useTemplateState';
import { editTemplate } from '../../../savedcontent/actions';

import TemplateFooter from '../footer/TemplateFooter';

function GoogleAdsTemplate({ data, onEditMode, hideFooter }) {
  const { templateValue, handleOnChange } = useTemplateState(data);
  // eslint-disable-next-line no-unsafe-optional-chaining
  const { headline, description } = templateValue?.content;
  return (
    <div
      style={{
        position: 'relative',
        marginBottom: 25,
      }}
    >
      <div
        className={clsx(
          'bg-white py-8 mb-2 shadow-md font-source',
          onEditMode ? 'px-12 ' : 'px-8 ',
        )}
        style={{
          borderRadius: '20px',
        }}
      >
        <h3
          className="text-lg xlg:text-xl font-semibold"
          style={{
            color: '#1D39C4',
          }}
        >
          <ContentEditable
            html={headline}
            onBlur={({ target: { innerHTML } }) =>
              editTemplate(innerHTML, data, 'content.headline')
            }
            onChange={(e) => handleOnChange(e.target.value, 'content.headline')}
            disabled={!onEditMode}
            spellCheck={false}
          />
        </h3>

        <div className="mt-2 text-gray-700 text-sm sm:text-md">
          <ContentEditable
            html={description}
            disabled={!onEditMode}
            onBlur={({ target: { innerHTML } }) =>
              editTemplate(innerHTML, data, 'content.description')
            }
            onChange={(e) =>
              handleOnChange(e.target.value, 'content.description')
            }
            spellCheck={false}
          />
        </div>
      </div>
      {!hideFooter && (
        <TemplateFooter
          data={templateValue}
          onEditMode={onEditMode}
          handleOnChange={handleOnChange}
          type="google-ads"
        />
      )}
    </div>
  );
}

GoogleAdsTemplate.defaultProps = {
  data: {},
  onEditMode: false,
  hideFooter: false,
};
GoogleAdsTemplate.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.objectOf(PropTypes.any),
  hideFooter: PropTypes.bool,
  onEditMode: PropTypes.bool,
};
export default React.memo(GoogleAdsTemplate);
