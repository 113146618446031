import React from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import Loader from '../../common/Loader/Loader';
import { isFramed, isNotUbTrial } from '../../helpers';
import BillingPlan from './BillingPlan';
import CreditsPackSection from './CreditsPackSection';
// import DeleteAccountSection from './DeleteAccountSection';
import RemainingCreditsSection from './RemainingCreditsSection';
// import UsageSection from './UsageSection';

function BillingTab() {
  const { isPaid, is_unlimited, payment_details: { provider } } = useSelector(
    (state: RootStateOrAny) => state['PERSONALIZE/DATA'],
  );
  const loading = useSelector((state: RootStateOrAny) => state.isLoading);
  const isComp = (provider === 'Free') && isPaid && is_unlimited;
  return isFramed() ? (
    <div className="mt-4">
      <p className="text-sm text-silver-chalice mr-4 inline-block mb-4">
        {`As an Unbounce customer you get unlimited access to Smart Copy${
          isNotUbTrial() ? '' : ' once your trial is over'
        }.`}
      </p>
    </div>
  ) : (
    <div>
      {!is_unlimited && <RemainingCreditsSection />}
      {/* <UsageSection /> */}
      {!isPaid && <CreditsPackSection />}
      {!isComp && <BillingPlan wrapperClass="my-6" /> }
      {/* <DeleteAccountSection /> */}
      {loading && <Loader />}
    </div>
  );
}

export default BillingTab;
