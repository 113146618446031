import React from 'react';

import Skeleton from 'react-loading-skeleton';

function SkeletonProductDescriptions() {
  return (
    <div
      style={{
        position: 'relative',
        marginBottom: 25,
      }}
    >
      <div
        className="bg-white p-8 my-4 shadow-md"
        style={{
          borderRadius: '16px',
        }}
      >
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-4 gap-x-6 items-center">
          <div className="w-full flex justify-center items-center bg-gray-200 h-48">
            <Skeleton />
          </div>
          <div className="w-full">
            <h3 className="text-lg text-left font-thick">
              <Skeleton width={100} />
            </h3>
            <h3 className="text-lg text-left font-thick mt-4">
              <Skeleton height={30} />
            </h3>
            <div className="text-left mt-4 text-gray-600">
              <Skeleton count={5} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SkeletonProductDescriptions;
