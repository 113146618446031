/* eslint-disable @typescript-eslint/no-empty-function */
import decode from 'jwt-decode';
// eslint-disable-next-line import/no-cycle
import { postLogout } from '../common/sendInfo';

import { AUTHENTICATION, REDIRECTED_URL } from '../modules/auth/constants';
import { isFramed, storeStorage } from '../modules/helpers';
import req from '../redux-setup';

export const updateTemplate = (
  name = '',
  id = '',
  newPayload = {},
  callback = () => {},
  idKey = 'id',
) => {
  const template = JSON.parse(localStorage.getItem(name));
  if (!template) return;
  if (template.contents?.length > 0 && Array.isArray(template.contents)) {
    const contents = [...template.contents];
    const contentIndex = contents.findIndex((content) => content[idKey] === id);
    if (contentIndex !== -1) {
      contents[contentIndex] = {
        ...contents[contentIndex],
        ...newPayload,
      };
      const newTemplate = { ...template, contents };
      storeStorage(name, newTemplate);
      callback(newTemplate);
    }
  }
};

export const unsavedTemplate = (name = '', id = '', callback = () => {}) => {
  const template = JSON.parse(localStorage.getItem(name));
  if (!template) return;
  if (template.contents?.length > 0 && Array.isArray(template.contents)) {
    const contents = [...template.contents];
    const contentIndex = contents.findIndex((content) => content.id === id);
    if (contentIndex !== -1) {
      delete contents[contentIndex].saved;
      delete contents[contentIndex].saveId;
      const newTemplate = { ...template, contents };
      storeStorage(name, newTemplate);
      callback(newTemplate);
    }
  }
};

export const setToken = (token) => localStorage.setItem('tkn', token);

export const logout = () => {
  postLogout()
    .then((response) => response.json())
    .then(() => {
      // if (result.code === 200) {
      //   // send a message to successful logged out
      // }
      req.reset();
      req.set(AUTHENTICATION, false);
      if (isFramed()) {
        const ube = encodeURIComponent(sessionStorage.getItem('ube') || '');
        const ubi = sessionStorage.getItem('ubi') || '';
        const ubt = sessionStorage.getItem('ubt') || '';
        window.location = `/unbounce/page?email=${ube}&trial=${ubt}&ubid=${ubi}`;
        localStorage.clear();
        sessionStorage.clear();
      } else {
        localStorage.clear();
        sessionStorage.clear();
      }
    })
    .catch(() => {
      req.reset();
      req.set(AUTHENTICATION, false);
      if (isFramed()) {
        const ube = encodeURIComponent(sessionStorage.getItem('ube') || '');
        const ubi = sessionStorage.getItem('ubi') || '';
        const ubt = sessionStorage.getItem('ubt') || '';
        localStorage.clear();
        sessionStorage.clear();
        window.location = `/unbounce/page?email=${ube}&trial=${ubt}&ubid=${ubi}`;
      } else {
        sessionStorage.clear();
        localStorage.clear();
      }
    });
};

export const getToken = () => localStorage.getItem('tkn') || null;

// export const setTokenExpiration = token => {
//   const expiry = decode(token).exp;
//   const date = new Date(expiry * 1000);
//   return date;
// };

export const checkAuth = () => {
  const token = getToken();
  let auth = true;
  if (!token) auth = false;

  if (auth) {
    const expiry = decode(token).exp;
    const stampNow = Date.now() / 1000;
    if (stampNow > expiry) auth = false;
  }

  if (!auth && window.location.pathname !== '/') {
    req.set(REDIRECTED_URL, window.location.pathname);
  }
  return auth;
};
