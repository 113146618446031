import React from 'react';

import { v4 } from 'uuid';

// eslint-disable-next-line import/no-cycle
import PageLayout from '../../partials/pagelayout/containers/PageLayout';
import ProdDescriptionTemplate from '../components/templates/ProdDescriptionTemplate';

export default function ProductionDescriptions() {
  return (
    <PageLayout template="product-descriptions" title="Product Description">
      {({ contents }) => (
        <>
          {React.Children.toArray(
            contents.map((productdescription) => (
              <ProdDescriptionTemplate key={v4()} data={productdescription} />
            )),
          )}
        </>
      )}
    </PageLayout>
  );
}
