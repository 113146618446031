import React from 'react';

function EmailSkeleton() {
  return (
    <div className="mb-4">
      <div className="border border-gallery bg-white py-4 px-6 mb-3 shadow-md font-source rounded-lg">
        <div className="font-semibold text-xl">
          <div role="status" className="w-full animate-pulse">
            <div className="h-2.5 bg-gray-300 rounded-full max-w-[90%] mb-2.5" />
            <div className="flex items-center mt-4">
              <svg
                className="mr-2 w-10 h-10 text-gray-200"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                  clipRule="evenodd"
                />
              </svg>
              <div className="w-20 h-2.5 bg-gray-200 rounded-full mr-3" />
              <div className="w-24 h-2 bg-gray-200 rounded-full" />
            </div>
            <div className="ml-12 w-12 h-2 bg-gray-200 rounded-full mb-4" />
            <div className="ml-12">
              <div className="h-2 bg-gray-200 rounded-full max-w-[98%] mb-2.5" />
              <div className="h-2 bg-gray-200 rounded-full mb-2.5" />
              <div className="h-2 bg-gray-200 rounded-full max-w-[95%] mb-2.5" />
              <div className="h-2 bg-gray-200 rounded-full max-w-[90%] mb-2.5" />
              <div className="h-2 bg-gray-200 rounded-full mb-2.5" />
              <div className="h-2 bg-gray-200 rounded-full max-w-[88%] mb-2.5" />
              <br />
              <div className="h-2 bg-gray-200 rounded-full mb-2.5" />
              <div className="h-2 bg-gray-200 rounded-full max-w-[98%] mb-2.5" />
              <div className="h-2 bg-gray-200 rounded-full max-w-[93%] mb-2.5" />
              <div className="h-2 bg-gray-200 rounded-full mb-2.5" />
              <div className="h-2 bg-gray-200 rounded-full max-w-[85%] mb-2.5" />
              <div className="h-2 bg-gray-200 rounded-full max-w-[80%] mb-2.5" />
              <br />
              <div className="h-2 bg-gray-200 rounded-full w-20 mb-2.5" />
              <div className="h-2 bg-gray-200 rounded-full w-16 mb-2.5" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmailSkeleton;
