import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../../../../common/Button';
import logo from '../../../../assets/img/UBSmartCopy-short.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleDollarToSlot } from '@fortawesome/pro-light-svg-icons';
import { getQueryParams } from '../../../helpers';
import { clsx } from 'clsx';

function CreditsSuccess() {
  const history = useHistory();
  const credits = getQueryParams('credits');
  // const payment_intent = getQueryParams('payment_intent');
  const [showContinue, setShowContinue] = useState(false);
  setTimeout(() => {
    setShowContinue(true);
  }, 3500);
  return (
    <div className="w-full flex flex-col items-center justify-center min-h-screen min-w-screen">
      <img src={logo} className="h-10 mb-20" />
      <FontAwesomeIcon
        className="rounded-lg h-36 w-36 mr-2 text-shamrock"
        icon={faCircleDollarToSlot}
      />
      <h1
        className={clsx(
          'font-barlow text-mine-shaft text-xl font-medium my-4',
          !showContinue && 'animate-pulse',
        )}
      >
        Your payment has been received
      </h1>
      <h2
        className={clsx(
          'text-shamrock font-barlow font-semibold text-4xl',
          !showContinue && 'animate-pulse',
        )}
      >
        +<span className="text-mine-shaft font-barlow">{` ${credits} `}</span>
        Credits
      </h2>
      {showContinue && (
        <div className="px-0 md:px-20 mt-6">
          <Button
            className="mt-4"
            type="primary"
            onClick={() => history.push('/browse')}
          >
            Continue Writing
          </Button>
        </div>
      )}
    </div>
  );
}

export default CreditsSuccess;
