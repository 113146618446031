import SkeletonColdEmail from './SkeletonColdEmail';
import SkeletonContentExpander from './SkeletonContentExpander';
import SkeletonFacebookAds from './SkeletonFacebookAds';
import SkeletonGoogleAds from './SkeletonGoogleAds';
import SkeletonLandingPages from './SkeletonLandingPages';
import SkeletonProductDescriptions from './SkeletonProductDescriptions';
import SkeletonTaglines from './SkeletonTaglines';

export default {
  'google-ads': SkeletonGoogleAds,
  'landing-pages': SkeletonLandingPages,
  taglines: SkeletonTaglines,
  'product-descriptions': SkeletonProductDescriptions,
  'facebook-ads': SkeletonFacebookAds,
  'content-expander': SkeletonContentExpander,
  'header-description': SkeletonContentExpander,
  benefits: SkeletonContentExpander,
  features: SkeletonContentExpander,
  'blog-ideas': SkeletonTaglines,
  'tiktok-ideas': SkeletonTaglines,
  remixer: SkeletonContentExpander,
  'cold-email': SkeletonColdEmail,
  'vc-email': SkeletonColdEmail,
  'angel-email': SkeletonColdEmail,
  default: SkeletonTaglines,
};
